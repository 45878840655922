import React, { FC, ReactNode, Ref, useRef } from 'react';
import { Popover } from '@material-ui/core';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { PopoverActions, PopoverProps } from '@material-ui/core/Popover/Popover';

type Props = {
  InputValue: ReactNode;
  isOpen: boolean;
  editable?: boolean;
  onOpen: () => void;
  onClose: () => void;
  formClassName?: string;
  className?: string;
  inputClassName?: string;
  inputOpenClassName?: string;
  updatePositionRef?: Ref<PopoverActions>;
} & Partial<PopoverProps>;

export const PopoverValue: FC<Props> = ({
  InputValue,
  children,
  isOpen,
  onOpen,
  onClose,
  editable,
  formClassName,
  className,
  inputClassName,
  inputOpenClassName,
  marginThreshold,
  updatePositionRef,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={anchorRef}
        onClick={editable ? onOpen : undefined}
        className={clsx(styles.input, inputClassName, {
          [inputOpenClassName || '']: isOpen,
          [styles.inputOpen]: isOpen && !inputOpenClassName,
          [styles.editable]: editable,
        })}
      >
        {InputValue}
      </div>
      <Popover
        anchorEl={anchorRef.current}
        onClose={onClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={clsx(className)}
        marginThreshold={marginThreshold}
        action={updatePositionRef}
      >
        <div className={clsx('p-16', styles.content, formClassName)}>{children}</div>
      </Popover>
    </>
  );
};
