import React from 'react';

interface InfoIconProps {
  className?: string;
}

export const InfoIcon = ({ className }: InfoIconProps) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10Z"
      fill="#85929A"
    />
    <path
      d="M10 8.5C10.4142 8.5 10.75 8.83579 10.75 9.25V13.75C10.75 14.1642 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.1642 9.25 13.75V9.25C9.25 8.83579 9.58579 8.5 10 8.5Z"
      fill="#85929A"
    />
    <path
      d="M11.125 6.625C11.125 7.24632 10.6213 7.75 10 7.75C9.37868 7.75 8.875 7.24632 8.875 6.625C8.875 6.00368 9.37868 5.5 10 5.5C10.6213 5.5 11.125 6.00368 11.125 6.625Z"
      fill="#85929A"
    />
  </svg>
);
