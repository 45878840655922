import {
  ResourcePlanningAssignmentMemberDataFragment,
  ResourcePlanningProjectAssignmentDataFragment,
} from 'generated/types';

export type MemberAssignment = ResourcePlanningAssignmentMemberDataFragment & {
  assignments: ResourcePlanningProjectAssignmentDataFragment[];
  role: string;
};

export type GroupedMembersType = {
  [key: string]: MemberAssignment[];
};

export const getMembersGroupedByRole = (members: MemberAssignment[] | undefined): GroupedMembersType => {
  if (members) {
    return members?.reduce((acc, rec) => {
      if (acc[rec.role]) {
        return { ...acc, [rec.role]: [...acc[rec.role], rec] };
      }
      return { ...acc, [rec.role]: [rec] };
    }, {} as GroupedMembersType);
  }
  return {};
};
