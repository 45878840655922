import React, { FC, useMemo } from 'react';
import { eachDayOfInterval, isAfter, isWeekend } from 'date-fns';
import { getAssignmentDate, getAssignmentFromRequest } from 'views/ResourcePlanning/utils';
import { getTotalWorkingHours } from 'utils';
import clsx from 'clsx';
import { DEFAULT_CAPACITY } from 'consts';
import { ResourcePlanningProjectsDataFragment } from 'generated/types';

import styles from './styles.module.scss';

interface Props {
  memberCapacity?: number | null;
  projects: ResourcePlanningProjectsDataFragment[];
  start: Date;
  end: Date;
}

export const CapacityMarker: FC<Props> = ({ memberCapacity, projects, start, end }) => {
  const getTotalAssignmentsHours = getTotalWorkingHours([]);
  const capacity = useMemo(() => (memberCapacity && memberCapacity > 0 ? memberCapacity : DEFAULT_CAPACITY), [
    memberCapacity,
  ]);

  const workingHours = useMemo(
    () => Number(capacity) * eachDayOfInterval({ start, end }).filter((date) => !isWeekend(date)).length,
    [start, end],
  );

  const totalHours = useMemo(
    () =>
      projects?.reduce((acc, item) => {
        const assignmentData = [
          ...(item.assignment || []),
          ...((item.requests || [])?.map(getAssignmentFromRequest) || []),
        ];
        const assignmentsHours = assignmentData?.reduce((acc, assignment) => {
          const startDate = getAssignmentDate(assignment?.startDate);
          const endDate = getAssignmentDate(assignment?.endDate);
          const assignmentHours = +getTotalAssignmentsHours(
            isAfter(startDate, start) ? startDate : start,
            isAfter(endDate, end) ? end : endDate,
            [],
            assignment?.allocationTimeAmount || 0,
          );

          return acc + assignmentHours;
        }, 0);

        return acc + assignmentsHours;
      }, 0),
    [projects],
  );

  return (
    <div className={styles.capacityBox}>
      <div
        className={clsx(styles.capacityMarker, {
          [styles.capacityMarkerFullTime]: workingHours === totalHours,
          [styles.capacityMarkerOvertime]: workingHours < totalHours,
          [styles.capacityMarkerPartTime]: workingHours > totalHours,
        })}
      />
    </div>
  );
};
