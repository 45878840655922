import { eachDayOfInterval, isAfter, isBefore, isWeekend } from 'date-fns';

import { Leave } from 'generated/types';
import { addTimezoneOffset } from './date';

export const getTotalWorkingHours = (holidays: Array<string>) => (
  startDate: string | Date,
  endDate: string | Date,
  memberLeaves: Pick<Leave, 'id' | 'memberId' | 'startDate' | 'endDate'>[] | undefined,
  allocation: number | string,
): string | number => {
  if (
    isAfter(new Date(startDate), new Date(endDate)) ||
    isBefore(new Date(endDate), new Date(startDate)) ||
    !startDate
  ) {
    return 0;
  }

  const leaves =
    memberLeaves?.reduce<string[]>((acc, rec) => {
      const diff = eachDayOfInterval({
        start: new Date(rec.startDate),
        end: new Date(rec.endDate),
      }).map((item) => addTimezoneOffset(item).toISOString());
      return [...acc, ...diff];
    }, []) || [];

  const unavailableDays = [...leaves, ...holidays];

  const workingDays = eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  }).filter((date) => !isWeekend(date) && unavailableDays.indexOf(addTimezoneOffset(date).toISOString()) === -1);

  const total = workingDays.length * +allocation;

  return total % 1 === 0 ? total : total.toFixed(2);
};
