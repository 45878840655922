import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { SearchIcon } from 'icons';

import styles from './styles.module.scss';
import Button from '@material-ui/core/Button';

interface SearchFieldProps {
  value?: string;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  onReset?: () => void;
  className?: string;
  fixed?: boolean;
  placeholder?: string;
}

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: '36px',
      '& .MuiInputBase-input': {
        fontSize: '14px',
        '&::placeholder': { color: '#85929A' },
      },
      '& fieldset': {
        borderColor: '#ebedee',
      },
      '&:hover ': {
        backgroundColor: '#ebedee',
        '& fieldset': {
          borderColor: '#ebedee',
        },
      },
      '&.Mui-focused': {
        backgroundColor: '#ffffff',
        '&:hover': {
          borderColor: '#050709',
          backgroundColor: '#ffffff',

          '& fieldset': {
            borderColor: '#050709',
          },
        },
        '& fieldset': {
          borderColor: '#050709',
        },
      },
    },
  },
});

export const MobileSearch = ({ value = '', onChange, onReset, onBlur, className, placeholder }: SearchFieldProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const handleSearchFieldChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value);

  const handleEnterPress = ({ target, key }: KeyboardEvent) => key === 'Enter' && (target as HTMLInputElement).blur();

  const resetSearch = () => {
    onChange(undefined);
    onReset?.();
  };

  const isShowClearButton = isFocused || value;

  return (
    <div className={clsx(isShowClearButton ? styles.boxFocused : styles.box, className)}>
      <TextField
        onChange={handleSearchFieldChange}
        onBlur={() => {
          onBlur?.();
          setIsFocused(false);
        }}
        onFocus={() => setIsFocused(true)}
        value={value}
        fullWidth={false}
        placeholder={placeholder ?? t('forms.search')}
        className={clsx(classes.root, isShowClearButton && 'mr-8')}
        onKeyDown={handleEnterPress}
        id="resizable-search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={styles.inputIcon} />
            </InputAdornment>
          ),
        }}
      />
      <Button onClick={resetSearch} className={clsx(styles.button, !isShowClearButton && styles.hiddenButton)}>
        {t('common.filter.clear')}
      </Button>
    </div>
  );
};
