import React from 'react';
import clsx from 'clsx';

import styles from './UnauthFormLabel.module.scss';

interface UnauthFormHeadingProps {
  heading: string | JSX.Element;
  children?: false | JSX.Element;
}

export const UnauthFormHeading = ({ heading, children }: UnauthFormHeadingProps) => {
  return (
    <div className={clsx('text-25', 'py-16', 'weight-600', 'flex', 'justify-content-center', styles.wrapper)}>
      {children}
      {heading}
    </div>
  );
};
