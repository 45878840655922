import {
  add,
  addQuarters,
  addYears,
  differenceInCalendarDays,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  getWeek,
  isSameMonth,
  isSameYear,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  sub,
  subQuarters,
  subYears,
} from 'date-fns';

import { TimelinePeriod } from 'contexts';
import { QUARTER_PERIOD, YEAR_PERIOD } from 'consts';

const FIVE_WEEKS_DAYS = 35;
const ONE_WEEKS_DAYS = 7;

export const getNextFourWeeks = (date: Date) => {
  const weekStartDate = startOfWeek(date, { weekStartsOn: 1 });

  return new Array<Date>(FIVE_WEEKS_DAYS)
    .fill(weekStartDate)
    .map((startDate, index) => add(startDate, { days: index }));
};

export const getPreviousPeriodDate = (date: Date) => {
  return sub(date, { weeks: 5 });
};

export const getNextPeriodDate = (date: Date) => {
  return add(date, { days: FIVE_WEEKS_DAYS });
};

export const getPreviousWeekPeriodDate = (date: Date) => {
  return sub(date, { weeks: 1 });
};

export const getNextWeekPeriodDate = (date: Date) => {
  return add(date, { days: ONE_WEEKS_DAYS });
};

export const getEndDateOfPreviousPeriod = (date: Date) => {
  return endOfWeek(sub(date, { weeks: 1 }), { weekStartsOn: 1 });
};

export const getStartDateOfNextPeriod = (date: Date) => {
  return startOfWeek(add(date, { days: FIVE_WEEKS_DAYS }), { weekStartsOn: 1 });
};

export const getWeekPeriodLabel = (date: Date): string => {
  const startPeriodDate = startOfWeek(date, { weekStartsOn: 1 });
  const endPeriodDate = add(date, { days: FIVE_WEEKS_DAYS - 1 });
  const isSameMonthPeriod = isSameMonth(startPeriodDate, endPeriodDate);

  if (isSameMonthPeriod) {
    return format(startPeriodDate, 'MMM yyyy');
  }

  const isSameYearPeriod = isSameYear(startPeriodDate, endPeriodDate);
  if (isSameYearPeriod) {
    return `${format(startPeriodDate, 'MMM')} - ${format(endPeriodDate, 'MMM')}, ${format(startPeriodDate, 'yyyy')}`;
  }

  return `${format(startPeriodDate, 'MMM')}, ${format(startPeriodDate, 'yyyy')} - ${format(
    endPeriodDate,
    'MMM',
  )}, ${format(endPeriodDate, 'yyyy')}`;
};

export const getStartEndPeriodDates = (date: Date, timelinePeriod: TimelinePeriod): [Date, Date] => {
  switch (timelinePeriod) {
    case QUARTER_PERIOD: {
      return [
        startOfWeek(startOfQuarter(date), { weekStartsOn: 1 }),
        endOfWeek(endOfQuarter(date), { weekStartsOn: 1 }),
      ];
    }
    case YEAR_PERIOD: {
      return [startOfWeek(startOfYear(date), { weekStartsOn: 1 }), endOfWeek(endOfYear(date), { weekStartsOn: 1 })];
    }
    default: {
      return [startOfWeek(date, { weekStartsOn: 1 }), add(date, { days: FIVE_WEEKS_DAYS - 1 })];
    }
  }
};

export const getDaysGroupedByBusinessWeek = (days: Date[]): { [key: string]: Date[] } => {
  return days.reduce((acc, day) => {
    const weekYearIndex = `w${getWeek(day, { weekStartsOn: 1 })}`;

    if (acc[weekYearIndex]) {
      return { ...acc, [weekYearIndex]: [...acc[weekYearIndex], day] };
    }

    return { ...acc, [weekYearIndex]: [day] };
  }, {} as { [key: string]: Date[] });
};

export const getCurrentQuarter = (date: Date): Date[] => {
  const quarterSize =
    differenceInCalendarDays(
      endOfWeek(endOfQuarter(date), { weekStartsOn: 1 }),
      startOfWeek(startOfQuarter(date), { weekStartsOn: 1 }),
    ) + 1;

  return new Array<Date>(quarterSize)
    .fill(startOfWeek(startOfQuarter(date), { weekStartsOn: 1 }))
    .map((startDate, index) => add(startDate, { days: index }));
};

export const getCurrentYear = (date: Date): Date[] => {
  const yearSize =
    differenceInCalendarDays(
      endOfWeek(endOfYear(date), { weekStartsOn: 1 }),
      startOfWeek(startOfYear(date), { weekStartsOn: 1 }),
    ) + 1;

  return new Array<Date>(yearSize)
    .fill(startOfWeek(startOfYear(date), { weekStartsOn: 1 }))
    .map((startDate, index) => add(startDate, { days: index }));
};

export const getCurrentWeekYearIndex = (currentDate: Date): string => `w${getWeek(currentDate, { weekStartsOn: 1 })}`;

export const getPrevQuarter = (date: Date) => {
  return subQuarters(date, 1);
};

export const getNextQuarter = (date: Date) => {
  return addQuarters(date, 1);
};

export const getPrevYear = (date: Date) => {
  return subYears(date, 1);
};

export const getNextYear = (date: Date) => {
  return addYears(date, 1);
};
