import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState, RightDrawer } from 'components';
import { GetRouteProps, ModalModeEnum, ModuleName, ScreenName } from 'types';
import { ActionsType, AvailableIntegrationDataFragment, IntegrationStatus } from 'generated/types';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, usePermissions, useTrackScreenView } from 'hooks';
import {
  CompanyIntegrationDataFragmentDoc,
  useAvailableIntegrationsQuery,
  useCompanyIntegrationsQuery,
  useDeleteCompanyIntegrationMutation,
  useEditCompanyIntegrationStatusMutation,
  useManualCompanyIntegrationSyncMutation,
} from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { IntegrationItem } from './components/IntegrationItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IntegrationsRoute } from './index';
import { CreateIntegration } from './components/CreateIntegration';
import { localStorageManager } from 'services';
import { IntegrationsEnum } from './Integrations.enum';
import { EditIntegration } from './components/EditIntegration';
import { toast } from 'react-toastify';
import { Reference } from '@apollo/client';

import styles from './styles.module.scss';

type Props = GetRouteProps<typeof IntegrationsRoute>;
const ZOHO_INTEGRATION_ID = 'ZOHO_INTEGRATION_ID';

const Integrations: FC<Props> = ({
  history: { push },
  match: {
    params: { mode, integrationId },
    query: { code, error },
    query,
  },
  link,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  useTrackScreenView(ModuleName.settings, ScreenName.integrations);

  const zohoIntegrationId = localStorageManager.getItem(ZOHO_INTEGRATION_ID);
  const {
    data: { companyIntegrations = [] } = {},
    loading: isCompanyIntegrationsLoading,
    refetch,
  } = useCompanyIntegrationsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
    skip: !hasAccess(ActionsType.OtherSettings),
  });

  const {
    data: { availableIntegrations = [] } = {},
    loading: isAvailableIntegrationsLoading,
  } = useAvailableIntegrationsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
    skip: !hasAccess(ActionsType.OtherSettings),
  });

  const integrations = useMemo(() => {
    return availableIntegrations.map((item) => ({
      ...item,
      integration: companyIntegrations.find(({ integrationId }) => integrationId === item.id),
    }));
  }, [isAvailableIntegrationsLoading, companyIntegrations]);

  const [deleteCompanyIntegration] = useDeleteCompanyIntegrationMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteCompanyIntegration) });
        cache.gc();
      }
    },
  });

  const [editIntegrationStatus] = useEditCompanyIntegrationStatusMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted({ editCompanyIntegrationStatus }) {
      toast.success(
        editCompanyIntegrationStatus.status === IntegrationStatus.Connected
          ? t('settings.integrations.notifications.connected', {
              name: editCompanyIntegrationStatus?.integration || '',
            })
          : t('settings.integrations.notifications.disconnected', {
              name: editCompanyIntegrationStatus?.integration || '',
            }),
      );
    },
    update(cache, { data }) {
      if (!data?.editCompanyIntegrationStatus) return;

      const updatedIntegrationRef = cache.writeFragment({
        data: data.editCompanyIntegrationStatus,
        fragment: CompanyIntegrationDataFragmentDoc,
      });

      cache.modify({
        id: cache.identify({ __typename: 'CompanyIntegration', id: data.editCompanyIntegrationStatus.id }),
        fields: {
          companyIntegrations(items: Reference[] = []) {
            return items.map((itemRef) =>
              itemRef.__ref === updatedIntegrationRef?.__ref ? updatedIntegrationRef : itemRef,
            );
          },
        },
      });
    },
  });

  const [integrationSync] = useManualCompanyIntegrationSyncMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      refetch();
    },
  });

  const onChangeIntegrationStatus = useCallback(async (id: string, status: IntegrationStatus) => {
    await editIntegrationStatus({
      variables: {
        companyId: userData!.company.id,
        companyIntegrationId: id,
        status,
      },
    });

    if (status === IntegrationStatus.Disconnected) {
      deleteCompanyIntegration({
        variables: {
          companyId: userData!.company.id,
          companyIntegrationId: id,
        },
      });
    }
  }, []);

  const onSyncIntegration = (id: string) => {
    integrationSync({
      variables: {
        companyId: userData!.company.id,
        companyIntegrationId: id,
      },
    });
  };

  const onOpenEditDrawer = (id: string) => push(link({ ...query, integrationId: id, mode: ModalModeEnum.edit }));

  const onOpenCreateDrawer = (item: AvailableIntegrationDataFragment) => {
    if (item.name === IntegrationsEnum.zohoPeople) {
      localStorageManager.setItem(ZOHO_INTEGRATION_ID, item.id);
      window.open(process.env.REACT_APP_ZOHO_URL);
      return;
    }

    localStorageManager.setItem(ZOHO_INTEGRATION_ID, '');
    push(link({ ...query, integrationId: item.id, mode: ModalModeEnum.create }));
  };

  const onCloseDrawer = () => {
    push(link({ integrationId: undefined, mode: undefined }));
    localStorageManager.setItem(ZOHO_INTEGRATION_ID, '');
  };

  if (!hasAccess(ActionsType.OtherSettings)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  if (isPermissionsLoading || isAvailableIntegrationsLoading || isCompanyIntegrationsLoading) {
    return <CircularProgress size={64} style={{ color: '#24343D' }} />;
  }

  const currentIntegration =
    integrationId || zohoIntegrationId
      ? integrations.find(({ id }) => id === integrationId || id === zohoIntegrationId)
      : undefined;

  return (
    <div className={styles.container}>
      {integrations.map((item) => (
        <IntegrationItem
          key={item.id}
          item={item}
          onChangeStatus={onChangeIntegrationStatus}
          onConnect={() => onOpenCreateDrawer(item)}
          onEdit={() => onOpenEditDrawer(item.id)}
          onSync={() => onSyncIntegration(item?.integration?.id || '')}
        />
      ))}

      <RightDrawer
        direction="right"
        open={mode === ModalModeEnum.edit}
        onClose={onCloseDrawer}
        title={t('settings.integrations.form.editOptions')}
      >
        <EditIntegration item={currentIntegration} onCancel={onCloseDrawer} />
      </RightDrawer>

      <RightDrawer
        direction="right"
        open={
          zohoIntegrationId ? !!(code && !currentIntegration?.integration) : mode === ModalModeEnum.create && !error
        }
        onClose={onCloseDrawer}
        title={t('settings.integrations.form.connect', { name: currentIntegration?.name || '' })}
      >
        <CreateIntegration integration={currentIntegration} onCancel={onCloseDrawer} />
      </RightDrawer>
    </div>
  );
};

export default Integrations;
