import React, { memo, useCallback, useMemo } from 'react';
import { endOfDay, isWeekend, isWithinInterval, startOfDay } from 'date-fns';
import type { Object as ObjectType } from 'ts-toolbelt';
import { OptionalDeep } from 'ts-toolbelt/out/Object/Optional';

import { useTimelineContext } from 'contexts';
import { useTimelinePeriodDays } from 'hooks';
import { DEFAULT_CAPACITY, MONTH_PERIOD, PER_DAY, QUARTER_PERIOD, YEAR_PERIOD } from 'consts';
import { Leave, ResourcePlanningMember } from 'generated/types';
import { getDaysGroupedByBusinessWeek } from 'views/ResourcePlanning/utils/days';

import { PerWeekView } from './PerWeekView';
import { PerDayView } from './PerDayView';
import {
  AvailabilityPerWeek,
  getAssignmentDate,
  getAssignmentFromRequest,
  getAvailabilityByWeek,
  isResourcePlanningMembers,
  MemberAssignment,
  useHolidays,
} from 'views/ResourcePlanning/utils';

import styles from './styles.module.scss';

export type AssignmentPeriod = {
  start: Date;
  end: Date;
};

export type TimelineAvailability = {
  start?: Date;
  amount?: number;
  hours?: number;
  leave?: boolean;
  leaveId?: string;
  assignmentPeriod?: AssignmentPeriod;
  assignedAllocation?: number;
};

type Props = {
  date: Date;
  member?: ObjectType.Partial<ResourcePlanningMember, 'deep'> | MemberAssignment;
  onLeave?: (id: string) => void;
};

export const Availability = memo<Props>(({ date, member, onLeave }) => {
  const { collapsedWeekends, hoursPeriod, timelinePeriod } = useTimelineContext();
  const { days, monthDays } = useTimelinePeriodDays(date);
  const holidays = useHolidays();

  const isQuarterView = timelinePeriod === QUARTER_PERIOD;
  const isMonthView = timelinePeriod === MONTH_PERIOD;
  const isYearView = timelinePeriod === YEAR_PERIOD;
  const isDayView = hoursPeriod === PER_DAY;

  const memberProjects = useMemo(() => {
    return member && 'projects' in member ? member.projects : [];
  }, [member]);

  const memberCapacity = useMemo(() => {
    return member?.capacity && member?.capacity > 0 ? member?.capacity : DEFAULT_CAPACITY;
  }, [member]);

  const memberLeaves = useMemo(() => {
    return member ? member.member_leave : [];
  }, [member]);

  const memberAssignments = isResourcePlanningMembers(member)
    ? member?.projects?.reduce((acc, project) => {
        const requestedAssignments = (project?.requests || [])?.map(getAssignmentFromRequest);

        return acc.concat(project?.assignment, requestedAssignments);
      }, [] as Array<any>)
    : member?.assignments;

  const assignments = useMemo(() => {
    return memberAssignments?.map((assignment) => ({
      start: getAssignmentDate(assignment.startDate),
      end: getAssignmentDate(assignment.endDate),
      hours: assignment.allocationTimeAmount,
    }));
  }, [memberAssignments]);

  const leaves = useMemo(() => {
    return member?.member_leave?.map((leave: OptionalDeep<Leave> | undefined) => ({
      start: startOfDay(getAssignmentDate(leave?.startDate)),
      end: endOfDay(getAssignmentDate(leave?.endDate)),
      id: leave?.id,
    }));
  }, [member?.member_leave]);

  const getAvailability = useCallback(
    (days: Date[]) => {
      return days.reduce<TimelineAvailability[]>((acc, day) => {
        if (!assignments) return acc;
        const { works, hours, assignmentPeriod } = assignments?.reduce<{
          hours: number;
          works: boolean;
          assignmentPeriod?: AssignmentPeriod;
        }>(
          (acc, { end, start, hours }) => {
            if (isWithinInterval(day, { start, end })) {
              return { ...acc, works: true, hours: acc.hours + hours, assignmentPeriod: { start, end } };
            }

            return acc;
          },
          { hours: 0, works: false, assignmentPeriod: undefined },
        );

        const leave = leaves?.find(({ start, end }) => isWithinInterval(day, { start, end }));

        if (isWeekend(day) || holidays.has(day.toString()) || (!works && !leave)) {
          const last = acc[acc.length - 1];
          return last?.amount
            ? acc.slice(0, acc.length - 1).concat({ ...last, amount: (last?.amount ?? 0) + 1 })
            : acc.concat({ start: day, amount: 1 });
        }

        if (leave) {
          return acc.concat({
            start: day,
            leave: true,
            leaveId: leave.id,
            assignedAllocation: hours,
            assignmentPeriod,
          });
        }

        if (works) {
          return acc.concat({ start: day, hours, assignmentPeriod });
        }

        return acc;
      }, []);
    },
    [member],
  );

  const availabilityPerWeek = useMemo(() => {
    const weeks = getDaysGroupedByBusinessWeek(days);

    return Object.keys(weeks).reduce<AvailabilityPerWeek>((acc, item) => {
      const availability = getAvailability(weeks[item]);
      const availabilityByWeek = getAvailabilityByWeek(availability, holidays);
      const disabledWeekend = collapsedWeekends || isQuarterView || isYearView;
      const weekend = disabledWeekend ? {} : { [`weekendAfter_${item}`]: { weekend: true } };

      return { ...acc, [item]: availabilityByWeek, ...weekend };
    }, {});
  }, [collapsedWeekends, timelinePeriod, memberProjects, memberLeaves, date]);

  const availabilityPerDay = useMemo(() => {
    return getAvailability(monthDays);
  }, [timelinePeriod, date, monthDays, member]);

  return (
    <section className={styles.availability}>
      {isDayView && isMonthView ? (
        <PerDayView availability={availabilityPerDay} memberCapacity={memberCapacity} onLeave={onLeave} />
      ) : (
        <PerWeekView
          availabilityPerWeek={availabilityPerWeek}
          memberCapacity={memberCapacity}
          days={days}
          isYearView={isYearView}
        />
      )}
    </section>
  );
});

Availability.displayName = 'Timeline.Availability';
