import { TimelinePeriod } from 'contexts';
import { QUARTER_PERIOD, YEAR_PERIOD } from 'consts';

const MONTH_MIN_COLUMN_WIDTH = 32;
const QUARTER_MIN_COLUMN_WIDTH = 12;
const YEAR_MIN_COLUMN_WIDTH = 3;

export const getMinColumnWidth = (timelinePeriod: TimelinePeriod) => {
  switch (timelinePeriod) {
    case QUARTER_PERIOD: {
      return QUARTER_MIN_COLUMN_WIDTH;
    }
    case YEAR_PERIOD: {
      return YEAR_MIN_COLUMN_WIDTH;
    }
    default: {
      return MONTH_MIN_COLUMN_WIDTH;
    }
  }
};
