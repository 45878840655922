import React from 'react';
import { Link } from '@material-ui/core';

import styles from './CustomToastWithLink.module.scss';

export const CustomToastWithLink = (link: string, linkTitle: string, text: string) => (
  <div>
    <Link href={link} className={styles.projectLink}>
      {linkTitle}
    </Link>
    &nbsp;
    {text}
  </div>
);
