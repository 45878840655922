import React, { FC } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MuiTextfield, { TextFieldProps } from '@material-ui/core/TextField';
import { FieldInputProps } from 'formik';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { DownTriangle } from 'icons';
import { DEFAULT } from 'consts';

import styles from './Select.module.scss';
import { DropdownOption } from 'types';
import { CheckIcon } from 'icons';

export interface SelectProps {
  name: string;
  defaultText?: string;
  className?: string;
  selectMenuTitle?: string;
  disabled?: boolean;
  showEmptyOption?: boolean;
  hideIconComponent?: boolean;
  emptyChildrenOptionLabel?: string;
  menuItemClassName?: string;
  hideCheckIcon?: boolean;
  options: (DropdownOption & {
    additionalText?: string;
  })[];
}

type Props = Partial<FieldInputProps<string>> &
  Omit<SelectProps, 'name'> &
  TextFieldProps & {
    error?: boolean | undefined;
    helperText?: string | false | undefined;
  };

export const Select: FC<Props> = ({
  defaultText,
  value,
  showEmptyOption,
  emptyChildrenOptionLabel,
  selectMenuTitle,
  options,
  InputProps,
  SelectProps,
  hideIconComponent,
  classes,
  menuItemClassName,
  hideCheckIcon,
  ...rest
}) => {
  const { t } = useTranslation();

  const mappedVal = value || DEFAULT;

  return (
    // Wrong types. Need to double-check after lib updates
    // @ts-ignore
    <MuiTextfield
      select
      variant="outlined"
      value={mappedVal}
      classes={{
        root: styles.container,
        ...classes,
      }}
      InputProps={{
        ...InputProps,
        classes: {
          root: styles.inputRoot,
          notchedOutline: styles.notchedOutline,
          ...InputProps?.classes,
        },
        notched: false,
      }}
      SelectProps={{
        ...SelectProps,
        classes: {
          root: mappedVal === DEFAULT ? styles.defaultText : '',
          select: clsx(styles.select, hideIconComponent && styles.selectWithoutIcon),
          ...SelectProps?.classes,
        },
        IconComponent: function icon() {
          return hideIconComponent ? null : <DownTriangle className="MuiSelect-icon" />;
        },
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          className: styles.menu,
        },
      }}
      {...rest}
    >
      <MenuItem value="" disabled className={clsx(selectMenuTitle ? styles.selectMenuTitle : 'd-none')}>
        {selectMenuTitle}
      </MenuItem>
      <MenuItem
        value={DEFAULT}
        disabled={!showEmptyOption}
        className={clsx(showEmptyOption ? 'text-secondary' : 'd-none')}
      >
        {defaultText}
      </MenuItem>
      {options.length ? (
        options.map((option) => (
          <MenuItem key={option.id} value={option.id} className={clsx(styles.menuItem, menuItemClassName)}>
            {option.name}
            {option.additionalText && <span className={styles.additionalText}>{option.additionalText}</span>}
            {mappedVal === option.id && !hideCheckIcon && <CheckIcon className={styles.check} />}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="no-option" disabled>
          {emptyChildrenOptionLabel || t('select.noOption')}
        </MenuItem>
      )}
    </MuiTextfield>
  );
};
