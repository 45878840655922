import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import {
  useCreateSeniorityMutation,
  useDeleteSeniorityMutation,
  useEditSeniorityMutation,
  useSenioritiesQuery,
} from 'generated/graphql';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { graphqlOnError } from 'utils';
import { ACTIONS, NAME } from 'consts';
import { RemoveConfirmIconButton, RightDrawer, Table, TableActionCell } from 'components';
import { DropdownOption, GetRouteProps, ModalModeEnum, TableCell } from 'types';
import { MemberSeniority } from 'generated/types';
import type { SeniorityRoute } from './index';

import { IconButton, Tooltip } from '@material-ui/core';
import { EditIcon, PlusIcon } from 'icons';
import { NewSeniority, NewSeniorityFormValues } from './NewSeniority';

type Props = GetRouteProps<typeof SeniorityRoute>;

const Seniority: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { seniorities = [] as DropdownOption[] } = {}, loading, refetch } = useSenioritiesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData?.company.id as string,
    },
  });

  const seniorityToEdit = useMemo(() => {
    if (!params.seniorityId) {
      return;
    }

    return seniorities.find((seniority) => seniority.id === params.seniorityId);
  }, [params.seniorityId, seniorities]);

  const onCloseCreateModal = useCallback(() => {
    push(link({ ...params, mode: undefined }));
  }, [params]);

  const onCloseEditModal = useCallback(() => {
    push(link({ ...params, mode: undefined, seniorityId: undefined }));
  }, [params]);

  const [createSeniority] = useCreateSeniorityMutation({
    onCompleted() {
      toast.success(t('seniority.seniorityCreatedSuccessfully'));
      onCloseCreateModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [editSeniority] = useEditSeniorityMutation({
    onCompleted() {
      toast.success(t('seniority.seniorityEditedSuccessfully'));
      onCloseEditModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteSeniority] = useDeleteSeniorityMutation({
    onCompleted() {
      toast.success(t('seniority.seniorityDeletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteSeniority) });
        cache.gc();
      }
    },
  });

  const editSeniorities = (values: NewSeniorityFormValues) => {
    editSeniority({
      variables: {
        data: {
          ...values,
        },
        companyId: userData?.company.id as string,
        seniorityId: seniorityToEdit?.id as string,
      },
    });
  };

  const handleCreateSeniority = (values: NewSeniorityFormValues) => {
    createSeniority({
      variables: {
        data: {
          ...values,
        },
        companyId: userData?.company.id as string,
      },
    });
  };

  const handleDeleteSeniority = (id: string) =>
    deleteSeniority({
      variables: {
        seniorityId: id,
        companyId: userData?.company.id as string,
      },
    });

  const columns = useMemo(
    () => [
      {
        Header: t('columns.seniority.name'),
        accessor: NAME,
      },
      {
        Header: ' ',
        accessor: ACTIONS,
        Cell({ row: { original }, isHovered }: TableCell<MemberSeniority>) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  onClick={() => push(link({ mode: ModalModeEnum.edit, seniorityId: original.id }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => handleDeleteSeniority(original.id)}
                confirmTitle={t('seniority.deleteSeniority')}
                confirmMessage={t('seniority.deleteSeniorityQuestion')}
                confirmSubmitButtonTitle={t('seniority.deleteSeniority')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('seniority.name')}</h2>

        <Button className="mr-8" onClick={() => push(link({ mode: ModalModeEnum.create }))} startIcon={<PlusIcon />}>
          {t('seniority.createSeniority')}
        </Button>
      </div>

      <Table data={seniorities as any} columns={columns as any} loading={loading} />

      <RightDrawer
        open={params.mode === ModalModeEnum.create}
        onClose={onCloseCreateModal}
        title={t('seniority.createSeniority')}
      >
        <NewSeniority onSubmit={handleCreateSeniority} onCancel={onCloseCreateModal} />
      </RightDrawer>

      <RightDrawer open={!!seniorityToEdit} onClose={onCloseEditModal} title={t('seniority.editSeniority')}>
        <NewSeniority
          onSubmit={editSeniorities}
          name={seniorityToEdit?.name}
          submitLabel={t('actions.save')}
          onCancel={onCloseEditModal}
        />
      </RightDrawer>
    </>
  );
};

export default Seniority;
