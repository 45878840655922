import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import { idRegEx } from 'utils/regex';
import SpecializationComponent from './Specialization';

export const SpecializationRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    specializationId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, specializationId }) => `/settings/team-settings/specializations/${mode}/${specializationId}`,
);

export const Specialization = SpecializationRoute(SpecializationComponent);
