import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import { AbsoluteSpinner, AssignmentForm, AssignmentFormValues, BillingTypes, EmptyState } from 'components';
import { useEditRequestMutation, useRequestQuery } from 'generated/graphql';
import { getAllocationTimeAmount, getAssignmentRequestFormattedFormData, graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, useIsOpen, usePermissions } from 'hooks';
import { ActionsType, Member, Project } from 'generated/types';
import { removeUTCTimezone } from 'utils/date';
import clsx from 'clsx';
import { Button, IconButton, TextField } from '@material-ui/core';
import styles from './styles.module.scss';
import { ArrowDown, PlusIcon } from 'icons';

interface Props {
  id: string;
  projectDisabled?: boolean;
  memberDisabled?: boolean;
  onCancel: () => void;
}

const INITIAL_ALLOCATION_VALUE = 8.0;

export const EditAssignmentRequest: FC<Props> = ({ id, projectDisabled, memberDisabled, onCancel }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const [isShowNote, onShowNote, onHideNote] = useIsOpen(false);
  const [note, setNote] = useState<string>('');
  const { data: { request } = {}, loading: requestLoading } = useRequestQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted(data) {
      if (data.request?.note) {
        setNote(data.request?.note);
        onShowNote();
      }
    },
    variables: {
      companyId: userData!.company?.id,
      requestId: id!,
    },
    skip: !userData!.company.id || !id,
  });

  const initialValue = useMemo<AssignmentFormValues | undefined>(() => {
    if (!request) {
      return;
    }

    const { data, changes } = request;
    const currentData = changes || data;

    return {
      member:
        currentData.memberId && currentData.memberFirstName
          ? (({
              first_name: currentData.memberFirstName,
              last_name: currentData.memberLastName,
              id: currentData.memberId,
              color: currentData.memberColor,
            } as unknown) as Member)
          : null,
      project:
        currentData.projectId && currentData.project
          ? ({ name: currentData.project, id: currentData.projectId, color: currentData.projectColor } as Project)
          : null,
      role: currentData.role && currentData.roleId ? { name: currentData.role, id: currentData.roleId } : null,
      seniority:
        currentData.seniority && currentData.seniorityId
          ? { name: currentData.seniority, id: currentData.seniorityId }
          : null,
      startDate: currentData.startDate ? removeUTCTimezone(currentData.startDate) : new Date(),
      endDate: currentData.endDate ? removeUTCTimezone(currentData.endDate) : new Date(),
      billingType: BillingTypes.find((type) => type.id === currentData.calculationType) || BillingTypes[0],
      allocationTimeAmount: currentData.allocationTimeAmount
        ? getAllocationTimeAmount(currentData.allocationTimeAmount)
        : INITIAL_ALLOCATION_VALUE,
      billable: currentData.billable ?? false,
    };
  }, [request]);

  const [editAssignmentRequest, { loading }] = useEditRequestMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('request.notifications.update'));
      mixpanel.track('Assignment request edited', { 'Drag and drop': false, Bulk: false });
      onCancel();
    },
  });

  const onEditAssignmentRequest = useCallback(
    (formValues: AssignmentFormValues) => {
      const data = {
        variables: {
          data: {
            note: note,
            data: getAssignmentRequestFormattedFormData(formValues),
          },
          requestId: id,
          companyId: userData!.company.id,
        },
      };

      editAssignmentRequest(data);
    },
    [note],
  );

  if (isPermissionsLoading || requestLoading) {
    return <AbsoluteSpinner />;
  }

  if (!hasAccess(ActionsType.SubmitAssignmentRequest)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <AssignmentForm
      onCancel={onCancel}
      onSubmit={onEditAssignmentRequest}
      initialValue={initialValue}
      projectId={request?.data.projectId}
      submitLabel={t('request.button.saveChanges')}
      submitDisabled={loading}
      memberDisabled={memberDisabled}
      projectDisabled={projectDisabled}
      additionalControlsContent={
        <div className={clsx('relative', !isShowNote && 'd-none')}>
          <TextField
            className={styles.noteTextarea}
            id="note-text-field"
            placeholder={t('request.notePlaceholder')}
            multiline
            rows={5}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <IconButton className={styles.hideNoteButton} onClick={onHideNote}>
            <ArrowDown />
          </IconButton>
        </div>
      }
      additionalButton={
        <Button
          variant="text"
          className={clsx(styles.noteButton, isShowNote && 'd-none')}
          classes={{
            startIcon: styles.addIcon,
          }}
          startIcon={note ? <ArrowDown className={styles.showNoteIcon} /> : <PlusIcon />}
          onClick={onShowNote}
        >
          {t('request.button.noteForAdmin')}
        </Button>
      }
    />
  );
};
