import React, { FC } from 'react';
import { TrashIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { ConfirmIconButton, ConfirmIconButtonProps } from '../ConfirmIconButton';

type Props = Omit<ConfirmIconButtonProps, 'children'>;

export const RemoveConfirmIconButton: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <ConfirmIconButton tooltipText={t('actions.delete')} isDelete {...props}>
      <TrashIcon />
    </ConfirmIconButton>
  );
};
