import React, { FC } from 'react';
import { ExchangeRateDataFragment } from 'generated/types';
import { formatCurrency } from 'utils';
import { useSettings } from 'contexts';

interface Props {
  value: number;
  exchangeRate: ExchangeRateDataFragment;
  showReversed?: boolean;
}

export const ExchangeHint: FC<Props> = ({ value, exchangeRate, showReversed }) => {
  const { baseCurrency, exchangeCurrency, exchangeRate: exchangeRateNumber = 1 } = exchangeRate || {};
  const { isFinancialsHidden } = useSettings();

  const convertCurrency = (value: number) => value / exchangeRateNumber;
  const revertCurrency = (value: number) => value * exchangeRateNumber;

  const getExchangeExample = (value = 1) =>
    showReversed
      ? `${formatCurrency(value, baseCurrency?.code, isFinancialsHidden)} = ${formatCurrency(
          revertCurrency(value),
          exchangeCurrency?.code,
          isFinancialsHidden,
        )}`
      : `${formatCurrency(value, exchangeCurrency?.code, isFinancialsHidden)} = ${formatCurrency(
          convertCurrency(value),
          baseCurrency?.code,
          isFinancialsHidden,
        )}`;

  return (
    <div>
      <p>{getExchangeExample(value)}</p>
      <p>{getExchangeExample()}</p>
    </div>
  );
};
