import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import { GetRouteProps, ModalModeEnum, TableCell } from 'types';
import { graphqlOnError } from 'utils';
import { RemoveConfirmIconButton, RightDrawer, Table, TableActionCell, Tag } from 'components';
import { useAuth } from 'contexts';
import { useCreateTagMutation, useDeleteTagMutation, useEditTagMutation, useTagsQuery } from 'generated/graphql';
import { useErrorMsgBuilder } from 'hooks';
import { ACTIONS } from 'consts';
import { Role, TagDataFragment } from 'generated/types';
import { ProjectTagsRoute } from './index';

import { IconButton, Tooltip } from '@material-ui/core';
import { EditIcon, PlusIcon } from 'icons';
import { NewTag, NewTagFormValues } from './NewTag';
import { Column } from 'react-table';

type Props = GetRouteProps<typeof ProjectTagsRoute>;

export const Tags: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { tags = [] } = {}, loading, refetch } = useTagsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const tagToEdit = useMemo(() => {
    if (!params.tagId) {
      return;
    }

    return tags.find((tag) => tag.id === params.tagId);
  }, [params.tagId, tags]);

  const onCloseCreateModal = useCallback(() => {
    push(link({ ...params, mode: undefined }));
  }, [params]);

  const onCloseEditModal = useCallback(() => {
    push(link({ ...params, mode: undefined, tagId: undefined }));
  }, [params]);

  const [createTag] = useCreateTagMutation({
    onCompleted() {
      toast.success(t('tag.notifications.create'));
      onCloseCreateModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [editTag] = useEditTagMutation({
    onCompleted() {
      toast.success(t('tag.notifications.edit'));
      onCloseEditModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteTag] = useDeleteTagMutation({
    onCompleted() {
      toast.success(t('tag.notifications.delete'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteTag) });
        cache.gc();
      }
    },
  });

  const handleCreateTag = (values: NewTagFormValues) => {
    createTag({
      variables: {
        data: {
          ...values,
        },
        companyId: userData!.company.id,
      },
    });
  };

  const editTags = (values: NewTagFormValues) => {
    editTag({
      variables: {
        data: {
          ...values,
        },
        companyId: userData!.company.id,
        tagId: tagToEdit!.id,
      },
    });
  };

  const handleDeleteTag = (id: string) =>
    deleteTag({
      variables: {
        tagId: id,
        companyId: userData!.company.id,
      },
    });

  const columns = useMemo<Column<TagDataFragment>[]>(
    () => [
      {
        id: 'title',
        Header: t('tag.title')!,
        accessor: ({ name, color }) => ({ name, color }),
        Cell: function tag({ value: { name, color } }: TableCell<Pick<TagDataFragment, 'name' | 'color'>>) {
          return <Tag color={color} name={name} />;
        },
      },
      {
        Header: ' ',
        id: ACTIONS,
        Cell({ row: { original }, isHovered }: TableCell<Role>) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  onClick={() => push(link({ mode: ModalModeEnum.edit, tagId: original.id }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => handleDeleteTag(original.id)}
                confirmTitle={t('tag.delete.title')}
                confirmMessage={t('tag.delete.submissionQuestion')}
                confirmSubmitButtonTitle={t('tag.delete.title')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('tag.title')}</h2>

        <Button className="mr-8" onClick={() => push(link({ mode: ModalModeEnum.create }))} startIcon={<PlusIcon />}>
          {t('tag.newTag')}
        </Button>
      </div>

      <Table data={tags} columns={columns} loading={loading} />

      <RightDrawer open={params.mode === ModalModeEnum.create} onClose={onCloseCreateModal} title={t('tag.newTag')}>
        <NewTag onSubmit={handleCreateTag} onCancel={onCloseCreateModal} />
      </RightDrawer>

      <RightDrawer open={!!tagToEdit} onClose={onCloseEditModal} title={t('tag.editTag')}>
        <NewTag
          name={tagToEdit?.name}
          color={tagToEdit?.color}
          onSubmit={editTags}
          onCancel={onCloseEditModal}
          submitLabel={t('actions.save')}
        />
      </RightDrawer>
    </>
  );
};
