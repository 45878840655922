import React, {
  ChangeEvent,
  ComponentProps,
  ElementType,
  forwardRef,
  ForwardedRef,
  ReactElement,
  ReactNode,
} from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import OriginalAutoComplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { ChevronIcon, DeleteIcon } from 'icons';
import { DropdownOption } from 'types';
import { getOptionName } from 'utils';
import { Popper } from '@material-ui/core';
import { PopperProps } from '@material-ui/core/Popper/Popper';

import styles from './Autocomplete.module.scss';
import clsx from 'clsx';

const PopperPlacementTop = (props: PopperProps) => <Popper {...props} placement="top" />;

interface AutocompleteProps<T> {
  options: T[];
  placeholder?: string;
  noOptions?: string;
  className?: string;
  textFieldClassName?: string;
  filterSelectedOptions?: boolean;
  multiple?: boolean;
  error?: string;
  limitTags?: number;
  disableClearable?: boolean;
  value?: T | T[] | null;
  name: string;
  onChange: (value: T | T[] | null) => void;
  onBlur?: () => void;
  getOptionLabel?: () => void;
  renderOption?: (value: T) => ReactNode;
  isPopperPlacementTop?: boolean;
  hidePopupIcon?: boolean;
}

export const Autocomplete = forwardRef(
  <T extends ElementType>(
    props: AutocompleteProps<T> & ComponentProps<T>,
    ref?: ForwardedRef<HTMLDivElement | null>,
  ): ReactElement<AutocompleteProps<T>> => {
    const { t } = useTranslation();
    const {
      options,
      noOptions,
      placeholder,
      className,
      value,
      onChange,
      error,
      getOptionLabel,
      onBlur,
      name,
      textFieldClassName,
      renderOption,
      isPopperPlacementTop,
      hidePopupIcon,
      ...autoCompleteProps
    } = props;

    const handleRenderInput = (params: AutocompleteRenderInputParams) => (
      <TextField
        onBlur={onBlur}
        name={name}
        {...params}
        fullWidth
        error={!!error}
        helperText={error}
        variant="outlined"
        placeholder={placeholder}
        className={textFieldClassName}
        inputRef={ref}
      />
    );

    const handleChange = (_: ChangeEvent<unknown>, value: DropdownOption[] | DropdownOption | null) => onChange(value);
    return (
      <OriginalAutoComplete
        {...autoCompleteProps}
        value={value}
        onChange={handleChange}
        className={className}
        classes={{
          ...(props.classes || {}),
          root: styles.root,
          inputRoot: clsx(props.multiple && styles.inputMultiple, props.classes?.inputRoot),
          listbox: styles.listbox,
          tag: styles.tag,
          paper: styles.paper,
        }}
        popupIcon={hidePopupIcon ? null : props.popupIcon || <ChevronIcon />}
        options={options ?? []}
        getOptionLabel={getOptionLabel ?? getOptionName}
        getOptionSelected={(option, value: DropdownOption) => option.id === value?.id}
        noOptionsText={noOptions || t('forms.newTeamMember.noOptionText')}
        renderInput={handleRenderInput}
        ListboxProps={{ className: styles.listBox }}
        renderOption={renderOption}
        PopperComponent={isPopperPlacementTop && PopperPlacementTop}
        ChipProps={{
          clickable: true,
          deleteIcon: <DeleteIcon />,
          classes: {
            root: styles.chip,
          },
        }}
      />
    );
  },
);

Autocomplete.displayName = 'Autocomplete';
