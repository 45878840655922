import { client } from 'graphql-client';
import { ProjectDataFragmentDoc, ProjectMilestonesDocument } from 'generated/graphql';
import { ProjectMilestoneDataFragment } from 'generated/types';
import { currencyToValue } from 'utils';
import { ApolloCache } from '@apollo/client';

interface Props {
  projectId: string;
  companyId: string;
  cache: ApolloCache<number>;
}

export const updateProjectTotalByMilestones = ({ projectId, companyId, cache }: Props) => {
  const variables = {
    projectId,
    companyId,
  };

  const { projectMilestones } = client.readQuery({
    query: ProjectMilestonesDocument,
    variables,
  });

  const feeAmount = ((projectMilestones as ProjectMilestoneDataFragment[]) || []).reduce<number>(
    (acc, { amount }) => acc + amount,
    0,
  );

  cache.updateFragment(
    {
      id: `Project:${projectId}`,
      fragmentName: 'ProjectData',
      fragment: ProjectDataFragmentDoc,
    },
    (data) => ({
      ...data,
      fee_amount: currencyToValue(feeAmount || 0),
    }),
  );
};
