import React, { FC } from 'react';
import styles from './styles.module.scss';
import { DragIcon } from 'icons';
import clsx from 'clsx';

interface Props {
  role: string;
  isDragging?: boolean;
}

export const RoleRatesDragItem: FC<Props> = ({ role, isDragging }) => {
  return (
    <div className={clsx(styles.dragItem, isDragging && styles.dragging)}>
      <h4 className={styles.title}>
        {role}
        <DragIcon className={styles.dragIcon} />
      </h4>
    </div>
  );
};
