import {
  TimeLogDataFragmentDoc,
  TimeLogsDocument,
  TotalTimeLogDataFragmentDoc,
  TotalTimeLogsDocument,
  useCreateTimeLogMutation,
  useCreateTimeLogPeriodMutation,
  useDeleteTimeLogMutation,
  useEditTimeLogMutation,
} from 'generated/graphql';
import { TimeLogDataFragment, TimeLogsQueryVariables, TotalTimeLogDataFragment } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useAuth } from 'contexts';

export const useTimeTrackingSubmit = (queryVariables?: TimeLogsQueryVariables) => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { t } = useTranslation();

  const [editTimeLog] = useEditTimeLogMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('forms.timeTracking.timeLog.success'));
    },
    update(cache, { data }) {
      const editTimeLog = data?.editTimeLog;

      if (!editTimeLog) {
        return;
      }

      const logsData: { timeLogs: TimeLogDataFragment[] } | null = cache.readQuery({
        query: TimeLogsDocument,
        variables: {
          companyId: userData!.company.id,
          data: {
            assignmentId: editTimeLog.assignment_id,
            date: editTimeLog.date,
          },
        },
      });

      const totalMinutes = logsData?.timeLogs.reduce((acc, item) => {
        return item.id === editTimeLog.id ? acc + editTimeLog.minutes : acc + item.minutes;
      }, 0);

      cache.updateQuery(
        {
          query: TotalTimeLogsDocument,
          variables: queryVariables,
        },
        (data) => {
          return {
            totalTimeLogs: data.totalTimeLogs.map((item: TotalTimeLogDataFragment) =>
              item.date === editTimeLog.date && item.assignment_id === editTimeLog.assignment_id
                ? { ...item, totalMinutes }
                : item,
            ),
          };
        },
      );
    },
  });

  const [createTimeLog] = useCreateTimeLogMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('forms.timeTracking.timeLog.success'));
    },
    update(cache, { data }) {
      const newTimeLog = data?.createTimeLog;

      if (!newTimeLog) {
        return;
      }

      const createdTimeLogRef = cache.writeFragment({
        data: newTimeLog,
        fragment: TimeLogDataFragmentDoc,
      });

      cache.updateQuery(
        {
          query: TimeLogsDocument,
          variables: {
            companyId: userData!.company.id,
            data: {
              assignmentId: newTimeLog.assignment_id,
              date: newTimeLog.date,
            },
          },
        },
        (data) => {
          return {
            timeLogs: [...(data?.timeLogs || []), createdTimeLogRef],
          };
        },
      );

      cache.updateQuery(
        {
          query: TotalTimeLogsDocument,
          variables: queryVariables,
        },
        (data) => {
          const isLogExist = data.totalTimeLogs.some(
            (item: TotalTimeLogDataFragment) =>
              item.date === newTimeLog.date && item.assignment_id === newTimeLog.assignment_id,
          );

          return {
            totalTimeLogs: isLogExist
              ? data.totalTimeLogs.map((item: TotalTimeLogDataFragment) =>
                  item.date === newTimeLog.date && item.assignment_id === newTimeLog.assignment_id
                    ? { ...item, totalMinutes: item.totalMinutes + newTimeLog.minutes }
                    : item,
                )
              : [
                  ...data.totalTimeLogs,
                  {
                    totalMinutes: newTimeLog.minutes,
                    member_id: newTimeLog.member_id,
                    date: newTimeLog.date,
                    assignment_id: newTimeLog.assignment_id,
                    __typename: 'TotalTimeLog',
                  },
                ],
          };
        },
      );
    },
  });

  const [createTimeLogPeriod] = useCreateTimeLogPeriodMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('forms.timeTracking.timeLog.success'));
    },
    refetchQueries: [TotalTimeLogsDocument],
  });

  const [deleteTimeLog] = useDeleteTimeLogMutation({
    onCompleted(data) {
      const isOneLogDeleted = data.deleteTimeLog.length === 1;
      toast.success(
        t(isOneLogDeleted ? 'timeTracking.delete.deletedSuccessfully' : 'timeTracking.delete.deletedLogsSuccessfully'),
      );
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      const deleteTimeLog = data?.deleteTimeLog;

      if (!deleteTimeLog) {
        return;
      }

      deleteTimeLog.forEach((log) => {
        cache.evict({ id: cache.identify(log) });
        cache.gc();

        cache.updateFragment(
          {
            id: `TotalTimeLog:${log.assignment_id}-${log.date}`,
            fragmentName: 'TotalTimeLogData',
            fragment: TotalTimeLogDataFragmentDoc,
          },
          (data) => ({ ...data, totalMinutes: (data?.totalMinutes || 0) - log.minutes }),
        );
      });
    },
  });

  return {
    editTimeLog,
    createTimeLog,
    createTimeLogPeriod,
    deleteTimeLog,
  };
};
