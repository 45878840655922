import React, { createContext, ReactNode, useContext, useState } from 'react';

interface BaseLayoutProviderProps {
  children: ReactNode;
}

interface LayoutContextInterface {
  isNavBarExpanded: boolean;
  onToggle: () => void;
}

const LayoutContext = createContext<LayoutContextInterface>({ isNavBarExpanded: true, onToggle: () => null });

export const useBaseLayoutContext = () => useContext(LayoutContext) as LayoutContextInterface;

export const BaseLayoutProvider = ({ children }: BaseLayoutProviderProps) => {
  const [isNavBarExpanded, setIsNavBarExpanded] = useState(true);

  const onToggle = () => setIsNavBarExpanded((prev) => !prev);

  return <LayoutContext.Provider value={{ isNavBarExpanded, onToggle }}>{children}</LayoutContext.Provider>;
};
