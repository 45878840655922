import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './TabItem.module.scss';

export interface TabItemProps {
  children: ReactNode;
  route: string;
  exact?: boolean;
  activeClassName?: string;
}

export const TabItem: FC<TabItemProps> = ({ children, route, exact = true, activeClassName }) => (
  <NavLink exact={exact} to={route} className={styles.tab} activeClassName={clsx(styles.active, activeClassName)}>
    {children}
  </NavLink>
);
