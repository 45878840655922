import React from 'react';
import { Route, getLinks } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Switch, Redirect } from 'react-router-dom';

import { ModuleName, ScreenName } from 'types';
import { EmptyState, TabItem } from 'components';
import { Holidays } from './Holidays';
import { SkillCategories } from './SkillCategories';
import { Skills } from './Skills';
import { LeaveTypes } from './LeaveTypes';

import styles from '../Settings.module.scss';
import { usePermissions, useTrackScreenView } from 'hooks';
import { ActionsType } from 'generated/types';

export const links = getLinks({
  Holidays,
  SkillCategories,
  Skills,
  LeaveTypes,
});

const ResourceManagementRoute = Route('/settings/resource-management');

const ResourceManagement = () => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();
  useTrackScreenView(ModuleName.settings, ScreenName.resourceManagement);

  if (!hasAccess(ActionsType.OtherSettings)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <div className="flex max-h-100 align-start">
        <div className={clsx(styles.tabs, 'flex-column align-start mr-30')}>
          <TabItem exact={false} route={links.Holidays()} activeClassName={styles.active}>
            {t('settings.resourceManagement.holidays')}
          </TabItem>
          <TabItem exact={false} route={links.SkillCategories()} activeClassName={styles.active}>
            {t('settings.resourceManagement.skillCategories.tab')}
          </TabItem>
          <TabItem exact={false} route={links.Skills()} activeClassName={styles.active}>
            {t('settings.resourceManagement.skills.tab')}
          </TabItem>
          <TabItem exact={false} route={links.LeaveTypes()} activeClassName={styles.active}>
            {t('settings.resourceManagement.leaveTypes.tab')}
          </TabItem>
        </div>

        <div className="flex-1 max-h-100 flex flex-column overflow-auto">
          <Switch>
            <Holidays exact />
            <SkillCategories />
            <Skills />
            <LeaveTypes />
            <Redirect to={links.Holidays()} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default ResourceManagementRoute(ResourceManagement);
