import clsx from 'clsx';
import React, { CSSProperties, FC, forwardRef, memo, ReactNode, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { hexToRGBA } from 'utils';

import { useTimelineContext } from 'contexts';
import { ActionsType } from 'generated/types';
import { AssignmentHandle, Boundaries, useDraggableAssignment, usePermissions } from 'hooks';
import { getMinColumnWidth } from 'views/ResourcePlanning/utils';

import { FlagIcon } from 'icons';
import styles from './styles.module.scss';

const SVG_SIZE = 16;
const DEFAULT_COLOR = '#5C6E79';
const PROJECT_TITLE_PADDINGS = 16;

interface EmptyAreaProps {
  last?: boolean;
  continues?: boolean;
  amount?: number;
}

export const EmptyArea = memo<EmptyAreaProps>(({ last, continues, amount }) => {
  const { timelinePeriod } = useTimelineContext();
  const minColumnWidth = getMinColumnWidth(timelinePeriod);

  return (
    <div
      className={clsx(styles.assignmentProject, {
        [styles.assignmentProjectAssignedLast]: last,
        [styles.assignmentProjectAssignedContinues]: continues,
      })}
      style={{
        flex: `${amount ?? 1} 1 0px`,
        minWidth: `${(amount ?? 1) * minColumnWidth}px`,
      }}
    />
  );
});

interface ProjectTabAssignmentAreaProps {
  last?: boolean;
  color?: string;
  continues?: boolean;
  amount?: number;
  name?: string;
}

export const ProjectTabAssignmentArea: FC<ProjectTabAssignmentAreaProps> = ({
  last,
  color,
  continues,
  amount,
  name,
}) => {
  const { timelinePeriod } = useTimelineContext();
  const minColumnWidth = getMinColumnWidth(timelinePeriod);

  return (
    <div
      className={clsx(styles.assignmentProject, styles.assignmentProjectAssigned, {
        [styles.assignmentProjectAssignedLast]: last,
        [styles.assignmentProjectAssignedContinues]: continues,
      })}
      style={{
        flex: `${amount ?? 1} 1 0px`,
        minWidth: `${(amount ?? 1) * minColumnWidth}px`,
      }}
    >
      <div className={styles.projectExtendedLine}>
        <div className={styles.projectExtendedLineHeader} style={{ backgroundColor: color }} />
        <div className={styles.projectExtendedLineContent}>
          <div
            className={styles.projectExtendedLineContentTitle}
            style={{ width: `${(amount ?? 1) * minColumnWidth - PROJECT_TITLE_PADDINGS - SVG_SIZE}px` }}
          >
            {name}
          </div>
          <FlagIcon style={{ fill: continues ? 'transparent' : color || DEFAULT_COLOR }} />
        </div>
      </div>
    </div>
  );
};

type AssignmentPosition = {
  x: number;
  y: number;
};

interface DraggableAreaProps {
  label: ReactNode;
  start?: Date;
  amount?: number;
  continues?: boolean;
  last?: boolean;
  color?: string;
  id?: string;
  onSelect?: () => void;
  boundaries?: Boundaries;
  onDrag?: () => void;
  onStop?: (e: DraggableEvent) => void;
  onStart?: (e: DraggableEvent) => void;
  isDragging?: boolean;
  isRequest?: boolean;
  disableStartDraggable?: boolean;
  disableEndDraggable?: boolean;
  hiddenAssignmentWidth?: number;
}

export const DraggableArea = forwardRef<AssignmentHandle, DraggableAreaProps>(
  (
    {
      continues,
      amount,
      label,
      color,
      last,
      onSelect,
      id,
      boundaries,
      onDrag,
      onStop,
      onStart,
      isDragging,
      isRequest,
      disableStartDraggable,
      disableEndDraggable,
      hiddenAssignmentWidth = 0,
    },
    ref,
  ) => {
    const { hasAccess } = usePermissions();

    const secondaryProjectColor = hexToRGBA(color || '', 0.35);

    const [position, setPosition] = useState<AssignmentPosition>({ x: 0, y: 0 });

    const resetPosition = () => setPosition({ x: 0, y: 0 });

    const isEditable = hasAccess(ActionsType.EditAssignments);

    const { localBoundaries, startDateRef, endDateRef, assignmentRef } = useDraggableAssignment(
      ref,
      resetPosition,
      amount,
      id,
    );

    const onLocalDrag = (e: DraggableEvent, data: DraggableData) => {
      setPosition({ x: data.x, y: data.y });
      if (onDrag) onDrag();
    };

    const rightBoundary = boundaries?.right && localBoundaries?.right ? boundaries.right - localBoundaries.right : 0;
    const leftBoundary = boundaries?.left && localBoundaries?.left ? boundaries.left - localBoundaries.left : 0;
    const isLineMoving = !!position.x;
    const continuousAssignmentAreaStyles: CSSProperties = {
      ...(position.x > 0
        ? {
            left: position.x > hiddenAssignmentWidth ? position.x - hiddenAssignmentWidth : 0,
            right: `-${position.x}px`,
            borderRadius: position.x > hiddenAssignmentWidth ? 6 : '0 6px 6px 0',
          }
        : {}),
      ...(position.x < 0
        ? {
            left: `${position.x}px`,
            right: -position.x > hiddenAssignmentWidth ? -(position.x + hiddenAssignmentWidth) : 0,
            borderRadius: -position.x > hiddenAssignmentWidth ? 6 : '6px 0 0 6px',
          }
        : {}),
    };

    return (
      <div
        className={clsx(styles.assignmentProject, styles.assignmentProjectAssigned, {
          [styles.assignmentProjectAssignedLast]: last,
          [styles.assignmentProjectAssignedContinues]: continues,
          [styles.assignmentProjectAssignedInteractive]: !!onSelect,
        })}
      >
        <div
          className={clsx(styles.continuousAssignmentArea, { [styles.projectLineDragging]: isDragging })}
          style={{ ...continuousAssignmentAreaStyles, backgroundColor: secondaryProjectColor }}
        />
        <div
          className={clsx(styles.continuousAssignmentArea, styles.continuousAssignmentAreaBackground)}
          style={{ ...continuousAssignmentAreaStyles }}
        />
        <div className={styles.projectLineWrapper}>
          <div
            className={clsx(
              styles.projectLineWrapperEdge,
              styles.projectLineWrapperEdgeStart,
              (!isEditable || disableStartDraggable) && styles.cursorDefault,
            )}
            ref={startDateRef}
            draggable={isEditable || !disableStartDraggable}
          />
          <Draggable
            onMouseDown={!isEditable ? onSelect : undefined}
            bounds={{ top: 0, bottom: 0, right: rightBoundary, left: leftBoundary }}
            onStop={onStop}
            onDrag={onLocalDrag}
            onStart={onStart}
            nodeRef={assignmentRef}
            position={position}
            disabled={!isEditable}
          >
            <div className={styles.draggableContentWrapper} ref={assignmentRef}>
              <div
                className={clsx(styles.projectLine, {
                  [styles.projectLineResize]: !last && !continues,
                  [styles.projectLineResizeStart]: !last && continues,
                  [styles.projectLineResizeEnd]: !continues && last,
                  [styles.projectLineHoverDisabled]: isLineMoving,
                })}
                style={{ borderColor: secondaryProjectColor }}
              >
                <div className="py-5 px-6" style={{ backgroundColor: secondaryProjectColor }}>
                  <div className={styles.projectMarker} style={{ backgroundColor: color }} />
                </div>

                <div
                  className={clsx(styles.projectLineTile, {
                    [styles.projectLineTileRequest]: isRequest,
                  })}
                  style={{ backgroundColor: secondaryProjectColor }}
                >
                  {label}
                </div>
              </div>
            </div>
          </Draggable>
          <div
            className={clsx(
              styles.projectLineWrapperEdge,
              styles.projectLineWrapperEdgeEnd,
              (!isEditable || disableEndDraggable) && styles.cursorDefault,
            )}
            ref={endDateRef}
            draggable={isEditable || !disableEndDraggable}
          />
        </div>
      </div>
    );
  },
);

EmptyArea.displayName = 'Timeline.EmptyArea';
DraggableArea.displayName = 'Timeline.DraggableArea';
