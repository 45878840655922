import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { CountChip } from 'components';

interface Props {
  count: number;
  className?: string;
  boxClassName?: string;
  isSkipFirstAnimation?: boolean;
}

export const AnimationCountChip: FC<Props> = ({ count, className, boxClassName, isSkipFirstAnimation }) => {
  const [isOngoingAnimation, setIsOngoingAnimation] = useState(false);
  const [isFirstAnimation, setIsFirstAnimation] = useState(true);

  useEffect(() => {
    if (isSkipFirstAnimation && isFirstAnimation) {
      setIsFirstAnimation(false);
      return;
    }

    if (count) {
      setIsOngoingAnimation(true);
      setTimeout(() => setIsOngoingAnimation(false), 200);
    }
  }, [count]);

  return (
    <div className={clsx(styles.box, boxClassName)}>
      <div className={clsx(styles.chipBox, isOngoingAnimation && styles.animation)} />
      <CountChip count={count} className={className} />
    </div>
  );
};
