import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { localStorageManager } from 'services';
import { COLLAPSED_WEEKENDS, PER_WEEK, RESOURCE_PLANNING_VIEW, MONTH_PERIOD } from 'consts';

type HoursPeriod = 'perWeek' | 'perDay';
export type TimelinePeriod = 'month' | 'quarter' | 'year';

interface TimelineContextInterface {
  collapsedWeekends: boolean;
  onCollapse: () => void;
  hoursPeriod: HoursPeriod;
  onChangeHoursPeriod: (value: HoursPeriod) => void;
  setCellWidth: (value: number) => void;
  cellWidth: number;
  timelinePeriod: TimelinePeriod;
  onChangeTimelinePeriod: (value: TimelinePeriod) => void;
}

interface TimelineProviderProps {
  children: ReactNode;
}

const TimelineContext = createContext<TimelineContextInterface>({
  collapsedWeekends: !!localStorageManager.getItem(COLLAPSED_WEEKENDS),
  onCollapse: () => null,
  hoursPeriod: PER_WEEK,
  onChangeHoursPeriod: () => null,
  timelinePeriod: MONTH_PERIOD,
  onChangeTimelinePeriod: () => null,
  setCellWidth: () => null,
  cellWidth: 0,
});

export const useTimelineContext = () => useContext(TimelineContext) as TimelineContextInterface;

export const TimelineProvider = ({ children }: TimelineProviderProps) => {
  const resourcePlanningTypeView: HoursPeriod =
    (localStorageManager.getItem(RESOURCE_PLANNING_VIEW) as HoursPeriod) || PER_WEEK;

  const [collapsedWeekends, setCollapsedWeekends] = useState(!!localStorageManager.getItem(COLLAPSED_WEEKENDS));
  const [hoursPeriod, setHoursPeriod] = useState<HoursPeriod>(resourcePlanningTypeView);
  const [timelinePeriod, setTimelinePeriod] = useState<TimelinePeriod>(MONTH_PERIOD);
  const [cellWidth, setNewCellWidth] = useState(0);

  const setCellWidth = useCallback((value: number) => setNewCellWidth(value), []);

  const onCollapse = useCallback(() => {
    collapsedWeekends
      ? localStorageManager.removeItem(COLLAPSED_WEEKENDS)
      : localStorageManager.setItem(COLLAPSED_WEEKENDS, true);

    setCollapsedWeekends((prev) => !prev);
  }, []);

  const onChangeHoursPeriod = useCallback((value: HoursPeriod) => {
    localStorageManager.setItem(RESOURCE_PLANNING_VIEW, value);
    setHoursPeriod(value);
  }, []);

  const onChangeTimelinePeriod = useCallback((value: TimelinePeriod) => {
    setTimelinePeriod(value);
  }, []);

  const contextValue = useMemo(() => {
    return {
      hoursPeriod,
      timelinePeriod,
      collapsedWeekends,
      onCollapse,
      onChangeHoursPeriod,
      onChangeTimelinePeriod,
      setCellWidth,
      cellWidth,
    };
  }, [hoursPeriod, timelinePeriod, collapsedWeekends, cellWidth]);

  return <TimelineContext.Provider value={contextValue}>{children}</TimelineContext.Provider>;
};
