import React, { FC } from 'react';
import { Route } from 'react-router-hoc';

import { AbsoluteSpinner, ArchiveProject, EmptyState } from 'components';
import { GroupByFilter, ModuleName, ScreenName } from 'types';
import { ActionsType } from 'generated/types';
import { usePermissions, useTrackScreenView } from 'hooks';
import { ProjectData, ProjectsTable } from 'views/Projects/components/ProjectsTable/ProjectsTable';

import styles from './styles.module.scss';

const ActiveProjectsTabRoute = Route('/projects/active');

interface Props {
  projects: ProjectData[];
  loading: boolean;
  groupBy?: GroupByFilter;
}

const Projects: FC<Props> = ({ projects, loading, groupBy }) => {
  const { hasAccess, isPermissionsLoading } = usePermissions();
  useTrackScreenView(ModuleName.projects, ScreenName.list);

  if (loading || isPermissionsLoading) {
    return <AbsoluteSpinner />;
  }

  if (!projects.length) {
    return <EmptyState className={styles.emptyState} />;
  }

  if (!hasAccess(ActionsType.ViewActiveProjects)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <ProjectsTable
      projects={projects}
      groupBy={groupBy}
      getTableActions={(id, isActiveAssignment) => <ArchiveProject id={id} isActiveAssignment={isActiveAssignment} />}
    />
  );
};

export default ActiveProjectsTabRoute(Projects);
