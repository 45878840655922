import { Button, InputLabel, TextField } from '@material-ui/core';
import { LoadingButton } from 'components';
import React, { FC, useMemo } from 'react';
import { ISkillCategory } from 'generated/types';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { submitForm } from 'utils';

interface Props {
  onSubmit: (data: ISkillCategory) => Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
  initialValues?: Partial<ISkillCategory>;
}

type FormValues = ISkillCategory;

export const SkillCategoryForm: FC<Props> = ({ onSubmit, submitLabel, onCancel, initialValues }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      object({
        name: string().required('validation.required'),
      }),
    [],
  );

  const {
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    errors,
    touched,
    submitCount,
    isSubmitting,
    setSubmitting,
  } = useFormik<FormValues>({
    onSubmit: (data) => {
      submitForm(data, setSubmitting, onSubmit);
    },
    validationSchema,
    initialValues: {
      name: initialValues?.name || '',
    },
  });

  return (
    <form onSubmit={handleSubmit} className="form">
      <div>
        <InputLabel>{t('settings.resourceManagement.skillCategories.categoryName')}</InputLabel>
        <TextField
          error={Boolean((submitCount || touched.name) && errors.name)}
          helperText={(submitCount || touched.name) && t(errors.name!)}
          onBlur={handleBlur}
          onChange={handleChange}
          name="name"
          value={values.name}
        />
      </div>

      <div className="controls">
        <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
          {submitLabel ?? t('actions.create')}
        </LoadingButton>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {t('forms.cancel')}
        </Button>
      </div>
    </form>
  );
};
