import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion } from '@material-ui/core';
import { DownTriangle } from 'icons';

import styles from './styles.module.scss';
import { AccordionProps } from '@material-ui/core/Accordion/Accordion';
import clsx from 'clsx';

interface Props {
  header: ReactElement;
  children: ReactNode;
  isChangeOnIconClick?: boolean;
  accordionClassName?: string;
  summaryClassName?: string;
  detailsClassName?: string;
  expandIconClassName?: string;
  expandIcon?: ReactElement;
}
export const Accordion: FC<Props & AccordionProps> = ({
  header,
  children,
  isChangeOnIconClick,
  accordionClassName,
  summaryClassName,
  detailsClassName,
  expandIconClassName,
  expandIcon,
  ...accordionProps
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className={styles.container}>
      <MuiAccordion
        expanded={isChangeOnIconClick ? accordionOpen : undefined}
        {...accordionProps}
        className={clsx(styles.accordion, accordionClassName)}
        TransitionProps={{ timeout: 0 }}
      >
        <AccordionSummary
          className={clsx(styles.accordionSummary, summaryClassName)}
          expandIcon={expandIcon || <DownTriangle />}
          IconButtonProps={{ onClick: () => setAccordionOpen(!accordionOpen) }}
          aria-controls="row-accordion-content"
          id="row-accordion-header"
          classes={{
            expandIcon: expandIconClassName,
          }}
        >
          {header}
        </AccordionSummary>
        <AccordionDetails className={clsx(styles.accordionDetails, detailsClassName)}>{children}</AccordionDetails>
      </MuiAccordion>
    </div>
  );
};
