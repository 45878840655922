import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './Badge.module.scss';

export enum Colors {
  purple = 'purple',
  orange = 'orange',
  pink = 'pink',
  green = 'green',
  navy = 'navy',
  emerald = 'emerald',
  blue = 'blue',
  yellow = 'yellow',
  red = 'red',
  accent = 'accent',
  gray = 'gray',
  greyscale = 'greyscale',
  disabled = 'disabled',
  success = 'success',
  failure = 'failure',
}

export interface BadgeProps {
  children: ReactNode;
  hasDot?: boolean;
  color: Colors;
  className?: string;
}

export const Badge = ({ children, hasDot, color, className }: BadgeProps) => {
  return (
    <div
      className={clsx([
        styles.badgeWrapper,
        'weight-500',
        'inline-flex',
        'align-items-center',
        styles[color],
        className,
      ])}
    >
      {!!hasDot && <div className={clsx([styles.dot])} />}
      {children}
    </div>
  );
};
