import React from 'react';

interface WarningIconProps {
  className?: string;
}

export const WarningIcon = ({ className }: WarningIconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60807 0.366667C8.33782 -0.122222 7.66218 -0.122222 7.39193 0.366666L0.095116 13.5667C-0.175136 14.0556 0.162679 14.6667 0.703183 14.6667H15.2968C15.8373 14.6667 16.1751 14.0556 15.9049 13.5667L8.60807 0.366667ZM8.66667 4.66667C8.66667 4.29848 8.36819 4 8 4C7.63181 4 7.33333 4.29848 7.33333 4.66667V10C7.33333 10.3682 7.63181 10.6667 8 10.6667C8.36819 10.6667 8.66667 10.3682 8.66667 10V4.66667ZM8.83333 12.1667C8.83333 12.6269 8.46023 13 8 13C7.53977 13 7.16667 12.6269 7.16667 12.1667C7.16667 11.7064 7.53977 11.3333 8 11.3333C8.46023 11.3333 8.83333 11.7064 8.83333 12.1667Z"
        fill="#FF9D0A"
      />
    </svg>
  );
};
