import React, { ChangeEvent, FC, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DialogWrapper, Portal, UploadFileItem } from 'components';
import { ImportDataDocument, useExportImportExampleLazyQuery } from 'generated/graphql';
import { toast } from 'react-toastify';
import { downloadExcelXLS, getFileFormat, getFileNameWithoutSpecificSymbols, graphqlOnError, MEGABYTE } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';

import { File as FileIcon, FileDownloadIcon } from 'icons';
import { Button } from '@material-ui/core';
import { ImportType } from 'generated/types';
import { IntegrationInfoBox } from './IntegrationInfoBox';
import { IntegrationWarningBox } from './IntegrationWarningBox';
import { client } from 'graphql-client';
import mixpanel from 'mixpanel-browser';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  importType: ImportType;
}

const MaxFileLimit100Mb = 100 * MEGABYTE;
const CancelRequestErrorMessage = 'text is not a function';
const AbortRequestErrorMessage = 'The user aborted a request.';

export const IntegrationModal: FC<Props> = ({ title, importType, isOpen, onClose }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const fileInputRef = useRef<null | HTMLInputElement>(null);
  const [errorFileLink, setErrorFileLink] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fileState, setFileState] = useState<File | null>(null);

  const [exportImportExample, { loading: exportLoading }] = useExportImportExampleLazyQuery({
    variables: {
      companyId: userData!.company.id,
      type: importType,
    },
    onCompleted(data) {
      if (data?.exportImportExample) {
        downloadExcelXLS(data.exportImportExample, t('import.template'));
      }
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    fetchPolicy: 'no-cache',
  });

  const handleClose = () => {
    setFileState(null);
    setErrorFileLink(null);
    setLoading(false);
    onClose();
  };

  const resetInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onChange = async ({ target: { validity, files } }: ChangeEvent<HTMLInputElement>) => {
    if (!validity.valid || !files?.length) {
      return;
    }

    const fileFormat = getFileFormat(files[0].name);
    if (fileFormat !== 'xls' && fileFormat !== 'xlsx') {
      toast.error(t('import.errorFileFormat'));
      setErrorFileLink(null);
      return;
    }

    setFileState(files[0]);
    setErrorFileLink(null);
  };

  const onUploadImportFile = () => {
    if (!fileState) {
      return;
    }

    const abortController = new AbortController();

    if (fileState.size > MaxFileLimit100Mb) {
      toast.error(t('import.maxFileSize'));
      return;
    }

    setFileName(fileState.name);
    setLoading(true);
    const newFile = new File([fileState], getFileNameWithoutSpecificSymbols(fileState.name), {
      type: fileState.type,
    });

    client
      .mutate({
        mutation: ImportDataDocument,
        fetchPolicy: 'network-only',
        variables: {
          companyId: userData!.company.id,
          data: {
            file: newFile,
            type: importType,
          },
        },
        context: {
          fetchOptions: { signal: abortController.signal },
        },
      })
      .then(({ data }) => {
        if (!data?.importData) {
          return;
        }

        const { importData: newFile } = data;

        if (newFile.success) {
          newFile.message && toast.success(t(newFile.message));
          handleClose();
          return;
        }

        setLoading(false);
        setFileState(null);
        newFile.message && toast.error(newFile.message);

        if (newFile.file) {
          setErrorFileLink(`data:application/vnd.ms-excel;base64,${newFile.file}`);
        }

        setFileState(null);
        mixpanel.track('Imported data', { type: importType });
      })
      .catch((err) => {
        if (err.message.includes(CancelRequestErrorMessage) || err.message === AbortRequestErrorMessage) {
          return;
        }
        setLoading(false);
        setFileState(null);
        graphqlOnError(err, tls(err.message));
      });

    resetInput();
  };

  return (
    <DialogWrapper
      open={isOpen}
      onClose={handleClose}
      className={styles.dialog}
      contentClassName={styles.dialogContent}
      title={title}
    >
      <IntegrationInfoBox>
        <Button
          className={styles.fileButton}
          variant="outlined"
          color="secondary"
          startIcon={<FileDownloadIcon />}
          onClick={() => exportImportExample()}
          disabled={exportLoading}
        >
          {t('import.templateXls')}
        </Button>
      </IntegrationInfoBox>

      {errorFileLink && (
        <IntegrationWarningBox fileName={fileName}>
          <a download="Error.xls" href={errorFileLink!}>
            <Button
              className={clsx(styles.fileButton, 'mt-16')}
              variant="outlined"
              color="secondary"
              startIcon={<FileDownloadIcon />}
            >
              {t('import.errorXls')}
            </Button>
          </a>
        </IntegrationWarningBox>
      )}

      {!fileState && (
        <div className={styles.dropZone}>
          <input
            type="file"
            multiple
            onChange={onChange}
            ref={fileInputRef}
            id="contained-file"
            size={MaxFileLimit100Mb}
            className={styles.fileInput}
          />
          <FileIcon className="mb-8" />
          <span className={styles.text}>{t('import.uploadFile.dropZone')}</span>
          <span className={styles.description}>{t('import.uploadFile.maxSize')}</span>
          <Button variant="outlined" color="secondary" className="mt-24">
            {t('import.uploadFile.browseFiles')}
          </Button>
        </div>
      )}

      <div className={styles.fileList}>
        {fileState ? (
          <UploadFileItem
            loading={loading}
            name={fileState.name}
            size={fileState.size}
            handleDelete={() => {
              setFileState(null);
            }}
          />
        ) : (
          ''
        )}
      </div>

      <Portal wrapperId="dialog-actions">
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {t('forms.cancel')}
        </Button>
        <Button onClick={onUploadImportFile} disabled={loading || !fileState}>
          {title}
        </Button>
      </Portal>
    </DialogWrapper>
  );
};
