import { RightDrawer } from 'components';
import { useAuth } from 'contexts';
import { useEditSkillCategoryMutation } from 'generated/graphql';
import { ISkillCategory, SkillCategoryDataFragment } from 'generated/types';
import { useErrorMsgBuilder } from 'hooks';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { SkillCategoryForm } from '../components/SkillCategoryForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  skill: SkillCategoryDataFragment;
}

export const EditSkillCategory: FC<Props> = ({ isOpen, onClose, skill }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();

  const [editSkillCategory] = useEditSkillCategoryMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('settings.resourceManagement.skillCategories.edit.notifications.success'));
      onClose();
    },
  });

  const { userData } = useAuth();

  const onSubmit = useCallback(
    async (data: ISkillCategory) => {
      if (!userData) {
        return;
      }

      await editSkillCategory({
        variables: {
          companyId: userData.company.id,
          skillCategoryId: skill.id,
          data: data,
        },
      });
    },
    [skill, userData?.company.id],
  );

  return (
    <RightDrawer
      direction="right"
      open={isOpen}
      onClose={onClose}
      title={t('settings.resourceManagement.skillCategories.edit.title')}
    >
      <SkillCategoryForm onSubmit={onSubmit} onCancel={onClose} initialValues={skill} submitLabel={t('actions.edit')} />
    </RightDrawer>
  );
};
