import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { ActivityTabs } from 'types';

export const ActivityRoute = Route(
  {
    tab: Route.params.oneOf(ActivityTabs.requestsInbox, ActivityTabs.todos, ActivityTabs.activityLog).optional,
  },
  ({ tab }) => `/activity/${tab}`,
);

export const Activity = ActivityRoute(lazy(() => import(/* webpackChunkName: "Activity" */ './Activity')));
