import { useLocation } from 'react-router';

export const useQueryParams = <T = Record<string, unknown>>(): T => {
  const location = useLocation();
  const urlParams = new URLSearchParams(decodeURI(location.search));
  const params: { [x: string]: any } = {};
  for (const key of Array.from(urlParams.keys())) {
    const param = urlParams.getAll(key);
    params[key] = param.length > 1 ? param : param[0];
  }
  return (params as unknown) as T;
};
