import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormik } from 'formik';

import { Autocomplete, DialogWrapper, LoadingButton, Portal, Tooltip } from 'components';
import { useFeedback, useIsOpen } from 'hooks';
import { QuestionIcon } from 'icons';
import styles from './styles.module.scss';
import * as Yup from 'yup';

export type FormValues = {
  issueType?: { id: string; name: string };
  description?: string;
};

export const FeedbackModal: FC = () => {
  const { t } = useTranslation();
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { sendFeedback } = useFeedback();

  const issueTypes = useMemo(
    () => [
      { id: t('feedback.form.issueCategories.bugReport'), name: t('feedback.form.issueCategories.bugReport') },
      {
        id: t('feedback.form.issueCategories.enhancementSuggestion'),
        name: t('feedback.form.issueCategories.enhancementSuggestion'),
      },
      {
        id: t('feedback.form.issueCategories.productQuestion'),
        name: t('feedback.form.issueCategories.productQuestion'),
      },
      { id: t('feedback.form.issueCategories.other'), name: t('feedback.form.issueCategories.other') },
    ],
    [],
  );

  const onSubmit = (data: FormValues) => {
    sendFeedback({ data, onCompleted: onClose });
  };

  const {
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    isSubmitting,
    values,
    touched,
    errors,
    resetForm,
  } = useFormik<FormValues>({
    initialValues: {
      issueType: issueTypes[0],
      description: '',
    },
    validationSchema: Yup.object({
      issueType: Yup.object().required(t('validation.required')),
      description: Yup.string()
        .required(t('validation.required'))
        .matches(/^\s*\S[\s\S]*$/, t('validation.blankspaces')),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
    <>
      <IconButton onClick={onOpen} className={styles.openButton}>
        <Tooltip title={t('feedback.tooltipText')!} className={styles.tooltip} placement="top" alwaysShowTooltip>
          <QuestionIcon />
        </Tooltip>
      </IconButton>

      <DialogWrapper
        open={isOpen}
        onClose={onClose}
        className={styles.dialog}
        contentClassName={styles.dialogContent}
        title={t('feedback.contactUs')}
      >
        <form onSubmit={handleSubmit}>
          <div className={styles.box}>
            <span className={styles.description}>{t('feedback.text')}</span>
            <InputLabel>{t('feedback.form.issueType')}</InputLabel>
            <Autocomplete
              name="issueType"
              placeholder={'Product feedback'}
              onBlur={handleBlur}
              value={values.issueType}
              className="mb-24"
              disableClearable
              options={issueTypes}
              error={touched.issueType ? errors.issueType : undefined}
              onChange={(issueType: FormValues['issueType']) => setValues({ ...values, issueType })}
            />

            <InputLabel>{t('feedback.form.description')}</InputLabel>
            <TextField
              name="description"
              className={styles.noteTextarea}
              id="note-text-field"
              placeholder={t('feedback.form.descriptionPlaceholder')}
              multiline
              rows={10}
              onChange={handleChange}
              error={touched.description ? !!errors.description : undefined}
              helperText={touched.description ? errors.description : undefined}
              value={values.description}
            />
          </div>

          <Portal wrapperId="dialog-actions">
            <Button variant="outlined" color="secondary" onClick={onClose}>
              {t('feedback.form.cancel')}
            </Button>

            <LoadingButton type="submit" loading={isSubmitting} onClick={() => handleSubmit()}>
              {t('feedback.form.submit')}
            </LoadingButton>
          </Portal>
        </form>
      </DialogWrapper>
    </>
  );
};
