import React, { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export const LeaveRuleHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <span className={styles.leaveTypes}>{t('viewProjectDetail.billableLeaves.type')}</span>
      <span className={styles.limit}>{t('viewProjectDetail.billableLeaves.limit')}</span>
      <span className={styles.accrualDate}>{t('viewProjectDetail.billableLeaves.accrualDate')}</span>
    </div>
  );
};
