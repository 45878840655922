import React, { ForwardedRef, forwardRef, RefObject, useRef } from 'react';
import { Checkbox } from '@material-ui/core';
import { TableToggleRowsSelectedProps } from 'react-table';

import styles from './styles.module.scss';

export const IndeterminateCheckbox = forwardRef(
  ({ ...rest }: TableToggleRowsSelectedProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    return (
      <Checkbox
        className={styles.checkBox}
        ref={resolvedRef as RefObject<HTMLButtonElement>}
        {...rest}
        color="primary"
        classes={{
          checked: styles.checked,
          indeterminate: styles.indeterminate,
        }}
      />
    );
  },
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';
