// for mapping BE response errors
export const jwt_expired = 'Token expired';
export const invalid_token = 'Invalid token';
export const no_user_found = 'no_user_found';
export const invalid_header = 'Invalid Authorization header';
export const jwt_malformed = 'jwt_malformed';
export const jwt_signature_is_required = 'jwt_signature_is_required';
export const invalid_signature = 'invalid_signature';
export const invalid_algorithm = 'invalid_algorithm';
export const no_company_membership_found = 'no_company_membership_found';
export const no_permissions = 'no_permissions';
export const data_consistency_error = 'data_consistency_error';
export const unknown_error = 'unknown_error';
export const no_invitation_found = 'no_invitation_found';
export const invitation_failed_validation = 'invitation_failed_validation';
export const failed_to_create_invitation = 'failed_to_create_invitation';
export const failed_to_accept_invitation = 'failed_to_accept_invitation';
export const unable_to_set_type = 'unable_to_set_type';
export const unable_to_edit_own_type = 'unable_to_edit_own_type';
export const unable_to_edit_owner_type = 'unable_to_edit_owner_type';

// specific to translations
export const BE_ERRORS_TLS_KEY = 'beErrors';
export const BE_ERRORS_TLS_DEFAULT_MSG_SELECTOR = 'genericInternal';
