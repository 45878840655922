import { ProjectAssignmentsListDocument, ProjectCommissionsDocument } from 'generated/graphql';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

export const getCommissionsRefetchQueries = (companyId: string, projectId: string) => {
  const start = format(startOfMonth(new Date()), DEFAULT_DATE_FORMAT);
  const end = format(endOfMonth(new Date()), DEFAULT_DATE_FORMAT);

  return [
    ProjectAssignmentsListDocument,
    {
      query: ProjectCommissionsDocument,
      variables: { companyId, projectId },
    },
    {
      query: ProjectAssignmentsListDocument,
      variables: {
        companyId,
        projectId,
        companyCurrency: false,
        paginationAssignmentData: { start, end },
      },
    },
    {
      query: ProjectAssignmentsListDocument,
      variables: {
        companyId,
        projectId,
        companyCurrency: true,
        paginationAssignmentData: { start, end },
      },
    },
  ];
};
