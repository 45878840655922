import { useCallback, useState } from 'react';
import { localStorageManager } from 'services';
import { PROJECTS_TABLE_COLUMN_ORDER, PROJECTS_TABLE_HIDDEN_COLUMNS, ProjectsTableColumns } from 'consts';
import { usePermissions } from './usePermissions';
import { ActionsType } from 'generated/types';

const defaultActiveColumns = [
  ProjectsTableColumns.Client,
  ProjectsTableColumns.Type,
  ProjectsTableColumns.PM,
  ProjectsTableColumns.StartDate,
  ProjectsTableColumns.EndDate,
  ProjectsTableColumns.File,
  ProjectsTableColumns.ActiveAssignments,
  ProjectsTableColumns.Margin,
  ProjectsTableColumns.ForecastMargin,
  ProjectsTableColumns.CurrentCommission,
];

const marginColumns = [ProjectsTableColumns.Margin, ProjectsTableColumns.ForecastMargin];

export const useProjectsTableSetting = () => {
  const { hasAccess } = usePermissions();
  const currentColumnOrder = (localStorageManager.getItem(
    PROJECTS_TABLE_COLUMN_ORDER,
  ) as unknown) as ProjectsTableColumns[];

  const [columnsOrder, setColumnsOrder] = useState<string[]>(currentColumnOrder || []);

  const onChangeColumnsOrder = useCallback((columns: string[]) => {
    setColumnsOrder(columns);
    localStorageManager.setItem(PROJECTS_TABLE_COLUMN_ORDER, columns);
  }, []);

  const hiddenColumnOptions = (localStorageManager.getItem(
    PROJECTS_TABLE_HIDDEN_COLUMNS,
  ) as unknown) as ProjectsTableColumns[];

  const currentColumnOptions = hiddenColumnOptions
    ? Object.values(ProjectsTableColumns).filter((column) => !hiddenColumnOptions?.includes(column))
    : defaultActiveColumns;

  const [activeColumns, setActiveColumns] = useState<string[]>(currentColumnOptions);

  const onChangeActiveColumns = useCallback((columns: string[]) => {
    setActiveColumns(columns);

    const hiddenColumns = Object.values(ProjectsTableColumns).filter((column) => !columns?.includes(column));
    localStorageManager.setItem(PROJECTS_TABLE_HIDDEN_COLUMNS, hiddenColumns);
  }, []);

  const updatedColumnsOrder =
    columnsOrder.length === defaultActiveColumns.length
      ? columnsOrder
      : [...columnsOrder, ...defaultActiveColumns.filter((column) => !columnsOrder.includes(column))];

  const defaultColumnsWithMargins = hasAccess(ActionsType.ViewProjectCosts)
    ? defaultActiveColumns
    : defaultActiveColumns.filter((item) => !marginColumns.includes(item));

  const columnsOrderWithMargins = hasAccess(ActionsType.ViewProjectCosts)
    ? updatedColumnsOrder
    : updatedColumnsOrder.filter((item) => !marginColumns.includes(item as ProjectsTableColumns));

  return {
    defaultActiveColumns: defaultColumnsWithMargins,
    columnsOrder: columnsOrderWithMargins,
    onChangeColumnsOrder,
    activeColumns,
    onChangeActiveColumns,
  };
};
