import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ResourcePlanningRequestDataFragmentDoc, useEditRequestMutation } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks/useErrorMsgBuilder';
import { useAuth } from 'contexts';
import { client } from 'graphql-client';

export const useEditDatesAssignmentRequest = (id?: string) => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { t } = useTranslation();

  const [editAssignmentRequest] = useEditRequestMutation({
    onCompleted() {
      toast.success(t('forms.newAssignment.editedRequestSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const onEditAssignmentRequest = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const request = client.readFragment({
      id: `Request:${id}`,
      fragmentName: 'ResourcePlanningRequestData',
      fragment: ResourcePlanningRequestDataFragmentDoc,
    });

    return editAssignmentRequest({
      variables: {
        data: {
          note: request?.note,
          data: {
            ...request?.data,
            startDate,
            endDate,
          },
        },
        requestId: id || '',
        companyId: userData!.company.id,
      },
    });
  };

  return { onEditAssignmentRequest };
};
