import React from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

interface Props {
  count: number;
  className?: string;
}

export const CountChip = ({ count, className }: Props) => {
  return <div className={clsx(styles.chip, className)}>{count}</div>;
};
