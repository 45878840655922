import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import styles from './EmptyState.module.scss';

interface EmptyStateProps {
  label?: string;
  className?: string;
}

export const EmptyState = ({ label, className }: EmptyStateProps) => {
  const { t } = useTranslation();

  return (
    <tbody className={clsx(styles.container, className)}>
      <tr className={clsx(styles.emptyState, 'flex align-items-center justify-content-center')}>
        <td className="flex flex-column align-items-center">
          <span className="weight-600 text-secondary text-14">{label || t('table.emptyState')}</span>
        </td>
      </tr>
    </tbody>
  );
};
