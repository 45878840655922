import { ManagedProjectDataFragment } from 'generated/types';

const NO_CLIENT = 'noClient';
const NO_PM = 'noPM';
const NO_TYPE = 'noType';

export const groupProjectsByName = (data: ManagedProjectDataFragment[]) =>
  data.reduce((acc: Record<string, ManagedProjectDataFragment[]>, item) => {
    const clientId: string = item?.client?.id ?? NO_CLIENT;
    acc[clientId] = acc[clientId] || [];
    acc[clientId].push(item);
    return acc;
  }, {});

export const groupProjectsByPM = (data: ManagedProjectDataFragment[]) =>
  data
    .sort((a, b) => {
      return `${a.pm.first_name} ${a.pm.last_name}`.localeCompare(`${b.pm.first_name} ${b.pm.last_name}`, 'en');
    })
    .reduce((acc: Record<string, ManagedProjectDataFragment[]>, item) => {
      const pmId: string = item?.pm?.id ?? NO_PM;
      acc[pmId] = acc[pmId] || [];
      acc[pmId].push(item);
      return acc;
    }, {});

export const groupProjectsByType = (data: ManagedProjectDataFragment[]) =>
  data
    .sort((a, b) => {
      return a.type.localeCompare(b.type, 'en');
    })
    .reduce((acc: Record<string, ManagedProjectDataFragment[]>, item) => {
      const type: string = item?.type ?? NO_TYPE;
      acc[type] = acc[type] || [];
      acc[type].push(item);
      return acc;
    }, {});
