import React from 'react';
import { Route } from 'react-router-hoc';
import { ViewHeading } from 'components';

const ResourceRequestRoute = Route('/resource-request');

const ResourceRequest = () => {
  return (
    <div>
      <ViewHeading>Resource Request</ViewHeading>
      <div className="layout-content-wrapper">Resource Request</div>
    </div>
  );
};

export default ResourceRequestRoute(ResourceRequest);
