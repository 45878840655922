import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { ModalModeEnum, DateRangeTypes } from 'types';

export const AssignmentsRoute = Route(
  {
    projectId: Route.params.string,
    id: Route.params.regex(idRegEx).optional,
    mode: Route.params.oneOf(...Object.values(ModalModeEnum)).optional,
    range: Route.query.oneOf(...Object.values(DateRangeTypes)),
    start: Route.query.string,
    end: Route.query.string,
  },
  ({ projectId, id, mode }) => `/project/view/${projectId}/assignments/${id}/${mode}`,
);

export const Assignments = AssignmentsRoute(lazy(() => import(/* webpackChunkName: "Assignments" */ './Assignments')));
