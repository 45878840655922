import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  format,
  getQuarter,
  isBefore,
  isFirstDayOfMonth,
  isFriday,
  isLastDayOfMonth,
  isSameDay,
  isSaturday,
  isSunday,
  isWeekend,
  lastDayOfQuarter,
  lastDayOfYear,
  startOfQuarter,
} from 'date-fns';

import { useTimelineContext } from 'contexts';
import { MONTH_PERIOD, QUARTERS, YEAR_PERIOD } from 'consts';

import styles from './MonthlyRow.module.scss';

export const MonthlyRow = memo<{ days: Date[]; currentDate: Date }>(({ days, currentDate }) => {
  const { collapsedWeekends, timelinePeriod } = useTimelineContext();
  const { t } = useTranslation();

  if (timelinePeriod === MONTH_PERIOD) {
    return (
      <section className={styles.monthlyRow}>
        {days.map((day, index) => {
          return (
            <div
              key={day.toString()}
              className={clsx(styles.monthlyRowItem, {
                [collapsedWeekends
                  ? styles.monthlyRowItemDoubleRightLightShadow
                  : styles.monthlyRowItemRightLightShadow]: isLastDayOfMonth(day) || isSunday(day) || isFriday(day),
                [styles.monthlyRowLabel]: isFirstDayOfMonth(day) || !index,
                [styles.monthlyRowItemRightDarkShadow]: isSaturday(day) && isLastDayOfMonth(day),
                [styles.monthlyRowItemWithBackgroundMonthLine]: !isWeekend(day),
                [styles.monthlyRowItemWithBackground]: isWeekend(day),
                [styles.monthlyRowItemCollapsed]: collapsedWeekends && isWeekend(day),
              })}
            >
              <span className={styles.monthlyRowLabelText}>
                {(isFirstDayOfMonth(day) || !index) && format(day, isLastDayOfMonth(day) ? 'MMM' : 'MMM yy')}
              </span>
            </div>
          );
        })}
      </section>
    );
  }

  const isYearPeriodActive = timelinePeriod === YEAR_PERIOD;
  const lastDay = isYearPeriodActive ? lastDayOfYear(currentDate) : lastDayOfQuarter(currentDate);
  const label = `${t('resourcePlanning.timeline.period.quarter')} ${QUARTERS[getQuarter(currentDate)]} -`;

  return (
    <section className={styles.monthlyRow}>
      {days.map((day) => {
        const quarterLabel = isSameDay(startOfQuarter(currentDate), day) && !isYearPeriodActive ? label : '';
        const showLabel = isFirstDayOfMonth(day) && isBefore(day, lastDay);

        return (
          <div
            className={clsx(isYearPeriodActive ? styles.monthlyRowYearItem : styles.monthlyRowQuarterItem, {
              [styles.monthlyRowItemRightLightShadow]: isLastDayOfMonth(day),
              [styles.monthlyRowLabel]: isFirstDayOfMonth(day),
            })}
            key={day.toString()}
          >
            <span className={styles.monthlyRowLabelText}>
              {showLabel && `${quarterLabel} ${format(day, `MMM yy`)}`}
            </span>
          </div>
        );
      })}
    </section>
  );
});

MonthlyRow.displayName = 'Timeline.MonthlyRow';
