import { SortByFn } from 'react-table';
import { useCallback } from 'react';
import { BenchMemberData } from '../views/Insights/Bench/components/BenchTable/getTableData';

export const useBenchTableSorts = () => {
  const sortMemberType = useCallback<SortByFn<BenchMemberData>>((rowA, rowB) => {
    return `${rowA.original.memberName}`.localeCompare(`${rowB.original.memberName}`, 'en');
  }, []);

  const sortTitleType = useCallback<SortByFn<BenchMemberData>>((rowA, rowB, _columnId, desc) => {
    if (!rowA.original?.jobTitle) {
      return desc ? -1 : 1;
    }
    if (!rowB.original?.jobTitle) {
      return desc ? 1 : -1;
    }

    return (rowA.original?.jobTitle || '').localeCompare(rowB.original?.jobTitle || '', 'en');
  }, []);

  return {
    sortMemberType,
    sortTitleType,
  };
};
