import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Popover } from '@material-ui/core';
import { CalendarIcon, CheckIcon, DownTriangle, NavArrowLeftIcon, PlusIcon } from 'icons';
import { DatePicker } from 'components/common/DatePicker';
import {
  addMonths,
  addYears,
  endOfYear,
  format,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSameMonth,
  lastDayOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { DEFAULT_DATE_FORMAT, DEFAULT_YEAR_FORMAT, SHORT_DATE_FORMAT } from 'consts';
import { DateRangeTypes } from 'types';
import { getDateRangeFromType } from 'utils';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

import styles from './styles.module.scss';

type Option<T> = {
  label: string;
  value: T;
};

interface Props<T> {
  className?: string;
  options: Option<T>[];
  value: T | [Date, Date];
  onChange: (value: T | [Date, Date]) => void;
  showMonthYearPicker?: boolean;
  ableOneMonthOrYearMode?: boolean;
}

export const MenuDateRange: <T extends string>(p: Props<T>) => React.ReactElement<Props<T>> = ({
  className,
  options,
  value,
  onChange,
  showMonthYearPicker = false,
  ableOneMonthOrYearMode = false,
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isCalendarVisible, setCalendarVisible] = useState(typeof value !== 'string');

  const [range, setRange] = useState<[Date | null, Date | null]>(typeof value !== 'string' ? value : [null, null]);
  const isOneMonthMode = useMemo(() => {
    const [start, end] = range;
    const startDate = start ? new Date(start) : null;
    const endDate = end ? new Date(end) : null;

    return (
      startDate &&
      endDate &&
      isFirstDayOfMonth(startDate) &&
      isLastDayOfMonth(endDate) &&
      isSameMonth(startDate, endDate)
    );
  }, [range]);

  const isOneYearMode = useMemo(() => {
    const [start, end] = range;
    const isDateStartOfYear = start ? isSameDay(start, startOfYear(start)) : false;
    const isDateEndOfYear = end ? isSameDay(end, endOfYear(end)) : false;

    return isDateStartOfYear && isDateEndOfYear;
  }, [range]);

  const onToggle = useCallback(() => {
    setOpen((isOpen) => !isOpen);
  }, []);

  useEffect(() => {
    if (typeof value !== 'string') {
      setRange(value);
    }

    const monthModeDateRangeTypes = value === DateRangeTypes.lastMonth || value === DateRangeTypes.thisMonth;
    const yearModeDateRangeTypes = value === DateRangeTypes.prevYear || value === DateRangeTypes.thisYear;
    if (ableOneMonthOrYearMode && (monthModeDateRangeTypes || yearModeDateRangeTypes)) {
      const [start, end] = getDateRangeFromType(value as DateRangeTypes);
      onChange([new Date(start || ''), new Date(end || '')]);
    }
  }, [value]);

  const dateFormat = showMonthYearPicker ? SHORT_DATE_FORMAT : DEFAULT_DATE_FORMAT;

  const label = useMemo(() => {
    if (isOneYearMode) {
      return range[0] ? format(range[0], DEFAULT_YEAR_FORMAT) : '';
    }

    if (isOneMonthMode) {
      return range[0] ? format(range[0], SHORT_DATE_FORMAT) : '';
    }

    if (typeof value !== 'string') {
      return `${format(value[0], dateFormat)} - ${format(value[1], dateFormat)}`;
    }

    return options.find((option) => option.value === value)?.label;
  }, [value, range, isOneYearMode, isOneMonthMode]);

  const setPrevMonthPeriod = () => {
    const [start, end] = range;
    start && end && onChange([subMonths(start, 1), lastDayOfMonth(subMonths(end, 1))]);
  };
  const setNextMonthPeriod = () => {
    const [start, end] = range;
    start && end && onChange([addMonths(start, 1), lastDayOfMonth(addMonths(end, 1))]);
  };
  const setPrevYearPeriod = () => {
    const [start, end] = range;
    start && end && onChange([subYears(start, 1), lastDayOfMonth(subYears(end, 1))]);
  };
  const setNextYearPeriod = () => {
    const [start, end] = range;
    start && end && onChange([addYears(start, 1), lastDayOfMonth(addYears(end, 1))]);
  };

  return (
    <div className={clsx('flex', className)}>
      <Button
        ref={anchorRef}
        startIcon={<CalendarIcon />}
        onClick={onToggle}
        endIcon={<DownTriangle />}
        variant="text"
        className={styles.label}
      >
        {label}
      </Button>

      {isOneYearMode || isOneMonthMode ? (
        <div>
          <IconButton onClick={isOneYearMode ? setPrevYearPeriod : setPrevMonthPeriod} className={styles.iconButton}>
            <NavArrowLeftIcon />
          </IconButton>
          <IconButton onClick={isOneYearMode ? setNextYearPeriod : setNextMonthPeriod} className={styles.iconButton}>
            <NavArrowLeftIcon className={styles.rightArrow} />
          </IconButton>
        </div>
      ) : (
        ''
      )}
      <Popover
        anchorEl={anchorRef.current}
        onClose={onToggle}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: styles.container }}
      >
        <div className="flex justify-content-center">
          <ul className={styles.options}>
            {options.map((option) => (
              <li
                key={String(option.value)}
                className={styles.option}
                onClick={() => {
                  onChange(option.value);
                  setCalendarVisible(false);
                  setRange([null, null]);
                  onToggle();
                }}
              >
                <span>{option.label}</span>
                {value === option.value && !isCalendarVisible && <CheckIcon />}
              </li>
            ))}
            <div className={styles.divider} />
            <li key="custom" className={styles.option} onClick={() => setCalendarVisible(true)}>
              <div className="flex align-items-center">
                <PlusIcon fill="#85929A" />
                <span className="ml-12">Custom range</span>
              </div>
            </li>
          </ul>
          {isCalendarVisible && (
            <div className={styles.calendar}>
              <DatePicker
                value={range}
                inline
                range
                showMonthYearPicker={showMonthYearPicker}
                onChange={(dateRange) => {
                  const [startDate, endDate] = dateRange || [null, null];

                  if (startDate && endDate) {
                    onChange([startDate as Date, endDate as Date]);
                    onToggle();
                  }

                  setRange([startDate as Date, endDate as Date]);
                }}
              />
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};
