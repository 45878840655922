import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';

import { theme } from 'styles/muiTheme';
import App from './App';
import { AuthProvider, RouteTrackerProvider, SettingsProvider } from 'contexts';
import { client } from 'graphql-client';

import 'react-toastify/dist/ReactToastify.css';

import './locales/i18n';
import './index.scss';
import toastStyles from './styles/toast.module.scss';

import reportWebVitals from './reportWebVitals';
import { getToastIcon } from 'utils';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <ToastContainer
          icon={getToastIcon}
          draggablePercent={30}
          hideProgressBar
          className={toastStyles.container}
          toastClassName={toastStyles.toast}
          bodyClassName={toastStyles.toastBody}
          closeButton
        />
        <ApolloProvider client={client}>
          <Router>
            <AuthProvider>
              <SettingsProvider>
                <RouteTrackerProvider>
                  <App />
                </RouteTrackerProvider>
              </SettingsProvider>
            </AuthProvider>
          </Router>
        </ApolloProvider>
      </StylesProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
