import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import { DropdownOption, GetRouteProps, ModalModeEnum, TableCell } from 'types';
import { graphqlOnError } from 'utils';
import { RemoveConfirmIconButton, RightDrawer, Table, TableActionCell } from 'components';
import { useAuth } from 'contexts';
import {
  useCreateMemberRoleMutation,
  useDeleteMemberRoleMutation,
  useEditMemberRoleMutation,
  useRolesQuery,
} from 'generated/graphql';
import { useErrorMsgBuilder } from 'hooks';
import { ACTIONS, NAME } from 'consts';
import { Role } from 'generated/types';
import { ProjectRolesRoute } from './index';

import { IconButton, Tooltip } from '@material-ui/core';
import { EditIcon, PlusIcon } from 'icons';
import { NewRole, NewRoleFormValues } from './NewRole';

type Props = GetRouteProps<typeof ProjectRolesRoute>;

const ProjectRoles: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { roles = [] as DropdownOption[] } = {}, loading, refetch } = useRolesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData?.company.id as string,
    },
  });

  const roleToEdit = useMemo(() => {
    if (!params.roleId) {
      return;
    }

    return roles.find((role) => role.id === params.roleId);
  }, [params.roleId, roles]);

  const onCloseCreateModal = useCallback(() => {
    push(link({ ...params, mode: undefined }));
  }, [params]);

  const onCloseEditModal = useCallback(() => {
    push(link({ ...params, mode: undefined, roleId: undefined }));
  }, [params]);

  const [createRole] = useCreateMemberRoleMutation({
    onCompleted() {
      toast.success(t('role.roleCreatedSuccessfully'));
      onCloseCreateModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [editRole] = useEditMemberRoleMutation({
    onCompleted() {
      toast.success(t('role.roleEditedSuccessfully'));
      onCloseEditModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteRole] = useDeleteMemberRoleMutation({
    onCompleted() {
      toast.success(t('role.roleDeletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteRole) });
        cache.gc();
      }
    },
  });

  const handleCreateRole = (values: NewRoleFormValues) => {
    createRole({
      variables: {
        data: {
          ...values,
        },
        companyId: userData?.company.id as string,
      },
    });
  };

  const editRoles = (values: NewRoleFormValues) => {
    editRole({
      variables: {
        data: {
          ...values,
        },
        companyId: userData?.company.id as string,
        memberRoleId: roleToEdit?.id as string,
      },
    });
  };

  const handleDeleteRole = (id: string) =>
    deleteRole({
      variables: {
        memberRoleId: id,
        companyId: userData?.company.id as string,
      },
    });

  const columns = useMemo(
    () => [
      {
        Header: t('columns.roles.name'),
        accessor: NAME,
      },
      {
        Header: ' ',
        accessor: ACTIONS,
        Cell({ row: { original }, isHovered }: TableCell<Role>) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  onClick={() => push(link({ mode: ModalModeEnum.edit, roleId: original.id }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => handleDeleteRole(original.id)}
                confirmTitle={t('role.deleteRole')}
                confirmMessage={t('role.deleteRoleQuestion')}
                confirmSubmitButtonTitle={t('role.deleteRole')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('role.name')}</h2>

        <Button className="mr-8" onClick={() => push(link({ mode: ModalModeEnum.create }))} startIcon={<PlusIcon />}>
          {t('role.createRole')}
        </Button>
      </div>

      <Table data={roles as any} columns={columns as any} loading={loading} />

      <RightDrawer
        open={params.mode === ModalModeEnum.create}
        onClose={onCloseCreateModal}
        title={t('role.createRole')}
      >
        <NewRole onSubmit={handleCreateRole} onCancel={onCloseCreateModal} />
      </RightDrawer>

      <RightDrawer open={!!roleToEdit} onClose={onCloseEditModal} title={t('role.editRole')}>
        <NewRole
          name={roleToEdit?.name}
          onSubmit={editRoles}
          onCancel={onCloseEditModal}
          submitLabel={t('actions.save')}
        />
      </RightDrawer>
    </>
  );
};

export default ProjectRoles;
