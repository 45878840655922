import { PeopleForceIcon, ZohoPeopleIcon } from 'icons';
import { IntegrationsEnum } from '../Integrations.enum';

export const getIntegrationIcon = (name: string) => {
  switch (name) {
    case IntegrationsEnum.peopleForce:
      return PeopleForceIcon;
    case IntegrationsEnum.zohoPeople:
      return ZohoPeopleIcon;
    default:
      return null;
  }
};
