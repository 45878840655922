import React, { FC, memo, useMemo, useRef } from 'react';
import {
  EditProjectMembershipAccessDate,
  EmptyState,
  Popper,
  ProjectInfoCell,
  ProjectMembershipAccessMenu,
} from 'components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { useErrorMsgBuilder, useIsOpen, useProjectMembershipSubmit } from 'hooks';
import { ChevronIcon } from 'icons';
import { CreateMemberProjectsMembership, ShareProjectFormValues } from './components/CreateMemberProjectsMembership';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CompanyUserDataFragmentDoc, useProjectMembershipsQuery } from 'generated/graphql';
import { ApolloError } from '@apollo/client/errors';
import { getSortedProjectMemberships, graphqlOnError } from 'utils';
import { ProjectAccess, ProjectType } from 'generated/types';
import { useAuth } from 'contexts';
import { endOfDay, format, isAfter } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { client } from 'graphql-client';

import styles from './styles.module.scss';

interface Props {
  id: string;
  memberId: string;
  projectsAccessCount: number;
}

interface EditData {
  startDate?: string;
  endDate?: string;
  accessLevel: ProjectAccess;
  projectMembershipId: string;
  projectId: string;
}

export const ProjectAccessCell: FC<Props> = memo(({ id, memberId, projectsAccessCount }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [isOpen, onOpen, onClose] = useIsOpen();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { data: { projectMemberships = [] } = {}, loading } = useProjectMembershipsQuery({
    onError: (err: ApolloError) => {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      data: { memberId },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !isOpen,
  });
  const projectIdsWithAccess = useMemo(() => projectMemberships.map(({ projectId }) => projectId), [
    projectMemberships,
  ]);
  const sortedProjectMemberships = useMemo(() => getSortedProjectMemberships(projectMemberships), [projectMemberships]);

  const {
    createMemberProjectsMembership,
    editProjectPm,
    editProjectMembership,
    deleteProjectMembership,
    createLoading,
  } = useProjectMembershipSubmit({ memberId });

  const userCacheUpdate = (projectsAccessCount: number) => {
    const data = client.readFragment({
      id: `CompanyUser:${id}`,
      fragmentName: 'CompanyUserData',
      fragment: CompanyUserDataFragmentDoc,
      variables: {
        withAccessCount: true,
      },
    });

    client.writeFragment({
      id: `CompanyUser:${id}`,
      fragmentName: 'CompanyUserData',
      fragment: CompanyUserDataFragmentDoc,
      data: { ...data, projectsAccessCount },
      variables: {
        withAccessCount: true,
      },
    });
  };

  const onDelete = async (projectMembershipId: string, projectId: string) => {
    await deleteProjectMembership({
      variables: {
        companyId: userData!.company.id,
        projectId,
        projectMembershipId,
      },
    });
    userCacheUpdate(projectMemberships.length - 1);
  };

  const onCreateProjectMembership = async ({
    projects,
    startDate,
    endDate,
    accessLevel,
    assignAsPM,
  }: ShareProjectFormValues) => {
    if (assignAsPM) {
      await editProjectPm({
        variables: {
          companyId: userData!.company.id,
          memberId,
          data: {
            projectsMembership: projects.map(({ id, type }) => ({
              accessLevel:
                accessLevel.id === ProjectAccess.RatesAccess && type === ProjectType.NonBillable
                  ? ProjectAccess.NonAccess
                  : accessLevel.id,
              projectId: id,
            })),
            startDate: startDate ? format(new Date(startDate), DEFAULT_DATE_FORMAT) : undefined,
            endDate: endDate ? format(new Date(endDate), DEFAULT_DATE_FORMAT) : undefined,
          },
        },
        onCompleted() {
          client.refetchQueries({ include: ['Users', 'ProjectMemberships'], optimistic: true });
        },
      });
      return;
    }

    const { data } = await createMemberProjectsMembership({
      variables: {
        companyId: userData!.company.id,
        memberId,
        data: {
          projectsMembership: projects.map(({ id, type }) => ({
            accessLevel:
              accessLevel.id === ProjectAccess.RatesAccess && type === ProjectType.NonBillable
                ? ProjectAccess.NonAccess
                : accessLevel.id,
            projectId: id,
          })),
          startDate: startDate ? format(new Date(startDate), DEFAULT_DATE_FORMAT) : undefined,
          endDate: endDate ? format(new Date(endDate), DEFAULT_DATE_FORMAT) : undefined,
        },
      },
    });
    userCacheUpdate(projectMemberships.length + (data?.createMemberProjectsMembership?.length || 0));
  };

  const onEditProjectMembership = async ({
    startDate,
    endDate,
    accessLevel,
    projectMembershipId,
    projectId,
  }: EditData) => {
    await editProjectMembership({
      variables: {
        companyId: userData!.company.id,
        projectId,
        projectMembershipId,
        data: {
          startDate: startDate ? format(new Date(startDate), DEFAULT_DATE_FORMAT) : undefined,
          endDate: endDate ? format(new Date(endDate), DEFAULT_DATE_FORMAT) : undefined,
          accessLevel,
        },
      },
    });
  };

  return (
    <>
      <Popper
        isOpen={isOpen && !(!projectMemberships.length && loading)}
        onClose={onClose}
        button={
          <Button
            ref={anchorRef}
            variant="text"
            className={styles.button}
            endIcon={<ChevronIcon className={clsx(styles.icon, isOpen && styles.open, !memberId && styles.disabled)} />}
            onClick={isOpen ? onClose : onOpen}
            disabled={!memberId}
          >
            {projectsAccessCount || 0}&nbsp;
            {projectsAccessCount === 1 ? t('shareProject.project') : t('shareProject.projects')}
          </Button>
        }
      >
        <div className={styles.container}>
          <CreateMemberProjectsMembership
            selectedProjectIds={projectIdsWithAccess}
            onSubmit={onCreateProjectMembership}
            loading={createLoading}
          />

          <h3 className={styles.listTitle}>{t('shareProject.projectAccess')}</h3>
          <div className={styles.box}>
            {!projectMemberships.length && loading ? (
              <div className="flex justify-content-center py-48">
                <CircularProgress color="inherit" size={16} />
              </div>
            ) : (
              ''
            )}
            {!loading && !sortedProjectMemberships.length && (
              <EmptyState className="mt-40" title="shareProject.noProjectsFound" />
            )}
            {sortedProjectMemberships.map(
              ({ projectId, pmId, projectName, projectColor, projectType, accessLevel, id, startDate, endDate }) => (
                <div
                  key={projectId}
                  className={clsx(
                    styles.projectItem,
                    endDate && isAfter(new Date(), endOfDay(new Date(endDate))) && styles.projectOpacity,
                  )}
                >
                  <div className="flex align-items-center">
                    <ProjectInfoCell
                      hasTooltip
                      titleClassName={styles.projectCell}
                      color={projectColor || ''}
                      title={projectName}
                    />
                    <div className="ml-auto">
                      <ProjectMembershipAccessMenu
                        value={accessLevel}
                        onChange={({ id: accessLevel }) =>
                          onEditProjectMembership({
                            startDate,
                            endDate,
                            accessLevel,
                            projectMembershipId: id,
                            projectId,
                          })
                        }
                        isNonBillableProject={projectType === ProjectType.NonBillable}
                        onDelete={pmId !== memberId ? () => onDelete(id, projectId) : undefined}
                      />
                    </div>
                  </div>
                  <div className={styles.memberDate}>
                    <EditProjectMembershipAccessDate
                      accessLevel={accessLevel}
                      startDate={startDate}
                      endDate={endDate}
                      onEdit={({ startDate, endDate }) =>
                        onEditProjectMembership({ startDate, endDate, accessLevel, projectMembershipId: id, projectId })
                      }
                      showConfirm
                    />
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </Popper>
    </>
  );
});

ProjectAccessCell.displayName = 'ProjectAccessCell';
