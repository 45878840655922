import { ActivityHistoryAction, AvailableDomains } from 'generated/types';
import { AssignmentLog, DeleteLog, EditLog, LeaveLog, ProjectLog, RateLog, TeamMemberLog } from 'icons';

interface Props {
  type: AvailableDomains;
  action: ActivityHistoryAction;
}

export const getLogIcon = ({ type, action }: Props) => {
  switch (true) {
    case action === ActivityHistoryAction.Delete:
      return DeleteLog;
    case action === ActivityHistoryAction.Update:
      return EditLog;
    case type === AvailableDomains.Member:
      return TeamMemberLog;
    case type === AvailableDomains.Assignment:
      return AssignmentLog;
    case type === AvailableDomains.MemberLeave:
      return LeaveLog;
    case type === AvailableDomains.MemberInternalRate:
      return RateLog;
    case type === AvailableDomains.Expense:
      return RateLog;
    case type === AvailableDomains.ExternalRate:
      return RateLog;
    case type === AvailableDomains.Project:
      return ProjectLog;
    default:
      return AssignmentLog;
  }
};
