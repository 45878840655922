import { Route } from 'react-router-hoc';
import { lazy } from 'react';
import { idRegEx } from 'utils/regex';

export const BillingRoute = Route(
  {
    id: Route.params.regex(idRegEx).optional,
    search: Route.query.string,
    status: Route.query.string,
  },
  ({ id }) => `/billing/${id}`,
);

export const Billing = BillingRoute(lazy(() => import(/* webpackChunkName: "Billing" */ './Billing')));
