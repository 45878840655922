import React, { FC } from 'react';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  isShowProject?: boolean;
  isShowMember?: boolean;
  longOptionalField?: boolean;
}

export const TimeLogsHeader: FC<Props> = ({ isShowProject, isShowMember, longOptionalField }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <span className={styles.date}>{t('timeTracking.date')}</span>
      {isShowMember ? <span className={styles.member}>{t('timeTracking.member')}</span> : ''}
      {isShowProject ? (
        <span className={longOptionalField ? styles.longOptionalField : styles.member}>
          {t('timeTracking.project')}
        </span>
      ) : (
        ''
      )}
      <span className={styles.logged}>{t('timeTracking.logged')}</span>
      <span className={styles.note}>{t('timeTracking.note')}</span>
    </div>
  );
};
