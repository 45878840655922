import { ExternalRateDataFragment } from 'generated/types';
import { isAfter, isWithinInterval } from 'date-fns';

export const getSeniorityRateData = (rates: ExternalRateDataFragment[]) => {
  return rates.reduce<{
    actualRate: ExternalRateDataFragment | null;
    rateItems: ExternalRateDataFragment[];
  }>(
    (acc, rate, index, array) => {
      if (array.length === 1) {
        return {
          actualRate: rate,
          rateItems: [],
        };
      }

      const isActualRate = rate.end_date
        ? isWithinInterval(new Date(), {
            start: new Date(rate.start_date),
            end: new Date(rate.end_date),
          })
        : isAfter(new Date(), new Date(rate.start_date));

      if (isActualRate) {
        return {
          actualRate: rate,
          rateItems: acc.rateItems,
        };
      }

      if (index + 1 === array.length && !acc.actualRate) {
        const [firstRate, ...rateItems] = acc.rateItems;

        return {
          actualRate: firstRate,
          rateItems: [...rateItems, rate],
        };
      }

      return {
        ...acc,
        rateItems: [...acc.rateItems, rate],
      };
    },
    {
      actualRate: null,
      rateItems: [],
    },
  );
};
