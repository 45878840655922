import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { CollapseArrowIcon, PlusIcon } from 'icons';
import { Collapse as CollapseComponent } from '@material-ui/core';

import styles from './styles.module.scss';

export interface CollapseProps {
  children: ReactNode;
  header: ReactNode;
  className?: string;
  withBorders?: boolean;
  onAddNewAction?: () => void;
}

export const Collapse = ({ children, header, className, onAddNewAction, withBorders }: CollapseProps) => {
  const [open, setOpen] = React.useState(true);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        className={clsx(
          styles.wrapper,
          withBorders && styles.wrapperWithBorders,
          open && withBorders && styles.openedWrapperWithBorders,
        )}
      >
        <div className={clsx(styles.collapse, className)} onClick={toggleCollapse}>
          <CollapseArrowIcon className={clsx(styles.collapseIcon, !open && styles.closed)} />
          {header}
        </div>
        {onAddNewAction && <PlusIcon fill={'#5C6E79'} onClick={onAddNewAction} className={styles.wrapperSvg} />}
      </div>
      <CollapseComponent in={open} timeout={0}>
        {children}
      </CollapseComponent>
    </>
  );
};
