export const KILOBYTE = 1024;
export const MEGABYTE = 1048576;

export const getFileSize = (number: number): string => {
  if (number < KILOBYTE) {
    return `${number} B`;
  }

  if (number > KILOBYTE && number < MEGABYTE) {
    return `${(number / KILOBYTE).toFixed(1)} KB`;
  }

  return `${(number / MEGABYTE).toFixed(1)} MB`;
};
