import { idRegEx } from 'utils/regex';
import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import SkillsComponent from './Skills';

export const SkillsRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    skillId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, skillId }) => `/settings/resource-management/skills/${mode}/${skillId}`,
);

export const Skills = SkillsRoute(SkillsComponent);
