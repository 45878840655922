import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'components';
import { RequestModalIcon } from 'icons';
import styles from './styles.module.scss';

interface RequestInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RequestInfoModal: FC<RequestInfoModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      title={t('request.confirmModal.title')}
      submitButtonTitle={t('forms.done')}
      isOpen={isOpen}
      onSubmit={onClose}
      onClose={onClose}
      hideCancelButton
    >
      <div className="flex">
        <RequestModalIcon className={styles.requestModalIcon} />

        <div className={styles.requestModalDescription}>
          <span>{t('request.confirmModal.text')}</span>
          <span>{t('request.confirmModal.description')}</span>
        </div>
      </div>
    </ConfirmModal>
  );
};
