import React, { memo, useMemo } from 'react';

import { QUARTER_PERIOD, YEAR_PERIOD } from 'consts';
import { useTimelineContext } from 'contexts';

import { DailyRow } from './DailyRow';
import { MonthlyRow } from './MonthlyRow';
import { getCurrentQuarter, getCurrentYear, getNextFourWeeks } from '../../utils';

export const TimelineHead = memo<{ date: Date }>(({ date }) => {
  const { timelinePeriod } = useTimelineContext();

  const yearDays = useMemo(() => getCurrentYear(date), [date]);
  const quarterDays = useMemo(() => getCurrentQuarter(date), [date]);
  const monthDays = useMemo(() => getNextFourWeeks(date), [date]);

  const days = useMemo(() => {
    switch (timelinePeriod) {
      case YEAR_PERIOD:
        return yearDays;
      case QUARTER_PERIOD:
        return quarterDays;
      default:
        return monthDays;
    }
  }, [date, timelinePeriod]);

  return (
    <>
      <MonthlyRow days={days} currentDate={date} />
      <DailyRow days={days} />
    </>
  );
});

TimelineHead.displayName = 'Timeline.Head';
