import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { localStorageManager } from 'services';
import { IS_EMPLOYEE_MODE, IS_FINANCIALS_HIDDEN } from 'consts';
import mixpanel from 'mixpanel-browser';

interface SettingsContextInterface {
  isFinancialsHidden: boolean;
  onToggleIsFinancialsHidden: () => void;
  isEmployeeMode: boolean;
  onChangeIsEmployeeMode: (value: boolean) => void;
}

interface SettingsProviderProps {
  children: ReactNode;
}

const SettingsContext = createContext<SettingsContextInterface>({
  isFinancialsHidden: false,
  onToggleIsFinancialsHidden: () => null,
  isEmployeeMode: false,
  onChangeIsEmployeeMode: () => null,
});

export const useSettings = () => useContext(SettingsContext) as SettingsContextInterface;

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const [isFinancialsHidden, setIsFinancialsHidden] = useState(!!localStorageManager.getItem(IS_FINANCIALS_HIDDEN));
  const [isEmployeeMode, setIsEmployeeMode] = useState(!!localStorageManager.getItem(IS_EMPLOYEE_MODE));

  const onToggleIsFinancialsHidden = useCallback(
    () =>
      setIsFinancialsHidden((prev) => {
        mixpanel.track(prev ? 'Financials shown' : 'Financials hidden');
        localStorageManager.setItem(IS_FINANCIALS_HIDDEN, !prev);
        return !prev;
      }),
    [],
  );

  const onChangeIsEmployeeMode = useCallback((value: boolean) => {
    localStorageManager.setItem(IS_EMPLOYEE_MODE, value);
    setIsEmployeeMode(value);
  }, []);

  const contextValue = useMemo(() => {
    return {
      isFinancialsHidden,
      isEmployeeMode,
      onToggleIsFinancialsHidden,
      onChangeIsEmployeeMode,
    };
  }, [isFinancialsHidden, isEmployeeMode]);

  return <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>;
};
