import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { lazy } from 'react';
import { TimeTrackingTabs } from 'types';

export const TimeTrackingRoute = Route(
  {
    id: Route.params.regex(idRegEx).optional,
    tab: Route.params.oneOf(TimeTrackingTabs.list, TimeTrackingTabs.timesheet).optional,
    month: Route.query.string,
    search: Route.query.string,
  },
  ({ id, tab }) => `/project/view/${id}/time-tracking/${tab}`,
);

export const TimeTracking = TimeTrackingRoute(
  lazy(() => import(/* webpackChunkName: "TimeTracking" */ './TimeTracking')),
);
