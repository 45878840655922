import React, { FC } from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';

import { AbsoluteSpinner, ConfirmIconButton, EmptyState } from 'components';
import { ActionsType } from 'generated/types';
import { ArchiveIcon } from 'icons';
import { usePermissions, useRestoreProject, useTrackScreenView } from 'hooks';
import { GroupByFilter, ModuleName, ScreenName } from 'types';
import { ProjectData, ProjectsTable } from 'views/Projects/components/ProjectsTable';

import styles from './styles.module.scss';

const ArchivedProjectsTabRoute = Route('/projects/archived');

interface Props {
  projects: ProjectData[];
  loading: boolean;
  groupBy?: GroupByFilter;
}

const Projects: FC<Props> = ({ projects, loading, groupBy }) => {
  const { t } = useTranslation();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  useTrackScreenView(ModuleName.projects, ScreenName.list);

  const handleRestoreProject = useRestoreProject();

  if (loading || isPermissionsLoading) {
    return <AbsoluteSpinner />;
  }

  if (!projects.length) {
    return <EmptyState className={styles.emptyState} />;
  }

  if (!hasAccess(ActionsType.ViewArchivedProjects)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <ProjectsTable
      projects={projects}
      groupBy={groupBy}
      getTableActions={(id) => (
        <ConfirmIconButton
          tooltipText={t('actions.restore')}
          onClick={() => handleRestoreProject(id)}
          confirmTitle={t('projects.restoreProject')}
          confirmMessage={t('projects.confirmRestoreProject')}
          confirmSubmitButtonTitle={t('projects.restoreProject')}
        >
          <ArchiveIcon />
        </ConfirmIconButton>
      )}
    />
  );
};

export default ArchivedProjectsTabRoute(Projects);
