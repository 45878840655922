import { useCreateSeniorityMutation, useSenioritiesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { Reference } from '@apollo/client';
import { useAuth } from '../contexts';
import { MemberSeniority } from '../generated/types';

export const useCreatableSeniorityData = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { seniorities = [] } = {}, loading: senioritiesLoading } = useSenioritiesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const [createSeniority] = useCreateSeniorityMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createSeniority) return;

      cache.modify({
        fields: {
          seniorities(items: Reference[] = []) {
            return [...items, data.createSeniority];
          },
        },
      });
    },
  });

  const getCreatedSeniority = async (value: Pick<MemberSeniority, 'id' | 'name'> | null) => {
    if (value?.id) {
      return value;
    }

    if (!value?.name) {
      return null;
    }

    const { data } = await createSeniority({
      variables: {
        data: { name: value?.name || '' },
        companyId: userData!.company.id,
      },
    });

    return data?.createSeniority || null;
  };

  return { seniorities, senioritiesLoading, getCreatedSeniority };
};
