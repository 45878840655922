import React, { useCallback, useMemo, useState } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { LoadingButton, PassVisibilityBtn } from 'components';
import { submitForm } from 'utils';

export interface CompleteProfileFormValues {
  firstName: string;
  lastName: string;
  password: string;
  confirmedPassword: string;
}

interface CompleteProfileProps {
  onSubmit: (values: CompleteProfileFormValues) => void | Promise<void>;
  firstName?: string;
  lastName?: string;
  password?: string;
  confirmedPassword?: string;
}

export const CompleteProfile = (props: CompleteProfileProps) => {
  const { t } = useTranslation();
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isPassConfVisible, setIsPassConfVisible] = useState(false);
  const { firstName, lastName, password, confirmedPassword, onSubmit } = props;

  const togglePasswordVisibility = useCallback(() => setIsPassVisible((prev) => !prev), []);
  const togglePasswordConfirmationVisibility = useCallback(() => setIsPassConfVisible((prev) => !prev), []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(t('forms.completeProfile.firstNameError')),
        lastName: Yup.string().required(t('forms.completeProfile.lastNameError')),
        password: Yup.string()
          .required(t('forms.completeProfile.passwordError.required'))
          .min(8, t('forms.completeProfile.passwordError.minLength'))
          .matches(/\S{8,}/, t('forms.completeProfile.passwordError.minLength')),
        confirmedPassword: Yup.string()
          .required(t('forms.completeProfile.confirmPasswordError.required'))
          .oneOf([Yup.ref('password')], t('forms.completeProfile.confirmPasswordError.match')),
      }),
    [],
  );

  const handleSubmit = (
    values: CompleteProfileFormValues,
    { setSubmitting }: FormikHelpers<CompleteProfileFormValues>,
  ) => {
    submitForm(values, setSubmitting, onSubmit);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        password: password ?? '',
        confirmedPassword: confirmedPassword ?? '',
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputLabel className="required">{t('forms.completeProfile.firstName')}</InputLabel>
          <Field
            component={TextField}
            name="firstName"
            placeholder={t('forms.completeProfile.firstName')}
            type="text"
            className="mb-24"
          />

          <InputLabel className="required">{t('forms.completeProfile.lastName')}</InputLabel>
          <Field
            component={TextField}
            name="lastName"
            placeholder={t('forms.completeProfile.lastName')}
            type="text"
            className="mb-24"
          />

          <InputLabel className="required">{t('forms.password')}</InputLabel>
          <Field
            component={TextField}
            type={isPassVisible ? 'text' : 'password'}
            name="password"
            placeholder={t('forms.completeProfile.newPassword')}
            className="mb-24"
            InputProps={{
              endAdornment: (
                <Tooltip
                  aria-label={t('forms.passwordVisibility')}
                  title={isPassVisible ? (t('forms.hidePassword') as string) : (t('forms.showPassword') as string)}
                  placement="right"
                >
                  <span className="ml-6">
                    <PassVisibilityBtn
                      isPassVisible={isPassVisible}
                      togglePasswordVisibility={togglePasswordVisibility}
                    />
                  </span>
                </Tooltip>
              ),
            }}
          />

          <InputLabel className="required">{t('forms.completeProfile.confirmPassword')}</InputLabel>
          <Field
            component={TextField}
            type={isPassConfVisible ? 'text' : 'password'}
            name="confirmedPassword"
            placeholder={t('forms.completeProfile.confirmPassword')}
            className="mb-24"
            InputProps={{
              endAdornment: (
                <Tooltip
                  aria-label={t('forms.passwordVisibility')}
                  title={isPassConfVisible ? (t('forms.hidePassword') as string) : (t('forms.showPassword') as string)}
                  placement="right"
                >
                  <span className="ml-6">
                    <PassVisibilityBtn
                      isPassVisible={isPassConfVisible}
                      togglePasswordVisibility={togglePasswordConfirmationVisibility}
                    />
                  </span>
                </Tooltip>
              ),
            }}
          />

          <LoadingButton type="submit" loading={isSubmitting} fullWidth>
            {t('forms.completeProfile.done')}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
};
