import React from 'react';
import clsx from 'clsx';
import { Route } from 'react-router-hoc';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

import { ForgotPass as ForgotPassForm, ForgotPasswordFormValues, UnauthFormHeading } from 'components';
import { useResetPasswordMutation } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder, useTrackScreenView } from 'hooks';
import { useHistory, useLocation } from 'react-router';
import { links } from 'App';
import { ArrowBack } from 'icons';

import styles from './ForgotPass.module.scss';
import { ModuleName, ScreenName } from 'types';

export const ForgotPass = () => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const history = useHistory();
  const { state } = useLocation();
  useTrackScreenView(ModuleName.auth, ScreenName.forgotPassword, false, true);

  const [resetPassword] = useResetPasswordMutation({
    onCompleted() {
      toast.success(t('forgotPassword.resetPasswordSuccessfully'));
      history.push(links.Login());
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const goBack = () => history.push(state.from);

  const handleResetPassword = async (values: ForgotPasswordFormValues) => {
    await resetPassword({
      variables: values,
    });
  };

  return (
    <div className={clsx('form-content', 'col-3')}>
      <UnauthFormHeading heading={t('forms.recoveryPassword')}>
        {state && (
          <IconButton onClick={goBack} className={clsx(styles.backBtn, 'mr-8', 'p-8')}>
            <ArrowBack />
          </IconButton>
        )}
      </UnauthFormHeading>
      <div className="px-30 pt-24 pb-30">
        <ForgotPassForm onSubmit={handleResetPassword} />
      </div>
    </div>
  );
};

export default Route('/forgot-password')(ForgotPass);
