import { Button, Checkbox, FormControlLabel, InputLabel, TextField } from '@material-ui/core';
import { LoadingButton } from 'components';
import React, { FC, useMemo } from 'react';
import { ILeaveType, LeaveTypeDataFragment } from 'generated/types';
import { useFormik } from 'formik';
import { boolean, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { submitForm } from 'utils';
import clsx from 'clsx';
import { CheckboxIcon } from 'icons';

import styles from './styles.module.scss';

export type FormValues = {
  name?: string;
  paid?: boolean;
};

interface Props {
  onSubmit: (data: ILeaveType) => Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
  initialValues?: LeaveTypeDataFragment;
}

export const LeaveTypeForm: FC<Props> = ({ onSubmit, submitLabel, onCancel, initialValues }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      object({
        name: string().required('validation.required'),
        paid: boolean().required('validation.required'),
      }),
    [],
  );

  const {
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    errors,
    touched,
    submitCount,
    isSubmitting,
    setSubmitting,
  } = useFormik<FormValues>({
    onSubmit: (data) => {
      submitForm({ name: data.name!, paid: !!data.paid }, setSubmitting, onSubmit);
    },
    validationSchema,
    initialValues: {
      name: initialValues?.name || '',
      paid: initialValues?.paid || false,
    },
  });

  return (
    <form onSubmit={handleSubmit} className="form">
      <div>
        <div className="mb-24">
          <InputLabel>{t('settings.resourceManagement.leaveTypes.leaveTypeName')}</InputLabel>
          <TextField
            error={Boolean((submitCount || touched.name) && errors.name)}
            helperText={(submitCount || touched.name) && t(errors.name!)}
            onBlur={handleBlur}
            onChange={handleChange}
            name="name"
            value={values.name}
          />
        </div>

        <FormControlLabel
          className={styles.checkboxLabel}
          classes={{
            label: clsx(styles.checkboxLabelText, values.paid && styles.active),
          }}
          control={<Checkbox color="primary" checked={values.paid} icon={<CheckboxIcon />} />}
          label={t('settings.resourceManagement.leaveTypes.paid')}
          name="paid"
          onChange={handleChange}
        />
      </div>

      <div className="controls">
        <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
          {submitLabel ?? t('actions.create')}
        </LoadingButton>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {t('forms.cancel')}
        </Button>
      </div>
    </form>
  );
};
