import React, { FC } from 'react';
import { MemberStatus } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { Warning } from 'icons';

import clsx from 'clsx';
import styles from './styles.module.scss';

export interface Props {
  integrationName: string;
  status: MemberStatus;
}

export const MemberIntegrationStatus: FC<Props> = ({ integrationName, status }) => {
  const { t } = useTranslation();
  const isDisabled = status === MemberStatus.Disabled;

  return (
    <div
      className={clsx(styles.box, isDisabled && styles.disabled, {
        [styles.disabled]: status === MemberStatus.Archived,
        [styles.active]: status === MemberStatus.Active,
        [styles.gray]: status === MemberStatus.NoAccount,
      })}
    >
      {isDisabled && <Warning className={styles.warningIcon} />}
      {t(`memberIntegrationStatuses.${status}`)} {integrationName}
    </div>
  );
};
