import React from 'react';

interface PlusIconProps {
  className?: string;
  onClick?: () => void;
  fill?: string;
}

export const PlusIcon = ({ className, onClick, fill = '#fff' }: PlusIconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M10 3.333c.46 0 .833.373.833.833v5h5a.833.833 0 110 1.667h-5v5a.833.833 0 01-1.666 0v-5h-5a.833.833 0 110-1.667h5v-5c0-.46.373-.833.833-.833z"
      ></path>
    </svg>
  );
};
