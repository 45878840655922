import React, { FC, useState } from 'react';
import { ManagedProjectDataFragmentDoc, useEditProjectPmFieldMutation } from 'generated/graphql';
import { getAcronym, getFullName, graphqlOnError } from 'utils';
import { PMAccessFormState, PMUpdateConfirm, UserInfo, UsersTagMenu } from 'components';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, useIsOpen, usePermissions } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ActionsType, Member, ProjectAccess, ProjectType } from 'generated/types';
import { client } from 'graphql-client';
import { toast } from 'react-toastify';

import styles from './styles.module.scss';

type ProjectManager = Pick<Member, 'id' | 'first_name' | 'last_name' | 'color'>;
interface Props {
  projectId: string;
  pm: ProjectManager;
  type: ProjectType;
}

export const ProjectManagerCell: FC<Props> = ({ pm, projectId, type }) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();
  const [selectedPM, setSelectedPM] = useState<ProjectManager | null>(null);
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen();

  const [editProjectPm] = useEditProjectPmFieldMutation({
    onCompleted() {
      toast.success(t('projects.projectsManagerSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const cacheUpdate = (pm: ProjectManager) => {
    const project = client.readFragment({
      id: `Project:${projectId}`,
      fragmentName: 'ManagedProjectData',
      fragment: ManagedProjectDataFragmentDoc,
    });

    client.writeFragment({
      id: `Project:${projectId}`,
      fragmentName: 'ManagedProjectData',
      fragment: ManagedProjectDataFragmentDoc,
      data: { ...project, pm },
    });
  };

  const onEditProjectPm = async (pm: ProjectManager) => {
    setSelectedPM(pm);
    onOpenConfirm();
  };

  const onSubmitEditProjectPm = async ({ startDate, endDate, accessLevel }: PMAccessFormState) => {
    if (selectedPM) {
      cacheUpdate(selectedPM);
      await editProjectPm({
        variables: {
          companyId: userData!.company.id,
          memberId: selectedPM.id || '',
          data: {
            projectsMembership: [{ accessLevel: accessLevel || ProjectAccess.FullAccess, projectId }],
            endDate,
            startDate,
          },
        },
      });
      onCloseConfirm();
    }
  };

  return (
    <>
      {hasAccess(ActionsType.ShareProject) ? (
        <UsersTagMenu value={pm} onChange={onEditProjectPm}>
          <UserInfo
            title={getFullName(pm.first_name, pm.last_name, t('notApplicable'))}
            titleClassName={styles.projectManagerTitle}
            avatarTitle={getAcronym(pm.first_name, pm.last_name)}
            color={pm.color}
          />
        </UsersTagMenu>
      ) : (
        <UserInfo
          title={getFullName(pm.first_name, pm.last_name, t('notApplicable'))}
          titleClassName={styles.projectManagerTitle}
          avatarTitle={getAcronym(pm.first_name, pm.last_name)}
          color={pm.color}
        />
      )}

      <PMUpdateConfirm
        pm={selectedPM}
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        onSubmit={onSubmitEditProjectPm}
        isNonBillableProject={type === ProjectType.NonBillable}
      />
    </>
  );
};
