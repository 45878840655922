export enum CostMainBlockValueTypes {
  profit = 'profit',
  cost = 'cost',
  revenue = 'revenue',
}

export const CostMainBlockValueTypesLabel = Object.freeze<Record<CostMainBlockValueTypes, string>>({
  [CostMainBlockValueTypes.profit]: 'costMainBlock.profit',
  [CostMainBlockValueTypes.cost]: 'costMainBlock.cost',
  [CostMainBlockValueTypes.revenue]: 'costMainBlock.revenue',
});
