import React, { memo, useLayoutEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { format, isFriday, isSaturday, isSunday, isToday, isWeekend } from 'date-fns';

import { MONTH_PERIOD, YEAR_PERIOD } from 'consts';
import { generateRandomId } from 'utils';
import { useTimelineContext } from 'contexts';

import { getCurrentWeekYearIndex, getDaysGroupedByBusinessWeek } from '../../../utils';

import { WeekendCollapse } from 'icons';
import styles from './DailyRow.module.scss';

const CALENDAR_WEEK = 7;
const YEAR_MIN_COLUMN_WIDTH = 3;
const QUARTER_MIN_COLUMN_WIDTH = 12;
const MONTH_VIEW_CALENDAR_DAYS = 35;
const MONTH_VIEW_BUSINESS_DAYS = 25;

interface WeekendCollapseIconProps {
  day: Date;
  index: number;
  secondLastIndex: number;
}

const WeekendCollapseIcon = memo<WeekendCollapseIconProps>(({ day, index, secondLastIndex }) => {
  const { collapsedWeekends } = useTimelineContext();

  return (
    <WeekendCollapse
      className={clsx(styles.dailyRowItemWeekendIcon, {
        [styles.dailyRowItemSaturdayIcon]: isSaturday(day),
        [styles.dailyRowItemSaturdayIconDisabled]: collapsedWeekends && isSaturday(day) && index === secondLastIndex,
        [styles.dailyRowItemSundayIcon]: isSunday(day),
      })}
    />
  );
});

export const DailyRow = memo<{ days: Date[] }>(({ days }) => {
  const { t } = useTranslation();
  const { collapsedWeekends, onCollapse, timelinePeriod, setCellWidth } = useTimelineContext();

  const secRef = useRef<HTMLDivElement | null>(null);

  const todayDate = new Date();
  const activePeriodDays = days.length;
  const secondLastDaysIndex = days.length - 2;
  const isYearPeriodActive = timelinePeriod === YEAR_PERIOD;
  const isMonthPeriodActive = timelinePeriod === MONTH_PERIOD;

  const monthViewDays = collapsedWeekends ? MONTH_VIEW_BUSINESS_DAYS : MONTH_VIEW_CALENDAR_DAYS;

  useLayoutEffect(() => {
    if (secRef.current?.clientWidth) {
      const width = secRef.current?.clientWidth / (isMonthPeriodActive ? monthViewDays : activePeriodDays);
      setCellWidth(Math.round(width * 10) / 10);
    }
  }, [collapsedWeekends, days]);

  const weeks = useMemo(() => getDaysGroupedByBusinessWeek(days), [days]);

  const tooltipTitle = `resourcePlanning.timeline.navigation.${
    collapsedWeekends ? 'expandWeekends' : 'collapseWeekends'
  }`;

  if (isMonthPeriodActive) {
    return (
      <section className={clsx(styles.dailyRow)} ref={secRef}>
        {days.map((day, index) => (
          <div
            className={clsx(styles.dailyRowItem, {
              [collapsedWeekends ? styles.dailyRowItemDoubleRightLightShadow : styles.dailyRowItemRightLightShadow]:
                isSunday(day) || isFriday(day),
              [styles.dailyRowItemCurrent]: isToday(day),
              [styles.dailyRowItemWithBackground]: !isWeekend(day) && !isToday(day),
              [styles.dailyRowItemCollapsed]: collapsedWeekends && isWeekend(day),
            })}
            key={day.toString()}
          >
            <WeekendCollapseIcon day={day} index={index} secondLastIndex={secondLastDaysIndex} />
            {isSaturday(day) && (
              <Tooltip title={t(tooltipTitle)!}>
                <div
                  onClick={onCollapse}
                  className={clsx(styles.hoverWeekendsSection, {
                    [styles.hoverWeekendsSectionCollapsed]: collapsedWeekends,
                    [styles.hoverWeekendsSectionCollapsedLast]: index === secondLastDaysIndex,
                  })}
                />
              </Tooltip>
            )}
            {collapsedWeekends && isWeekend(day) ? '' : format(day, 'd')}
          </div>
        ))}
      </section>
    );
  }

  return (
    <section className={clsx(styles.dailyRow)} ref={secRef}>
      {Object.keys(weeks).map((item) => {
        return (
          <div
            key={generateRandomId()}
            className={clsx(styles.weeklyItem, {
              [styles.weeklyItemActive]: item === getCurrentWeekYearIndex(todayDate),
            })}
            style={{
              minWidth: `${CALENDAR_WEEK * (isYearPeriodActive ? YEAR_MIN_COLUMN_WIDTH : QUARTER_MIN_COLUMN_WIDTH)}px`,
            }}
          >
            <span>{isYearPeriodActive ? item.substring(1) : item}</span>
          </div>
        );
      })}
    </section>
  );
});

DailyRow.displayName = 'Timeline.DailyRow';

WeekendCollapseIcon.displayName = 'Timeline.WeekendCollapseIcon';
