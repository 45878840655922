import { Route } from 'react-router-hoc';
import { lazy } from 'react';
import { TimeTrackingTabs } from 'types';

export const EmployeeTimeTrackingRoute = Route(
  {
    tab: Route.params.oneOf(TimeTrackingTabs.list, TimeTrackingTabs.timesheet).optional,
    month: Route.query.string,
  },
  ({ tab }) => `/employee-portal/${tab}`,
);

export const EmployeeTimeTracking = EmployeeTimeTrackingRoute(
  lazy(() => import(/* webpackChunkName: "EmployeeTimeTracking" */ './EmployeeTimeTracking')),
);
