import React, { memo, useMemo } from 'react';
import { isWeekend } from 'date-fns';
import clsx from 'clsx';

import { YEAR_PERIOD } from 'consts';
import { generateRandomId } from 'utils';
import { useTimelineContext } from 'contexts';

import {
  useHolidays,
  getNextFourWeeks,
  getCurrentQuarter,
  getCurrentWeekYearIndex,
  getDaysGroupedByBusinessWeek,
  getCurrentYear,
} from '../../utils';

import styles from './TimelineColumns.module.scss';

const CALENDAR_WEEK = 7;
const YEAR_MIN_COLUMN_WIDTH = 3;
const QUARTER_CELL_MIN_WIDTH = 12;

export const WeeklyTimelineColumns = memo<{ date: Date }>(({ date }) => {
  const { timelinePeriod } = useTimelineContext();
  const isYearPeriodActive = timelinePeriod === YEAR_PERIOD;

  const quarterDays = useMemo(() => {
    return getCurrentQuarter(date);
  }, [date]);

  const yearDays = useMemo(() => {
    return getCurrentYear(date);
  }, [date]);

  const weeks = useMemo(() => getDaysGroupedByBusinessWeek(isYearPeriodActive ? yearDays : quarterDays), [
    date,
    timelinePeriod,
  ]);

  const todayDate = new Date();

  return (
    <section className={styles.timelineColumns}>
      {Object.keys(weeks).map((item) => {
        return (
          <div
            key={generateRandomId()}
            className={clsx(styles.timelineColumnsWeeklyItem, {
              [styles.timelineColumnsWeeklyItemActive]: item === getCurrentWeekYearIndex(todayDate),
            })}
            style={{
              minWidth: `${CALENDAR_WEEK * (isYearPeriodActive ? YEAR_MIN_COLUMN_WIDTH : QUARTER_CELL_MIN_WIDTH)}px`,
            }}
          />
        );
      })}
    </section>
  );
});

export const DailyTimelineColumns = memo<{ date: Date }>(({ date }) => {
  const currentDateWithoutTime = new Date().setHours(0, 0, 0, 0);
  const holidays = useHolidays();
  const { collapsedWeekends } = useTimelineContext();

  const monthDays = useMemo(() => {
    return getNextFourWeeks(date);
  }, [date]);

  return (
    <section className={styles.timelineColumns}>
      {monthDays.map((day) => (
        <div
          key={day.toString()}
          className={clsx(styles.timelineColumnsDailyItem, {
            [styles.timelineColumnsDailyItemDisabled]: holidays.has(day.toString()) || isWeekend(day),
            [styles.timelineColumnsDailyItemCurrent]: day.getTime() === currentDateWithoutTime,
            [styles.timelineColumnsDailyItemCollapsed]: collapsedWeekends && isWeekend(day),
          })}
        ></div>
      ))}
    </section>
  );
});

DailyTimelineColumns.displayName = 'Timeline.DailyColumns';
WeeklyTimelineColumns.displayName = 'Timeline.WeeklyColumns';
