import { CircularProgress } from '@material-ui/core';
import React, { FC } from 'react';

import styles from './styles.module.scss';

export const AbsoluteSpinner: FC = () => {
  return (
    <div className={styles.container}>
      <CircularProgress size={64} style={{ color: '#24343D' }} />
    </div>
  );
};
