import React, { ChangeEvent, Dispatch, FC, SetStateAction, useMemo } from 'react';
import { MenuDateRange, Select } from 'components';
import { BillableLeaveRuleDataFragment } from 'generated/types';
import { DateRangeTypes, DateRangeTypesLabel } from 'types';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface Props {
  billableLeavesRules: BillableLeaveRuleDataFragment[];
  selectedRuleId: string;
  setSelectedRuleId: Dispatch<SetStateAction<string>>;
  dateRange: DateRangeTypes | [Date, Date] | null;
  setDateRange: Dispatch<SetStateAction<DateRangeTypes | [Date, Date] | null>>;
}

export const LeavesBalanceFilter: FC<Props> = ({
  billableLeavesRules,
  selectedRuleId,
  setSelectedRuleId,
  dateRange,
  setDateRange,
}) => {
  const { t } = useTranslation();
  const leavesRulesOptions = useMemo(
    () =>
      [...billableLeavesRules]
        .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
        .map(({ id, leaveTypes }) => ({ id, name: leaveTypes.map(({ name }) => name).join(', ') })),
    [billableLeavesRules],
  );

  const dateRangeOptions = useMemo(
    () =>
      [DateRangeTypes.thisYear, DateRangeTypes.prevYear].map((value: DateRangeTypes) => ({
        value,
        label: t(DateRangeTypesLabel[value]),
      })),
    [],
  );

  const onChangeDateFilter = (value: DateRangeTypes | [Date, Date]) => {
    setDateRange(value);
  };

  return (
    <div className={styles.header}>
      <MenuDateRange
        options={dateRangeOptions}
        onChange={onChangeDateFilter}
        value={dateRange || DateRangeTypes.thisYear}
        showMonthYearPicker
        ableOneMonthOrYearMode
      />
      <Select
        value={selectedRuleId}
        className="ml-auto"
        InputProps={{
          classes: {
            input: styles.selectInput,
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSelectedRuleId(e.target.value);
        }}
        options={leavesRulesOptions}
        menuItemClassName={styles.selectItemTitle}
        hideCheckIcon
      />
    </div>
  );
};
