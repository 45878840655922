import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { QueryOptions } from '@apollo/client';

import { links } from 'App';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { CustomToastWithLink, ProjectFormValues } from 'components';
import { ProjectType, RateUnit } from 'generated/types';
import { addTimezoneOffset, currencyToValue, graphqlOnError } from 'utils';
import { useCreateProjectMutation } from 'generated/graphql';
import mixpanel from 'mixpanel-browser';
import { ManagerTrackAccess, ProjectTrackType } from 'types';

export const useCreateProject = (
  onClose: () => void,
  refetchAction?: Array<string | DocumentNode | QueryOptions>,
): (({
  type,
  fee_amount,
  unit,
  fee_currency,
  start_date,
  end_date,
  pm,
  client,
  costBudgetAmount,
  commission,
  ...values
}: ProjectFormValues) => Promise<void>) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [createProject] = useCreateProjectMutation({
    async onCompleted(data) {
      const projectLink = links.ProjectDetail({ id: data.createProject.id });
      const projectName = data.createProject.name;
      const toastMessage = t('projects.projectCreateSuccessfully');
      toast.success(CustomToastWithLink(projectLink, projectName, toastMessage));
      mixpanel.track('Project created', {
        Type: ProjectTrackType[data.createProject.type],
        Unit: data.createProject.unit,
        'Manager access': ManagerTrackAccess[data.createProject.pmFinAccess!.accessLevel],
        'Budget set': !!data.createProject.cost_budget_amount,
        'Commission set': !!data.createProject.commission,
      });
      onClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: refetchAction,
  });

  return async ({
    type,
    fee_amount,
    unit,
    fee_currency,
    start_date,
    end_date,
    pm,
    client,
    pmFinAccess,
    pmFinAccessStart,
    pmFinAccessEnd,
    costBudgetAmount,
    commission,
    overtimeMultiplier,
    ...values
  }: ProjectFormValues) => {
    await createProject({
      variables: {
        data: {
          ...(type.id === ProjectType.FixedPrice
            ? {
                fee_amount: currencyToValue(fee_amount),
                feeCurrencyId: fee_currency.id,
              }
            : { costBudgetAmount: !!costBudgetAmount ? currencyToValue(costBudgetAmount) : null }),
          ...((type.id === ProjectType.TimeAndMaterial || type.id === ProjectType.Retainer) && {
            feeCurrencyId: fee_currency.id,
          }),
          ...values,
          client: client?.id ?? null,
          unit: type.id !== ProjectType.TimeAndMaterial && type.id !== ProjectType.Retainer ? RateUnit.Hour : unit.id,
          pm: pm?.id ?? '',
          type: type.id,
          start_date: addTimezoneOffset(new Date(start_date)),
          end_date: addTimezoneOffset(new Date(end_date)),
          pmFinAccess: pmFinAccess.id,
          pmAccessStart: pmFinAccessStart ? addTimezoneOffset(new Date(pmFinAccessStart)) : undefined,
          pmAccessEnd: pmFinAccessEnd ? addTimezoneOffset(new Date(pmFinAccessEnd)) : undefined,
          commission: commission ? Number(commission) : undefined,
          overtimeMultiplier:
            (type.id === ProjectType.TimeAndMaterial || type.id === ProjectType.Retainer) && overtimeMultiplier
              ? Number(overtimeMultiplier)
              : undefined,
        },
        companyId: userData!.company.id,
      },
    });
  };
};
