import React, { FC } from 'react';
import { Link, Tooltip } from 'components';
import clsx from 'clsx';
import { links } from 'App';
import styles from './styles.module.scss';

interface Props {
  data: {
    id: string;
    name: string;
    color: string;
    role: {
      name: string;
    };
    seniority?: string;
  };
  className?: string;
  textClassName?: string;
  roleClassName?: string;
  withProjectLink?: boolean;
}

export const AssignmentInfo: FC<Props> = ({
  data: { id, name, role, seniority, color },
  className,
  textClassName,
  roleClassName,
  withProjectLink,
}) => (
  <div className={clsx(styles.container, className)}>
    <span className={styles.projectColor} style={{ backgroundColor: color }} />

    <div>
      {withProjectLink ? (
        <Link to={links.ProjectDetail({ id })}>
          <Tooltip title={name} placement="top" textClassName={clsx(styles.project, textClassName)}>
            {name}
          </Tooltip>
        </Link>
      ) : (
        <Tooltip title={name} placement="top" textClassName={clsx(styles.project, textClassName)}>
          {name}
        </Tooltip>
      )}

      <h4 className={clsx(styles.role, roleClassName)}>{seniority ? `${role.name}, ${seniority}` : role.name}</h4>
    </div>
  </div>
);
