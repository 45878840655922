import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogWrapper, ExpandedColumn, Portal, Table, UserInfo } from 'components';
import { BalanceMemberDataFragment, BillableLeaveRuleMonthBalanceDataFragment } from 'generated/types';
import { useBillableLeaveRuleMonthBalanceQuery } from 'generated/graphql';
import { getAcronym, graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import Button from '@material-ui/core/Button';
import { CellProps } from 'react-table';

import styles from './styles.module.scss';

interface Props {
  memberId: string | null;
  isOpen: boolean;
  onClose: () => void;
  filtersComponent: ReactElement;
  selectedRuleId: string;
  interval: { start: string; end: string };
  selectedMemberData?: BalanceMemberDataFragment;
}

export const MemberLeavesBalance: FC<Props> = ({
  isOpen,
  onClose,
  memberId,
  filtersComponent,
  selectedRuleId,
  interval,
  selectedMemberData,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const [member, setMember] = useState(selectedMemberData);

  useEffect(() => {
    if (selectedMemberData) {
      setMember(selectedMemberData);
    }
  }, [selectedMemberData]);

  const {
    data: { billableLeaveRuleMonthBalance = [] } = {},
    previousData: { billableLeaveRuleMonthBalance: previousBillableLeaveRuleMonthBalance = [] } = {},
    loading: loadingBalance,
  } = useBillableLeaveRuleMonthBalanceQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      memberId: memberId!,
      data: {
        interval,
        billableLeaveRuleId: selectedRuleId,
      },
    },
    skip: !selectedRuleId || !memberId,
  });

  const memberBalance =
    !selectedRuleId || !memberId ? previousBillableLeaveRuleMonthBalance : billableLeaveRuleMonthBalance;

  const columns = useMemo<ExpandedColumn<BillableLeaveRuleMonthBalanceDataFragment>[]>(
    () => [
      {
        Header: t('viewProjectDetail.leavesBalance.month')!,
        accessor: 'month',
        Cell: function month({ value }: CellProps<BillableLeaveRuleMonthBalanceDataFragment>) {
          return <span>{value}</span>;
        },
      },
      {
        Header: t('viewProjectDetail.leavesBalance.accrualAmount')!,
        accessor: 'accrualAmount',
        Cell: function accrualAmount({ value }: CellProps<BillableLeaveRuleMonthBalanceDataFragment>) {
          return <span>{value}</span>;
        },
      },
      {
        Header: t('viewProjectDetail.leavesBalance.billedLeaves')!,
        accessor: 'billedLeaves',
        Cell: function billedLeaves({ value }: CellProps<BillableLeaveRuleMonthBalanceDataFragment>) {
          return <span>{value}</span>;
        },
      },
      {
        Header: t('viewProjectDetail.leavesBalance.balance')!,
        accessor: 'balance',
        Cell: function balance({ value }: CellProps<BillableLeaveRuleMonthBalanceDataFragment>) {
          return <span>{value}</span>;
        },
      },
    ],
    [],
  );

  return (
    <DialogWrapper
      open={isOpen}
      onClose={onClose}
      className={styles.dialog}
      title={
        <div className="flex gap-16">
          {t('viewProjectDetail.leavesBalance.balance')}
          {member && (
            <UserInfo
              title={`${member.first_name} ${member.last_name}`}
              titleClassName={styles.userTitle}
              avatarTitle={getAcronym(member.first_name, member.last_name)}
              color={member.color}
            />
          )}
        </div>
      }
    >
      <div className={styles.box}>
        {filtersComponent}

        <Table data={memberBalance} columns={columns} loading={loadingBalance} className={styles.table} />
      </div>
      <Portal wrapperId="dialog-actions">
        <Button onClick={onClose}>{t('forms.close')}</Button>
      </Portal>
    </DialogWrapper>
  );
};
