import React, { useState, MouseEvent, ReactNode } from 'react';
import Menu from '@material-ui/core/Menu';
import type { PopoverOrigin } from '@material-ui/core';

import { ActionButton } from 'icons';

interface DropdownMenuProps {
  trigger?: (handleClick: (event: MouseEvent<HTMLElement>) => void, open?: boolean) => ReactNode;
  children: (handleClose: () => void) => ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const DropdownMenu = ({ children, trigger, anchorOrigin, transformOrigin }: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <div className="text-right">
      {trigger ? trigger(handleClick, Boolean(anchorEl)) : <ActionButton onClick={handleClick} />}
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin ?? { vertical: 'top', horizontal: 'right' }}
        transformOrigin={transformOrigin ?? { vertical: 'bottom', horizontal: 'center' }}
        // think why we need this?
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionDuration={300}
      >
        {children(handleClose)}
      </Menu>
    </div>
  );
};
