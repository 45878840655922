import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Popper } from '@material-ui/core';
import { EditIcon } from 'icons';
import styles from './ColorPicker.module.scss';
import { colorsPalette } from 'consts';

interface ColorPickerProps {
  color: string;
  palette?: string[];
  onChange: (value: string) => void;
}

export const ColorPicker: FC<ColorPickerProps> = ({ color, palette = colorsPalette, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const togglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isPopperOpen = Boolean(anchorEl);

  return (
    <div
      className={clsx(styles.wrapper, isPopperOpen && styles.active)}
      style={{ background: color }}
      onClick={togglePopper}
    >
      <EditIcon className={styles.editIcon} />
      <Popper className={styles.popper} open={isPopperOpen} placement="bottom-start" anchorEl={anchorEl}>
        <span className={styles.arrow} />
        <div className={styles.grid}>
          {palette.map((colorItem) => (
            <div
              key={colorItem}
              className={clsx(styles.item, colorItem === color && styles.selected)}
              onClick={() => onChange(colorItem)}
              style={{ background: colorItem }}
            />
          ))}
        </div>
      </Popper>
    </div>
  );
};
