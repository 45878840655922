import React, { memo, PropsWithChildren, useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { usePermissions } from 'hooks';
import { EmptyState } from 'components';
import { ActionsType } from 'generated/types';
import { TimelinePeriod, TimelineProvider, useTimelineContext } from 'contexts/Timeline';

import { TeamTimeline } from './TeamTimeline';
import { ProjectTimeline } from './ProjectTimeline';

import { getStartEndPeriodDates } from '../../utils';
import { TimelineHead } from '../TimelineHead';
import { TimelineSettings } from '../TimelineSettings';
import { DailyTimelineColumns, WeeklyTimelineColumns } from '../TimelineColumns';

import styles from './styles.module.scss';

type Props = {
  date: Date;
  onChange: (date: Date) => void;
  type: 'team' | 'project';
};

const TEAM = 'team';
const PROJECT = 'project';

const timelines: { [P in Props['type']]: React.ComponentType<{ date: Date; paginationPeriod: [Date, Date] }> } = {
  project: ProjectTimeline,
  team: TeamTimeline,
};

const timelineColumns: { [P in TimelinePeriod]: React.ComponentType<{ date: Date }> } = {
  month: DailyTimelineColumns,
  quarter: WeeklyTimelineColumns,
  year: WeeklyTimelineColumns,
};

const TimeLineContent = memo<{ date: Date; type: 'team' | 'project' }>(({ date, type }) => {
  const { timelinePeriod } = useTimelineContext();

  const paginationPeriod = useMemo(() => getStartEndPeriodDates(date, timelinePeriod), [date, timelinePeriod]);

  const Timeline = timelines[type];
  const TimelineColumns = timelineColumns[timelinePeriod];

  return (
    <section className={styles.timelineContent}>
      <TimelineColumns date={date} />
      <Timeline date={date} paginationPeriod={paginationPeriod} />
      <div className={styles.timelineEmpty} />
    </section>
  );
});
export const Timeline = memo<PropsWithChildren<Props>>(({ date, onChange, type = 'team' }) => {
  const { hasAccess, isPermissionsLoading } = usePermissions();

  if (isPermissionsLoading) {
    return (
      <div className={styles.spinnerBox}>
        <CircularProgress size={64} style={{ color: '#24343D' }} />
      </div>
    );
  }

  if (
    (type === TEAM && !hasAccess(ActionsType.ViewResourcePlanningMembers)) ||
    (type === PROJECT && !hasAccess(ActionsType.ViewResourcePlanningProjects))
  ) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <TimelineProvider>
      <div className={styles.timeline}>
        <TimelineSettings date={date} onNavigate={onChange} />
        <TimelineHead date={date} />
        <TimeLineContent date={date} type={type} />
      </div>
    </TimelineProvider>
  );
});

Timeline.displayName = 'Timeline';
TimeLineContent.displayName = 'Timeline.Content';
