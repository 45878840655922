import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EventEmitter from 'event-emitter';

import { localStorageManager } from 'services';
import { useLoginMutation, useMeLazyQuery } from 'generated/graphql';
import { LoginMutation } from 'generated/types';
import { isValidToken } from 'utils';
import { FAILED_TO_REFRESH_TOKEN, NON_REFRESHABLE_AUTH_ISSUE, REFRESHED_TOKEN, TIME_LOG_EVENT, TOKEN } from 'consts';
import { useErrorMsgBuilder } from 'hooks';
import { client } from 'graphql-client';
import { ExtractArray } from 'react-router-hoc/lib/types';
import mixpanel from 'mixpanel-browser';

export const AuthBus = EventEmitter();

type UserData = Omit<ExtractArray<LoginMutation['login']['me']>, '__typename' | 'member'> & {
  member: Omit<ExtractArray<LoginMutation['login']['me']>['member'], '__typename'>;
};

interface AuthContextInterface {
  isInitializing: boolean;
  isAuthenticated: boolean;
  token: string;
  handleLogin: (email: string, password: string, remember?: boolean) => Promise<void>;
  handleLogout: () => void;
  handleAuth: (token: string) => void;
  userData: UserData | null;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

export const useAuth = () => useContext(AuthContext) as AuthContextInterface;

interface AuthContextProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthContextProps) => {
  const tls = useErrorMsgBuilder();
  const [fetchUserData, { data }] = useMeLazyQuery({
    onError: () => {
      // this will handle server specific errors, means- they're not related to token stuff
      resetToken();
    },
  });
  const [loginMutation, loginData] = useLoginMutation({
    onError: (err) => {
      resetToken();
      toast.error(tls(err.message));
    },
  });

  const [token, setAuthToken] = useState(() => {
    const tokenFromStorage = localStorageManager.getItem(TOKEN);

    if (tokenFromStorage && !isValidToken(tokenFromStorage)) {
      localStorageManager.removeItem(TOKEN);

      return '';
    }

    return tokenFromStorage || '';
  });

  const resetToken = () => {
    localStorageManager.removeItem(TOKEN);
    setAuthToken('');
  };

  const setToken = (token: string) => {
    const validated = isValidToken(token);

    if (validated) {
      localStorageManager.setItem<typeof token>(TOKEN, token);
      setAuthToken(token);
    }
  };

  // this is used in different cases:
  // if user clicks on logout
  // if we refresh token and encounter an issue, that makes refreshing not possible- we need to logout user
  // cleanup on init (see useEffect)
  const clearUserData = () => {
    // later on we probably want to add here request to log out user on BE as well, but for now no need.
    resetToken();
    mixpanel.reset();
    client.clearStore();
    localStorageManager.removeItem(TIME_LOG_EVENT);
  };

  const handleLogout = () => {
    mixpanel.track('Logged out');
    clearUserData();
  };

  const handleAuth = (token: string) => {
    setToken(token);
    fetchUserData();
  };

  const handleLogin = async (email: string, password: string, remember?: boolean) => {
    try {
      const { data } = await loginMutation({
        variables: {
          email,
          password,
          remember,
        },
      });

      mixpanel.track('Logged in', {
        'Remember me': remember,
      });

      const token = data?.login?.token;

      token && setToken(token);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // user refreshes the page, we wan't to identify if there's token, if yes- get user data,
    // if no- make sure everything related to token is cleaned up
    token ? handleAuth(token) : clearUserData();

    // this is way to communicate with context outside of react components
    // e.g. our apollo client catched that our token has been expired and via error link tries to fetch refresh token
    // but this refresh failed, so we want to log out our user and move them to login screen
    AuthBus.on(FAILED_TO_REFRESH_TOKEN, () => {
      clearUserData();
    });

    AuthBus.on(NON_REFRESHABLE_AUTH_ISSUE, () => {
      clearUserData();
    });

    // and that's basically success case of situation described above (we fetched refresh token and want to update state)
    AuthBus.on(REFRESHED_TOKEN, setToken);
  }, []);

  const userData = loginData?.data?.login?.me[0] || data?.me[0] || null;

  // initializing in this case means: I have token but still don't have info about user profile, that's related to this token
  // will be initialized once user data is there
  // if error occured
  const isInitializing = !!token && !userData;

  // means: token is there, user data is there- we're good to go.
  const isAuthenticated = !!(token && userData);

  return (
    <AuthContext.Provider
      value={{
        isInitializing,
        isAuthenticated,
        token,
        userData,
        handleLogout,
        // this is used to login using default flow (email + pass)
        handleLogin,
        // this is used to login having token
        handleAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
