import { Route } from 'react-router-hoc';
import IntegrationsComponent from './Integrations';
import { ModalModeEnum } from 'types';
import { idRegEx } from 'utils/regex';

export const IntegrationsRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    integrationId: Route.params.regex(idRegEx).optional,
    code: Route.query.string,
    error: Route.query.string,
    'accounts-server': Route.query.string,
    location: Route.query.string,
  },
  ({ mode, integrationId }) => `/settings/integrations/${mode}/${integrationId}`,
);

export const Integrations = IntegrationsRoute(IntegrationsComponent);
