import { idRegEx } from 'utils/regex';
import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import SkillCategoriesComponent from './SkillCategories';

export const SkillCategoriesRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    categoryId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, categoryId }) => `/settings/resource-management/skill-categories/${mode}/${categoryId}`,
);

export const SkillCategories = SkillCategoriesRoute(SkillCategoriesComponent);
