import React from 'react';

interface ChevronIconProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

export const ChevronIcon = ({ className, onClick, style }: ChevronIconProps) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86177 5.52859C4.12212 5.26824 4.54423 5.26824 4.80458 5.52859L8.33317 9.05719L11.8618 5.52859C12.1221 5.26824 12.5442 5.26824 12.8046 5.52859C13.0649 5.78894 13.0649 6.21105 12.8046 6.4714L8.80457 10.4714C8.54423 10.7317 8.12212 10.7317 7.86177 10.4714L3.86177 6.4714C3.60142 6.21105 3.60142 5.78894 3.86177 5.52859Z"
        fill="#5C6E79"
      />
    </svg>
  );
};
