import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { DragItem, RightDrawer } from 'components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';
import { ColumnSettingDragItem } from 'views/Insights/components/ColumnSettingDragItem';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

interface FormValues {
  activeColumns: string[];
}
interface Props {
  open: boolean;
  onClose: () => void;
  activeColumns: string[];
  allColumnOptions: string[];
  defaultShownColumns?: string[];
  columnsOrder: string[];
  onSubmit: (value: string[]) => void;
  onChangeOrder: (value: string[]) => void;
  getColumnTitle: (value: string) => string;
}

const validationSchema = object({
  activeColumns: array(string()),
});

export const TableColumnSettingDrawer: FC<Props> = ({
  onClose,
  open,
  allColumnOptions,
  defaultShownColumns,
  activeColumns,
  columnsOrder,
  onSubmit,
  onChangeOrder,
  getColumnTitle,
}) => {
  const { t } = useTranslation();
  const columnOptions =
    columnsOrder.length && columnsOrder.length === allColumnOptions.length ? columnsOrder : allColumnOptions;
  const [list, setList] = useState(columnOptions);

  useEffect(() => {
    setList(columnOptions);
  }, [open]);

  const onSubmitForm = ({ activeColumns }: FormValues) => {
    onSubmit(activeColumns);
    onChangeOrder(list);
    onClose();
  };

  const reset = () => {
    onSubmit(defaultShownColumns || allColumnOptions);
    onChangeOrder(Object.values(allColumnOptions));
    onClose();
  };

  const getNewFieldValue = (id: string, value: string[]): string[] => {
    const isActive = value.includes(id);

    return isActive ? value.filter((selectedId) => selectedId !== id) : [...value, id];
  };

  const { values, handleSubmit, setValues } = useFormik<FormValues>({
    onSubmit: onSubmitForm,
    validationSchema,
    initialValues: { activeColumns },
  });

  useEffect(() => {
    if (open) {
      setValues({ activeColumns });
    }
  }, [open]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setList((prev) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      }),
    );
  }, []);

  return (
    <RightDrawer
      open={open}
      onClose={onClose}
      boxClassName={styles.container}
      title={t('table.columnSettings.customiseColumns')}
    >
      <form onSubmit={handleSubmit} className={clsx('form', styles.form)}>
        <div className="mb-24">
          <DndProvider backend={HTML5Backend}>
            {list.map((column, index) => {
              const isChecked = values.activeColumns?.includes(column);

              return (
                <DragItem key={column} id={column} index={index} moveCard={moveCard}>
                  <ColumnSettingDragItem
                    checked={isChecked}
                    onCheck={() =>
                      setValues({ ...values, activeColumns: getNewFieldValue(column, values.activeColumns) })
                    }
                    title={getColumnTitle(column)}
                  />
                </DragItem>
              );
            })}
          </DndProvider>
        </div>

        <div className={styles.buttons}>
          <Button type="submit">{t('forms.save')}</Button>
          <Button color="secondary" variant="outlined" onClick={onClose}>
            {t('forms.cancel')}
          </Button>
          <Button color="secondary" variant="text" className="ml-auto" onClick={reset}>
            {t('table.columnSettings.reset')}
          </Button>
        </div>
      </form>
    </RightDrawer>
  );
};
