import React, { FC, useCallback, useMemo } from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { EditIcon, PlusIcon } from 'icons';
import { GetRouteProps } from 'types/utils/router';
import type { SkillCategoriesRoute } from './index';
import { EmptyState, RemoveConfirmIconButton, Table, TableActionCell } from 'components';
import { useTranslation } from 'react-i18next';
import { useDeleteSkillCategoryMutation, useSkillCategoriesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { useAuth } from 'contexts';
import { CreateSkillCategory } from './Create';
import { EditSkillCategory } from './Edit';
import { ModalModeEnum, TableCell } from 'types';
import { ActionsType, SkillCategoryDataFragment } from 'generated/types';
import { toast } from 'react-toastify';

type Props = GetRouteProps<typeof SkillCategoriesRoute>;

const SkillCategories: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess } = usePermissions();

  if (!hasAccess(ActionsType.OtherSettings)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  const { data: { skillCategories = [] } = {}, loading } = useSkillCategoriesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
    skip: !userData,
  });

  const skillCategoryToEdit = useMemo(() => {
    if (!params.categoryId) {
      return;
    }

    return skillCategories.find((category) => category.id === params.categoryId);
  }, [params.categoryId, skillCategories]);

  const [deleteSkillCategory] = useDeleteSkillCategoryMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('settings.resourceManagement.skillCategories.delete.notifications.success'));
    },
  });

  const onDeleteSkillCategory = (categoryId: string) => {
    userData &&
      deleteSkillCategory({
        variables: {
          skillCategoryId: categoryId,
          companyId: userData.company.id,
        },
        update(cache, { data }) {
          if (data) {
            cache.evict({ id: cache.identify(data.deleteSkillCategory) });
            cache.gc();
          }
        },
      });
  };

  const onClose = useCallback(() => push(link({ ...params, mode: undefined, categoryId: undefined })), [params]);

  const columns = useMemo(
    () => [
      {
        Header: t('settings.resourceManagement.skillCategories.name'),
        accessor: 'name',
      },
      {
        Header: ' ',
        accessor: '',
        Cell({ row: { original }, isHovered }: TableCell<SkillCategoryDataFragment> & { isHovered?: boolean }) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  disabled={!userData?.company?.id}
                  onClick={() => push(link({ mode: ModalModeEnum.edit, categoryId: original.id }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => onDeleteSkillCategory(original.id)}
                confirmTitle={t('settings.resourceManagement.skillCategories.delete.title')}
                confirmMessage={t('settings.resourceManagement.skillCategories.delete.submissionQuestion')}
                confirmSubmitButtonTitle={t('settings.resourceManagement.skillCategories.delete.title')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('settings.resourceManagement.skillCategories.tab')}</h2>

        <Button className="mr-8" startIcon={<PlusIcon />} onClick={() => push(link({ mode: ModalModeEnum.create }))}>
          {t('settings.resourceManagement.skillCategories.add')}
        </Button>
      </div>

      <Table
        data={skillCategories}
        emptyStateLabel={t('table.emptyState')}
        columns={columns as any}
        loading={loading}
      />

      <CreateSkillCategory isOpen={params.mode === 'create'} onClose={onClose} />

      <EditSkillCategory isOpen={!!skillCategoryToEdit} onClose={onClose} skill={skillCategoryToEdit!} />
    </>
  );
};

export default SkillCategories;
