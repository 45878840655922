import React, { createRef, memo, useRef, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import PopperJs from 'popper.js';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const FollowingTooltip = memo<TooltipProps>(({ children, ...props }) => {
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [tooltipPositionX, setTooltipPositionX] = useState<number>(0);
  const popperRef = createRef<PopperJs>();
  const areaRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };
    setTooltipPositionX(event.clientX);

    if (popperRef.current != null) {
      popperRef.current?.scheduleUpdate();
    }
  };

  return (
    <Tooltip
      {...props}
      placement="top"
      className={clsx(styles.tooltip, props.className)}
      classes={{
        popper: !tooltipPositionX ? 'd-none' : '',
      }}
      PopperProps={{
        popperRef,
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => ({
            top: areaRef.current?.getBoundingClientRect().top ?? 0,
            left: positionRef.current.x,
            right: positionRef.current.x,
            bottom: areaRef.current?.getBoundingClientRect().bottom ?? 0,
            width: 0,
            height: 0,
          }),
        },
      }}
    >
      <div ref={areaRef} onMouseMove={handleMouseMove}>
        {children}
      </div>
    </Tooltip>
  );
});
