import React, { FC, MouseEvent, ReactNode, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { ConfirmModal, Tooltip } from 'components';
import clsx from 'clsx';

export interface ConfirmIconButtonProps {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  isDelete?: boolean;
  confirmTitle: string;
  confirmMessage: string;
  tooltipText?: string;
  confirmSubmitButtonTitle?: string;
  hideDeleteIcon?: boolean;
  children: ReactNode;
}

export const ConfirmIconButton: FC<ConfirmIconButtonProps> = ({
  onClick,
  className,
  disabled,
  confirmTitle,
  tooltipText,
  confirmMessage,
  confirmSubmitButtonTitle,
  hideDeleteIcon,
  children,
  isDelete,
}) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  return (
    <>
      <Tooltip title={tooltipText || ''} placement="top" alwaysShowTooltip>
        <IconButton
          className={clsx(isDelete ? 'removeIconButton' : 'editIconButton', className)}
          size="small"
          disabled={disabled}
          onClick={(e: MouseEvent<HTMLElement>) => {
            setIsOpenConfirm(true);
            e.preventDefault();
          }}
        >
          {children}
        </IconButton>
      </Tooltip>

      <ConfirmModal
        title={confirmTitle}
        submitButtonTitle={confirmSubmitButtonTitle}
        isDelete={isDelete}
        hideDeleteIcon={hideDeleteIcon}
        isOpen={isOpenConfirm}
        onSubmit={() => {
          onClick();
          setIsOpenConfirm(false);
        }}
        onClose={() => setIsOpenConfirm(false)}
      >
        {confirmMessage}
      </ConfirmModal>
    </>
  );
};
