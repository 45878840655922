import { formatDistanceToNowStrict, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const MINUTES_IN_HOUR = 60;

export const getDateFrom = (date: string, addPrefix?: boolean) => {
  const { t } = useTranslation();
  const minutesToNow = +formatDistanceToNowStrict(new Date(date), { unit: 'minute', addSuffix: false }).split(' ')[0];

  if (minutesToNow < 1) {
    return t('time.justNow');
  }

  if (minutesToNow === 1) {
    return `${minutesToNow} ${t('time.minuteAgo')}`;
  }

  const hoursToNow = +(minutesToNow / MINUTES_IN_HOUR).toFixed();

  if (hoursToNow < 1) {
    return `${minutesToNow} ${t('time.minutesAgo')}`;
  }

  if (hoursToNow === 1) {
    return `${hoursToNow} ${t('time.hourAgo')}`;
  }

  if (hoursToNow < 24) {
    return `${hoursToNow} ${t('time.hoursAgo')}`;
  }

  return addPrefix
    ? `${t('time.on')} ${format(new Date(date), 'MMM dd, h:mm a')}`
    : format(new Date(date), 'MMM dd, h:mm a');
};
