import { ExternalRateDataFragment, MemberSeniority, Role } from 'generated/types';
import { NO_SENIORITY } from 'consts';

import { RateData, SeniorityRates } from '../Rates';

export const getRates = (
  role: Role | null,
  seniority: Pick<MemberSeniority, 'id' | 'name'> | null,
  rateData?: RateData,
  seniorityRates?: SeniorityRates,
  rates?: ExternalRateDataFragment[],
) => {
  if (!rateData && !seniorityRates && !rates) return [];

  if (rateData && role) return rateData[role.id] ? rateData[role.id][seniority?.name || NO_SENIORITY] : [];

  if (seniorityRates) return seniorityRates[seniority?.name || NO_SENIORITY];

  if (rates) return rates;
};
