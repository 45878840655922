export const UTILIZATION_TABLE_HIDDEN_COLUMNS = 'UTILIZATION_TABLE_HIDDEN_COLUMNS';
export const UTILIZATION_TABLE_COLUMN_ORDER = 'UTILIZATION_TABLE_COLUMN_ORDER';
export const BENCH_TABLE_HIDDEN_COLUMNS = 'BENCH_TABLE_HIDDEN_COLUMNS';
export const BENCH_TABLE_COLUMN_ORDER = 'BENCH_TABLE_COLUMN_ORDER';

export enum BenchTableColumns {
  name = 'name',
  title = 'title',
  nonscheduledHours = 'nonscheduledHours',
  cost = 'cost',
}
