import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import mixpanel from 'mixpanel-browser';
import { ModuleName } from 'types';
import { links } from 'App';
import { Select, Switch } from 'components';
import { useTimelineContext } from 'contexts';
import { useQueryParams } from 'utils/useQueryParams';
import { localStorageManager } from 'services';
import { GROUP_MEMBERS_BY, GROUP_PROJECTS_BY, MONTH_PERIOD, PER_DAY, PER_WEEK } from 'consts';

import { Filters, MemberTypesFilter, ProjectsGroupingTypesFilter, TeamGroupingTypesFilter } from '../../types';

import { GroupIcon } from 'icons';
import clsx from 'clsx';
import styles from './styles.module.scss';

export const TimelineFilters = memo(() => {
  const { search: searchQuery, membersType, groupingType, showRequests, ...query } = useQueryParams<Filters>();
  const { t } = useTranslation();
  const { hoursPeriod, onChangeHoursPeriod, timelinePeriod } = useTimelineContext();
  const isMonthView = timelinePeriod === MONTH_PERIOD;
  const { params } = useRouteMatch<{ type?: 'project' }>();
  const { push } = useHistory();
  const search = searchQuery ?? '';
  const [isShowRequest, setIsShowRequest] = useState(showRequests ?? true);

  const teamGroupingOptions = useMemo(
    () =>
      Object.values(TeamGroupingTypesFilter).map((filter) => ({
        id: filter,
        name: t(`resourcePlanning.timeline.filters.groupBy.${filter}`),
      })),
    [],
  );

  const projectsGroupingOptions = useMemo(
    () =>
      Object.values(ProjectsGroupingTypesFilter).map((filter) => ({
        id: filter,
        name: t(`resourcePlanning.timeline.filters.groupBy.${filter}`),
      })),
    [],
  );

  const memberTypeOptions = useMemo(
    () =>
      Object.values(MemberTypesFilter).map((filter) => ({
        id: filter,
        name: t(`resourcePlanning.timeline.filters.members.${filter}`),
      })),
    [],
  );

  return (
    <>
      <div className={styles.filters}>
        <div id="hide-team-member-menu" />
        {params.type !== 'project' ? (
          <>
            <Select
              InputProps={{
                classes: {
                  input: styles.groupSelect,
                },
                startAdornment: (
                  <section className={styles.filtersSelectAdornmentWithText}>
                    <GroupIcon />
                    <span>{t('resourcePlanning.groupBy')}</span>
                  </section>
                ),
              }}
              value={
                groupingType || localStorageManager.getItem(GROUP_MEMBERS_BY) || TeamGroupingTypesFilter.availability
              }
              onChange={(event: ChangeEvent<any>) => {
                mixpanel.track('Grouping updated', {
                  'Screen name': ModuleName.resourcePlanning,
                  Grouping: event.target.value,
                });
                localStorageManager.setItem(GROUP_MEMBERS_BY, event.target.value);
                push(
                  links.ResourcePlanning({
                    ...params,
                    ...query,
                    groupingType: event.target.value,
                    membersType,
                    search,
                  }),
                );
              }}
              defaultText={'Group by:'}
              options={teamGroupingOptions}
            />
            <Select
              value={membersType}
              InputProps={{
                classes: {
                  input: styles.select,
                },
              }}
              onChange={(event: ChangeEvent<any>) => {
                push(links.ResourcePlanning({ ...params, ...query, membersType: event.target.value, search }));
              }}
              defaultText={'All team'}
              options={memberTypeOptions}
            />
          </>
        ) : (
          <Select
            InputProps={{
              classes: {
                input: styles.groupSelect,
              },
              startAdornment: (
                <section className={styles.filtersSelectAdornmentWithText}>
                  <GroupIcon />
                  <span>{t('resourcePlanning.groupBy')}</span>
                </section>
              ),
            }}
            value={
              groupingType ?? localStorageManager.getItem(GROUP_PROJECTS_BY) ?? ProjectsGroupingTypesFilter.projectType
            }
            onChange={(event: ChangeEvent<any>) => {
              localStorageManager.setItem(GROUP_PROJECTS_BY, event.target.value);
              push(
                links.ResourcePlanning({ ...params, ...query, groupingType: event.target.value, membersType, search }),
              );
            }}
            defaultText={'Group by:'}
            options={projectsGroupingOptions}
          />
        )}
      </div>

      <div className={clsx(styles.filters, 'justify-content-end')}>
        {params.type !== 'project' && (
          <>
            <span className={styles.hideRequest}>{t('resourcePlanning.timeline.filters.hideRequests')}</span>
            <Switch
              className="mr-4"
              checked={!isShowRequest}
              onChange={() => {
                setIsShowRequest(!isShowRequest);
                push(
                  links.ResourcePlanning({ ...params, ...query, membersType, search, showRequests: !isShowRequest }),
                );
              }}
              color="primary"
              name="showRequests"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </>
        )}

        {isMonthView && (
          <Select
            value={hoursPeriod}
            InputProps={{ classes: { input: styles.select } }}
            onChange={(event: ChangeEvent<any>) => onChangeHoursPeriod(event.target.value)}
            defaultText={PER_WEEK}
            options={[
              { id: PER_WEEK, name: t('resourcePlanning.timeline.filters.hoursPeriod.perWeek') },
              { id: PER_DAY, name: t('resourcePlanning.timeline.filters.hoursPeriod.perDay') },
            ]}
          />
        )}
      </div>
    </>
  );
});

TimelineFilters.displayName = 'Timeline.Filters';
