import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';

import { useAuth } from 'contexts';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { NewClientFormValues } from 'components';
import { ClientDataFragmentDoc, useCreateClientMutation } from 'generated/graphql';
import { Reference } from '@apollo/client';

export const useCreateClient = (onClose: () => void): ((values: NewClientFormValues) => Promise<void>) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [createClient] = useCreateClientMutation({
    onCompleted(data) {
      toast.success(t('projects.clientCreateSuccessfully', { client: data.createClient.name }));
      mixpanel.track('Client created');
      onClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createClient) return;

      const createdClientRef = cache.writeFragment({
        data: data.createClient,
        fragment: ClientDataFragmentDoc,
      });

      cache.modify({
        fields: {
          clients(items: Reference[] = []) {
            return [...items, createdClientRef];
          },
        },
      });
    },
  });

  return async (values: NewClientFormValues) => {
    await createClient({
      variables: {
        data: {
          ...values,
        },
        companyId: userData?.company.id as string,
      },
    });
  };
};
