import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'icons';
import { RemoveConfirmIconButton, Tooltip } from 'components';
import { IconButton } from '@material-ui/core';
import { LeaveType } from 'generated/types';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  limit: number;
  leaveTypes: Pick<LeaveType, 'name' | 'id'>[];
  accrualDate: string;
  onDelete: () => void;
  onEdit: () => void;
}

export const ViewLeaveRule: FC<Props> = ({ limit, leaveTypes, accrualDate, onDelete, onEdit }) => {
  const { t } = useTranslation();

  const leaves = leaveTypes.map(({ name }) => name).join(', ');

  return (
    <div className={styles.row}>
      <span className={clsx(styles.item, styles.leaveTypes)} onClick={onEdit}>
        <Tooltip title={leaves} placement="top" textClassName={styles.leaveTypesTooltip}>
          {leaves}
        </Tooltip>
      </span>

      <div className={styles.divider} />

      <span className={clsx(styles.item, styles.limit)} onClick={onEdit}>
        {limit}
      </span>

      <div className={styles.divider} />

      <span className={clsx(styles.item, styles.accrualDate)} onClick={onEdit}>
        {accrualDate || (
          <div>
            <span className={styles.secondaryColor}>{t('viewProjectDetail.billableLeaves.member')} ▸ </span>{' '}
            {t('viewProjectDetail.billableLeaves.startDate')}
          </div>
        )}
      </span>

      <div className={styles.divider} />

      <div className={styles.menu}>
        <Tooltip title={t('actions.edit')!} placement="top" alwaysShowTooltip>
          <IconButton className={styles.iconButton} size="small" onClick={onEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <RemoveConfirmIconButton
          onClick={onDelete}
          className={styles.iconButton}
          confirmTitle={t('viewProjectDetail.billableLeaves.deleteRule')}
          confirmMessage={t('viewProjectDetail.billableLeaves.confirmDelete')}
          confirmSubmitButtonTitle={t('viewProjectDetail.billableLeaves.deleteRule')}
        />
      </div>
    </div>
  );
};
