import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';

import { DialogWrapper, Portal, UploadFileItem } from 'components';
import { CreateProjectDocumentDocument, useDeleteProjectDocumentMutation } from 'generated/graphql';
import { toast } from 'react-toastify';
import {
  generateRandomId,
  getFileNameWithoutSpecificSymbols,
  getUniqueFileName,
  graphqlOnError,
  MEGABYTE,
} from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';

import styles from './styles.module.scss';
import { File as FileIcon } from 'icons';
import { Button } from '@material-ui/core';
import { ProjectDocumentDataFragment } from 'generated/types';
import { client } from 'graphql-client';

interface Props {
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
}
interface LoadingFile {
  name: string;
  size: number;
  abortController: AbortController;
}

const MaxFileLimit100Mb = 100 * MEGABYTE;
const CancelRequestErrorMessage = 'text is not a function';
const AbortRequestErrorMessage = 'The user aborted a request.';

export const UploadFileModal: FC<Props> = ({ projectId, isOpen, onClose }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const fileInputRef = useRef<null | HTMLInputElement>(null);
  const [loadingFiles, setLoadingFiles] = useState<LoadingFile[]>([]);
  const [files, setFiles] = useState<ProjectDocumentDataFragment[]>([]);

  const [deleteProjectDocument] = useDeleteProjectDocumentMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted({ deleteProjectDocument }) {
      setFiles((prev) => prev.filter(({ id }) => id !== deleteProjectDocument.id));
      toast.success(t('projectFile.notifications.delete'));
    },
  });

  useEffect(() => {
    if (isOpen) {
      setFiles([]);
      setLoadingFiles([]);
    }
  }, [isOpen]);

  const handleDelete = (id: string) => {
    deleteProjectDocument({
      variables: {
        companyId: userData!.company.id,
        projectId: projectId,
        projectDocumentId: id,
      },
    });
  };

  const handleClose = () => {
    onClose();
    loadingFiles.forEach(({ abortController }) => abortController.abort());
  };

  const resetInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onChange = async ({ target: { validity, files } }: ChangeEvent<HTMLInputElement>) => {
    if (!validity.valid || !files?.length) {
      return;
    }

    [...files].forEach((file) => {
      const abortController = new AbortController();

      if (file.size > MaxFileLimit100Mb) {
        toast.error(t('projectFile.notifications.maxFileSize'));
        return;
      }

      const sameFilesCount = loadingFiles.filter(({ name }) => name.includes(file.name.split('.')[0])).length;
      const newFile = sameFilesCount
        ? new File([file], getUniqueFileName(getFileNameWithoutSpecificSymbols(file.name), sameFilesCount), {
            type: file.type,
          })
        : new File([file], getFileNameWithoutSpecificSymbols(file.name), {
            type: file.type,
          });

      client
        .mutate({
          mutation: CreateProjectDocumentDocument,
          fetchPolicy: 'network-only',
          variables: {
            companyId: userData!.company.id,
            data: {
              file: newFile,
              projectId,
            },
          },
          context: {
            fetchOptions: { signal: abortController.signal },
          },
        })
        .then(({ data }) => {
          if (data?.createProjectDocument) {
            const { createProjectDocument: newFile } = data;
            setFiles((prev) => [...prev, newFile]);
            setLoadingFiles((prev) =>
              prev.filter(({ name, size }) => name !== newFile.document.name || size !== newFile.document.size),
            );

            toast.success(t('projectFile.notifications.create'));
            mixpanel.track('File uploaded');
          }
        })
        .catch((err) => {
          if (err.message.includes(CancelRequestErrorMessage) || err.message === AbortRequestErrorMessage) {
            return;
          }
          graphqlOnError(err, tls(err.message));
        });

      setLoadingFiles((prev) => [
        ...prev,
        { name: newFile.name, size: newFile.size, abortController: abortController },
      ]);
    });

    resetInput();
  };

  return (
    <DialogWrapper
      open={isOpen}
      onClose={onClose}
      className={styles.dialog}
      contentClassName={styles.dialogContent}
      title={t('projectFile.uploadFile.label')}
    >
      <div className={styles.dropZone}>
        <input
          type="file"
          multiple
          onChange={onChange}
          ref={fileInputRef}
          id="contained-file"
          size={MaxFileLimit100Mb}
          className={styles.fileInput}
        />
        <FileIcon className="mb-8" />
        <span className={styles.text}>{t('projectFile.uploadFile.dropZone')}</span>
        <span className={styles.description}>{t('projectFile.uploadFile.maxSize')}</span>
        <Button variant="outlined" color="secondary" className="mt-24">
          {t('projectFile.uploadFile.browseFiles')}
        </Button>
      </div>

      <div className={styles.fileList}>
        {files.map(({ document, id }) => (
          <UploadFileItem
            key={id}
            name={document.name}
            size={document.size}
            handleDelete={() => {
              handleDelete(id);
            }}
          />
        ))}
        {loadingFiles.map(({ size, name, abortController }) => (
          <UploadFileItem
            loading
            key={generateRandomId()}
            name={name}
            size={size}
            handleDelete={() => {
              abortController.abort();
              setLoadingFiles((prev) => prev.filter((item) => item.name !== name || item.size !== size));
            }}
          />
        ))}
      </div>

      <Portal wrapperId="dialog-actions">
        {files.length ? (
          <Button onClick={onClose}>{t('forms.done')}</Button>
        ) : (
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {t('forms.cancel')}
          </Button>
        )}
      </Portal>
    </DialogWrapper>
  );
};
