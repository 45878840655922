import { useEffect } from 'react';
import { IdType } from 'react-table';

interface Props<D extends Record<string, unknown> = Record<string, never>> {
  columnOrder?: string[] | null;
  setColumnOrder: (updater: ((columnOrder: Array<IdType<D>>) => Array<IdType<D>>) | Array<IdType<D>>) => void;
}

export const useTableColumnsOrder = <D extends Record<string, unknown> = Record<string, never>>({
  columnOrder,
  setColumnOrder,
}: Props<D>) => {
  if (!setColumnOrder || !columnOrder?.length) {
    return;
  }

  useEffect(() => {
    setColumnOrder(columnOrder);
  }, [columnOrder]);
};
