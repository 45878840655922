import React, { FC, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { ColorPicker, LoadingButton } from 'components';
import { sample } from 'lodash';
import { tagColorsPalette } from 'consts';

export interface NewTagFormValues {
  name: string;
  color: string;
}

interface Props {
  onSubmit: (values: NewTagFormValues) => void | Promise<void>;
  name?: string;
  color?: string;
  onCancel: () => void;
  submitLabel?: string;
  cancelLabel?: string;
}

export const NewTag: FC<Props> = ({ name, color, submitLabel, cancelLabel, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t('tag.tagNameRequired')),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: name ?? '',
        color: color ?? sample(tagColorsPalette)!,
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="form">
          <div className="flex">
            <ColorPicker
              color={values.color}
              palette={tagColorsPalette}
              onChange={(color: string) => setFieldValue('color', color)}
            />

            <div className="ml-16 flex-1">
              <InputLabel className="required">{t('tag.tagName')}</InputLabel>
              <Field component={TextField} name="name" placeholder={t('tag.tagName')} className="mb-24" />
            </div>
          </div>

          <div className="controls">
            <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
              {submitLabel ?? t('forms.create')}
            </LoadingButton>

            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {cancelLabel ?? t('forms.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
