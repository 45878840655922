import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { isAfter, isSameDay, parseISO } from 'date-fns';

import { addTimezoneOffset, graphqlOnError, toShortFormat } from 'utils';
import { EmptyState, RemoveConfirmIconButton, RightDrawer, Table, TableActionCell } from 'components';
import { useAuth } from 'contexts';
import {
  useCreateHolidayMutation,
  useDeleteHolidayMutation,
  useEditHolidayMutation,
  useHolidaysQuery,
} from 'generated/graphql';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { ACTIONS, NAME } from 'consts';
import { ActionsType, Holiday } from 'generated/types';
import { GetRouteProps, ModalModeEnum, TableCell } from 'types';
import { HolidaysRoute } from './index';
import { IconButton, Tooltip } from '@material-ui/core';
import { EditIcon, PlusIcon } from 'icons';
import { NewHoliday, NewHolidayFormValues } from './NewHoliday';

type Props = GetRouteProps<typeof HolidaysRoute>;

const Holidays: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess } = usePermissions();

  const { data: { holidays = [] as Holiday[] } = {}, loading, refetch } = useHolidaysQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const holidayToEdit = useMemo(() => {
    if (!params.holidayId) {
      return;
    }

    return holidays.find((holiday) => holiday.id === params.holidayId);
  }, [params.holidayId, holidays]);

  const onCloseCreateModal = useCallback(() => {
    push(link({ ...params, mode: undefined }));
  }, [params]);

  const onCloseEditModal = useCallback(() => {
    push(link({ ...params, mode: undefined, holidayId: undefined }));
  }, [params]);

  const sortedHolidays = useMemo(
    () =>
      holidays.slice().sort(({ date: dateA }, { date: dateB }) => {
        if (isSameDay(parseISO(dateA), parseISO(dateB))) return 0;

        return isAfter(parseISO(dateA), parseISO(dateB)) ? 1 : -1;
      }),
    [holidays],
  );

  const [createHoliday] = useCreateHolidayMutation({
    onCompleted() {
      toast.success(t('holidays.holidayCreatedSuccessfully'));
      onCloseCreateModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [editHoliday] = useEditHolidayMutation({
    onCompleted() {
      toast.success(t('holidays.holidayEditedSuccessfully'));
      onCloseEditModal();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteHoliday] = useDeleteHolidayMutation({
    onCompleted() {
      toast.success(t('holidays.holidayDeletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteHoliday) });
        cache.gc();
      }
    },
  });

  const handleDeleteHoliday = (id: string) =>
    deleteHoliday({
      variables: {
        holidayId: id,
        companyId: userData?.company.id as string,
      },
    });

  const createHolidays = (values: NewHolidayFormValues) => {
    createHoliday({
      variables: {
        data: {
          ...values,
          date: addTimezoneOffset(new Date(values.date)),
        },
        companyId: userData?.company.id as string,
      },
    });
  };

  const editHolidays = (values: NewHolidayFormValues) => {
    editHoliday({
      variables: {
        data: {
          ...values,
          date: addTimezoneOffset(new Date(values.date)),
        },
        companyId: userData?.company.id as string,
        holidayId: holidayToEdit?.id as string,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: t('columns.holidays.name'),
        accessor: NAME,
      },
      {
        Header: t('columns.holidays.date'),
        accessor: '',
        Cell: function date({
          row: {
            original: { date },
          },
        }: TableCell<Holiday>) {
          return toShortFormat(new Date(date));
        },
      },
      {
        Header: ' ',
        accessor: ACTIONS,
        Cell({ row: { original }, isHovered }: TableCell<Holiday>) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  onClick={() => push(link({ mode: ModalModeEnum.edit, holidayId: original.id }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => handleDeleteHoliday(original.id)}
                confirmTitle={t('holidays.deleteHoliday')}
                confirmMessage={t('holidays.deleteHolidayQuestion')}
                confirmSubmitButtonTitle={t('holidays.deleteHoliday')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  if (!hasAccess(ActionsType.OtherSettings)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('holidays.name')}</h2>

        <Button className="mr-8" onClick={() => push(link({ mode: ModalModeEnum.create }))} startIcon={<PlusIcon />}>
          {t('holidays.createHoliday')}
        </Button>
      </div>

      <Table data={sortedHolidays as any} columns={columns as any} loading={loading} />

      <RightDrawer
        direction="right"
        open={params.mode === ModalModeEnum.create}
        onClose={onCloseCreateModal}
        title={t('holidays.createHoliday')}
      >
        <NewHoliday onSubmit={createHolidays} onCancel={onCloseCreateModal} />
      </RightDrawer>

      <RightDrawer
        direction="right"
        open={!!holidayToEdit}
        onClose={onCloseEditModal}
        title={t('holidays.editHoliday')}
      >
        <NewHoliday
          name={holidayToEdit?.name}
          date={holidayToEdit?.date}
          onSubmit={editHolidays}
          onCancel={onCloseEditModal}
          submitLabel={t('actions.save')}
        />
      </RightDrawer>
    </>
  );
};

export default Holidays;
