import React, { FC } from 'react';
import { Redirect } from 'react-router';

import { useAuth } from 'contexts';
import { MemberType } from 'generated/types';

type GetProps<T> = T extends React.ComponentType<infer P> ? P : string;

interface RedirectTo {
  (): string;
}

export function ProtectedRoute<T extends FC>(access: (type: MemberType) => boolean, redirectTo?: RedirectTo) {
  return (WrappedComponent: FC) => {
    const Component: FC<GetProps<T>> = ({ ...rest }) => {
      const { userData } = useAuth();

      return access(userData?.type as MemberType) ? (
        <WrappedComponent {...rest} />
      ) : (
        <Redirect to={redirectTo ? redirectTo() : '/team'} />
      );
    };

    return Component;
  };
}
