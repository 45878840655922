import React, { FC, useCallback } from 'react';
import { RightDrawer } from 'components';
import { useAuth } from 'contexts';
import { LeaveTypeDataFragmentDoc, useCreateLeaveTypeMutation } from 'generated/graphql';
import { ILeaveType } from 'generated/types';
import { useErrorMsgBuilder } from 'hooks';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { Reference } from '@apollo/client';
import { LeaveTypeForm } from '../components/LeaveTypeForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateLeaveType: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();

  const [createLeaveType] = useCreateLeaveTypeMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('settings.resourceManagement.leaveTypes.create.notifications.success'));
      onClose();
    },
    update(cache, { data }) {
      if (!data?.createLeaveType) {
        return;
      }

      const newLeaveTypeRef = cache.writeFragment({
        data: data?.createLeaveType,
        fragment: LeaveTypeDataFragmentDoc,
      });

      cache.modify({
        fields: {
          leaveTypes(leaveTypes: Reference[] = []) {
            return [...leaveTypes, newLeaveTypeRef];
          },
        },
      });
    },
  });

  const { userData } = useAuth();

  const onSubmit = useCallback(async (data: ILeaveType) => {
    if (!userData) {
      return;
    }

    await createLeaveType({
      variables: {
        companyId: userData.company.id,
        data: data,
      },
    });
  }, []);

  return (
    <RightDrawer
      direction="right"
      open={isOpen}
      onClose={onClose}
      title={t('settings.resourceManagement.skills.create.title')}
    >
      <LeaveTypeForm onSubmit={onSubmit} onCancel={onClose} />
    </RightDrawer>
  );
};
