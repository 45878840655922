import {
  ProjectDocumentDataFragmentDoc,
  useCreateTagMutation,
  useEditProjectDocumentTagsMutation,
  useTagsQuery,
} from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useAuth } from 'contexts';
import { TagDataFragment } from 'generated/types';
import { useCallback } from 'react';
import { client } from '../graphql-client';
import mixpanel from 'mixpanel-browser';

export const useFileTags = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { tags: tagsOptions = [] } = {}, loading: isTagsLoading, refetch: tagsOptionsRefetch } = useTagsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const projectDocumentTagsUpdate = (id: string, tags: TagDataFragment[]) => {
    const data = client.readFragment({
      id: `ProjectDocument:${id}`,
      fragmentName: 'ProjectDocumentData',
      fragment: ProjectDocumentDataFragmentDoc,
    });

    client.writeFragment({
      id: `ProjectDocument:${id}`,
      fragmentName: 'ProjectDocumentData',
      fragment: ProjectDocumentDataFragmentDoc,
      data: {
        ...data,
        tags,
      },
    });

    tagsOptionsRefetch();
  };

  const [editProjectDocumentTags] = useEditProjectDocumentTagsMutation({
    onCompleted() {
      mixpanel.track('Tags updated');
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [createTag] = useCreateTagMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const onCreateFileTag = useCallback(async (value: TagDataFragment) => {
    const { data } = await createTag({
      variables: {
        data: {
          color: value.color,
          name: value.name,
        },
        companyId: userData!.company.id,
      },
    });

    return data?.createTag;
  }, []);

  const onChangeFileTag = useCallback((projectDocumentId: string) => {
    return (tags: string[]) =>
      editProjectDocumentTags({
        variables: {
          companyId: userData!.company.id,
          data: {
            projectDocumentId,
            tags,
          },
        },
      });
  }, []);

  return {
    tagsOptions,
    isTagsLoading,
    projectDocumentTagsUpdate,
    onChangeFileTag,
    onCreateFileTag,
  };
};
