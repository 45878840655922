import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { MenuIcon } from 'icons';
import { Select } from 'components';
import { useTranslation } from 'react-i18next';
import { GroupByFilter } from 'types';
import { DEFAULT } from 'consts';

import styles from './styles.module.scss';

interface Props {
  value?: GroupByFilter;
  onChange: (value: GroupByFilter) => void;
  options: GroupByFilter[];
}
export const GroupBySelectFilter: FC<Props> = ({ value, onChange, options }) => {
  const { t } = useTranslation();

  const groupByOptions = useMemo(
    () =>
      options.map((filter) => ({
        id: filter,
        name: t(`projects.filters.groupBy.${filter}`),
      })),
    [],
  );

  const renderSelectLabel = useCallback(
    (value) => {
      if (!value || value === DEFAULT) {
        return t('projects.group');
      }

      return (
        <span>
          {t('projects.groupBy')}: {t(`projects.filters.groupBy.${value}`)}
        </span>
      );
    },
    [value],
  );

  return (
    <Select
      InputProps={{
        startAdornment: (
          <section className={styles.selectAdornment}>
            <MenuIcon />
          </section>
        ),
      }}
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as GroupByFilter);
      }}
      defaultText={t('projects.group')}
      options={groupByOptions}
      SelectProps={{
        renderValue: renderSelectLabel,
      }}
      hideIconComponent
    />
  );
};
