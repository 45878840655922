import { EMPTY_STATE } from 'consts';

export const toShortFormat = (date: Date | string, separator = ',') => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (typeof date === 'string') {
    date = new Date(date);

    if (isNaN(Date.parse(date.toString()))) {
      return EMPTY_STATE;
    }
  }

  const day = date.getUTCDate();

  const monthIndex = date.getUTCMonth();
  const monthName = monthNames[monthIndex];

  const year = date.getUTCFullYear();

  return `${day} ${monthName}${separator} ${year}`;
};
