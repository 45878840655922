import { useAuth } from 'contexts';
import { ActionsType, ScenarioAction, ScenarioDataFragment } from 'generated/types';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { ScenarioTabs } from 'types';
import { useTranslation } from 'react-i18next';
import {
  ScenariosDocument,
  useArchiveScenarioMutation,
  useDeleteScenarioMutation,
  useScenariosQuery,
} from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { toast } from 'react-toastify';
import { ApolloCache } from '@apollo/client';

interface Props {
  projectId?: string;
  tab: ScenarioTabs;
}

export const useScenarios = ({ projectId, tab }: Props) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess } = usePermissions();

  // TODO: need scenarios are always empty after fetching scenario by id. This issue is related to the cache handling.
  const { data: { scenarios = [] } = {}, loading } = useScenariosQuery({
    variables: {
      companyId: userData!.company.id,
      isArchived: tab === ScenarioTabs.archived,
      ...(projectId ? { projectId } : {}),
    },
    skip: !userData?.company.id || !hasAccess(ActionsType.ViewScenarios),
  });

  const [deleteScenario] = useDeleteScenarioMutation({
    onCompleted() {
      toast.success(t('scenarios.delete.notifications.success'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteScenario) });
        cache.gc();
      }
    },
  });

  const onDeleteScenario = (id: string, projectId: string) => {
    deleteScenario({
      variables: {
        scenarioId: id,
        projectId,
        companyId: userData!.company.id,
      },
    });
  };

  const updateCachedActiveScenarios = ({
    cache,
    scenario,
    projectId,
    isArchivedScenario,
  }: {
    cache: ApolloCache<number>;
    scenario: ScenarioDataFragment;
    projectId?: string;
    isArchivedScenario: boolean;
  }) => {
    const cachedActiveScenarios = cache.readQuery({
      query: ScenariosDocument,
      variables: {
        companyId: userData!.company.id,
        isArchived: false,
        ...(projectId ? { projectId } : {}),
      },
    });

    if (cachedActiveScenarios) {
      cache.updateQuery(
        {
          query: ScenariosDocument,
          variables: {
            companyId: userData!.company.id,
            isArchived: false,
            ...(projectId ? { projectId } : {}),
          },
        },
        (data) => ({
          scenarios: !isArchivedScenario
            ? [...(data.scenarios || []), scenario]
            : (data.scenarios || []).filter(({ id }: ScenarioDataFragment) => id !== scenario.id),
        }),
      );
    }
  };

  const updateCachedArchiveScenarios = ({
    cache,
    scenario,
    projectId,
    isArchivedScenario,
  }: {
    cache: ApolloCache<number>;
    scenario: ScenarioDataFragment;
    projectId?: string;
    isArchivedScenario: boolean;
  }) => {
    const cachedArchiveScenarios = cache.readQuery({
      query: ScenariosDocument,
      variables: {
        companyId: userData!.company.id,
        isArchived: true,
        ...(projectId ? { projectId } : {}),
      },
    });

    if (cachedArchiveScenarios) {
      cache.updateQuery(
        {
          query: ScenariosDocument,
          variables: {
            companyId: userData!.company.id,
            isArchived: true,
            ...(projectId ? { projectId } : {}),
          },
        },
        (data) => ({
          scenarios: isArchivedScenario
            ? [...data.scenarios, scenario]
            : data.scenarios?.filter(({ id }: ScenarioDataFragment) => id !== scenario.id),
        }),
      );
    }
  };

  const [archiveScenario] = useArchiveScenarioMutation({
    onCompleted() {
      toast.success(
        t(
          tab === ScenarioTabs.archived
            ? 'scenarios.actions.restoredSuccessfully'
            : 'scenarios.actions.archivedSuccessfully',
        ),
      );
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      const scenario = data?.archiveScenario;
      const isArchivedScenario = !!data?.archiveScenario?.archived_at;

      if (!scenario) return;

      updateCachedActiveScenarios({
        cache,
        scenario,
        isArchivedScenario,
      });

      updateCachedArchiveScenarios({
        cache,
        scenario,
        isArchivedScenario,
      });

      if (projectId) {
        updateCachedActiveScenarios({
          cache,
          scenario,
          projectId,
          isArchivedScenario,
        });

        updateCachedArchiveScenarios({
          cache,
          scenario,
          projectId,
          isArchivedScenario,
        });
      }
    },
  });

  const onChangeArchiveScenario = (id: string, projectId: string) => {
    archiveScenario({
      variables: {
        scenarioId: id,
        projectId,
        action: tab === ScenarioTabs.archived ? ScenarioAction.Restore : ScenarioAction.Archive,
        companyId: userData!.company.id,
      },
    });
  };

  return { scenarios, loading, onDeleteScenario, onChangeArchiveScenario };
};
