import React, { CSSProperties, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getTotalWorkingHours } from 'utils';

import { Scalars } from 'generated/types';
import { getAssignmentDate, getPeriodLabel } from 'views/ResourcePlanning/utils';
import { FollowingTooltip } from 'components';
import { DEFAULT_CAPACITY } from 'consts';

type Props = {
  type?: string;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
  style?: CSSProperties;
  children: ReactElement;
  className?: string;
};

export const MemberLeavesTooltip = memo<Props>(({ type, end, start, style, children, className }) => {
  const { t } = useTranslation();
  const startDate = getAssignmentDate(start);
  const endDate = getAssignmentDate(end);
  const totalHours = getTotalWorkingHours([])(startDate, endDate, [], DEFAULT_CAPACITY);

  return (
    <FollowingTooltip
      className={className}
      style={style}
      title={
        <div className="flex flex-column gap-6">
          <span className="flex justify-content-between gap-16">
            <span>{t('resourcePlanning.assignment.dates')}</span> {getPeriodLabel(startDate, endDate)}
          </span>
          <span className="flex justify-content-between gap-16">
            <span>{t('resourcePlanning.assignment.duration')}</span>
            <span>
              {t('resourcePlanning.hours', { hours: totalHours })} ({DEFAULT_CAPACITY}
              {t('resourcePlanning.assignment.hrsPerDay')})
            </span>
          </span>
          <span className="flex justify-content-between gap-16">
            <span>{t('resourcePlanning.assignment.type')}</span> {type}
          </span>
        </div>
      }
    >
      {children}
    </FollowingTooltip>
  );
});

MemberLeavesTooltip.displayName = 'LeavesTooltip';
