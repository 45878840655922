import React from 'react';
import { AlertIcon, SuccessIcon } from 'icons';

interface Props {
  type: string;
}

export const getToastIcon = ({ type }: Props) => {
  switch (type) {
    case 'error':
      return <AlertIcon />;
    case 'success':
      return <SuccessIcon />;
    default:
      return false;
  }
};
