import React, { useCallback, useMemo, useState } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { LoadingButton, PassVisibilityBtn } from 'components';
import { links } from 'App';

import styles from './Login.module.scss';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import { localStorageManager } from 'services';

export interface FormValues {
  email: string;
  password: string;
  remember: boolean;
}

interface LoginProps {
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void | Promise<void>;
  email?: string;
  password?: string;
}

const REMEMBER_ME = 'REMEMBER_ME';

export const Login = (props: LoginProps) => {
  const { t } = useTranslation();
  const [isPassVisible, setIsPassVisible] = useState(false);
  const { email, password, onSubmit } = props;
  const location = useLocation();
  const rememberValue = !!localStorageManager.getItem(REMEMBER_ME);

  const togglePasswordVisibility = useCallback(() => setIsPassVisible((prev) => !prev), []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email(t('forms.emailFormatError')).required(t('forms.emailRequiredError')),
        password: Yup.string().required(t('forms.passwordErrorRequired')),
        remember: Yup.boolean().nullable(),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: email ?? '',
        password: password ?? '',
        remember: rememberValue || false,
      }}
      onSubmit={async (values, formikHelpers) => {
        localStorageManager.setItem(REMEMBER_ME, values.remember);
        await onSubmit(values, formikHelpers);
        formikHelpers.setSubmitting(false);
      }}
    >
      {({ isSubmitting, handleChange, values }) => (
        <Form>
          <InputLabel className="required">{t('forms.emailAddress')}</InputLabel>
          <Field component={TextField} name="email" placeholder={t('forms.email')} type="email" className="mb-24" />

          <InputLabel>
            <div className="flex justify-content-between">
              <div className="required">{t('forms.password')}</div>
              <NavLink
                to={{ pathname: links.ForgotPass(), state: { from: location.pathname } }}
                className={styles.navLink}
              >
                {t('forms.forgotPassword')}
              </NavLink>
            </div>
          </InputLabel>
          <Field
            component={TextField}
            type={isPassVisible ? 'text' : 'password'}
            name="password"
            placeholder={t('forms.password')}
            className="mb-24"
            InputProps={{
              endAdornment: (
                <Tooltip
                  aria-label={t('forms.passwordVisibility')}
                  title={isPassVisible ? t('forms.hidePassword')! : t('forms.showPassword')!}
                  placement="right"
                >
                  <span className="ml-6">
                    <PassVisibilityBtn
                      isPassVisible={isPassVisible}
                      togglePasswordVisibility={togglePasswordVisibility}
                    />
                  </span>
                </Tooltip>
              ),
            }}
          />

          <FormControlLabel
            control={<Checkbox checked={values.remember} color="primary" />}
            label={t('forms.keepLoggedIn')}
            name="remember"
            onChange={handleChange}
            className="mb-24 ml-0"
            classes={{ label: clsx(styles.checkboxLabelText, values.remember && styles.active) }}
          />

          <LoadingButton type="submit" loading={isSubmitting} fullWidth>
            {t('forms.login')}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
};
