import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { Tooltip } from 'components';
import { IconButton } from '@material-ui/core';
import { Close } from 'icons';
import clsx from 'clsx';

export interface TagProps {
  color?: string;
  name: string;
  onDelete?: () => void;
  onClick?: () => void;
  tooltipClassName?: string;
  className?: string;
  startIcon?: ReactNode;
}

export const Tag: FC<TagProps> = ({
  color = 'inherit',
  name,
  onDelete,
  onClick,
  tooltipClassName,
  className,
  startIcon,
}) => {
  return (
    <div className={clsx(styles.tag, className)} style={{ backgroundColor: color }} onClick={onClick}>
      {startIcon}
      <Tooltip title={name} placement="top" textClassName={clsx(styles.tagTooltip, tooltipClassName)}>
        {name}
      </Tooltip>
      {onDelete && (
        <IconButton onClick={onDelete} className={styles.deleteButton}>
          <Close className={styles.deleteIcon} />
        </IconButton>
      )}
    </div>
  );
};
