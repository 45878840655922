import React, { useCallback, useState } from 'react';
import clsx from 'clsx';

import { Maybe } from 'generated/types';
import styles from './Avatar.module.scss';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export enum SIZES {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export interface AvatarProps {
  src?: string;
  alt?: string;
  className?: string;
  size?: SIZES;
  fallback?: JSX.Element;
  avatarTitle?: string | JSX.Element;
  showIndicator?: boolean;
  color?: Maybe<string>;
}

export const Avatar = ({
  src = '',
  alt = '',
  className,
  size = SIZES.sm,
  fallback,
  avatarTitle,
  showIndicator,
  color,
}: AvatarProps) => {
  const [hasError, setError] = useState(false);
  const { t } = useTranslation();
  const onError = useCallback(() => setError(true), []);

  const defaultColor = '#D6DBDD';

  const Indicator = (
    <Tooltip title={t('employmentType.contractor')!}>
      <div className={styles.indicator}>
        <div>
          <div />
        </div>
      </div>
    </Tooltip>
  );

  if (hasError || !src) {
    // TODO write a logic in case error occured; we need to provide avatarTitle(acronym)
    if (fallback) return fallback;

    return (
      <div
        style={{ backgroundColor: avatarTitle && color ? color : defaultColor }}
        className={clsx([
          styles.avatar,
          styles[size],
          'flex',
          'align-items-center',
          'justify-content-center',
          className,
        ])}
      >
        {showIndicator && Indicator}
        {avatarTitle ?? t('nullable_user_acronym')}
      </div>
    );
  }

  return (
    <div className={clsx([styles.avatar, styles[size], className])}>
      <img src={src} alt={alt} onError={onError} />
      {showIndicator && Indicator}
    </div>
  );
};
