import { SortByFn } from 'react-table';
import { useCallback } from 'react';
import { UtilizationMemberData } from '../views/Insights/Utilization/components/UtilizationTable/getTableData';

export const useUtilizationTableSorts = () => {
  const sortMemberType = useCallback<SortByFn<UtilizationMemberData>>((rowA, rowB) => {
    return `${rowA.original.memberName}`.localeCompare(`${rowB.original.memberName}`, 'en');
  }, []);

  return {
    sortMemberType,
  };
};
