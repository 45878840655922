import React, { FC, useCallback, useMemo } from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { EditIcon, PlusIcon } from 'icons';
import { GetRouteProps } from 'types/utils/router';
import type { SkillsRoute } from './index';
import { RemoveConfirmIconButton, Table, TableActionCell } from 'components';
import { useTranslation } from 'react-i18next';
import { useDeleteSkillMutation, useSkillCategoriesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { ModalModeEnum, TableCell } from 'types';
import { SkillDataFragment } from 'generated/types';
import { CreateSkill } from './Create';
import { EditSkill } from './Edit';
import { toast } from 'react-toastify';

type Props = GetRouteProps<typeof SkillsRoute>;

const Skills: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { skillCategories = [] } = {}, loading } = useSkillCategoriesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      withSkills: true,
    },
    skip: !userData,
  });

  const [deleteSkill] = useDeleteSkillMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('settings.resourceManagement.skills.delete.notifications.success'));
    },
  });

  const skills = useMemo(
    () =>
      skillCategories
        .map((skillCategory) =>
          skillCategory.skills!.map((skill) => ({
            ...skill,
            categoryName: skillCategory.name,
            skillCategoryId: skillCategory.id,
          })),
        )
        .flat(),
    [skillCategories],
  );

  const skillToEdit = useMemo(() => {
    if (!params.skillId) {
      return;
    }

    return skills.find((skill) => skill.id === params.skillId);
  }, [params.skillId, skills]);

  const onDeleteSkill = (skillId: string) => {
    userData &&
      deleteSkill({
        variables: {
          skillId,
          companyId: userData.company.id,
        },
        update(cache, { data }) {
          if (data) {
            cache.evict({ id: cache.identify(data.deleteSkill) });
            cache.gc();
          }
        },
      });
  };

  const onClose = useCallback(() => push(link({ ...params, mode: undefined, skillId: undefined })), [params]);

  const columns = useMemo(
    () => [
      {
        Header: t('settings.resourceManagement.skills.name'),
        accessor: 'name',
      },
      {
        Header: t('settings.resourceManagement.skills.categoryName'),
        accessor: 'categoryName',
      },
      {
        Header: ' ',
        accessor: '',
        Cell({ row: { original }, isHovered }: TableCell<SkillDataFragment> & { isHovered?: boolean }) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  disabled={!userData?.company?.id}
                  onClick={() => push(link({ mode: ModalModeEnum.edit, skillId: original.id }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => onDeleteSkill(original.id)}
                confirmTitle={t('settings.resourceManagement.skills.delete.title')}
                confirmMessage={t('settings.resourceManagement.skills.delete.submissionQuestion')}
                confirmSubmitButtonTitle={t('settings.resourceManagement.skills.delete.title')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('settings.resourceManagement.skills.tab')}</h2>

        <Button className="mr-8" startIcon={<PlusIcon />} onClick={() => push(link({ mode: ModalModeEnum.create }))}>
          {t('settings.resourceManagement.skills.add')}
        </Button>
      </div>

      <Table data={skills} emptyStateLabel={t('table.emptyState')} columns={columns as any} loading={loading} />

      <CreateSkill isOpen={params.mode === ModalModeEnum.create} onClose={onClose} />

      <EditSkill isOpen={!!skillToEdit} onClose={onClose} skill={skillToEdit!} />
    </>
  );
};

export default Skills;
