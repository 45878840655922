import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form, Field, FormikHelpers, FieldProps } from 'formik';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';

import { LoadingButton, DatePicker } from 'components';
import { submitForm } from 'utils';

export interface GeneratePayrollValues {
  startDate: Date;
  endDate: Date;
}

interface Props {
  onSubmit: (values: GeneratePayrollValues) => void | Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
}

export const GeneratePayroll: FC<Props> = ({ onSubmit, onCancel, submitLabel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          startDate: Yup.string()
            .required(t('forms.generatePayroll.startRequired'))
            .typeError(t('forms.generatePayroll.startRequired')),
          endDate: Yup.string()
            .required(t('forms.generatePayroll.endRequired'))
            .typeError(t('forms.generatePayroll.endRequired')),
        },
        [],
      ),
    [],
  );

  const handleSubmit = (values: GeneratePayrollValues, { setSubmitting }: FormikHelpers<GeneratePayrollValues>) => {
    submitForm(values, setSubmitting, onSubmit);
  };

  // TODO: find better solution for work with timezones
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        startDate: new Date(),
        endDate: new Date(),
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputLabel className="required">{t('forms.generatePayroll.startDateEndDate')}</InputLabel>
          <Field>
            {({
              form: {
                setValues,
                values: { startDate, endDate },
                values,
                errors,
                submitCount,
              },
            }: FieldProps<any, Pick<GeneratePayrollValues, 'startDate' | 'endDate'>>) => {
              return (
                <DatePicker
                  className="mb-30"
                  value={[startDate, endDate] as const}
                  range
                  error={Boolean(submitCount && (errors.startDate || errors.endDate))}
                  helperText={!!submitCount && (errors.startDate || errors.endDate)}
                  onChange={(date) => {
                    const [startDate, endDate] = date || [null, null];
                    setValues({
                      ...values,
                      startDate: startDate as Date,
                      endDate: endDate as Date,
                    });
                  }}
                />
              );
            }}
          </Field>

          <div className="mt-8 mb-24 flex justify-content-between">
            <div>
              <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
                {submitLabel ?? t('forms.generatePayroll.create')}
              </LoadingButton>
              <Button variant="outlined" color="secondary" onClick={onCancel} className="mr-8">
                {t('forms.cancel')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
