import { Route } from 'react-router-hoc';
import { MemberTypesFilter, ProjectsGroupingTypesFilter, TeamGroupingTypesFilter } from './types';
import ResourcePlanningComponent from './ResourcePlanning';
import { ModalModeEnum } from 'types';

export const ResourcePlanningRoute = Route(
  {
    date: Route.query.number,
    type: Route.params.oneOf('project').optional,
    mode: Route.params.oneOf(
      ModalModeEnum.manage,
      ModalModeEnum.request,
      ModalModeEnum.requestEdit,
      ModalModeEnum.leave,
      ModalModeEnum.assigmentIntegration,
    ).optional,
    search: Route.query.string,
    showRequests: Route.query.boolean,
    id: Route.params.string.optional,
    groupingType: Route.query.oneOf(
      ...Object.values(TeamGroupingTypesFilter),
      ...Object.values(ProjectsGroupingTypesFilter),
    ),
    membersType: Route.query.oneOf(...Object.values(MemberTypesFilter)),
    memberId: Route.query.string,
    projectId: Route.query.string,
  },
  ({ type, mode, id }) => `/resource-planning/${type}/${mode}/${id}`,
);

export const ResourcePlanning = ResourcePlanningRoute(ResourcePlanningComponent);
