import React, { FC, MouseEvent } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import clsx from 'clsx';
import styles from './styles.module.scss';

type Props = LinkProps & {
  disableNavigation?: boolean;
  className?: string;
};

export const Link: FC<Props> = ({ children, disableNavigation, className, ...props }) => {
  return (
    <RouterLink
      onClick={(e: MouseEvent<HTMLElement>) => {
        disableNavigation && e.preventDefault();
        e.stopPropagation();
      }}
      className={clsx(styles.link, !disableNavigation && styles.linkActive, className)}
      color="textPrimary"
      {...props}
    >
      {children}
    </RouterLink>
  );
};
