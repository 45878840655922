import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, NumberTextField } from 'components';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CancelCircle, SubmitCircle } from 'icons';
import { number } from 'yup';

import styles from './styles.module.scss';

export type FormValues = {
  commission?: number;
  effectiveFrom: string | null;
};

interface Props {
  onSubmit: (value: FormValues) => void;
  onCancel: () => void;
  initialValue?: FormValues;
  loading?: boolean;
}

export const CommissionForm: FC<Props> = ({ initialValue, loading, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      commission: number()
        .required(t('validation.required'))
        .positive(t('validation.positive'))
        .min(0, t('validation.min', { digits: 0 }))
        .max(100, t('validation.max', { max: 100 }))
        .test('maxDigitsAfterDecimal', t('validation.decimal', { digits: 2 }), (number) =>
          /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
        ),
      effectiveFrom: Yup.string().nullable().required(t('validation.required')),
    });
  }, []);

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    setValues,
    touched,
    errors,
    submitCount,
    setFieldTouched,
  } = useFormik<FormValues>({
    initialValues: initialValue || {
      effectiveFrom: null,
    },
    validationSchema,
    onSubmit,
    validateOnChange: true,
  });

  return (
    <form className={styles.row}>
      <NumberTextField
        onBlur={handleBlur}
        onChange={handleChange}
        name="commission"
        placeholder="0"
        value={typeof values.commission === 'number' ? String(values.commission) : undefined}
        className={clsx(styles.item, styles.title)}
        error={Boolean(submitCount || touched.commission) && !!errors.commission}
        helperText={Boolean(submitCount || touched.commission) && errors.commission}
        InputProps={{
          endAdornment: <span className={styles.endAdornment}>%</span>,
          className: styles.input,
        }}
      />
      <DatePicker
        placeholder="MM YYYY"
        dateFormat="MMM yyyy"
        name="effectiveFrom"
        value={values.effectiveFrom}
        showMonthYearPicker
        error={Boolean((submitCount || touched.effectiveFrom) && errors.effectiveFrom)}
        helperText={Boolean(submitCount || touched.effectiveFrom) && errors.effectiveFrom}
        onChange={(paymentDate) => {
          setFieldTouched('paymentDate', true);
          setValues({ ...values, effectiveFrom: paymentDate as FormValues['effectiveFrom'] });
        }}
        hideCalendarIcon={!!values.effectiveFrom}
        className={clsx(styles.item, styles.effectiveFrom)}
        InputProps={{ className: styles.input }}
      />

      <div className={styles.menu}>
        <IconButton size="small" disabled={loading} className={styles.iconButton} onClick={() => handleSubmit()}>
          <SubmitCircle className={styles.icon} />
        </IconButton>
        <IconButton size="small" className={styles.iconButton} onClick={onCancel}>
          <CancelCircle className={styles.icon} />
        </IconButton>
      </div>
    </form>
  );
};
