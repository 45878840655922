import React, { FC, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { ArrowDown, PlusIcon } from 'icons';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { CreateRate } from '../CreateRate';
import { ActionsType, ExternalRateDataFragment, RateUnit } from 'generated/types';
import { RateItem } from '../components/RateItem';
import { usePermissions } from 'hooks';
import { Accordion } from 'components';
import { getSeniorityRateData } from '../helpers';

interface Props {
  projectId: string;
  currency?: string;
  rates: ExternalRateDataFragment[];
  unit: RateUnit;
  allRates?: ExternalRateDataFragment[];
}

export const SeniorityRates: FC<Props> = ({ rates, projectId, currency, unit, allRates }) => {
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { hasAccess } = usePermissions({ projectId });

  const cancelCreating = () => setIsCreating(false);

  const { actualRate, rateItems } = useMemo(() => {
    const sorted = rates.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime());

    return getSeniorityRateData(sorted);
  }, [rates]);

  return (
    <div className={styles.container}>
      <Accordion
        header={
          <div className="w-100">
            <RateItem
              key={actualRate!.id}
              rate={actualRate!}
              currency={currency}
              disableAddRate={isCreating && expanded}
              onAddRate={() => {
                setIsCreating(true);
                setExpanded(true);
              }}
            />
          </div>
        }
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
        isChangeOnIconClick
        expandIcon={
          <div className={styles.expandIconBox}>
            <ArrowDown />
          </div>
        }
        accordionClassName={styles.accordion}
        expandIconClassName={styles.expandIcon}
        summaryClassName={styles.accordionSummary}
        detailsClassName={styles.accordionExpand}
      >
        {rateItems.map((rate) => (
          <RateItem key={rate.id} rate={rate} hideSeniority currency={currency} />
        ))}
        {isCreating && (
          <CreateRate
            roleId={rates[0].roleId}
            projectId={projectId}
            currency={currency}
            unit={unit}
            onClose={cancelCreating}
            seniorityId={rates[0].seniorityId}
            rates={allRates}
          />
        )}
        {hasAccess(ActionsType.CreateProjectRatecards) && (
          <Button
            variant="text"
            color="secondary"
            className={styles.button}
            startIcon={<PlusIcon className={styles.icon} />}
            onClick={() => setIsCreating(true)}
            disabled={isCreating}
          >
            {t('rateCard.addNewRate')}...
          </Button>
        )}
      </Accordion>
    </div>
  );
};
