import { SortByFn } from 'react-table';
import { isAfter } from 'date-fns';
import { useCallback } from 'react';
import { ManagedProjectDataFragment } from '../generated/types';

type ProjectData = ManagedProjectDataFragment & { margin: number; forecastMargin: number };

export const useProjectTableSorts = () => {
  const sortNameType = useCallback<SortByFn<ProjectData>>((rowA, rowB) => {
    return rowA.original.name.localeCompare(rowB.original.name, 'en');
  }, []);

  const sortClientType = useCallback<SortByFn<ProjectData>>((rowA, rowB) => {
    return (rowA.original.client?.name || '').localeCompare(rowB.original.client?.name || '', 'en');
  }, []);

  const sortEndDateType = useCallback<SortByFn<ProjectData>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.end_date), new Date(rowB.original.end_date)) ? 1 : -1;
  }, []);

  const sortStartDateType = useCallback<SortByFn<ProjectData>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.start_date), new Date(rowB.original.start_date)) ? 1 : -1;
  }, []);

  const sortPMType = useCallback<SortByFn<ProjectData>>((rowA, rowB) => {
    return `${rowA.original.pm?.first_name} ${rowA.original.pm?.last_name}`.localeCompare(
      `${rowB.original.pm?.first_name} ${rowB.original.pm?.last_name}`,
      'en',
    );
  }, []);

  return { sortNameType, sortClientType, sortStartDateType, sortEndDateType, sortPMType };
};
