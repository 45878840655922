import { ProjectAssignmentTimeLogDataFragment, TimeLogDataFragment } from 'generated/types';

type LogData = {
  assignment?: ProjectAssignmentTimeLogDataFragment;
  member_id: string;
} & TimeLogDataFragment;

export const groupTimeLogsByDate = (data: LogData[]) =>
  data.reduce((acc: Record<string, LogData[]>, item) => {
    const date: string = item.date;
    return { ...acc, [date]: [...(acc[date] || []), item] };
  }, {});

export const groupTimeLogsByMember = (data: LogData[]) =>
  data.reduce((acc: Record<string, LogData[]>, item) => {
    const memberId: string = item.member_id;
    return { ...acc, [memberId]: [...(acc[memberId] || []), item] };
  }, {});
