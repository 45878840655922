import React, { FC, useMemo } from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  AbsoluteSpinner,
  EmptyState,
  NewProject,
  ProjectFormValues,
  RightDrawer,
  TabItem,
  Tabs,
  ViewHeading,
} from 'components';
import { links } from 'App';
import { useAuth } from 'contexts';
import { addTimezoneOffset, currencyToValue, graphqlOnError, valueToCurrency } from 'utils';
import { ActionsType, Client, Member, ProjectType, RateUnit } from 'generated/types';
import { useEditProjectMutation, useExchangeRateQuery, useProjectByIdQuery } from 'generated/graphql';
import { useDeviceTypeByWidth, useErrorMsgBuilder, usePermissions, useRestoreProject } from 'hooks';
import {
  ProjectAssignments,
  ProjectExpenses,
  ProjectScenarios,
  SharedProjects,
  useRedirectWithoutPermissions,
} from 'views/ProjectDetail';
import { TimeTracking } from './TimeTracking';
import { Redirect } from 'react-router-dom';
import { GetRouteProps } from 'types/utils/router';

import { useSetAppTitle } from 'hooks/useSetAppTitle';
import { RateCard } from 'views/ProjectDetail/RateCard';
import { DateRangeTypes, DrawerQueries, InsightsTabs, ProjectTabs } from 'types';
import { ActivityHistory } from '../ActivityHistory';
import { ProjectFiles } from './Files';
import { ProjectBilling } from './Billing';
import { LeavesBalance } from './LeavesBalance';
import { HeaderButtons, Heading } from './components';

import styles from './ProjectDetail.module.scss';

const ProjectDetailRoute = Route(
  {
    id: Route.params.string,
    tab: Route.params.oneOf(
      ProjectTabs.assignments,
      ProjectTabs.expenses,
      ProjectTabs.timeTracking,
      ProjectTabs.files,
      ProjectTabs.billing,
    ).optional,
    start: Route.query.string,
    end: Route.query.string,
    range: Route.query.oneOf(...Object.values(DateRangeTypes)),
    month: Route.query.string,
    search: Route.query.string,
    mode: Route.query.oneOf(
      DrawerQueries.rateCardMode,
      DrawerQueries.activityHistoryMode,
      DrawerQueries.editMode,
      DrawerQueries.leavesBalanceMode,
      DrawerQueries.shareProjectsMode,
    ),
  },
  ({ id, tab }) => `/project/view/${id}/${tab}`,
);

type Props = GetRouteProps<typeof ProjectDetailRoute>;

const ProjectDetail: FC<Props> = ({
  match: {
    params: { id },
    params,
    query: { mode },
    query,
  },
  history: { push },
  link,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { hasAccess, isPermissionsLoading } = usePermissions({ projectId: id });
  const { startAccessDate, endAccessDate, loadingProjectMembership } = useRedirectWithoutPermissions(id, push);
  const { isMobileDevice } = useDeviceTypeByWidth();
  const { data: { project } = {}, loading } = useProjectByIdQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      projectId: id,
      withCommission: true,
    },
    skip: isPermissionsLoading,
  });
  useSetAppTitle(project && `${project?.name}`);

  const isProjectCurrencySameAsCompany = useMemo(() => {
    if (!project?.feeCurrencyId) return false;

    return project?.feeCurrencyId === userData?.company.primaryCurrencyId;
  }, [project?.feeCurrencyId, userData?.company.primaryCurrencyId]);

  const { data: { exchangeRate } = {} } = useExchangeRateQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      baseCurrencyId: userData!.company.primaryCurrencyId || '',
      exchangeCurrencyId: project?.feeCurrencyId || '',
    },
    skip: isProjectCurrencySameAsCompany || loading || !project?.feeCurrencyId,
  });

  const [editProject] = useEditProjectMutation({
    onCompleted() {
      toast.success(t('projects.projectEditSuccessfully'));
      handleDialogClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: ['projectAssignmentsList', 'profitability'],
  });

  const handleRestoreProject = useRestoreProject(id);

  const handleDialogOpen = (
    mode:
      | DrawerQueries.rateCardMode
      | DrawerQueries.activityHistoryMode
      | DrawerQueries.leavesBalanceMode
      | DrawerQueries.editMode
      | DrawerQueries.shareProjectsMode,
  ) => push(link({ ...params, ...query, mode }));
  const handleDialogClose = () => push(link({ ...params, ...query, mode: undefined }));

  const updateProject = async ({
    type,
    fee_amount,
    unit,
    fee_currency,
    start_date,
    end_date,
    pm,
    client,
    pmFinAccess,
    pmFinAccessStart,
    pmFinAccessEnd,
    costBudgetAmount,
    billableLeaves,
    overtimeMultiplier,
    ...values
  }: ProjectFormValues) => {
    await editProject({
      variables: {
        data: {
          ...(type.id === ProjectType.FixedPrice
            ? {
                fee_amount: currencyToValue(fee_amount),
                feeCurrencyId: fee_currency.id,
              }
            : { costBudgetAmount: !!costBudgetAmount ? currencyToValue(costBudgetAmount) : null }),
          ...((type.id === ProjectType.TimeAndMaterial || type.id === ProjectType.Retainer) && {
            feeCurrencyId: fee_currency.id,
          }),
          ...values,
          client: client?.id ?? null,
          unit: type.id !== ProjectType.TimeAndMaterial && type.id !== ProjectType.Retainer ? RateUnit.Hour : unit.id,
          pm: pm?.id ?? '',
          type: type.id,
          start_date: addTimezoneOffset(new Date(start_date)),
          end_date: addTimezoneOffset(new Date(end_date)),
          pmFinAccess: pmFinAccess.id,
          pmAccessStart: pmFinAccessStart ? addTimezoneOffset(new Date(pmFinAccessStart)) : null,
          pmAccessEnd: pmFinAccessEnd ? addTimezoneOffset(new Date(pmFinAccessEnd)) : null,
          overtimeMultiplier:
            (type.id === ProjectType.TimeAndMaterial || type.id === ProjectType.Retainer) && overtimeMultiplier
              ? Number(overtimeMultiplier)
              : undefined,
          billableLeaves:
            type.id === ProjectType.Retainer || type.id === ProjectType.TimeAndMaterial ? billableLeaves : false,
        },
        projectId: id,
        companyId: userData!.company.id,
      },
    });
  };

  if (loading || isPermissionsLoading || loadingProjectMembership) {
    return <AbsoluteSpinner />;
  }

  if (!project) {
    return <EmptyState />;
  }

  return (
    <>
      <ViewHeading
        headingClassName={styles.heading}
        className={styles.headingBox}
        label={<Heading project={project} />}
      >
        <HeaderButtons
          project={project}
          projectId={id}
          handleDialogOpen={handleDialogOpen}
          handleRestoreProject={handleRestoreProject}
          onInsightsRedirect={() => push(links.Insights({ tab: InsightsTabs.projects, projectId: id }))}
        />
      </ViewHeading>

      <Tabs className="tabs-box">
        {hasAccess(ActionsType.ViewProjectAssignments) && (
          <TabItem route={links.ProjectAssignments({ projectId: id })}>
            {t('viewProjectDetail.assignments.label')}
          </TabItem>
        )}
        {hasAccess(ActionsType.ViewExpenses) && (
          <TabItem route={links.ProjectExpenses({ id })}>{t('viewProjectDetail.expenses.label')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewTimelogs) && (
          <TabItem exact={false} route={links.TimeTracking({ id })}>
            {t('timeTracking.label')}
          </TabItem>
        )}
        {hasAccess(ActionsType.ViewDocuments) && (
          <TabItem route={links.ProjectFiles({ id })}>{t('projectFile.label')}</TabItem>
        )}
        {hasAccess(ActionsType.BillingReport) && (
          <TabItem route={links.ProjectBilling({ id })}>{t('billing.label')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewScenarios) && !isMobileDevice && (
          <TabItem route={links.ProjectScenarios({ id })}>{t('scenarios.title')}</TabItem>
        )}
      </Tabs>

      <Switch>
        <ProjectAssignments
          startAccessDate={startAccessDate}
          endAccessDate={endAccessDate}
          exact
          project={project}
          isProjectCurrencySameAsCompany={isProjectCurrencySameAsCompany}
          exchangeRate={exchangeRate}
        />
        <ProjectExpenses exact project={project} />
        <TimeTracking />
        <ProjectFiles />
        <ProjectScenarios project={project} />
        <ProjectBilling project={project} />
        <Redirect to={links.ProjectAssignments({ projectId: id })} />
      </Switch>

      <RightDrawer
        direction="right"
        open={mode === DrawerQueries.editMode}
        onClose={handleDialogClose}
        title={t('projects.editProject')}
        paperClassName={styles.editProjectPaper}
      >
        <NewProject
          id={project.id}
          client={project.client as Client}
          name={project.name}
          type={project.type}
          pm={project.pm as Member}
          unit={project?.unit}
          fee_amount={valueToCurrency(project.fee_amount as number)}
          feeCurrencyId={project.feeCurrencyId}
          start_date={project.start_date}
          end_date={project.end_date}
          color={project.color}
          pmFinAccessId={project.pmFinAccess?.accessLevel}
          pmFinAccessStart={project.pmFinAccess?.startDate}
          pmFinAccessEnd={project.pmFinAccess?.endDate}
          commission={project.currentCommission}
          overtimeMultiplier={project.overtimeMultiplier}
          billableLeaves={project.billableLeaves}
          onSubmit={updateProject}
          onCancel={handleDialogClose}
          submitLabel={t('actions.save')}
          costBudgetAmount={project.cost_budget_amount && valueToCurrency(project.cost_budget_amount)}
        />
      </RightDrawer>
      <RateCard isOpen={mode === DrawerQueries.rateCardMode} onClose={handleDialogClose} project={project} />
      <LeavesBalance isOpen={mode === DrawerQueries.leavesBalanceMode} onClose={handleDialogClose} projectId={id} />
      <ActivityHistory
        isOpen={mode === DrawerQueries.activityHistoryMode}
        onClose={handleDialogClose}
        projectId={project.id}
        currencyCode={project.fee_currency || ''}
        isShowMemberField
      />
      <SharedProjects
        isOpen={mode === DrawerQueries.shareProjectsMode}
        onClose={handleDialogClose}
        projectId={project.id}
        pmId={project.pm.id}
        isNonBillableProject={project.type === ProjectType.NonBillable}
      />
    </>
  );
};

export default ProjectDetailRoute(ProjectDetail);
