import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { FieldInputProps } from 'formik/dist/types';

type Props = TextFieldProps & { field?: FieldInputProps<string> };
export const NumberTextField: FC<Props> = ({ onChange, field, ...props }) => {
  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const NUMBER_DOT_COMMA = /^[\d,.]*$/;
    const fieldValue = (e.target as HTMLInputElement)!.value;
    const fieldHasCommaOrDot = fieldValue.includes('.') || fieldValue.includes(',');
    const keyIsCommaOrDot = e.key === '.' || e.key === ',';
    const keyIsEnter = e.key === 'Enter';

    if ((!NUMBER_DOT_COMMA.test(e.key) || (keyIsCommaOrDot && fieldHasCommaOrDot)) && !keyIsEnter) {
      e.preventDefault();
    }
    (e.target as HTMLInputElement)!.value = fieldValue.replace(',', '.');
  };

  return (
    <TextField
      onKeyPress={onKeyPress}
      {...field}
      {...props}
      {...(onChange
        ? {
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(',', '.');
              onChange?.(e);
            },
          }
        : {})}
    />
  );
};
