import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { ModalModeEnum } from 'types';

export const FinancialRoute = Route(
  {
    id: Route.params.regex(idRegEx),
    mode: Route.params.oneOf(...Object.values(ModalModeEnum)).optional,
    costUnitId: Route.params.regex(idRegEx).optional,
  },
  ({ id, mode, costUnitId }) => `/team-member/view/${id}/financial/${mode}/${costUnitId}`,
);

export const Financial = FinancialRoute(lazy(() => import(/* webpackChunkName: "Financial" */ './Financial')));
