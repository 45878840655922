export const getDifferentValuePercentage = (value?: number | null, prevValue?: number | null) => {
  switch (true) {
    case prevValue === 0 && value !== 0:
      return NaN;
    case value && value < 0 && prevValue && prevValue < 0:
      return (Math.abs(value || 0) - Math.abs(prevValue || 0)) / ((prevValue || 0) * 0.01 || 1);
    case (prevValue && prevValue < 0 && value && value < 0) || (prevValue && prevValue < 0 && value && value >= 0):
      return (Math.abs(value || 0) + Math.abs(prevValue || 0)) / (Math.abs(prevValue || 0) * 0.01 || 1);
    default:
      return ((value || 0) - (prevValue || 0)) / ((prevValue || 0) * 0.01 || 1);
  }
};
