import React, { CSSProperties, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getAssignmentDate, getPeriodLabel } from 'views/ResourcePlanning/utils';
import { AssignmentRowItem } from 'views/ResourcePlanning/types';
import { FollowingTooltip } from 'components';

type Props = {
  children: ReactElement;
  assignment: AssignmentRowItem;
  style?: CSSProperties;
};

export const AssignmentTooltip = memo<Props>(({ children, assignment, style }) => {
  const { t } = useTranslation();
  const startDate = getAssignmentDate(assignment.startDate);
  const endDate = getAssignmentDate(assignment.endDate);

  return (
    <FollowingTooltip
      style={style}
      title={
        <div className="flex flex-column gap-6">
          <span className="flex justify-content-between gap-16">
            <span>{t('resourcePlanning.assignment.dates')}</span> {getPeriodLabel(startDate, endDate)}
          </span>
          <span className="flex justify-content-between gap-16">
            <span>{t('resourcePlanning.assignment.allocation')}</span>
            <span>
              {assignment.allocationTimeAmount} {t('resourcePlanning.assignment.hrsPerDay')}
            </span>
          </span>
          <span className="flex justify-content-between gap-16">
            <span>{t('resourcePlanning.assignment.type')}</span>
            <>
              {assignment.billable
                ? t('resourcePlanning.assignment.billable')
                : t('resourcePlanning.assignment.nonBillable')}
            </>
          </span>
          {assignment.isRequest && (
            <span className="flex justify-content-between gap-16">
              <span>{t('resourcePlanning.assignment.status')}</span>
              <>{t('resourcePlanning.assignment.requestPending')}</>
            </span>
          )}
        </div>
      }
    >
      {children}
    </FollowingTooltip>
  );
});

AssignmentTooltip.displayName = 'Timeline.AssignmentTooltip';
