import React from 'react';

interface CollapseArrowIconProps {
  className?: string;
}

export const CollapseArrowIcon = ({ className }: CollapseArrowIconProps) => (
  <svg className={className} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.06748 6.00023C0.643565 6.00023 0.411987 5.5058 0.683373 5.18013L4.61585 0.461158C4.81575 0.22128 5.18418 0.221281 5.38407 0.461158L9.31655 5.18013C9.58794 5.5058 9.35636 6.00023 8.93244 6.00023L1.06748 6.00023Z"
      fill="#5C6E79"
    />
  </svg>
);
