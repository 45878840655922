import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Tooltip } from 'components';

import styles from './SidebarItem.module.scss';

export interface SidebarItemProps {
  children: React.ReactNode;
  tooltipText?: string;
  route: string;
  className?: string;
  activeClass?: string;
  iconOnly?: boolean;
  isActive?: () => boolean;
}

export const SidebarItem = ({
  children,
  tooltipText,
  route,
  className,
  activeClass,
  iconOnly,
  isActive,
}: SidebarItemProps) => {
  return (
    <Tooltip title={tooltipText || ''} alwaysShowTooltip={!!tooltipText} placement="right">
      <NavLink
        to={route}
        className={clsx(
          styles.sidebarLink,
          !iconOnly && styles.svgOffset,
          iconOnly ? 'px-14' : 'px-16',
          'my-2',
          'py-8',
          'flex',
          'align-items-center',
          'text-14',
          'text-black',
          className,
        )}
        activeClassName={activeClass}
        isActive={isActive}
      >
        {children}
      </NavLink>
    </Tooltip>
  );
};
