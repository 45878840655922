import { useAuth } from 'contexts';
import { startOfDay } from 'date-fns';
import { useHolidaysQuery } from 'generated/graphql';
import { useMemo } from 'react';

export const useHolidays = () => {
  const { userData } = useAuth();

  const { data } = useHolidaysQuery({ variables: { companyId: userData?.company.id ?? '' }, skip: !userData });

  return useMemo(() => {
    return new Set(data?.holidays.map(({ date }) => startOfDay(new Date(date)).toString()));
  }, [data?.holidays]);
};
