import React from 'react';

interface ChevronIconProps {
  className?: string;
  onClick?: () => void;
}

export const DotsIcon = ({ className, onClick }: ChevronIconProps) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 11.6666C10.9205 11.6666 11.6667 10.9204 11.6667 9.99992C11.6667 9.07944 10.9205 8.33325 10 8.33325C9.07957 8.33325 8.33337 9.07944 8.33337 9.99992C8.33337 10.9204 9.07957 11.6666 10 11.6666Z"
        fill="#85929A"
      />
      <path
        d="M5.00004 11.6666C5.92052 11.6666 6.66671 10.9204 6.66671 9.99992C6.66671 9.07944 5.92052 8.33325 5.00004 8.33325C4.07957 8.33325 3.33337 9.07944 3.33337 9.99992C3.33337 10.9204 4.07957 11.6666 5.00004 11.6666Z"
        fill="#85929A"
      />
      <path
        d="M15 11.6666C15.9205 11.6666 16.6667 10.9204 16.6667 9.99992C16.6667 9.07944 15.9205 8.33325 15 8.33325C14.0796 8.33325 13.3334 9.07944 13.3334 9.99992C13.3334 10.9204 14.0796 11.6666 15 11.6666Z"
        fill="#85929A"
      />
    </svg>
  );
};
