import { idRegEx } from 'utils/regex';
import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import ExpenseTypesComponent from './ExpenseTypes';

export const ExpenseTypesRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    expenseId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, expenseId }) => `/settings/project-settings/expense-types/${mode}/${expenseId}`,
);

export const ExpenseTypes = ExpenseTypesRoute(ExpenseTypesComponent);
