import React, { memo } from 'react';
import clsx from 'clsx';

import { LeaveIcon } from 'icons';
import { useTimelineContext } from 'contexts';

import { TimelineAvailability } from './Availability';

import styles from './styles.module.scss';

const MIN_COLUMN_WIDTH = 32;

interface PerDayViewProps {
  availability: TimelineAvailability[];
  memberCapacity: number;
  onLeave?: (id: string) => void;
}

const getHoursLabel = (hours?: number) => {
  if (hours && hours.toString().replace('.', '').length > 3) {
    return hours.toPrecision(3);
  }
  return hours;
};
export const PerDayView = memo<PerDayViewProps>(({ availability, memberCapacity, onLeave }) => {
  const { collapsedWeekends } = useTimelineContext();

  return (
    <>
      {availability.map(({ start, amount, hours, leave, leaveId }) => {
        const workingHours = hours ?? 0;
        return (
          <div
            key={`perHourView_${start?.toString()}`}
            className={clsx(styles.availabilityItem, {
              [styles.availabilityItemUnavailable]: !amount,
              [styles.availabilityItemUnavailableOvertime]: workingHours > memberCapacity,
              [styles.availabilityItemUnavailablePartTimeLess]: workingHours <= memberCapacity / 2,
              [styles.availabilityItemUnavailablePartTimeMore]: workingHours > memberCapacity / 2,
              [styles.availabilityItemUnavailableFullTime]: workingHours === memberCapacity,
              [styles.availabilityItemUnavailableLeave]: leave,
              [styles.availabilityItemUnavailableLeaveClickable]: leave && onLeave,
              [styles.availabilityItemUnavailableLeaveCollapsed]: leave && collapsedWeekends,
              [styles.availabilityItemUnavailableCollapsed]: collapsedWeekends,
            })}
            onClick={() => leaveId && onLeave?.(leaveId)}
            style={{ flex: `${amount ?? 1} 1 0px`, minWidth: `${(amount ?? 1) * MIN_COLUMN_WIDTH}px` }}
          >
            {leave ? <LeaveIcon /> : getHoursLabel(hours)}
          </div>
        );
      })}
    </>
  );
});

PerDayView.displayName = 'PerDayView';
