import { ReportDataWithPmFragment } from 'generated/types';
import { isAfter } from 'date-fns';

const NO_PROJECT = 'noProject';
const NO_PM = 'noPM';

export const groupReportsByProject = (data: ReportDataWithPmFragment[]) =>
  data
    .sort((a, b) => a.project.name.localeCompare(b.project.name, 'en'))
    .reduce((acc: Record<string, ReportDataWithPmFragment[]>, item) => {
      const projectId: string = item?.project?.id ?? NO_PROJECT;
      acc[projectId] = acc[projectId] || [];
      acc[projectId].push(item);
      return acc;
    }, {});

export const groupReportsByPM = (data: ReportDataWithPmFragment[]) =>
  data
    .sort((a, b) => {
      return `${a.project.pm.first_name} ${a.project.pm.last_name}`.localeCompare(
        `${b.project.pm.first_name} ${b.project.pm.last_name}`,
        'en',
      );
    })
    .reduce((acc: Record<string, ReportDataWithPmFragment[]>, item) => {
      const pmId: string = item?.project.pm?.id ?? NO_PM;
      acc[pmId] = acc[pmId] || [];
      acc[pmId].push(item);
      return acc;
    }, {});

export const groupReportsByPeriod = (data: ReportDataWithPmFragment[]) =>
  data
    .sort((a, b) => (isAfter(new Date(a.startDate), new Date(b.startDate)) ? -1 : 1))
    .reduce((acc: Record<string, ReportDataWithPmFragment[]>, item) => {
      const period: string = item?.startDate?.slice(0, 7);
      acc[period] = acc[period] || [];
      acc[period].push(item);
      return acc;
    }, {});
