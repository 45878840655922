import React, { memo, useMemo } from 'react';
import clsx from 'clsx';
import { LinearProgress } from '@material-ui/core';

import { useTimelineContext } from 'contexts';
import { generateRandomId } from 'utils';
import { Tooltip } from 'components';
import { QUARTER_PERIOD } from 'consts';
import { LeaveIcon } from 'icons';

import { AssignmentPeriod } from './Availability';
import { DefaultWeekLabel, LeaveWeekLabel, TooltipContent } from './WeekLabels';

import styles from './styles.module.scss';

const CALENDAR_WEEK = 7;
const DEFAULT_WEEK_BLOCK_MARGIN = 5;
const YEAR_WEEK_BLOCK_MARGIN = 3;
const YEAR_MIN_COLUMN_WIDTH = 3;
const WORKING_DAYS_PER_WEEK = 5;

type WeeklyViewLeave = {
  start: Date;
  end: Date;
};

type WeeklyAvailability = {
  start?: Date;
  workingHours?: number;
  leaveHours?: number;
  amount?: number;
  weekend?: boolean;
  leaveId?: string;
  leaveDays?: { [key: string]: WeeklyViewLeave };
  assignmentPeriod?: AssignmentPeriod;
  publicHolidays?: string[];
};

type AvailabilityPerWeek = {
  [key: string]: WeeklyAvailability;
};

type PerWeekViewProps = {
  memberCapacity: number;
  days: Date[];
  availabilityPerWeek: AvailabilityPerWeek;
  isYearView: boolean;
};

export const PerWeekView = memo<PerWeekViewProps>(({ memberCapacity, days, availabilityPerWeek, isYearView }) => {
  const yearViewData = useMemo(
    () =>
      Object.keys(availabilityPerWeek).reduce<WeeklyAvailability[]>((acc, rec) => {
        if (!!availabilityPerWeek[rec].workingHours || !!availabilityPerWeek[rec].leaveHours) {
          return acc.concat({ ...availabilityPerWeek[rec] });
        }
        const last = acc[acc.length - 1];

        if (last?.amount) {
          return acc.slice(0, acc.length - 1).concat({
            ...last,
            amount: (last?.amount ?? 0) + 1,
          });
        }

        return acc.concat({ amount: 1 });
      }, []),
    [availabilityPerWeek],
  );

  if (isYearView) {
    return (
      <>
        {yearViewData.map((data) => {
          return (
            <YearWeeklyCell
              data={data}
              memberCapacity={memberCapacity}
              key={generateRandomId()}
              timelineLength={Object.keys(availabilityPerWeek).length}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      {Object.values(availabilityPerWeek).map((data) => {
        return (
          <DefaultWeeklyCell
            data={data}
            memberCapacity={memberCapacity}
            key={generateRandomId()}
            timelineLength={days.length}
          />
        );
      })}
    </>
  );
});

type WeeklyCellProps = {
  data: WeeklyAvailability;
  memberCapacity: number;
  timelineLength: number;
};

const DefaultWeeklyCell = memo<WeeklyCellProps>(({ data, memberCapacity, timelineLength }) => {
  const { collapsedWeekends, timelinePeriod } = useTimelineContext();
  const { workingHours, leaveHours, weekend, leaveDays, publicHolidays, assignmentPeriod } = data;

  const mixedWeek =
    workingHours && leaveHours
      ? workingHours > 0 && leaveHours > 0
      : publicHolidays
      ? publicHolidays.length > 0
      : false;
  const weeklyCapacity = memberCapacity * (WORKING_DAYS_PER_WEEK - (publicHolidays?.length ?? 0));
  const totalHours = (assignmentPeriod || mixedWeek) && workingHours ? Math.round(workingHours * 100) / 100 : 0;
  const capacityPercent =
    (assignmentPeriod || mixedWeek) && workingHours ? Math.round((workingHours / weeklyCapacity) * 1000) / 10 : 0;

  const leaveWeek = weeklyCapacity === (leaveHours ?? 0);
  const partTimeWeek = capacityPercent > 0 && capacityPercent < 100 && workingHours && workingHours > 0;
  const emptyWeek = typeof workingHours === 'undefined' && typeof leaveHours === 'undefined';
  const shortLeaveWeek = typeof workingHours === 'undefined' && !!leaveHours && !leaveWeek;

  const isQuarterView = timelinePeriod === QUARTER_PERIOD;
  const lineProgress = leaveWeek ? 100 : capacityPercent > 100 ? 100 : capacityPercent;

  const quarterViewInlineStyles = {
    flexBasis: `calc(${CALENDAR_WEEK} / ${timelineLength} * 100% - ${DEFAULT_WEEK_BLOCK_MARGIN}px`,
  };

  const tooltipContent = (
    <TooltipContent totalHours={assignmentPeriod || mixedWeek ? totalHours : 0} leaveDays={leaveDays} />
  );

  const weekLabel = leaveWeek ? (
    <LeaveWeekLabel />
  ) : (
    <DefaultWeekLabel
      totalHours={totalHours}
      capacityPercent={capacityPercent}
      leaveHours={leaveHours}
      shortLeaveWeek={shortLeaveWeek}
      mixedWeek={mixedWeek}
      workingHours={workingHours}
    />
  );

  if (weekend) {
    return <div className={styles.availabilityWeekendBox} />;
  }

  return (
    <div
      className={clsx(weekend ? styles.availabilityWeekendBox : styles.availabilityWeekBox, {
        [styles.availabilityWeekBoxCollapsed]: collapsedWeekends,
        [styles.availabilityWeekBoxFullTime]: capacityPercent === 100,
        [styles.availabilityWeekBoxOvertime]: capacityPercent > 100,
        [styles.availabilityWeekBoxPartTime]: partTimeWeek,
        [styles.availabilityWeekBoxFullLeave]: leaveWeek,
        [styles.availabilityWeekBoxFullLeaveCollapsed]: leaveWeek && collapsedWeekends,
        [styles.availabilityWeekBoxEmpty]: emptyWeek || shortLeaveWeek,
        [styles.availabilityWeekBoxQuarterView]: isQuarterView,
      })}
      style={isQuarterView ? quarterViewInlineStyles : {}}
    >
      <Tooltip title={tooltipContent} placement="top" alwaysShowTooltip className={styles.weeklyCellTooltip}>
        <LinearProgress
          variant="determinate"
          value={lineProgress}
          className={clsx(styles.progressBar, capacityPercent === 0 && styles.progressBarEmpty)}
          classes={{
            barColorPrimary: clsx({
              [styles.progressBarFullTime]: capacityPercent === 100,
              [styles.progressBarOvertime]: capacityPercent > 100,
              [styles.progressBarPartTime]: partTimeWeek,
              [styles.progressBarFullLeave]: leaveWeek,
            }),
          }}
        />
        {weekLabel}
      </Tooltip>
    </div>
  );
});

const YearWeeklyCell = memo<WeeklyCellProps>(({ data, memberCapacity, timelineLength }) => {
  const { workingHours, leaveHours, weekend, publicHolidays, assignmentPeriod, amount } = data;

  const weeklyCapacity = memberCapacity * (WORKING_DAYS_PER_WEEK - (publicHolidays?.length ?? 0));
  const leaveWeek = weeklyCapacity === (leaveHours ?? 0);
  const mixedWeek = !leaveWeek && leaveHours ? leaveHours > 0 : publicHolidays ? publicHolidays.length > 0 : false;

  const totalHours = (assignmentPeriod || mixedWeek) && workingHours ? Math.round(workingHours) : 0;
  const capacityPercent =
    (assignmentPeriod || mixedWeek) && workingHours ? Math.round((workingHours / weeklyCapacity) * 1000) / 10 : 0;

  const partTimeWeek = capacityPercent > 0 && capacityPercent < 100 && workingHours && workingHours > 0;
  const emptyWeek = typeof workingHours === 'undefined' && typeof leaveHours === 'undefined';
  const shortLeaveWeek = typeof workingHours === 'undefined' && !!leaveHours && !leaveWeek;

  if (amount) {
    return (
      <div
        style={{
          flexBasis: `calc(${amount} / ${timelineLength} * 100%`,
          minWidth: `${amount * YEAR_MIN_COLUMN_WIDTH * CALENDAR_WEEK}px`,
        }}
      />
    );
  }

  return (
    <div
      style={{ flexBasis: `calc(1 / ${timelineLength} * 100% - ${YEAR_WEEK_BLOCK_MARGIN}px` }}
      className={clsx(weekend ? styles.availabilityWeekendBox : styles.availabilityWeekBox, {
        [styles.availabilityWeekBoxFullTime]: capacityPercent === 100,
        [styles.availabilityWeekBoxOvertime]: capacityPercent > 100,
        [styles.availabilityWeekBoxYearViewPartTime]: partTimeWeek,
        [styles.availabilityWeekBoxYearViewEmpty]: emptyWeek || shortLeaveWeek,
        [styles.availabilityWeekBoxYearView]: true,
        [styles.availabilityWeekBoxYearViewFullLeave]: leaveWeek,
      })}
    >
      <div className={styles.availabilityWeekBoxYearViewLabelWrapper}>
        <span>{leaveWeek ? <LeaveIcon /> : totalHours}</span>
        {mixedWeek && !leaveWeek && leaveHours && (
          <>
            <div className={styles.divider} />
            <LeaveIcon />
          </>
        )}
      </div>
    </div>
  );
});

PerWeekView.displayName = 'PerWeekView';
YearWeeklyCell.displayName = 'PerWeekView.YearWeeklyCell';
DefaultWeeklyCell.displayName = 'PerWeekView.DefaultWeeklyCell';
