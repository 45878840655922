import React from 'react';
import { compose, getLinks, Route } from 'react-router-hoc';
import { Redirect, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabItem, Tabs, ViewHeading } from 'components';
import { Users } from './Users';
import { TeamSettings } from './TeamSettings';
import { ResourceManagement } from './ResourceManagement';
import { ProjectSettings } from './ProjectSettings';
import { isAdminPermission, ProtectedRoute } from 'utils';
import { useSetAppTitle } from 'hooks/useSetAppTitle';
import { usePermissions } from 'hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ActionsType } from 'generated/types';
import { Integrations } from './Integrations';
import { ActivityHistory } from './ActivityHistory';

export const links = getLinks({
  TeamSettings,
  ResourceManagement,
  Users,
  ProjectSettings,
  Integrations,
  ActivityHistory: ActivityHistory,
});

const SettingsRoute = compose(ProtectedRoute(isAdminPermission), Route('/settings'));

const Settings = () => {
  const { t } = useTranslation();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  useSetAppTitle(t('navigation.settings'));

  if (isPermissionsLoading) {
    return <CircularProgress size={64} style={{ color: '#24343D' }} />;
  }

  return (
    <>
      <ViewHeading hasSmartBackBtn={false} label={t('settings.label')} />

      <div className="tabs-container">
        <Tabs>
          {(hasAccess(ActionsType.ViewUsers) || hasAccess(ActionsType.ViewInvitations)) && (
            <TabItem exact={false} route={links.Users()}>
              {t('settings.users')}
            </TabItem>
          )}
          {hasAccess(ActionsType.OtherSettings) && (
            <>
              <TabItem exact={false} route={links.ProjectSettings()}>
                {t('settings.projectSettings.label')}
              </TabItem>
              <TabItem exact={false} route={links.TeamSettings()}>
                {t('settings.teamSettings.label')}
              </TabItem>
              <TabItem exact={false} route={links.ResourceManagement()}>
                {t('settings.resourceManagement.label')}
              </TabItem>
              <TabItem exact={false} route={links.Integrations()}>
                {t('settings.integrations.label')}
              </TabItem>
              <TabItem exact={false} route={links.ActivityHistory()}>
                {t('settings.activityHistory.label')}
              </TabItem>
            </>
          )}
        </Tabs>
      </div>

      <div className="layout-content-wrapper">
        <Switch>
          <TeamSettings />
          <ProjectSettings />
          <ResourceManagement />
          <Users />
          <Integrations />
          <ActivityHistory />
          <Redirect push={false} exact from={'/settings'} to={links.Users()} />
        </Switch>
      </div>
    </>
  );
};

export default SettingsRoute(Settings);
