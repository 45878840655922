import { SortByFn } from 'react-table';
import { isAfter } from 'date-fns';
import { useCallback } from 'react';
import { ScenarioDataFragment } from 'generated/types';

export const useScenarioTableSorts = () => {
  const sortTitleType = useCallback<SortByFn<ScenarioDataFragment>>((rowA, rowB) => {
    return rowA.original.title.localeCompare(rowB.original.title, 'en');
  }, []);

  const sortCreatedByMemberNameType = useCallback<SortByFn<ScenarioDataFragment>>((rowA, rowB) => {
    return rowA.original.createdByMemberName.localeCompare(rowB.original.createdByMemberName, 'en');
  }, []);

  const sortCreatedDateType = useCallback<SortByFn<ScenarioDataFragment>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.created_at), new Date(rowB.original.created_at)) ? 1 : -1;
  }, []);

  const sortTeamCountDateType = useCallback<SortByFn<ScenarioDataFragment>>((rowA, rowB) => {
    return rowA.original.activeMembers > rowB.original.activeMembers ? 1 : -1;
  }, []);

  const sortProjectNameType = useCallback<SortByFn<ScenarioDataFragment>>((rowA, rowB) => {
    return rowA.original.project.name.localeCompare(rowB.original.project.name, 'en');
  }, []);

  return {
    sortTitleType,
    sortCreatedByMemberNameType,
    sortCreatedDateType,
    sortTeamCountDateType,
    sortProjectNameType,
  };
};
