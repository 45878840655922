import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'icons';
import { RemoveConfirmIconButton, Tooltip } from 'components';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import { formatAmount } from 'utils';
import { EMPTY_DATA_STATE } from 'consts';

import styles from './styles.module.scss';

interface Props {
  amount: number;
  notes?: string | null;
  paymentDate: string;
  title: string;
  onDelete: () => void;
  onEdit: () => void;
}

export const ViewMilestone: FC<Props> = ({ amount, notes, paymentDate, title, onDelete, onEdit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.row}>
      <span className={clsx(styles.item, styles.title)} onClick={onEdit}>
        <Tooltip title={title} placement="top" textClassName={styles.tooltip}>
          {title}
        </Tooltip>
      </span>

      <div className={styles.divider} />

      <span className={clsx(styles.item, styles.paymentDate)} onClick={onEdit}>
        {format(new Date(paymentDate), 'dd/MM/yyyy')}
      </span>

      <div className={styles.divider} />

      <span className={clsx(styles.item, styles.amount)} onClick={onEdit}>
        {formatAmount(amount)}
      </span>

      <div className={styles.divider} />

      <span className={clsx(styles.item, styles.notes)} onClick={onEdit}>
        <Tooltip title={notes || ''} placement="top" textClassName={styles.tooltip}>
          {notes || EMPTY_DATA_STATE}
        </Tooltip>
      </span>

      <div className={styles.divider} />

      <div className={styles.menu}>
        <Tooltip title={t('actions.edit')!} placement="top" alwaysShowTooltip>
          <IconButton className={styles.iconButton} size="small" onClick={onEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <RemoveConfirmIconButton
          onClick={onDelete}
          className={styles.iconButton}
          confirmTitle={t('viewProjectDetail.milestones.deleteMilestone')}
          confirmMessage={t('viewProjectDetail.milestones.confirmDelete')}
          confirmSubmitButtonTitle={t('viewProjectDetail.milestones.deleteMilestone')}
        />
      </div>
    </div>
  );
};
