import React, { FC, ReactNode } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TimeLogInput } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel, IconButton, TextField } from '@material-ui/core';
import { CancelCircle, SubmitCircle } from 'icons';

import styles from './styles.module.scss';
import clsx from 'clsx';

export interface FormValues {
  minutes: number;
  notes?: string | null;
  id?: string;
  overtime?: boolean;
  overtimeMultiplier?: number | null;
}

interface Props {
  date: string;
  id?: string;
  minutes?: number;
  notes?: string;
  overtime?: boolean | null;
  overtimeMultiplier?: number | null;
  onCancel: () => void;
  onSubmit: (value: FormValues) => void;
  optionalColumn: ReactNode;
}

export const TimeLogForm: FC<Props> = ({
  id,
  date,
  minutes = 0,
  notes = '',
  overtime,
  overtimeMultiplier,
  optionalColumn,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldTouched } = useFormik<FormValues>({
    initialValues: { minutes, notes, overtime: overtime || false, overtimeMultiplier },
    validationSchema: Yup.object({
      minutes: Yup.number()
        .typeError(t('validation.invalid'))
        .min(0, t('validation.min', { digits: 0 }))
        .moreThan(0, t('validation.moreThan', { digits: 0 }))
        .required(t('validation.required')),
    }),
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit({
        ...values,
        minutes: Number(values.minutes),
        id,
      });
      setSubmitting(false);
      onCancel();
    },
  });

  const showError = () => {
    setFieldTouched('minutes');
  };

  return (
    <form className={styles.box}>
      <span className={styles.date}>{format(new Date(date), 'MMM dd, yyyy')}</span>

      <span className={styles.member}>{optionalColumn}</span>

      <span className={styles.logged}>
        <TimeLogInput
          name="minutes"
          className={styles.input}
          onChange={(minutes) => handleChange('minutes')(`${minutes}`)}
          onBlur={handleBlur}
          value={values.minutes}
          error={touched.minutes ? !!errors.minutes : undefined}
          helperText={touched.minutes && errors.minutes}
        />
      </span>

      <span className={styles.note}>
        <TextField
          name="notes"
          size="medium"
          className={styles.input}
          InputProps={{ className: 'white-background' }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.notes}
          placeholder={t('forms.typeNoteHere')}
        />
      </span>

      <FormControlLabel
        name="overtime"
        value={values.overtime}
        label={t('timeTracking.overtime')}
        onChange={handleChange}
        control={<Checkbox checked={values.overtime} color="primary" />}
        className={styles.checkboxLabel}
        classes={{ label: clsx(styles.checkboxLabelText, values.overtime && styles.active) }}
      />

      <div className={styles.menu}>
        <IconButton
          size="small"
          className={styles.iconButton}
          onClick={() => (Object.keys(errors).length ? showError() : handleSubmit())}
        >
          <SubmitCircle className={styles.icon} />
        </IconButton>
        <IconButton size="small" className={styles.iconButton} onClick={onCancel}>
          <CancelCircle className={styles.icon} />
        </IconButton>
      </div>
    </form>
  );
};
