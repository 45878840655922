import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  countLabel?: string | ReactNode;
  className?: string;
  children?: ReactNode;
}

export const TableCountHeader: FC<Props> = ({ countLabel, className, children }) => {
  return (
    <div className={clsx(styles.box, className)}>
      <div className={styles.count}>{countLabel || ''}</div>
      {children}
    </div>
  );
};
