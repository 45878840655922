import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { ArchiveProjectConfirm, Tooltip } from 'components';
import { ArchiveIcon } from 'icons';
import { useIsOpen } from 'hooks';

interface Props {
  id: string;
  isActiveAssignment: boolean;
}

export const ArchiveProject: FC<Props> = ({ id, isActiveAssignment }) => {
  const { t } = useTranslation();
  const [isOpenArchiveConfirm, onOpenArchiveConfirm, onCloseArchiveConfirm] = useIsOpen();

  return (
    <>
      <Tooltip title={t('actions.archive') || ''} placement="top" alwaysShowTooltip>
        <IconButton
          size="small"
          onClick={(e: MouseEvent<HTMLElement>) => {
            onOpenArchiveConfirm();
            e.preventDefault();
          }}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>

      <ArchiveProjectConfirm
        id={id}
        isActiveAssignment={isActiveAssignment}
        isOpenConfirm={isOpenArchiveConfirm}
        onCloseConfirm={onCloseArchiveConfirm}
      />
    </>
  );
};
