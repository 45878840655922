import { Overrides } from '@material-ui/core/styles/overrides';

export const overrides: Overrides = {
  MuiButton: {
    root: {
      fontWeight: 'bold',
      textTransform: 'none',
      padding: '10px 15px',
      height: '36px',
      fontSize: '1.4rem',
    },
    label: {
      lineHeight: '16px',
    },
    outlinedPrimary: {
      padding: '10px 15px',
      height: '36px',
      fontSize: '1.4rem',
      color: '#433DB3',
      borderColor: '#615CC7',
    },
  },
  MuiInputLabel: {
    root: {
      fontWeight: 600,
      color: '#5C6E79',
      fontSize: '1.4rem',
      lineHeight: '16px',
      marginBottom: '8px',
      whiteSpace: 'nowrap',
      letterSpacing: 0,
    },
  },
  MuiOutlinedInput: {
    root: {
      height: '36px',
      '&$disabled': {
        background: '#F2F6F7',
      },
      '&.Mui-focused': {
        '&$focused $notchedOutline': {
          borderWidth: '1px',
        },
      },
      '&.Mui-error:hover': {
        '&$error $notchedOutline': {
          borderColor: '#f44336',
        },
      },
      '&.Mui-disabled, &.Mui-disabled:hover': {
        '&$disabled $notchedOutline': {
          borderColor: '#DBE6EB',
        },
      },
      '& .MuiSelect-icon': {
        position: 'absolute',
        right: '11px',
        top: 'auto',
      },
    },
    notchedOutline: {
      borderColor: '#DBE6EB',
    },
    input: {
      fontWeight: 600,
      color: '#161B1D',
      '&::placeholder': {
        color: '#809DAD',
        opacity: 1,
      },
      fontSize: '1.4rem',
      padding: '13px 8px',
      lineHeight: '16px',
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #fff inset',
      },
    },
    multiline: {
      height: '74px',
      fontWeight: 600,
      color: '#161B1D',
      '&::placeholder': {
        color: '#809DAD',
        opacity: 1,
      },
      fontSize: '1.2rem',
      padding: '13px 8px',
      lineHeight: '16px',
    },
    adornedEnd: {
      paddingRight: '6px',
      '& svg': {
        flexShrink: 0,
      },
    },
    adornedStart: {
      paddingLeft: '8px',
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: 0,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '1.2rem',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      padding: '8px 6px',
    },
  },

  MuiMenu: {
    paper: {
      minWidth: '75px',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
      color: '#24343D',
    },
    list: {
      borderRadius: '6px',
    },
  },

  MuiMenuItem: {
    root: {
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: '16px',
      paddingRight: '12px',
      fontSize: '1.4rem',
      fontWeight: 600,
      '&:hover': {
        background: '#F5F6F7',
      },
    },
  },

  MuiDivider: {
    root: {
      backgroundColor: '#DCE5E8',
    },
  },

  MuiTabs: {
    indicator: {
      display: 'none',
    },
    root: {
      minHeight: 'auto',
      marginBottom: '24px',
      '& button': {
        border: 'none',
        justifyContent: 'flex-start',
        color: '#5C6E79',
        textTransform: 'none',
        fontSize: '1.8rem',
        fontWeight: 'bold',
        textAlign: 'left',
        opacity: '1',
        minWidth: 'auto',
        marginRight: '24px',
        padding: 0,
        height: 'auto',
        minHeight: 'auto',
        '&.Mui-selected': {
          color: '#050709',
        },
        '&:hover': {
          color: '#050709',
        },
      },
    },
  },

  MuiSelect: {
    root: {
      fontWeight: 'bold',
      color: '#161B1D',
      '&::placeholder': {
        color: '#809DAD',
        opacity: 1,
      },
      fontSize: '1.2rem',
      padding: '12px 8px',
      lineHeight: '16px',
      width: '100%',
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },

  MuiDrawer: {
    paper: {
      borderRadius: '16px 0 0 16px',
      width: '420px',
      boxShadow: '0px 10px 18px rgba(77, 83, 87, 0.05)',
    },
  },

  MuiCheckbox: {
    root: {
      padding: 0,
      marginLeft: '-2px',
      color: '#dbe6eb;',
      marginRight: '8px',
    },
  },

  MuiAutocomplete: {
    root: {
      '& .MuiAutocomplete-inputRoot': {
        fontSize: '1.2rem',
        height: '36px',
        padding: '10px 8px 8px 8px',
        boxSizing: 'border-box',

        '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
          paddingLeft: 0,
        },

        '& .MuiAutocomplete-input': {
          marginBottom: '2px',
          height: '20px',
          boxSizing: 'border-box',
          '&:first-child': {
            paddingLeft: 0,
          },
        },
      },

      '& .MuiAutocomplete-endAdornment': {
        top: '8px',
      },

      '& .MuiAutocomplete-tag': {
        maxWidth: 'none',
      },

      '& .MuiAutocomplete-popupIndicator': {
        marginRight: 0,
      },

      '& .MuiChip-root': {
        background: '#F5F6F7',
        marginBottom: '2px',
        fontSize: '1.2rem',
        fontWeight: 500,
        margin: 0,
        marginRight: '2px',
        height: '20px',
        padding: '2px 8px',

        '& .MuiChip-label': {
          padding: 0,
          fontWeight: 500,
          marginRight: '8px',
        },

        '& .MuiChip-deleteIcon': {
          width: '8px',
          height: '8px',
          margin: 0,
        },
      },
    },

    paper: {
      margin: 0,
      boxShadow: '0px 10px 18px rgba(77, 83, 87, 0.1)',

      '& .MuiAutocomplete-noOptions': {
        padding: '7px 15px',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
      },
    },

    listbox: {
      borderRadius: '4px',
      padding: '2px',

      '& li': {
        borderRadius: '4px',
        padding: '5px 12px',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',

        '&:hover': {
          backgroundColor: '#cfceee',
        },
      },
    },
  },
  MuiDialog: {
    paper: {
      boxShadow: '0px 10px 18px rgba(77, 83, 87, 0.05)',
      borderRadius: '16px',
    },
  },
  MuiCircularProgress: {
    root: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
    },
  },
};
