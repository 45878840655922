import React, { FC, ReactNode, useState } from 'react';
import { TimeLogDataFragment } from 'generated/types';
import { TimeLogView } from '../TimeLogView';
import { TimeLogForm, TimeLogFormValues } from '../TimeLogForm';

interface Props {
  item: TimeLogDataFragment;
  optionalColumn: ReactNode;
  onEdit: (data: TimeLogFormValues) => Promise<void> | void;
  onDelete: (id: string) => void;
  overtimeMultiplier?: number | null;
}

export const TimeLogItem: FC<Props> = ({ item, overtimeMultiplier, optionalColumn, onEdit, onDelete }) => {
  const [isEdit, setIsEdit] = useState(false);

  return isEdit ? (
    <TimeLogForm
      id={item.id}
      date={item.date}
      minutes={item.minutes}
      overtime={item.overtime}
      overtimeMultiplier={overtimeMultiplier}
      notes={item.notes || ''}
      optionalColumn={optionalColumn}
      onCancel={() => setIsEdit(false)}
      onSubmit={onEdit}
    />
  ) : (
    <TimeLogView
      item={item}
      overtimeMultiplier={overtimeMultiplier}
      optionalColumn={optionalColumn}
      onDelete={() => onDelete(item.id)}
      onEdit={() => setIsEdit(true)}
    />
  );
};
