import clsx from 'clsx';
import { ResourcePlanningProjectDataFragment } from 'generated/types';
import { UsersIcon, ChevronIcon } from 'icons';
import React, { memo, ReactNode } from 'react';

import styles from './styles.module.scss';
import { IconButton } from '@material-ui/core';
import { links } from 'App';
import { Link } from 'components/common';
import { getAcronym } from 'utils';
import { Avatar, SIZES } from 'components';
import { getMembersGroupedByRole, MemberAssignment } from '../../utils';

type Props = {
  onExpand?: () => void;
  expanded?: boolean;
  className?: string;
  tileClassName?: string;
  data: ResourcePlanningProjectDataFragment & { id: string };
  members?: MemberAssignment[];
  additionalExpandComponent?: ReactNode;
};

export const Project = memo<Props>(
  ({ data: { name, id, color }, members, className, tileClassName, onExpand, expanded, additionalExpandComponent }) => {
    const isExpanding = !!members?.length || additionalExpandComponent;

    const membersGroupedByRole = getMembersGroupedByRole(members);

    return (
      <section className={clsx(styles.sidebar, className)}>
        <section
          onClick={() => members?.length && onExpand?.()}
          className={clsx(styles.sidebarTile, tileClassName, expanded && styles.sidebarTileExpended)}
        >
          <div className={styles.sidebarTileIcon} style={{ backgroundColor: color }} />
          <section className={styles.sidebarTileHeading}>
            <Link to={links.ProjectDetail({ id })}>
              <p>{name}</p>
            </Link>
            {!!members?.length && (
              <section className={styles.sidebarTileInfo}>
                <UsersIcon />
                <p>{members.length}</p>
              </section>
            )}
          </section>
          {isExpanding && (
            <IconButton className="p-4" onClick={() => isExpanding && onExpand?.()}>
              <ChevronIcon style={{ transform: `rotate(${expanded ? '180deg' : '0deg'})` }} />
            </IconButton>
          )}
        </section>
        {expanded && (
          <section>
            {Object.keys(membersGroupedByRole)
              .sort()
              .map((group) => {
                return (
                  <div key={group}>
                    <div className={styles.sidebarGroup}>{group}</div>
                    {membersGroupedByRole[group].map((member) => {
                      return (
                        <section
                          key={member?.id}
                          className={clsx(styles.sidebarTile, styles.sidebarTileMember, styles.projectSidebarTile)}
                        >
                          <Avatar
                            avatarTitle={getAcronym(member?.first_name, member?.last_name)}
                            size={SIZES.md}
                            className="mr-12"
                            color={member?.color}
                          />
                          <Link to={links.ViewTeamMember({ id: member?.id })}>
                            <div className={styles.sidebarTileHeadingBox}>
                              <section className={clsx(styles.sidebarTileHeading, styles.sidebarTileMemberHeading)}>
                                <p>
                                  {member?.first_name} {member?.last_name}
                                </p>
                              </section>
                              <section className={styles.sidebarTileInfo}>
                                {member?.job_title && <span>{member?.job_title}</span>}
                              </section>
                            </div>
                          </Link>
                        </section>
                      );
                    })}
                  </div>
                );
              })}
          </section>
        )}
        {expanded && additionalExpandComponent}
      </section>
    );
  },
);

Project.displayName = 'Timeline.Project';
