export const palette = {
  text: {
    primary: '#24343D',
  },

  primary: {
    main: '#050709',
  },

  secondary: {
    main: '#5C6E79',
  },
};
