import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { lazy } from 'react';

export const FilesRoute = Route(
  {
    id: Route.params.regex(idRegEx).optional,
  },
  ({ id }) => `/project/view/${id}/project-files`,
);

export const ProjectFiles = FilesRoute(lazy(() => import(/* webpackChunkName: "Files" */ './Files')));
