import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns';

export const getPeriodLabel = (start: Date, end: Date): string => {
  const isSameYearPeriod = isSameYear(start, end);
  const isSameMonthPeriod = isSameMonth(start, end);
  const isSameDayPeriod = isSameDay(start, end);

  if (isSameDayPeriod) {
    return format(start, 'dd MMM');
  }

  return `${format(start, isSameYearPeriod ? (isSameMonthPeriod ? 'dd' : 'dd MMM') : 'dd MMM, yyyy')}-${format(
    end,
    isSameYearPeriod ? 'dd MMM' : 'dd MMM, yyyy',
  )}`;
};
