import React, { FC, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, InputLabel, TextField } from '@material-ui/core';
import { Autocomplete, DatePicker, LoadingButton, TimeLogInput } from 'components';
import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { string } from 'yup';
import styles from './styles.module.scss';
import { DashIcon } from 'icons';
import { minutesInHour } from 'date-fns';
import clsx from 'clsx';
import { formatTimeLog } from 'utils';

export interface FormValues {
  startDate: string | Date;
  endDate: string | Date | null;
  minutes: number;
  notes?: string | null;
  periodType: { id: PeriodType; name: string };
  nonWorkingDays: boolean;
  monthMode?: boolean;
}

interface Props {
  date: Date;
  initialData?: Omit<FormValues, 'periodType' | 'nonWorkingDays'>;
  onSubmit: (data: FormValues) => Promise<void> | void;
  onClose: () => void;
  monthMode?: boolean;
  periodType?: PeriodType;
}

export enum PeriodType {
  'HrsPerDay' = 'hrsPerDay',
  'TotalHrs' = 'totalHrs',
}

const MAX_HOURS = 12;
const MAX_LOG = MAX_HOURS * minutesInHour;

export const PeriodTimeLogForm: FC<Props> = ({ date, initialData, onSubmit, onClose, monthMode, periodType }) => {
  const { t } = useTranslation();
  const [isMinutesValueChanged, setIsMinutesValueChanged] = useState(false);

  const periodTypes = useMemo(
    () => [
      { id: PeriodType.HrsPerDay, name: t('timeTracking.hrsPerDay') },
      { id: PeriodType.TotalHrs, name: t('timeTracking.totalHrs') },
    ],
    [],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    initialValues,
    setValues,
    submitCount,
    touched,
    errors,
    isSubmitting,
  } = useFormik<FormValues>({
    initialValues: initialData
      ? {
          periodType: periodTypes.find(({ id }) => id === periodType) || periodTypes[0],
          nonWorkingDays: false,
          ...initialData,
        }
      : {
          startDate: new Date(date),
          endDate: null,
          periodType: periodTypes[0],
          minutes: 0,
          notes: '',
          nonWorkingDays: false,
        },
    validationSchema: Yup.object({
      startDate: string().required(t('validation.required')).nullable(),
      endDate: string().required(t('validation.required')).nullable(),
      nonWorkingDays: Yup.boolean().default(false),
      minutes: Yup.number().when('periodType', {
        is: (periodType: { id: PeriodType }) => periodType.id === PeriodType.HrsPerDay,
        then: Yup.number()
          .typeError(t('validation.invalid'))
          .min(0, t('validation.min', { digits: 0 }))
          .moreThan(0, t('validation.moreThan', { digits: 0 }))
          .max(MAX_LOG, t('validation.maxHours', { max: `${MAX_HOURS}` }))
          .required(t('validation.required')),
        otherwise: Yup.number()
          .typeError(t('validation.invalid'))
          .min(0, t('validation.min', { digits: 0 }))
          .required(t('validation.required')),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit({
        ...values,
        minutes: Number(values.minutes),
        monthMode,
      });
      setSubmitting(false);
      onClose();
    },
  });

  const errorsText = useMemo<FormikErrors<FormValues>>(
    () => ({ minutes: submitCount || touched.minutes ? errors.minutes : undefined }),
    [submitCount, touched, errors],
  );

  const isDisabledSaveButton =
    monthMode &&
    values.nonWorkingDays === initialValues.nonWorkingDays &&
    !isMinutesValueChanged &&
    (values.notes || '') === (initialValues.notes || '') &&
    values.periodType?.id === initialValues.periodType?.id;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className="flex gap-8 mb-16">
        <div>
          <InputLabel>{`${t('timeTracking.startDate')}*`}</InputLabel>
          <DatePicker
            value={values.startDate}
            error={Boolean((submitCount || touched.startDate) && errors.startDate)}
            helperText={Boolean(submitCount || touched.startDate) && errors.startDate}
            onChange={(startDate) => setValues({ ...values, startDate: startDate as FormValues['startDate'] })}
            disabled={monthMode}
          />
        </div>

        <div className="pt-36">
          <DashIcon />
        </div>

        <div>
          <InputLabel>{`${t('timeTracking.endDate')}*`}</InputLabel>
          <DatePicker
            value={values.endDate}
            error={Boolean((submitCount || touched.endDate) && errors.endDate)}
            helperText={Boolean(submitCount || touched.endDate) && errors.endDate}
            onChange={(endDate) => setValues({ ...values, endDate: endDate as FormValues['endDate'] })}
            disabled={monthMode}
          />
        </div>
      </div>

      <div className="flex align-items-center mb-24">
        <FormControlLabel
          className={styles.checkboxLabel}
          classes={{ label: clsx(styles.checkboxLabelText, values.nonWorkingDays && styles.active) }}
          control={<Checkbox color="primary" checked={values.nonWorkingDays} />}
          label={t('timeTracking.nonWorkingDays')}
          name="nonWorkingDays"
          onChange={() => setValues({ ...values, nonWorkingDays: !values.nonWorkingDays })}
        />
      </div>

      <section className="flex gap-16 mb-16">
        <div className={styles.minuteInputBox}>
          <InputLabel className="mb-8">{`${t('timeTracking.timeLogged')}*`}</InputLabel>
          <TimeLogInput
            name="minutes"
            onChange={(minutes) => handleChange('minutes')(`${minutes}`)}
            onInputChange={(value) => setIsMinutesValueChanged(value !== formatTimeLog(initialValues.minutes))}
            onBlur={handleBlur}
            value={values.minutes}
            error={!!errorsText.minutes}
            helperText={errorsText.minutes}
          />
        </div>

        <Autocomplete
          value={values.periodType}
          name="externalRateUnit"
          className={styles.periodTypeInput}
          onBlur={handleBlur}
          onChange={(value: { id: PeriodType; name: string }) => setValues({ ...values, periodType: value })}
          options={periodTypes}
          error={Boolean(submitCount || touched.periodType) && errors.periodType}
          disableClearable
        />
      </section>
      <section>
        <InputLabel className="mb-8">{t('forms.timeTracking.note')}</InputLabel>
        <TextField
          name="notes"
          size="medium"
          multiline
          rows={3}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.notes}
          placeholder={t('forms.typeNoteHere')}
        />
      </section>
      <div className="mt-16 flex justify-content-end">
        <Button variant="outlined" color="secondary" className="mr-8" onClick={onClose}>
          {t('forms.cancel')}
        </Button>
        <LoadingButton type="submit" loading={isSubmitting} disabled={isDisabledSaveButton}>
          {t('forms.saveRecord')}
        </LoadingButton>
      </div>
    </form>
  );
};
