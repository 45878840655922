import React, { ChangeEvent, FC, MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { Tag } from 'components';
import { Popover } from '@material-ui/core';
import styles from './styles.module.scss';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { PlusIcon } from 'icons';
import { tagColorsPalette } from 'consts';
import { useTranslation } from 'react-i18next';
import { sample } from 'lodash';
import { TagDataFragment } from 'generated/types';

interface Tag {
  color: string;
  name: string;
  id: string;
}

interface TagValue {
  symbolCount: number;
  renderItem: ReactElement[];
  hiddenItemsCount: number;
}
interface Props {
  tags: Tag[];
  defaultTags?: Tag[];
  onChange: (value: string[]) => void;
  onCreate?: (value: Tag) => Promise<TagDataFragment | undefined>;
  onTagsUpdate?: (tags: TagDataFragment[]) => void;
  loading?: boolean;
}

const MAX_TAGS_SYMBOL_COUNT = 25;

export const TagsAutocomplete: FC<Props> = ({ tags, defaultTags, loading, onChange, onCreate, onTagsUpdate }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newTagColor, setNewTagColor] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<Tag[]>(defaultTags || []);
  const [createdTags, setCreatedTags] = useState<Tag[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setNewTagColor(sample(tagColorsPalette) || '');
  }, []);

  useEffect(() => {
    setSelectedTags(defaultTags || []);
  }, [defaultTags]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onTagsUpdate && createdTags.length) {
      onTagsUpdate(selectedTags || []);
    }
  };

  const handleChange = async (_event: ChangeEvent<unknown>, value: Tag[]) => {
    const lastItemIndex = value.length - 1;
    const newTag = value[lastItemIndex];

    const tagIds = value.reduce<string[]>((acc, { id }) => {
      return id ? [...acc, id] : acc;
    }, []);

    // if tag was added by typing in autocomplete we need first save it on the BE
    if (newTag && !newTag?.id && onCreate) {
      const createdTag = await onCreate(newTag);
      createdTag && onChange([...tagIds, createdTag.id]);
      createdTag && setSelectedTags([...value.slice(0, lastItemIndex), createdTag]);
      createdTag && setCreatedTags((prev) => [...prev, createdTag]);
      setNewTagColor(sample(tagColorsPalette) || '');
      return;
    }

    onChange(tagIds);
    setSelectedTags(value);
  };

  const filterOptions = (options: Tag[], { inputValue }: FilterOptionsState<Tag>) => {
    const selectedTagIds = selectedTags.map(({ id }) => id);
    return [...options, ...createdTags].filter(
      ({ id, name }) =>
        !selectedTagIds.includes(id) && name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
    );
  };

  const getCreateOptions = (options: Tag[], { inputValue }: FilterOptionsState<Tag>) => {
    const isItemInOptionsList = [...options, ...selectedTags].find(({ name }) => name === inputValue);
    if (inputValue !== '' && !isItemInOptionsList) {
      return {
        id: '',
        color: newTagColor,
        name: inputValue,
      };
    }
  };

  const renderOption = (option: Tag) =>
    option.id ? (
      <Tag key={option.id} name={option.name} color={option.color} tooltipClassName={styles.optionTagTooltip} />
    ) : (
      <>
        <span className={styles.createText}>{t('tag.create')}</span>
        <Tag key={option.id} name={option.name} color={option.color} tooltipClassName={styles.optionCreateTagTooltip} />
      </>
    );

  const renderTags = (options: Tag[], getTagProps: AutocompleteGetTagProps) =>
    options.map(({ id, name, color }, index) => {
      const tagProps = getTagProps({ index });

      return <Tag {...tagProps} key={id} name={name} color={color} tooltipClassName={styles.optionTagTooltip} />;
    });

  const tagsValue = useMemo(
    () =>
      selectedTags.reduce<TagValue>(
        (acc, { id, name, color }) => {
          if (acc.symbolCount + name.length > MAX_TAGS_SYMBOL_COUNT) {
            return { ...acc, hiddenItemsCount: acc.hiddenItemsCount + 1 };
          }

          const item = <Tag key={id} name={name} color={color} tooltipClassName={styles.optionTagTooltip} />;
          return { ...acc, symbolCount: acc.symbolCount + name.length, renderItem: [...acc.renderItem, item] };
        },
        {
          symbolCount: 0,
          renderItem: [],
          hiddenItemsCount: 0,
        },
      ),
    [selectedTags],
  );

  return (
    <>
      <button aria-describedby="tag-popper" type="button" className={styles.valueButton} onClick={handleClick}>
        {selectedTags.length ? (
          <div className={styles.tagsValue}>
            {tagsValue.renderItem}
            {tagsValue.hiddenItemsCount ? (
              <Tag name={`+${tagsValue.hiddenItemsCount}`} color={tagColorsPalette[0]} />
            ) : (
              ''
            )}
          </div>
        ) : (
          <Tag
            name={t('tag.addTag')}
            color="inherit"
            startIcon={<PlusIcon className={styles.addTagIcon} />}
            className={styles.addTag}
            tooltipClassName={styles.addTagTooltip}
          />
        )}
      </button>

      <Popover
        id="tag-popper"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContainer}>
          <Autocomplete
            multiple
            id="multiple-tags"
            disableCloseOnSelect
            disableClearable
            disablePortal
            open
            filterOptions={(options, params) => {
              const filtered = filterOptions(options, params);
              const createOptions = onCreate && getCreateOptions(filtered, params);

              return createOptions ? [...filtered, createOptions] : filtered;
            }}
            popupIcon={null}
            classes={{
              inputRoot: styles.inputRoot,
              popperDisablePortal: styles.autocompletePopper,
              groupLabel: styles.groupLabel,
              listbox: styles.listBox,
              option: styles.option,
              noOptions: styles.noOptions,
            }}
            debug
            loading={loading}
            groupBy={() => t(onCreate ? 'tag.selectOptionOrCreate' : 'tag.selectOption')}
            noOptionsText={t('tag.noTagsFound')}
            options={tags}
            value={selectedTags}
            getOptionLabel={(option) => option.name}
            onChange={handleChange}
            renderOption={renderOption}
            renderTags={renderTags}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                classes={{
                  root: styles.textFieldRoot,
                }}
              />
            )}
          />
        </div>
      </Popover>
    </>
  );
};
