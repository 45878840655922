import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { FeedbackModal, Sidebar } from 'components';

import styles from './BaseLayout.module.scss';
import { BaseLayoutProvider } from 'contexts';

interface BaseLayoutProps {
  children: ReactNode;
}

export const BaseLayout = ({ children }: BaseLayoutProps) => (
  <div className={clsx(styles.wrapper, 'bg-gray', 'flex-column')}>
    <div className={clsx(styles.container, 'flex', 'flex-1')}>
      <BaseLayoutProvider>
        <Sidebar />
        <div className={clsx(styles.content, 'bg-white', 'flex-1')}>{children}</div>
        <FeedbackModal />
      </BaseLayoutProvider>
    </div>
  </div>
);
