import { useEffect } from 'react';
import { IdType, Row } from 'react-table';

interface Props<D extends Record<string, unknown> = Record<string, never>> {
  rows?: Row<D>[] | null;
  onSelectRows?: (ids: string[]) => void;
  selectIdName?: string;
}

export const useTableSelectedRows = <D extends Record<string, unknown> = Record<string, never>>({
  rows,
  onSelectRows,
  selectIdName,
}: Props<D>) => {
  if (!onSelectRows) {
    return;
  }

  useEffect(() => {
    onSelectRows(
      (rows || []).map((row: Row<D>) => (selectIdName ? row.original[selectIdName] : row.original.id) as IdType<D>),
    );
  }, [rows]);
};
