import { ASC, FIRST_NAME, LAST_NAME } from 'consts';
import { CompanyUser, Member } from 'generated/types';
import { sortByField } from 'utils';
import type { Object as ObjectType } from 'ts-toolbelt';

export class MembersBuilder<T extends ObjectType.Partial<Member, 'deep'>> {
  private members: T[];

  constructor(members: T[]) {
    this.members = members;
  }

  buildNonArchivedMembers() {
    this.members = this.members.filter(({ archived_at }) => !archived_at);
  }

  buildArchivedMembers() {
    this.members = this.members.filter(({ archived_at }) => archived_at);
  }

  buildReportingToOrCostRateManagersMembers(userData: CompanyUser) {
    this.members = this.members.filter(
      ({ reportingToId, costRateManagersIds }) =>
        reportingToId === userData?.member.id || costRateManagersIds?.some((id) => userData?.member.id === id),
    );
  }

  buildReportingToMembers(userData: CompanyUser) {
    this.members = this.members.filter(({ reportingToId }) => reportingToId === userData?.member.id);
  }

  sortMembersByName() {
    this.members = sortByField(this.members, ASC, FIRST_NAME, LAST_NAME);
  }

  buildActiveMembers() {
    this.members = this.members.filter(({ is_pending }) => !is_pending);
  }

  buildExistingCompanyUsers() {
    this.members = this.members.filter(({ companyUserId }) => companyUserId);
  }

  getMembers() {
    return this.members;
  }
}
