import { idRegEx } from 'utils/regex';
import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import LeaveTypesComponent from './LeaveTypes';

export const LeaveTypesRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    leaveTypeId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, leaveTypeId }) => `/settings/resource-management/leave-types/${mode}/${leaveTypeId}`,
);

export const LeaveTypes = LeaveTypesRoute(LeaveTypesComponent);
