import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  addDays,
  differenceInBusinessDays,
  differenceInCalendarDays,
  endOfDay,
  isAfter,
  isBefore,
  isSameDay,
  isWeekend,
  subDays,
} from 'date-fns';

import { MONTH_PERIOD } from 'consts';
import { addTimezoneOffset } from 'utils';
import { useTimelinePeriodDays } from 'hooks';
import { useTimelineContext } from 'contexts';
import { LeaveIcon } from 'icons';

import { Leave, ResourcePlanningMembersDataFragment, Scalars } from 'generated/types';
import { getMinColumnWidth, MemberAssignment } from 'views/ResourcePlanning/utils';
import { MemberLeavesTooltip } from 'views/ResourcePlanning/components/MemberLeavesTooltip';

import styles from './MemberLeaves.module.scss';

type Props = {
  date: Date;
  member?: ResourcePlanningMembersDataFragment | MemberAssignment;
  onLeave?: (id: string) => void;
};

type MemberLeave = {
  amount: number;
  leave: boolean;
  id?: string;
  type?: string;
  start?: Scalars['DateTime'];
  end?: Scalars['DateTime'];
};

export const MemberLeaves = memo<Props>(({ member, date, onLeave }) => {
  const { t } = useTranslation();
  const { collapsedWeekends, timelinePeriod } = useTimelineContext();
  const { days, monthDays } = useTimelinePeriodDays(date);
  const minColumnWidth = getMinColumnWidth(timelinePeriod);

  const firstPeriodDay = timelinePeriod === MONTH_PERIOD ? monthDays[0] : days[0];
  const lastPeriodDay = timelinePeriod === MONTH_PERIOD ? monthDays[monthDays.length - 1] : days[days.length - 1];

  const getDiff = (dayA: Date | number, dayB: Date | number) => {
    return collapsedWeekends ? differenceInBusinessDays(dayA, dayB) : differenceInCalendarDays(dayA, dayB);
  };

  const sortedByStartDateLeaves =
    member?.member_leave &&
    [...(member?.member_leave as Leave[])]
      .sort((a, b) => (new Date(a.startDate).getTime() < new Date(b.startDate).getTime() ? -1 : 1))
      .filter((leave) => {
        if (collapsedWeekends) {
          const diff = getDiff(addTimezoneOffset(endOfDay(new Date(leave.endDate))), new Date(leave.startDate));

          if (diff > 0) return leave;

          return !isWeekend(new Date(leave.startDate)) && !isWeekend(new Date(leave.endDate));
        }
        return leave;
      });

  const leaves = useMemo(() => {
    return sortedByStartDateLeaves
      ? sortedByStartDateLeaves.reduce<MemberLeave[]>((acc, rec, index) => {
          const isLastLeave = index === sortedByStartDateLeaves.length - 1;
          const prevLeave = index > 0 ? sortedByStartDateLeaves[index - 1] : undefined;
          const endDate = isAfter(new Date(rec.endDate), lastPeriodDay) ? lastPeriodDay : rec.endDate;

          const item: MemberLeave = {
            leave: true,
            id: rec.id,
            type: rec.leaveType?.name,
            start: rec.startDate,
            end: rec.endDate,
            amount: isSameDay(new Date(endDate), new Date(rec.startDate))
              ? 1
              : getDiff(addTimezoneOffset(endOfDay(new Date(endDate))), new Date(rec.startDate)),
          };

          const lastEmptyItem: MemberLeave = {
            leave: false,
            start: addDays(new Date(rec.endDate), 1).toISOString(),
            end: addTimezoneOffset(lastPeriodDay).toISOString(),
            amount: getDiff(addTimezoneOffset(endOfDay(lastPeriodDay)), addDays(new Date(rec.endDate), 1)),
          };

          if (isBefore(new Date(rec.startDate), firstPeriodDay) && index === 0) {
            const firstItem: MemberLeave = {
              leave: true,
              id: rec.id,
              type: rec.leaveType?.name,
              start: addTimezoneOffset(firstPeriodDay).toISOString(),
              end: rec.endDate,
              amount: getDiff(addTimezoneOffset(endOfDay(new Date(rec.endDate))), firstPeriodDay),
            };
            return isLastLeave ? [...acc, firstItem, lastEmptyItem] : [...acc, firstItem];
          }

          if (isSameDay(firstPeriodDay, new Date(rec.startDate)) && index === 0) {
            return isLastLeave ? [...acc, item, lastEmptyItem] : [...acc, item];
          }

          if (isAfter(new Date(rec.startDate), firstPeriodDay) && index === 0) {
            const firstEmptyItem: MemberLeave = {
              leave: false,
              start: addTimezoneOffset(firstPeriodDay).toISOString(),
              end: subDays(new Date(rec.startDate), 1).toISOString(),
              amount: getDiff(new Date(rec.startDate), firstPeriodDay),
            };

            return isLastLeave ? [...acc, firstEmptyItem, item, lastEmptyItem] : [...acc, firstEmptyItem, item];
          }

          if (prevLeave) {
            const prevLeaveStart = addDays(new Date(prevLeave!.endDate), 1);
            const prevLeaveEnd = endOfDay(subDays(new Date(rec.startDate), 1));

            const prevEmptyItem: MemberLeave = {
              leave: false,
              start: prevLeaveStart.toISOString(),
              end: prevLeaveEnd.toISOString(),
              amount: getDiff(addTimezoneOffset(prevLeaveEnd), prevLeaveStart),
            };

            return isLastLeave ? [...acc, prevEmptyItem, item, lastEmptyItem] : [...acc, prevEmptyItem, item];
          }

          return acc;
        }, [])
      : [];
  }, [collapsedWeekends, sortedByStartDateLeaves, timelinePeriod]);

  return (
    <section className={styles.memberLeaves}>
      {leaves.map((item) => (
        <MemberLeavesTooltip
          key={`${item.start}-${item.end}`}
          start={item.start}
          end={item.end}
          type={item.type}
          style={{
            flex: `${item.amount ?? 1} 1 0px`,
            minWidth: `${(item.amount ?? 1) * minColumnWidth}px`,
          }}
        >
          <div className={clsx(item.leave && styles.leave)} onClick={() => onLeave && item.id && onLeave(item.id)}>
            {item.leave && (
              <span className={styles.label}>
                <span className={styles.labelContent}>
                  <LeaveIcon className={styles.icon} /> {t('resourcePlanning.onLeave')}
                </span>
              </span>
            )}
          </div>
        </MemberLeavesTooltip>
      ))}
    </section>
  );
});

MemberLeaves.displayName = 'MemberLeaves';
