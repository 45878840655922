import React, { FC, MouseEvent, useState } from 'react';
import { getAcronym, getFullName } from 'utils';
import { AnimationCountChip, EmptyState, LoadingButton, Tooltip, UserInfo } from 'components';
import styles from './styles.module.scss';
import Menu from '@material-ui/core/Menu';
import { IconButton, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Eye, EyeClosed, TriangleDown } from 'icons';
import { ResourcePlanningMembersDataFragment } from 'generated/types';
import clsx from 'clsx';

interface Props {
  hiddenMembers: ResourcePlanningMembersDataFragment[];
  onShowMember: (value: string) => void;
  onShowAllMember: () => void;
}

export const HideMembersMenu: FC<Props> = ({ hiddenMembers, onShowMember, onShowAllMember }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleOpen = (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    handleClick(e);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  return (
    <div>
      <LoadingButton
        size="medium"
        variant="outlined"
        color="secondary"
        onClick={handleOpen}
        className={clsx(styles.userCellButton, !anchorEl || !hiddenMembers.length ? styles.userCellButtonBorder : '')}
      >
        <EyeClosed className={clsx(styles.hideIcon, 'mr-4')} />
        {t('resourcePlanning.hide.hidden')}
        <AnimationCountChip
          isSkipFirstAnimation
          count={hiddenMembers.length}
          boxClassName={!hiddenMembers.length ? 'd-none' : ''}
        />
        {hiddenMembers.length ? '' : ': 0'}
        {hiddenMembers.length ? <TriangleDown className={clsx(styles.buttonArrow, anchorEl && styles.open)} /> : ''}
      </LoadingButton>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.menu}
      >
        {!hiddenMembers?.length ? (
          <EmptyState className={styles.emptyMessage} title="resourcePlanning.hide.noHidden" />
        ) : (
          ''
        )}
        {hiddenMembers.map((member) => (
          <MenuItem key={member.id} className={styles.menuItem}>
            <UserInfo
              title={getFullName(member.first_name, member.last_name)}
              titleClassName={styles.userTitle}
              subTitleClassName={styles.userTitle}
              avatarTitle={getAcronym(member.first_name, member.last_name)}
              color={member.color || ''}
              subTitle={member.job_title || ''}
            />
            <Tooltip title={t('resourcePlanning.hide.showMemberInView')!} placement="top" alwaysShowTooltip>
              <IconButton className="p-6" onClick={() => onShowMember(member.id)}>
                <Eye className={styles.buttonIcon} />
              </IconButton>
            </Tooltip>
          </MenuItem>
        ))}
        {hiddenMembers?.length ? (
          <MenuItem
            className={styles.showAllMenuItem}
            onClick={(e) => {
              onShowAllMember();
              handleClose(e);
            }}
          >
            <Eye className={styles.hideIcon} />
            {t('resourcePlanning.hide.showAll')}
          </MenuItem>
        ) : (
          ''
        )}
      </Menu>
    </div>
  );
};
