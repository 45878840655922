import { useCreateClientMutation, useProjectClientQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { Reference } from '@apollo/client';
import { useAuth } from '../contexts';
import { Client } from '../generated/types';

export const useCreatableClientData = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { clients = [] } = {}, loading: clientsLoading } = useProjectClientQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const [createClient] = useCreateClientMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createClient) return;

      cache.modify({
        fields: {
          clients(items: Reference[] = []) {
            return [...items, data.createClient];
          },
        },
      });
    },
  });

  const getCreatedClient = async (value: Client | null) => {
    if (value?.id) {
      return value;
    }

    if (!value?.name) {
      return null;
    }

    const { data } = await createClient({
      variables: {
        data: { name: value?.name || '' },
        companyId: userData!.company.id,
      },
    });

    return data?.createClient as Client;
  };

  return { clients: clients as Client[], clientsLoading, getCreatedClient };
};
