import React from 'react';

import clsx from 'clsx';

import styles from './ActionButton.module.scss';

interface ActionButtonProps {
  className?: string;
  // eslint-disable-next-line
  onClick?: (event: any) => void;
}

export const ActionButton = ({ className, onClick }: ActionButtonProps) => {
  return (
    <svg
      onClick={onClick}
      className={clsx(styles.actionButton, className)}
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17Z"
        fill="#161B1D"
      />
      <path
        d="M20 17C20.5523 17 21 16.5523 21 16C21 15.4477 20.5523 15 20 15C19.4477 15 19 15.4477 19 16C19 16.5523 19.4477 17 20 17Z"
        fill="#161B1D"
      />
      <path
        d="M24 17C24.5523 17 25 16.5523 25 16C25 15.4477 24.5523 15 24 15C23.4477 15 23 15.4477 23 16C23 16.5523 23.4477 17 24 17Z"
        fill="#161B1D"
      />
    </svg>
  );
};
