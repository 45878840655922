import React, { FC, MouseEvent } from 'react';
import clsx from 'clsx';
import { Project } from 'generated/types';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import { links } from 'App';
import { useHistory } from 'react-router-dom';

import styles from '../styles.module.scss';

export interface TeamDropdownItemProps {
  role: string;
  project: Pick<Project, 'id' | 'name' | 'color'>;
  handleClose: (e: MouseEvent<HTMLElement>) => void;
}

export const ProjectDropdownItem: FC<TeamDropdownItemProps> = ({ project, role, handleClose }) => {
  const history = useHistory();
  const { id, name, color } = project;

  return (
    <MenuItem
      className={clsx('flex', 'align-items-center', 'py-6')}
      onClick={(e) => {
        e.stopPropagation();
        handleClose(e);
        history.push(links.ProjectDetail({ id }));
      }}
    >
      <ListItemIcon className={styles.avatar}>
        <div className={styles.projectColor} style={{ backgroundColor: color }} />
      </ListItemIcon>
      <span className={clsx(['flex', 'flex-column'])}>
        <span className={styles.title}>{name}</span>
        <span className={styles.subtitle}>{role}</span>
      </span>
    </MenuItem>
  );
};
