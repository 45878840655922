import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmModal, DropdownMenu } from 'components';
import clsx from 'clsx';
import styles from '../styles.module.scss';
import { IconButton, MenuItem } from '@material-ui/core';
import { DotsIcon } from 'icons';

interface Props {
  onDelete: () => void;
  floatOptionsClassName?: string;
  deleteConfirmOptions?: DeleteConfirmOptions;
}

export type DeleteConfirmOptions = {
  title: string;
  message: string;
};

export const Menu: FC<Props> = ({ floatOptionsClassName, onDelete, deleteConfirmOptions }) => {
  const { t } = useTranslation();
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownMenu
        trigger={(handleClick, open) => (
          <span className={clsx(styles.deleteButton, floatOptionsClassName, open && styles.visible)}>
            <IconButton size="small" onClick={handleClick}>
              <DotsIcon />
            </IconButton>
          </span>
        )}
      >
        {(handleClose) => [
          <MenuItem
            key="delete-option"
            className="text-red"
            onClick={() => {
              handleClose();
              setIsOpenDeleteConfirm(true);
            }}
          >
            {t('actions.delete')}
          </MenuItem>,
        ]}
      </DropdownMenu>

      <ConfirmModal
        title={deleteConfirmOptions?.title || t('teams.deleteMember')}
        submitButtonTitle={deleteConfirmOptions?.title || t('teams.deleteMember')}
        isDelete
        isOpen={isOpenDeleteConfirm}
        onSubmit={() => {
          onDelete();
          setIsOpenDeleteConfirm(false);
        }}
        onClose={() => setIsOpenDeleteConfirm(false)}
      >
        {deleteConfirmOptions?.message || t('teams.confirmDeleteUser')}
      </ConfirmModal>
    </div>
  );
};
