export const colorsPalette = [
  '#C44618',
  '#EE5F2B',
  '#FFAF91',
  '#97B26D',
  '#B8D48E',
  '#E3EED2',
  '#335FB8',
  '#5582DD',
  '#DDE6F8',
  '#D68038',
  '#EF9C56',
  '#F9D7BB',
  '#009C7D',
  '#00CAA2',
  '#9AEDDD',
  '#A56ED4',
  '#C584FD',
  '#EBD6FD',
  '#EBBC34',
  '#FFCC38',
  '#FFE8A5',
  '#45C9E5',
  '#72E0F8',
  '#CBF0F8',
  '#CF72C2',
  '#F7A1EB',
  '#FDE3F9',
];
