export enum GroupByFilter {
  none = 'none',
  client = 'client',
  project = 'project',
  projectType = 'project-type',
  projectManager = 'project-manager',
  department = 'department',
  roles = 'roles',
  member = 'member',
  date = 'date',
  type = 'type',
  pm = 'pm',
  period = 'period',
}
