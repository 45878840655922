import React, { memo, useMemo } from 'react';
import { isAfter, isBefore, isWithinInterval } from 'date-fns';

import { MONTH_PERIOD } from 'consts';
import { useTimelinePeriodDays } from 'hooks';
import { useTimelineContext } from 'contexts';
import { ResourcePlanningProjectDataFragment } from 'generated/types';
import {
  getEndDateOfPreviousPeriod,
  getStartDateOfNextPeriod,
  getStartEndPeriodDates,
} from 'views/ResourcePlanning/utils/days';
import { getAssignmentDate } from 'views/ResourcePlanning/utils';

import { EmptyArea, ProjectTabAssignmentArea } from './AssignmentAreas';

import clsx from 'clsx';
import styles from './styles.module.scss';

type Props = {
  date: Date;
  project: ResourcePlanningProjectDataFragment;
};

type ProjectAssignment = {
  start?: Date;
  amount?: number;
  assigned?: boolean;
  continues?: boolean;
  last?: boolean;
  color?: string;
  id?: string;
};

export const ProjectAssignments = memo<Props>(({ project, date }) => {
  const { timelinePeriod } = useTimelineContext();
  const { days } = useTimelinePeriodDays(date);
  const [startPeriodDate, endPeriodDate] = getStartEndPeriodDates(date, timelinePeriod);

  const isMonthView = timelinePeriod === MONTH_PERIOD;

  const { prevPeriod, nextPeriod } = useMemo(
    () => ({
      prevPeriod: getEndDateOfPreviousPeriod(date),
      nextPeriod: getStartDateOfNextPeriod(date),
    }),
    [date],
  );

  const assignment = days.reduce<ProjectAssignment[]>((acc, day) => {
    const start = getAssignmentDate(project.start_date);
    const end = getAssignmentDate(project.end_date);

    const startToCompare = isMonthView ? prevPeriod : startPeriodDate;
    const endToCompare = isMonthView ? nextPeriod : endPeriodDate;

    const { assigned, ...options } = {
      assigned: isWithinInterval(day, { start, end }),
      last: isBefore(start, startToCompare),
      continues: isAfter(end, endToCompare),
      color: project.color && String(project.color),
    };

    const last = acc[acc.length - 1];

    return last?.assigned === assigned
      ? acc.slice(0, acc.length - 1).concat({
          ...last,
          amount: (last?.amount ?? 0) + 1,
          ...options,
        })
      : acc.concat({ start: day, amount: 1, assigned, ...options });
  }, []);

  return (
    <section className={clsx(styles.assignment, styles.tileMinHeight)}>
      {assignment.map(({ start, assigned, ...props }) => {
        return assigned ? (
          <ProjectTabAssignmentArea key={start?.toString()} {...props} name={project.name} />
        ) : (
          <EmptyArea key={start?.toString()} last={props.last} continues={props.continues} amount={props.amount} />
        );
      })}
    </section>
  );
});

ProjectAssignments.displayName = 'Timeline.Project.ProjectAssignments';
