import { OptionalDeep } from 'ts-toolbelt/out/Object/Optional';
import { Assignment as AssignmentType } from 'generated/types';

export enum AvailabilitiesFilter {
  all = 'all',
  available = 'available',
  booked = 'booked',
}

export enum MemberTypesFilter {
  all = 'all',
  employees = 'employees',
  contractors = 'contractors',
}

export enum AvailabilityGroups {
  unassigned = 'unassigned',
  partCapacity = 'partCapacity',
  fullCapacity = 'fullCapacity',
  overAllocated = 'overAllocated',
}

export enum TeamGroupingTypesFilter {
  specialization = 'specialization',
  availability = 'availability',
  none = 'none',
}
export enum ProjectsGroupingTypesFilter {
  projectType = 'projectType',
  none = 'none',
}

export type Filters = {
  search?: string;
  availability?: AvailabilitiesFilter;
  membersType?: MemberTypesFilter;
  groupingType?: TeamGroupingTypesFilter;
  showRequests?: boolean;
};

export type AssignmentRowItem = OptionalDeep<AssignmentType> & { isRequest?: boolean };
