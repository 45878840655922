import { Button, Checkbox, FormControlLabel, InputLabel, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { IntegrationOptionDataFragment, IntegrationOptionSyncValues } from 'generated/types';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { InfoIcon } from 'icons';
import { Autocomplete, LoadingButton, Tooltip } from 'components';
import { object, string } from 'yup';
import { DateFormats } from 'types';

interface Option {
  integrationOptionId: string;
  syncOption?: string;
}

export type FormValues = {
  token: string;
  options: Option[];
  dateFormat: { id: DateFormats; name: DateFormats };
};

type Props = {
  infoBox?: ReactElement;
  hideTokenField?: boolean;
  tokenCode?: string;
  onSubmit: (data: FormValues) => void;
  onCancel?: () => void;
  options?: IntegrationOptionDataFragment[];
  dateFormatId?: DateFormats | null;
  optionsInitial?: Option[];
  submitButtonText?: string;
  isLoading?: boolean;
  showDateFormat?: boolean;
};

interface ChangeOptionProps {
  id: string;
  syncOption?: string;
  isSelected: boolean;
}

export const IntegrationForm: FC<Props> = ({
  infoBox,
  hideTokenField,
  tokenCode,
  onSubmit,
  onCancel,
  options,
  dateFormatId,
  optionsInitial,
  submitButtonText,
  isLoading,
  showDateFormat,
}) => {
  const { t } = useTranslation();
  const allOptionsByDefaultSelected =
    options?.map(({ id, syncOptions }) => ({ integrationOptionId: id, syncOption: syncOptions[0]?.id || undefined })) ||
    [];
  const datesOptions = Object.values(DateFormats).map((format) => ({ id: format, name: format }));

  const validationSchema = hideTokenField
    ? undefined
    : object({
        token: string()
          .required('validation.required')
          .matches(/^\s*\S[\s\S]*$/, t('validation.blankspaces')),
      });

  const {
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    submitCount,
  } = useFormik<FormValues>({
    onSubmit,
    validationSchema,
    initialValues: {
      token: tokenCode || '',
      dateFormat: dateFormatId ? { id: dateFormatId, name: dateFormatId } : datesOptions[0],
      options: optionsInitial || allOptionsByDefaultSelected,
    },
  });

  const onChangeOption = ({ id, syncOption, isSelected }: ChangeOptionProps) => {
    const newValue = isSelected
      ? values.options.filter(({ integrationOptionId }) => integrationOptionId !== id)
      : [...values.options, { integrationOptionId: id, syncOption }];
    setFieldValue('options', newValue);
  };

  const onChangeSyncOption = ({ id, syncOption }: Omit<ChangeOptionProps, 'isSelected'>) => {
    const newValue = values.options.map((option) =>
      option.integrationOptionId === id ? { ...option, syncOption } : option,
    );
    setFieldValue('options', newValue);
  };

  const getSyncOptionName = ({ name }: { name: string }) => {
    return t(`settings.integrations.syncOptions.${name}`);
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <div>
        {infoBox}
        {!hideTokenField && (
          <section className="mb-20">
            <InputLabel>{t('settings.integrations.form.token')}</InputLabel>
            <TextField
              error={Boolean((submitCount || touched.token) && errors.token)}
              helperText={(submitCount || touched.token) && t(errors.token!)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={!!tokenCode}
              name="token"
              value={values.token}
            />
          </section>
        )}

        {showDateFormat ? (
          <>
            <InputLabel>{t('settings.integrations.form.dateFormat')}</InputLabel>
            <Autocomplete
              className="flex w-100 mb-20"
              value={values.dateFormat}
              onBlur={handleBlur}
              getOptionLabel={({ name }: { name: string }) => name}
              options={datesOptions || []}
              disableClearable
              onChange={(value: FormValues['dateFormat']) => setFieldValue('dateFormat', value)}
            />
          </>
        ) : (
          ''
        )}

        <div className={styles.optionsHeader}>
          {t('settings.integrations.form.recordTypes')}
          <Tooltip title={t('settings.integrations.form.tooltipText')!} placement="bottom" alwaysShowTooltip>
            <InfoIcon />
          </Tooltip>
        </div>

        {options?.map(({ id, name, syncOptions }) => {
          const item = values.options?.find(({ integrationOptionId }) => integrationOptionId === id);
          const isSelected = !!item;
          const currentSyncOption = item?.syncOption
            ? syncOptions.find(({ id }) => id === item.syncOption)
            : syncOptions[0] || undefined;

          return (
            <section className={styles.optionBox} key={id}>
              <FormControlLabel
                control={<Checkbox checked={isSelected} color="primary" />}
                label={name}
                onChange={() => onChangeOption({ id, isSelected })}
                className="ml-0"
                classes={{ label: clsx(styles.checkboxLabelText, isSelected && styles.active) }}
              />

              {syncOptions.length && isSelected ? (
                <Autocomplete
                  className="flex w-100"
                  value={currentSyncOption}
                  onBlur={handleBlur}
                  getOptionLabel={getSyncOptionName}
                  options={syncOptions || []}
                  disableClearable
                  onChange={(option: IntegrationOptionSyncValues) => {
                    onChangeSyncOption({ id, syncOption: option.id });
                  }}
                />
              ) : undefined}
            </section>
          );
        })}
      </div>
      <div className="controls">
        <LoadingButton type="submit" loading={isLoading} className="mr-8">
          {submitButtonText}
        </LoadingButton>

        <Button color="secondary" onClick={onCancel} variant="outlined">
          {t('settings.integrations.form.cancel')}
        </Button>
      </div>
    </form>
  );
};
