import { CurrencyType } from 'types';
import { Currency } from 'generated/types';
import { HIDDEN_CURRENCY_SYMBOL } from 'consts';

export const currencyToValue = (value: number, currency?: CurrencyType) => {
  switch (currency) {
    case CurrencyType.Usd: {
      return +(value * 100).toFixed();
    }
    case CurrencyType.Eur: {
      return +(value * 100).toFixed();
    }
    default: {
      return +(value * 100).toFixed();
    }
  }
};

export const valueToCurrency = (value: number, currency?: CurrencyType) => {
  switch (currency) {
    case CurrencyType.Usd: {
      return value / 100;
    }
    case CurrencyType.Eur: {
      return value / 100;
    }
    default: {
      return value / 100;
    }
  }
};

export const roundCurrency = (value: number) => Math.round(value / 10) * 10;

export const formatCurrency = (value: number, currencyCode?: string | null, hideAmount?: boolean) => {
  const result = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode || 'USD',
    maximumFractionDigits: 2,
  }).format(+value);
  return hideAmount ? result.replace(/\d/g, HIDDEN_CURRENCY_SYMBOL) : result;
};

export const formatAmount = (value: number, hideAmount?: boolean) => {
  const result = Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(+value);
  return hideAmount ? result.replace(/\d/g, HIDDEN_CURRENCY_SYMBOL) : result;
};

export const formatShortCurrency = (
  value: number | string,
  currency: string | null | undefined,
  maximumFractionDigits = 0,
  hideAmount?: boolean,
) => {
  const result = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits,
    minimumFractionDigits: 0,
    minimumIntegerDigits: 1,
    notation: 'compact',
  })
    .formatToParts(+value)
    .map((part) => (part.type === 'compact' ? part.value.toLowerCase() : part.value))
    .join('');
  return hideAmount ? result.replace(/\d/g, HIDDEN_CURRENCY_SYMBOL) : result;
};

export const getCurrencyCode = (currency: Currency) => currency.code.toUpperCase();

export const getCurrencySymbol = (currencyCode: string | null | undefined) =>
  Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode || 'USD' }).formatToParts()[0]?.value;
