import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'icons';
import { RemoveConfirmIconButton, Tooltip } from 'components';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';

import styles from './styles.module.scss';

interface Props {
  commission: number;
  effectiveFrom: string;
  onDelete: () => void;
  onEdit: () => void;
}

export const ViewCommission: FC<Props> = ({ commission, effectiveFrom, onDelete, onEdit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.row}>
      <span className={clsx(styles.item, styles.title)} onClick={onEdit}>
        {commission}%
      </span>

      <div className={styles.divider} />

      <span className={clsx(styles.item, styles.effectiveFrom)} onClick={onEdit}>
        {format(new Date(effectiveFrom), 'MMM yyyy')}
      </span>

      <div className={styles.divider} />

      <div className={styles.menu}>
        <Tooltip title={t('actions.edit')!} placement="top" alwaysShowTooltip>
          <IconButton className={styles.iconButton} size="small" onClick={onEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <RemoveConfirmIconButton
          onClick={onDelete}
          className={styles.iconButton}
          confirmTitle={t('viewProjectDetail.commissions.delete')}
          confirmMessage={t('viewProjectDetail.commissions.confirmDelete')}
          confirmSubmitButtonTitle={t('viewProjectDetail.commissions.delete')}
        />
      </div>
    </div>
  );
};
