import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers, Field, FieldProps } from 'formik';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';

import { LoadingButton, Autocomplete } from 'components';
import { useTeamMembersForAssignmentsQuery } from 'generated/graphql';
import { getFullName, graphqlOnError, MembersBuilder, submitForm } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { Member } from 'generated/types';

export interface PayrollEntityFormValues {
  member: Member | null;
  net_pay: number;
  notes: string;
}

interface NewPayrollEntityProps {
  onSubmit: (values: PayrollEntityFormValues) => void | Promise<void>;
  onCancel: () => void;
  onDelete?: () => void;
  notes?: string;
  net_pay?: number;
  member?: Member;
  submitLabel?: string;
}

export const NewPayrollEntity = ({
  onSubmit,
  onCancel,
  onDelete,
  member,
  notes,
  net_pay,
  submitLabel,
}: NewPayrollEntityProps) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { members = [] } = {}, loading } = useTeamMembersForAssignmentsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData?.company.id as string,
    },
  });

  const teamMember = () => {
    const membersBuilder = new MembersBuilder(members);

    membersBuilder.sortMembersByName();

    return membersBuilder.getMembers();
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          member: Yup.object().nullable().required(t('forms.newPayrollEntity.memberRequired')),
          net_pay: Yup.number()
            .typeError(t('forms.newPayrollEntity.netPayRequired'))
            .required(t('forms.newPayrollEntity.netPayRequired'))
            .min(0, t('forms.newPayrollEntity.positiveNetPay')),
          notes: Yup.string(),
        },
        [],
      ),
    [loading],
  );

  const handleSubmit = (values: PayrollEntityFormValues, { setSubmitting }: FormikHelpers<PayrollEntityFormValues>) => {
    submitForm(values, setSubmitting, onSubmit);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        member: member || null,
        net_pay: net_pay ?? 0,
        notes: notes ?? '',
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setValues, values }) => (
        <Form>
          <InputLabel className="required">{t('forms.newPayrollEntity.teamMember')}</InputLabel>
          <Field name="member">
            {({
              field: { value, onBlur },
              meta: { touched, error },
            }: FieldProps<PayrollEntityFormValues['member'], PayrollEntityFormValues>) => (
              <Autocomplete
                placeholder={t('forms.newPayrollEntity.teamMember')}
                name="member"
                onBlur={onBlur}
                className="mb-24"
                getOptionLabel={(option: Member) => getFullName(option.first_name, option.last_name)}
                value={value}
                error={touched ? error : undefined}
                onChange={(member: Member) => setValues({ ...values, member })}
                options={loading && member ? [member] : teamMember()}
              />
            )}
          </Field>

          <InputLabel className="required">{t('forms.newPayrollEntity.netPay')}</InputLabel>
          <Field
            component={TextField}
            name="net_pay"
            placeholder={t('forms.newPayrollEntity.netPay')}
            className="mb-24"
          />

          <InputLabel>{t('forms.newExpense.notes')}</InputLabel>
          <Field
            component={TextField}
            multiline
            rows={3}
            name="notes"
            placeholder={t('forms.newPayrollEntity.notes')}
            className="mb-24"
          />

          <div className="mt-8 mb-24 flex justify-content-between">
            <div>
              <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
                {submitLabel ?? t('forms.create')}
              </LoadingButton>
              <Button variant="outlined" color="secondary" className="mr-8" onClick={onCancel}>
                {t('forms.cancel')}
              </Button>
            </div>

            {onDelete && (
              <Button variant="outlined" className="danger-btn" onClick={onDelete}>
                {t('forms.delete')}
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
