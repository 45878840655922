import { useScreenWidth } from './useScreenWidth';
import { Breakpoints } from 'consts';

export const useDeviceTypeByWidth = () => {
  const screenWidth = useScreenWidth();

  return {
    isMobileDevice: screenWidth <= Breakpoints.Tablet,
    isTabletDevice: screenWidth > Breakpoints.Tablet && screenWidth <= Breakpoints.Desktop,
    iscDevice: screenWidth > Breakpoints.Desktop,
  };
};
