import { ProjectDataFragment, ProjectType } from 'generated/types';
import { formatByThousands, valueToCurrency } from 'utils';
import { EMPTY_STATE } from 'consts';

const maxPercentValue = 100;

export const calculateTotalAssignments = (
  project: ProjectDataFragment,
  data: Array<{ revenue?: number | null; cost?: number | null; commission?: number | null }>,
) => {
  const hasCostRateError = data.some(({ cost }) => cost === null);
  const isProjectTimeAndMaterialOrRetainer =
    project?.type === ProjectType.TimeAndMaterial || project?.type === ProjectType.Retainer;
  const isProjectFixedPrice = project?.type === ProjectType.FixedPrice;

  const totalCost = hasCostRateError ? 0 : data.reduce((acc, { cost }) => acc + cost!, 0);
  const totalRevenue =
    (isProjectFixedPrice && valueToCurrency(project?.fee_amount as number)) ||
    (isProjectTimeAndMaterialOrRetainer && data.reduce((acc, { revenue }) => acc + (revenue || 0), 0));
  const totalCommission = data.reduce((acc, { commission }) => acc + (commission || 0), 0);
  const totalDp = hasCostRateError ? 0 : +totalRevenue - totalCost - totalCommission;
  const totalDpm = hasCostRateError ? 0 : (totalDp / +totalRevenue) * maxPercentValue;

  return {
    totalCost,
    totalDp,
    totalCommission,
    totalRevenue: typeof totalRevenue !== 'boolean' ? totalRevenue : 0,
    totalDpm: !isNaN(totalDpm) && isFinite(totalDpm) ? `${formatByThousands(totalDpm.toFixed(1))} %` : EMPTY_STATE,
    hasCostRateError,
  };
};
