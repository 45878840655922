import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface StickyFooterProps {
  children: ReactNode;
  className?: string;
}

export const StickyFooter = ({ children, className }: StickyFooterProps) => (
  <footer className={clsx(styles.stickyFooter, className)}>{children}</footer>
);
