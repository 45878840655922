import { areIntervalsOverlapping } from 'date-fns';
import { getAssignmentDate, getAssignmentFromRequest } from 'views/ResourcePlanning/utils';
import { AssignmentRowItem } from 'views/ResourcePlanning/types';
import { ResourcePlanningProjectsDataFragment } from 'generated/types';

type AssignmentsRowData = { [key: string]: AssignmentRowItem[] | undefined };

// Here, we check assignments to ensure they are not overlapping, and if it's true, we stack them in one row.
export const getGroupedRowAssignments = (assignments: AssignmentRowItem[]) => {
  return Object.values(
    assignments.reduce<AssignmentsRowData>((acc, assignment: AssignmentRowItem) => {
      let rowIndex = Object.keys(acc).findIndex((key) => {
        const row = acc[parseInt(key)];
        return (row || []).every(({ startDate, endDate }) => {
          return !areIntervalsOverlapping(
            { start: getAssignmentDate(startDate), end: getAssignmentDate(endDate) },
            { start: getAssignmentDate(assignment.startDate), end: getAssignmentDate(assignment.endDate) },
            { inclusive: true },
          );
        });
      });

      if (rowIndex === -1) {
        rowIndex = Object.keys(acc).length;
      }

      if (!acc[rowIndex]) {
        acc[rowIndex] = [];
      }
      acc[rowIndex]?.push(assignment);

      return acc;
    }, {}),
  );
};

export const getGroupedRowAssignmentsLength = (project: ResourcePlanningProjectsDataFragment) => {
  const assignments = (project.assignment || []) as AssignmentRowItem[];
  const requests = project.requests || [];

  if (assignments.length + requests.length <= 1) {
    return 1;
  }

  const requestedAssignments = project.requests?.map(getAssignmentFromRequest) || [];

  return getGroupedRowAssignments([...assignments, ...requestedAssignments]).length;
};
