import { TextField, TextFieldProps } from '@material-ui/core';
import React, { FC, KeyboardEvent, useCallback, useState } from 'react';
import { formatTimeLog, parseTimeLog } from 'utils';

type Props = Omit<TextFieldProps, 'value' | 'onChange' | 'type'> & {
  // logged minutes
  value: number;
  onChange: (loggedMinutes: number) => void;
  onInputChange?: (value: string) => void;
};

export const TimeLogInput: FC<Props> = ({ value, onChange, onInputChange, onBlur, ...props }) => {
  const [inputValue, setInputValue] = useState<string>(value ? formatTimeLog(value) : '');

  const handleBlur = useCallback(
    (e) => {
      const minutes = parseTimeLog(inputValue);
      if (!Number.isNaN(minutes)) {
        setInputValue(minutes ? formatTimeLog(minutes) : '');
      }
      onChange(minutes);
      onBlur?.(e);
    },
    [inputValue],
  );

  const onEnter = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        handleBlur(e);
      }
    },
    [handleBlur],
  );

  return (
    <TextField
      {...props}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
        onInputChange?.(e.target.value);
      }}
      onBlur={handleBlur}
      onKeyDown={onEnter}
      placeholder="0h 0m"
      InputProps={{ className: 'white-background' }}
    />
  );
};
