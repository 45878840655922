import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import { Button, debounce } from '@material-ui/core';

import { links } from 'App';
import mixpanel from 'mixpanel-browser';
import { GroupByFilter, ModuleName, ProjectModals, ProjectsTabs, RouteProps } from 'types';
import { useSetAppTitle } from 'hooks/useSetAppTitle';
import {
  ChangeFiltersData,
  CommonActions,
  CommonFilterOptions,
  CommonFilters,
  GroupBySelectFilter,
  IntegrationModal,
  MobileSearch,
  ResizableSearch,
  TabItem,
  TableCountHeader,
  Tabs,
  ViewHeading,
} from 'components';
import { ActiveProjects, ArchivedProjects } from './parts';
import { useDeviceTypeByWidth, useErrorMsgBuilder, usePermissions } from 'hooks';
import { ActionsType, ImportType, ProjectMarginsDataFragment } from 'generated/types';
import { useManagedProjectsQuery, useProjectsMarginsQuery } from 'generated/graphql';
import { graphqlOnError, searchFilter } from 'utils';
import { useAuth } from 'contexts';
import { localStorageManager } from 'services';
import { PROJECTS_GROUP_BY } from 'consts';
import { UploadFile } from 'icons';
import { ApolloError } from '@apollo/client/errors';

import styles from './styles.module.scss';

interface ProjectsMarginsData {
  [key: string]: ProjectMarginsDataFragment;
}

const ProjectsRoute = Route(
  {
    search: Route.query.string,
    tab: Route.params.oneOf(...Object.values(ProjectsTabs)),
    client: Route.query.array(Route.query.string),
    pm: Route.query.array(Route.query.string),
    type: Route.query.array(Route.query.string),
    modal: Route.query.oneOf(...Object.values(ProjectModals)),
  },
  ({ tab }) => `/projects/${tab}`,
);

const Projects = ({
  match: {
    query,
    query: { search, client, pm, type, modal },
    params,
    params: { tab },
  },
  link,
  history: { push },
}: RouteProps) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [searchTerm, setSearchTerm] = useState(search);
  const [groupBy, setGroupBy] = useState<GroupByFilter | undefined>(
    (localStorageManager.getItem(PROJECTS_GROUP_BY) as GroupByFilter) || undefined,
  );
  const { hasAccess } = usePermissions();
  const isActiveProjectsAccess = hasAccess(ActionsType.ViewActiveProjects);
  const isArchiveProjectsAccess = hasAccess(ActionsType.ViewActiveProjects);
  const { isMobileDevice } = useDeviceTypeByWidth();
  useSetAppTitle(t('navigation.projects'));

  useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  const queryOptions = {
    onError: (err: ApolloError) => {
      graphqlOnError(err, tls(err.message));
    },
    skip:
      (!isActiveProjectsAccess && tab !== ProjectsTabs.active) ||
      (!isArchiveProjectsAccess && tab === ProjectsTabs.archived),
  };

  const { data: { managedProjects = [] } = {}, loading } = useManagedProjectsQuery({
    ...queryOptions,
    variables: {
      companyId: userData!.company.id,
      isArchived: tab === ProjectsTabs.archived,
      withCommission: true,
      filterData: {
        client: (client as string[]) || null,
        pm: (pm as string[]) || null,
        type: type || null,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { data: { projectsMargins = [] } = {} } = useProjectsMarginsQuery({
    ...queryOptions,
    variables: {
      companyId: userData!.company.id,
      isArchived: tab === ProjectsTabs.archived,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: queryOptions.skip || !hasAccess(ActionsType.ViewProjectCosts),
  });

  const projectsMarginsData = useMemo(
    () => projectsMargins.reduce<ProjectsMarginsData>((acc, item) => ({ ...acc, [item.projectId]: item }), {}),
    [projectsMargins],
  );

  const filteredProjects = useMemo(() => {
    if (!managedProjects) return [];

    return managedProjects
      .filter((item) => searchFilter(search, item.name, item?.client?.name ? item.client.name : null))
      .sort((a, b) => {
        if (a?.client && b?.client) return a.client.name.localeCompare(b.client.name);
        if (b?.client) return 1;
        return -1;
      })
      .map((item) => ({
        ...item,
        margin: projectsMarginsData[item.id]?.margin,
        forecastMargin: projectsMarginsData[item.id]?.forecastMargin,
      }));
  }, [managedProjects, search, projectsMarginsData]);

  const onChangeFilters = (data: ChangeFiltersData) => {
    push(link({ tab, ...query, ...data }));
  };

  const changeQueryParams = useCallback(
    debounce((search?: string) => {
      push(link({ tab, ...query, search: search || undefined }));
    }, 200),
    [query, search, tab],
  );

  const onSearchChange = useCallback(
    (value?: string) => {
      setSearchTerm(value);
      changeQueryParams(value);
    },
    [changeQueryParams],
  );
  const onCloseModal = () => push(link({ ...query, ...params, modal: undefined }));

  const countLabel = loading
    ? ''
    : `${filteredProjects.length} ${filteredProjects.length === 1 ? t('projects.project') : t('projects.projects')}`;

  return (
    <>
      <ViewHeading hasSmartBackBtn={false} label={t('projects.label')} className={styles.viewHeading}>
        <section className="flex gap-8 ml-8">
          {hasAccess(ActionsType.CreateAssignments) && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<UploadFile />}
              onClick={() => push(link({ ...query, ...params, modal: ProjectModals.integration }))}
            >
              {t('projects.importProjects')}
            </Button>
          )}

          <CommonActions
            menuItemsOrder={['client', 'project', 'assignment', 'requestAssignment', 'teamMember', 'leave']}
            refetchProjectsAction={['ManagedProjects']}
            refetchMembersAction={['TeamMembers']}
          />
        </section>
      </ViewHeading>
      <Tabs className="tabs-box">
        {hasAccess(ActionsType.ViewActiveProjects) && (
          <TabItem route={links.ActiveProjects()}>{t('projects.active')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewArchivedProjects) && (
          <TabItem route={links.ArchivedProjects()}>{t('projects.archived')}</TabItem>
        )}
      </Tabs>
      <TableCountHeader countLabel={countLabel}>
        {!isMobileDevice && <ResizableSearch onChange={onSearchChange} value={searchTerm} className="mr-12" />}
        <GroupBySelectFilter
          value={groupBy}
          onChange={(value) => {
            mixpanel.track('Grouping updated', {
              'Screen name': ModuleName.projects,
              Grouping: value || GroupByFilter.none,
            });
            localStorageManager.setItem(PROJECTS_GROUP_BY, value);
            setGroupBy(value);
          }}
          options={[GroupByFilter.none, GroupByFilter.projectType, GroupByFilter.projectManager, GroupByFilter.client]}
        />
        <CommonFilters
          onChange={onChangeFilters}
          filterButtonClassName={styles.filterButton}
          filtersOptions={[CommonFilterOptions.client, CommonFilterOptions.pm, CommonFilterOptions.type]}
          client={client}
          pm={pm}
          type={type}
        />
      </TableCountHeader>

      {isMobileDevice && <MobileSearch onChange={onSearchChange} value={searchTerm} className="px-8 pb-12" />}

      <div className="layout-content-wrapper">
        <Switch>
          <ActiveProjects projects={filteredProjects} loading={loading} groupBy={groupBy} />
          <ArchivedProjects projects={filteredProjects} loading={loading} groupBy={groupBy} />
        </Switch>
      </div>

      <IntegrationModal
        title={t('projects.importProjects')}
        importType={ImportType.Project}
        isOpen={hasAccess(ActionsType.CreateProjects) && modal === ProjectModals.integration}
        onClose={onCloseModal}
      />
    </>
  );
};

export default ProjectsRoute(Projects);
