import { TotalTimeLogDataFragment } from 'generated/types';

export const groupTimeLogs = (timeLogs: TotalTimeLogDataFragment[]) =>
  timeLogs.reduce((groupedLogs, timeLog) => {
    const memberTimeLogs = groupedLogs.get(timeLog.assignment_id) || [];

    return groupedLogs.set(timeLog.assignment_id, [
      ...memberTimeLogs,
      { member_id: timeLog.member_id, date: timeLog.date, totalMinutes: timeLog.totalMinutes },
    ]);
  }, new Map<TotalTimeLogDataFragment['assignment_id'], Pick<TotalTimeLogDataFragment, 'date' | 'totalMinutes' | 'member_id'>[]>());
