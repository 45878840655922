import { useCurrenciesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useAuth } from 'contexts';

export const useCompanyCurrencyCode = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { currencies = [] } = {} } = useCurrenciesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const companyCurrencyCode = currencies.find(({ id }) => id === userData?.company.primaryCurrencyId);

  return companyCurrencyCode?.code || '';
};
