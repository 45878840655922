import React, { ReactNode } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { IconButton, SwipeableDrawer } from '@material-ui/core';
import { Close } from 'icons';
import { useDeviceTypeByWidth } from 'hooks';

import styles from './RightDrawer.module.scss';

interface RightDialogProps {
  open: boolean;
  title: string | ReactNode;
  direction?: 'right' | 'left' | 'top' | 'bottom';
  onClose: () => void;
  children?: React.ReactNode;
  paperClassName?: string;
  boxClassName?: string;
  titleClassName?: string;
  headerClassName?: string;
  additionalButton?: ReactNode;
}

export const RightDrawer = ({
  open,
  title,
  direction = 'right',
  onClose,
  children,
  paperClassName,
  boxClassName,
  titleClassName,
  headerClassName,
  additionalButton,
}: RightDialogProps) => {
  const { isMobileDevice } = useDeviceTypeByWidth();
  const DrawerComponent = isMobileDevice ? SwipeableDrawer : Drawer;

  return (
    <DrawerComponent
      classes={{ paper: clsx(styles.drawer, paperClassName) }}
      anchor={direction}
      open={open}
      onClose={onClose}
      onOpen={onClose}
    >
      <div className={clsx(styles.box, headerClassName)}>
        <h2 className={clsx(styles.title, titleClassName)}>{title}</h2>

        <div>
          {additionalButton}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </div>

      <div className={clsx('px-24', 'h-100', boxClassName)}>{children}</div>
    </DrawerComponent>
  );
};
