import React, { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { Warning } from 'icons';

interface Props {
  fileName: string;
  children?: ReactNode;
}

export const IntegrationWarningBox: FC<Props> = ({ fileName, children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <Warning className={styles.infoIcon} />
      <div className={styles.content}>
        <span className={styles.title}>{t('import.errorTitle', { name: fileName })}</span>
        <span>{t('import.errorDescription')}</span>
        {children}
      </div>
    </div>
  );
};
