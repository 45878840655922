import React from 'react';

interface MailIconProps {
  className?: string;
}

export const MailIcon = ({ className }: MailIconProps) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66699 4.99967C1.66699 4.0792 2.41318 3.33301 3.33366 3.33301H16.667C17.5875 3.33301 18.3337 4.0792 18.3337 4.99967V14.9997C18.3337 15.9201 17.5875 16.6663 16.667 16.6663H3.33366C2.41319 16.6663 1.66699 15.9201 1.66699 14.9997V4.99967ZM4.59915 4.99967L10.0003 9.7257L15.4015 4.99967H4.59915ZM16.667 6.10698L10.5491 11.4602C10.2349 11.7351 9.76576 11.7351 9.45157 11.4602L3.33366 6.10698V14.9997H16.667V6.10698Z"
      fill="#5C6E79"
    />
  </svg>
);
