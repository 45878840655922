export const tagColorsPalette = [
  '#EBEDEE',
  '#F5EBFD',
  '#FDE3F9',
  '#DDE6F8',
  '#CBF0F8',
  '#FFF6DB',
  '#FFE3D9',
  '#CFF2D3',
];
