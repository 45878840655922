import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../contexts';
import { ModuleName, ScreenName } from 'types';

export const useTrackScreenView = (
  module: ModuleName,
  screenName: ScreenName,
  loading?: boolean,
  isNoAuthorizedUser?: boolean,
): void => {
  const { userData } = useAuth();

  if (!userData && !isNoAuthorizedUser) return;

  useEffect(() => {
    if (loading) return;

    mixpanel.track('Screen viewed', {
      Module: module,
      'Screen name': screenName,
    });
  }, [loading]);
};
