import { useCreateVendorMutation, useVendorsQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { Reference } from '@apollo/client';
import { useAuth } from 'contexts';
import { Vendor } from 'generated/types';

export const useCreatableVendorData = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { vendors = [] } = {}, loading: vendorsLoading } = useVendorsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const [createVendor] = useCreateVendorMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createVendor) return;

      cache.modify({
        fields: {
          vendors(items: Reference[] = []) {
            return [...items, data.createVendor];
          },
        },
      });
    },
  });

  const getCreatedVendor = async (value: Pick<Vendor, 'id' | 'name'> | null) => {
    if (value?.id) {
      return value;
    }

    if (!value?.name) {
      return null;
    }

    const { data } = await createVendor({
      variables: {
        data: { name: value?.name || '' },
        companyId: userData!.company.id,
      },
    });

    return data?.createVendor || null;
  };

  return { vendors, vendorsLoading, getCreatedVendor };
};
