import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface StickyFooterProps {
  children: ReactNode;
  label: string;
  className?: string;
}

export const LabelValueCell = ({ label, children, className }: StickyFooterProps) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {children}
      <p className={styles.label}>{t(label)}</p>
    </div>
  );
};
