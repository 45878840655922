import { getTimezoneOffset } from 'date-fns-tz';

export const newUTCDate = (now = new Date()) => {
  return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
};

export const addTimezoneOffset = (date: Date) =>
  new Date(date.getTime() + getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, date));

export const removeUTCTimezone = (date: string) => (date.endsWith('Z') ? date.slice(0, -1) : date);
