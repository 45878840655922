import { RefObject, useEffect } from 'react';

interface Props {
  ref: RefObject<HTMLDivElement>;
  expanded?: boolean;
  shouldScrollAfterExpand?: boolean;
}
export const useScrollExpandedIntoView = ({ ref, shouldScrollAfterExpand, expanded }: Props) => {
  useEffect(() => {
    if (expanded && shouldScrollAfterExpand) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [expanded]);
};
