import { EmploymentType } from 'generated/types';
import { AvailabilitiesFilter, MemberTypesFilter } from '../types';

export const availabilityFilter: { [P in AvailabilitiesFilter]: (available?: boolean) => boolean } = {
  [AvailabilitiesFilter.all]: () => true,
  [AvailabilitiesFilter.available]: (available) => !!available,
  [AvailabilitiesFilter.booked]: (available) => !available,
};

export const memberFilter: { [P in MemberTypesFilter]: (type?: EmploymentType) => boolean } = {
  [MemberTypesFilter.all]: () => true,
  [MemberTypesFilter.contractors]: (type) => type === EmploymentType.Contractor,
  [MemberTypesFilter.employees]: (type) => type === EmploymentType.Employee,
};
