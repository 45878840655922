import React from 'react';
import clsx from 'clsx';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

import { ResetPassword as ResetPasswordForm, ResetPasswordFormValues, UnauthFormHeading } from 'components';
import { ModuleName, RouteProps, ScreenName } from 'types';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder, useTrackScreenView } from 'hooks';
import { useChangePasswordFromEmailMutation } from 'generated/graphql';
import { useAuth } from 'contexts';
import { links } from 'App';

// import styles from './ResetPassword.module.scss';

const ResetPasswordRoute = Route(
  {
    reset_id: Route.query.string,
    secret: Route.query.string,
  },
  '/reset-password',
);

export const ResetPassword = ({
  match: {
    query: { reset_id = '', secret = '' },
  },
}: RouteProps) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { handleAuth } = useAuth();
  const history = useHistory();
  useTrackScreenView(ModuleName.auth, ScreenName.resetPassword, false, true);

  const [resetPassword] = useChangePasswordFromEmailMutation({
    onCompleted({ changePasswordFromEmail: { token } }) {
      toast.success(t('resetPassword.resetPasswordSuccessfully'));
      handleAuth(token);
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const handlePasswordChange = async ({ password }: ResetPasswordFormValues) => {
    await resetPassword({
      variables: {
        password,
        resetId: reset_id,
        secret,
      },
    });
  };

  const goToLogin = () => history.replace(links.Login());

  return (
    <div className={clsx('form-content', 'col-3')}>
      <UnauthFormHeading heading={t('resetPassword.title')} />
      <div className="px-30 pt-24 pb-30">
        <ResetPasswordForm onCancel={goToLogin} onSubmit={handlePasswordChange} />
      </div>
    </div>
  );
};

export default ResetPasswordRoute<RouteProps>(ResetPassword);
