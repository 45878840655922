import React, { FC } from 'react';
import { Avatar, SIZES, Tag, TagProps } from 'components';
import { getAcronym } from 'utils';

interface Props {
  avatarColor: string;
  firstName: string;
  lastName: string;
}

export const MemberTag: FC<Props & Omit<TagProps, 'name'>> = ({ firstName, lastName, avatarColor, ...tagProps }) => {
  return (
    <Tag
      startIcon={
        <Avatar className="mr-6" avatarTitle={getAcronym(firstName, lastName)} size={SIZES.xs} color={avatarColor} />
      }
      {...tagProps}
      name={`${firstName} ${lastName}`}
    />
  );
};
