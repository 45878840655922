export enum ProficiencyRates {
  Basic = '1',
  Beginner = '2',
  Medium = '3',
  Pro = '4',
  Mastery = '5',
}

export const ProficiencyRatesLabel = Object.freeze<Record<ProficiencyRates, string>>({
  [ProficiencyRates.Basic]: 'proficiencyRates.title.basic',
  [ProficiencyRates.Beginner]: 'proficiencyRates.title.beginner',
  [ProficiencyRates.Medium]: 'proficiencyRates.title.medium',
  [ProficiencyRates.Pro]: 'proficiencyRates.title.pro',
  [ProficiencyRates.Mastery]: 'proficiencyRates.title.mastery',
});

export const ProficiencyRatesDescription = Object.freeze<Record<ProficiencyRates, string>>({
  [ProficiencyRates.Basic]: 'proficiencyRates.description.basic',
  [ProficiencyRates.Beginner]: 'proficiencyRates.description.beginner',
  [ProficiencyRates.Medium]: 'proficiencyRates.description.medium',
  [ProficiencyRates.Pro]: 'proficiencyRates.description.pro',
  [ProficiencyRates.Mastery]: 'proficiencyRates.description.mastery',
});
