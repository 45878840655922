import { useCallback, useState } from 'react';

interface AccordionOpenState {
  [k: string]: boolean;
}

interface Data {
  id: string;
}

export const useAccordionData = () => {
  const [accordionOpenState, setAccordionOpenState] = useState<AccordionOpenState>({});

  const setAccordionState = useCallback((data: Data[], isAllExpanded = true) => {
    const accordionOpenState = data.reduce<AccordionOpenState>((acc, item) => {
      return { ...acc, [item.id]: isAllExpanded };
    }, {});
    setAccordionOpenState(accordionOpenState);
  }, []);

  const toggleAccordion = useCallback((id: string) => {
    setAccordionOpenState((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const changeAllAccordionsState = useCallback((isExpand: boolean) => {
    setAccordionOpenState((prev) =>
      Object.keys(prev).reduce((acc, key) => {
        return { ...acc, [key]: isExpand };
      }, {}),
    );
  }, []);

  const accordionOpenStateValues = Object.values(accordionOpenState);
  const isAllCollapsed = !!accordionOpenStateValues.length && !accordionOpenStateValues.includes(true);

  return {
    accordionOpenState,
    setAccordionState,
    toggleAccordion,
    changeAllAccordionsState,
    isAllCollapsed,
  };
};
