import React, { FC } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProficiencyRates, ProficiencyRatesLabel } from './Rates.enum';

import styles from './styles.module.scss';
import { ActionsType } from 'generated/types';
import { usePermissions } from 'hooks';

export interface ProficiencyRateProps {
  editable?: boolean;
  className?: string;
  selectedRate?: number | null;
  onChangeRate: (rate: number) => void;
}

export const ProficiencyRate: FC<ProficiencyRateProps> = ({ editable, selectedRate, onChangeRate, className }) => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();

  return (
    <div className={className}>
      {Object.values(ProficiencyRates).map((proficiencyRate) => {
        const selected = +proficiencyRate === selectedRate;

        return (
          <Tooltip
            placement="top"
            key={proficiencyRate}
            title={`${proficiencyRate} - ${t(ProficiencyRatesLabel[proficiencyRate])}`}
          >
            <div
              onClick={() => hasAccess(ActionsType.EditMemberSkills) && onChangeRate(+proficiencyRate)}
              className={clsx(styles.item, { [styles.selected]: selected, [styles.editable]: editable })}
            >
              {proficiencyRate}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
