import React, { FC, useCallback, useEffect, useState } from 'react';
import { useProjectMilestonesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'components';
import { MilestonesHeader } from './components/MilestonesHeader';
import { MilestoneItem } from './components/MilestoneItem';
import { CreateMilestone } from './CreateMilestone';
import Button from '@material-ui/core/Button';
import { ChevronIcon, PlusIcon } from 'icons';
import { roundValue } from 'views/Insights/helpers';

import styles from './styles.module.scss';

interface Props {
  projectId: string;
  onChangeIsMilestonesUnsaved: (value: boolean) => void;
  onChangeProjectTotal: (value: number) => void;
}

export const Milestones: FC<Props> = ({ projectId, onChangeIsMilestonesUnsaved, onChangeProjectTotal }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const [isCreating, setIsCreating] = useState(false);

  const { data: { projectMilestones = [] } = {}, loading } = useProjectMilestonesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      projectId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const total = projectMilestones.reduce((acc, item) => acc + item.amount, 0);
    onChangeProjectTotal(roundValue(total));
  }, [projectMilestones]);

  const isEmptyList = !projectMilestones.length && !loading;
  const cancelCreating = () => setIsCreating(false);
  const [editingMilestones, setEditingMilestones] = useState<string[]>([]);

  const onToggleEditing = useCallback((id: string) => {
    setEditingMilestones((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]));
  }, []);

  useEffect(() => {
    onChangeIsMilestonesUnsaved(!!editingMilestones.length || isCreating || isEmptyList);
  }, [editingMilestones, isCreating, isEmptyList]);

  return (
    <div className={styles.container}>
      <Accordion
        header={
          <>
            {t('viewProjectDetail.milestones.label')}
            <div className={styles.count}>{projectMilestones.length}</div>
          </>
        }
        expandIcon={<ChevronIcon />}
        detailsClassName={styles.accordionDetails}
        summaryClassName={styles.accordionSummary}
      >
        <>
          <div className={styles.box}>
            <MilestonesHeader />
            {[...projectMilestones]
              .sort((a, b) => new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime())
              .map((milestone) => (
                <MilestoneItem key={milestone.id} projectMilestone={milestone} onToggleEditing={onToggleEditing} />
              ))}
            {(isCreating || isEmptyList) && <CreateMilestone projectId={projectId} onClose={cancelCreating} />}
          </div>

          {!isCreating && !isEmptyList && (
            <Button
              variant="text"
              color="secondary"
              className={styles.button}
              startIcon={<PlusIcon className={styles.icon} />}
              onClick={() => setIsCreating(true)}
            >
              {t('viewProjectDetail.milestones.addNewMilestone')}
            </Button>
          )}
        </>
      </Accordion>
    </div>
  );
};
