import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { InsightsTabs } from 'types';

export const InsightsRoute = Route(
  {
    tab: Route.params.oneOf(InsightsTabs.utilization, InsightsTabs.bench, InsightsTabs.projects).optional,
    projectId: Route.query.string,
  },
  ({ tab }) => `/insights/${tab}`,
);

export const Insights = InsightsRoute(lazy(() => import(/* webpackChunkName: "Insights" */ './Insights')));
