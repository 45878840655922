import React, { ReactNode } from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import { useDeviceTypeByWidth } from 'hooks';

import { ChevronIcon } from 'icons';
import { useRouteTracker } from 'contexts';
import { useHistory } from 'react-router-dom';

import styles from './ViewHeading.module.scss';

interface ViewHeadingProps {
  label?: string | ReactNode;
  children?: ReactNode;
  headingClassName?: string;
  className?: string;
  backPath?: string;
  hasSmartBackBtn?: boolean;
  sticky?: boolean;
  navigation?: ReactNode;
}

export const ViewHeading = ({
  label,
  children,
  className,
  hasSmartBackBtn = true,
  backPath,
  headingClassName,
  sticky = true,
  navigation,
}: ViewHeadingProps) => {
  const { hasPrev, navigateToPrevRoute } = useRouteTracker();
  const { isMobileDevice } = useDeviceTypeByWidth();
  const history = useHistory();

  const renderBackBtn = () => {
    return (
      <IconButton
        onClick={backPath ? () => history.push(backPath) : navigateToPrevRoute}
        className={clsx(styles.backButton, 'mr-24', 'p-5')}
      >
        <ChevronIcon className={styles.icon} />
      </IconButton>
    );
  };

  return (
    <div className={clsx(sticky && styles.sticky, headingClassName)}>
      <div
        className={clsx(
          navigation ? 'py-8' : 'py-14',
          isMobileDevice ? 'px-8' : 'px-30',
          'flex align-items-center justify-content-between',
          className,
        )}
      >
        <h1 className="weight-600 text-20 flex align-items-center">
          {((hasSmartBackBtn && hasPrev) || !!backPath) && renderBackBtn()}
          {label}
        </h1>
        {children}
      </div>
      {navigation}
    </div>
  );
};
