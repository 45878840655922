import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { QueryOptions } from '@apollo/client';

import { links } from 'App';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { EmploymentType } from 'generated/types';
import { CustomToastWithLink, TeamMemberFormValues } from 'components';
import { addTimezoneOffset, graphqlOnError } from 'utils';
import { useCreateMemberMutation } from 'generated/graphql';
import mixpanel from 'mixpanel-browser';

export const useCreateMember = (
  onClose: () => void,
  refetchAction?: Array<string | DocumentNode | QueryOptions>,
): (({
  first_name,
  last_name,
  email,
  employment_type,
  join_date,
  exit_date,
  job_title,
  member_role,
  reportingTo,
  specialization,
  seniority,
  costRateManagers,
  capacity,
}: TeamMemberFormValues) => Promise<void>) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [createMember] = useCreateMemberMutation({
    async onCompleted(data) {
      const memberLink = links.ViewTeamMember({ id: data.createMember.id });
      const memberName = `${data.createMember.first_name} ${data.createMember.last_name}`;
      const toastMessage = t('teams.teamMemberCreateSuccessfully');
      toast.success(CustomToastWithLink(memberLink, memberName, toastMessage));
      mixpanel.track('Member created', {
        Type: data.createMember.employment_type,
        'Title set': !!data.createMember.job_title,
        'Joining date set': !!data.createMember.join_date,
        'Seniority set': !!data.createMember.member_seniority,
        'Specialization set': !!data.createMember.member_specialization,
        'Manager set': !!data.createMember.reportingToId,
      });
      onClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: refetchAction,
  });

  return async ({
    first_name,
    last_name,
    email,
    employment_type,
    join_date,
    exit_date,
    job_title,
    member_role,
    reportingTo,
    specialization,
    seniority,
    costRateManagers,
    capacity,
  }: TeamMemberFormValues) => {
    userData &&
      (await createMember({
        variables: {
          data: {
            first_name,
            last_name,
            email,
            employment_type: employment_type?.id as EmploymentType,
            join_date: join_date && addTimezoneOffset(new Date(join_date)),
            exit_date: exit_date && addTimezoneOffset(new Date(exit_date)),
            capacity: Number(capacity),
            member_role: member_role.map(({ id }) => id.toString()),
            costRateManagers: costRateManagers.map(({ id }) => id.toString()),
            specialization_id: specialization ? specialization.id.toString() : null,
            job_title: job_title || null,
            seniority_id: seniority ? seniority.id.toString() : null,
            reportingTo: reportingTo ? reportingTo.id : null,
          },
          companyId: userData.company.id,
        },
      }));
  };
};
