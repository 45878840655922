import React, { FC } from 'react';
import chartIcon from './images/chartIcon.svg';

import styles from './styles.module.scss';

interface ChartEmptyStateProps {
  label: string;
}

export const ChartEmptyState: FC<ChartEmptyStateProps> = ({ label }) => (
  <div className={styles.emptyState}>
    <img src={chartIcon} alt="chart-icon" className="mb-16" />
    <p className={styles.emptyStateLabel}>{label}</p>
  </div>
);
