import { SortByFn } from 'react-table';
import { useCallback } from 'react';
import { RequestDataFragment } from 'generated/types';
import { isAfter } from 'date-fns';

export const useRequestTableSorts = () => {
  const sortProjectType = useCallback<SortByFn<RequestDataFragment>>((rowA, rowB) => {
    return (rowA.original.data.project || '').localeCompare(rowB.original.data.project || '', 'en');
  }, []);

  const sortRoleType = useCallback<SortByFn<RequestDataFragment>>((rowA, rowB) => {
    return (rowA.original.data.role || '').localeCompare(rowB.original.data.role || '', 'en');
  }, []);

  const sortStatusType = useCallback<SortByFn<RequestDataFragment>>((rowA, rowB) => {
    return (rowA.original.status || '').localeCompare(rowB.original.status || '', 'en');
  }, []);

  const sortMemberType = useCallback<SortByFn<RequestDataFragment>>((rowA, rowB) => {
    return `${rowA.original.data.memberFirstName} ${rowA.original.data.memberLastName}`.localeCompare(
      `${rowB.original.data.memberFirstName} ${rowB.original.data.memberLastName}`,
      'en',
    );
  }, []);

  const sortDatesType = useCallback<SortByFn<RequestDataFragment>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.data.startDate), new Date(rowB.original.data.startDate)) ? 1 : -1;
  }, []);

  const sortCalculationType = useCallback<SortByFn<RequestDataFragment>>((rowA, rowB) => {
    return (rowA.original.data.calculationType || '').localeCompare(rowB.original.data.calculationType || '', 'en');
  }, []);

  const sortCreatedByType = useCallback<SortByFn<RequestDataFragment>>((rowA, rowB) => {
    return (rowA.original.createdByMemberName || '').localeCompare(rowB.original.createdByMemberName || '', 'en');
  }, []);

  return {
    sortProjectType,
    sortRoleType,
    sortStatusType,
    sortMemberType,
    sortDatesType,
    sortCalculationType,
    sortCreatedByType,
  };
};
