import { useCallback, useState } from 'react';
import { ResourcePlanningMembersDataFragment } from 'generated/types';
import { localStorageManager } from 'services';
import mixpanel from 'mixpanel-browser';

const HIDDEN_RESOURCE_PLANNING_MEMBERS_IDS = 'HIDDEN_RESOURCE_PLANNING_MEMBERS_IDS';

export const useHideMembers = () => {
  const initialHiddenMembersIds = (localStorageManager.getItem(
    HIDDEN_RESOURCE_PLANNING_MEMBERS_IDS,
  ) as unknown) as string[];
  const [hiddenMembersIds, setHiddenMembersIds] = useState<string[]>(initialHiddenMembersIds || []);

  const onHideMember = useCallback(
    (id: string) => () => {
      setHiddenMembersIds((prev) => {
        const newValue = [...prev, id];

        mixpanel.track('Resource planning member hidden');
        localStorageManager.setItem(HIDDEN_RESOURCE_PLANNING_MEMBERS_IDS, newValue);
        return newValue;
      });
    },
    [],
  );

  const onShowMember = useCallback((id: string) => {
    setHiddenMembersIds((prev) => {
      const newValue = prev.filter((item) => item !== id);

      localStorageManager.setItem(HIDDEN_RESOURCE_PLANNING_MEMBERS_IDS, newValue);
      return newValue;
    });
  }, []);

  const onShowAllMember = useCallback(() => {
    setHiddenMembersIds([]);
    localStorageManager.setItem(HIDDEN_RESOURCE_PLANNING_MEMBERS_IDS, []);
  }, []);

  const getHiddenMembers = useCallback(
    (members: ResourcePlanningMembersDataFragment[]) => {
      if (!hiddenMembersIds.length) {
        return [];
      }

      return members?.filter(({ id }) => hiddenMembersIds.includes(id)) || [];
    },
    [hiddenMembersIds],
  );

  return { hiddenMembersIds, onHideMember, onShowMember, onShowAllMember, getHiddenMembers };
};
