import React, { CSSProperties, FC, ReactNode } from 'react';
import { AnimatableStyles, RouteTransition, spring } from 'react-router-transition';
import { Switch } from 'react-router-dom';

import styles from './SlideSwitch.module.scss';

const mapStyles = (styles: CSSProperties): AnimatableStyles => {
  return {
    opacity: styles?.opacity,
    transform: `translateY(${styles?.offset}px)`,
    display: 'flex',
    flexDirection: 'column',
  };
};

const bounce = (val: number) => {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
};

const bounceTransition = {
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

interface Props {
  children: ReactNode;
}

export const SlideSwitch: FC<Props> = ({ children }) => {
  return (
    <RouteTransition
      atEnter={bounceTransition.atEnter}
      atLeave={bounceTransition.atLeave}
      atActive={bounceTransition.atActive}
      className={styles.routeWrapper}
      mapStyles={mapStyles}
    >
      <Switch>{children}</Switch>
    </RouteTransition>
  );
};
