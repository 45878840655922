import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { startOfWeek } from 'date-fns';
import clsx from 'clsx';
import { Button, ButtonGroup, Tooltip } from '@material-ui/core';
import { TimelinePeriod, useTimelineContext } from 'contexts';
import { MONTH_PERIOD, QUARTER_PERIOD, YEAR_PERIOD } from 'consts';

import { TimelineFilters } from '../Filters';
import {
  getNextPeriodDate,
  getNextQuarter,
  getNextWeekPeriodDate,
  getNextYear,
  getPreviousPeriodDate,
  getPreviousWeekPeriodDate,
  getPrevQuarter,
  getPrevYear,
} from '../../utils';

import { NavArrowLeftIcon, NavDoubleArrowLeftIcon } from 'icons';
import styles from './TimelineSettings.module.scss';

type TimelineSettings = {
  date: Date;
  onNavigate: (date: Date) => void;
};

const getNextNavLabel = (timelinePeriod: TimelinePeriod) => {
  switch (timelinePeriod) {
    case YEAR_PERIOD:
      return 'nextYear';
    case QUARTER_PERIOD:
      return 'nextQuarter';
    default:
      return 'nextWeek';
  }
};

const getPrevNavLabel = (timelinePeriod: TimelinePeriod) => {
  switch (timelinePeriod) {
    case YEAR_PERIOD:
      return 'prevYear';
    case QUARTER_PERIOD:
      return 'prevQuarter';
    default:
      return 'prevWeek';
  }
};

const getNextTimePeriod = (timelinePeriod: TimelinePeriod, date: Date) => {
  switch (timelinePeriod) {
    case YEAR_PERIOD:
      return getNextYear(date);
    case QUARTER_PERIOD:
      return getNextQuarter(date);
    default:
      return getNextWeekPeriodDate(date);
  }
};

const getPrevTimePeriod = (timelinePeriod: TimelinePeriod, date: Date) => {
  switch (timelinePeriod) {
    case YEAR_PERIOD:
      return getPrevYear(date);
    case QUARTER_PERIOD:
      return getPrevQuarter(date);
    default:
      return getPreviousWeekPeriodDate(date);
  }
};

export const TimelineSettings: FC<TimelineSettings> = ({ onNavigate, date }) => {
  const { t } = useTranslation();
  const { timelinePeriod, onChangeTimelinePeriod } = useTimelineContext();

  const isMonthView = timelinePeriod === MONTH_PERIOD;

  return (
    <>
      <section className={styles.timelineSettings}>
        <TimelineFilters />
      </section>

      <section className={clsx(styles.timelineSettings, styles.timelineSettingsSecondRow)}>
        <div className={styles.timelineSettingsNav}>
          <ButtonGroup aria-label="contained period nav button group" className={styles.timelineSettingsNavButtonGroup}>
            <Button
              color="inherit"
              variant="text"
              onClick={() => onChangeTimelinePeriod(MONTH_PERIOD)}
              className={clsx('h-auto', styles.timelineSettingsNavTimePeriod, {
                [styles.timelineSettingsNavTimePeriodActive]: isMonthView,
              })}
            >
              {t('resourcePlanning.timeline.period.month')}
            </Button>
            <Button
              color="inherit"
              variant="text"
              onClick={() => onChangeTimelinePeriod(QUARTER_PERIOD)}
              className={clsx('h-auto', styles.timelineSettingsNavTimePeriod, {
                [styles.timelineSettingsNavTimePeriodActive]: timelinePeriod === QUARTER_PERIOD,
              })}
            >
              {t('resourcePlanning.timeline.period.quarter')}
            </Button>
            <Button
              color="inherit"
              variant="text"
              onClick={() => onChangeTimelinePeriod(YEAR_PERIOD)}
              className={clsx('h-auto', styles.timelineSettingsNavTimePeriod, {
                [styles.timelineSettingsNavTimePeriodActive]: timelinePeriod === YEAR_PERIOD,
              })}
            >
              {t('resourcePlanning.timeline.period.year')}
            </Button>
          </ButtonGroup>
        </div>

        <div className={styles.timelineSettingsNav}>
          <ButtonGroup aria-label="contained period nav button group" className={styles.timelineSettingsNavButtonGroup}>
            {isMonthView && (
              <Tooltip title={t('resourcePlanning.timeline.navigation.prevPeriod')!} placement="top">
                <Button
                  color="inherit"
                  variant="text"
                  onClick={() => onNavigate(getPreviousPeriodDate(date))}
                  className="h-auto"
                >
                  <NavDoubleArrowLeftIcon />
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={t(`resourcePlanning.timeline.navigation.${getPrevNavLabel(timelinePeriod)}`)!}
              placement="top"
            >
              <Button
                color="inherit"
                variant="text"
                onClick={() => onNavigate(getPrevTimePeriod(timelinePeriod, date))}
                className="h-auto"
              >
                <NavArrowLeftIcon />
              </Button>
            </Tooltip>

            <Button
              onClick={() => onNavigate(startOfWeek(new Date(), { weekStartsOn: 1 }))}
              color="inherit"
              variant="text"
              className={clsx('h-auto', styles.timelineSettingsNavTimePeriod)}
            >
              {t('resourcePlanning.timeline.days.today')}
            </Button>

            <Tooltip
              title={t(`resourcePlanning.timeline.navigation.${getNextNavLabel(timelinePeriod)}`)!}
              placement="top"
            >
              <Button
                color="inherit"
                variant="text"
                onClick={() => onNavigate(getNextTimePeriod(timelinePeriod, date))}
                className="h-auto"
              >
                <NavArrowLeftIcon className={styles.timelineSettingsNavNext} />
              </Button>
            </Tooltip>
            {isMonthView && (
              <Tooltip title={t('resourcePlanning.timeline.navigation.nextPeriod')!} placement="top">
                <Button
                  color="inherit"
                  variant="text"
                  onClick={() => onNavigate(getNextPeriodDate(date))}
                  className="h-auto"
                >
                  <NavDoubleArrowLeftIcon className={clsx(styles.timelineSettingsNavNext)} />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
        </div>
      </section>
    </>
  );
};
