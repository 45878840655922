import { endOfDay, isAfter } from 'date-fns';
import { ProjectMembershipDataFragment } from 'generated/types';

export const getSortedProjectMemberships = (data: ProjectMembershipDataFragment[]) =>
  [...data]
    .sort((a, b) => {
      if (
        a.endDate &&
        b.endDate &&
        isAfter(new Date(), endOfDay(new Date(a.endDate))) &&
        isAfter(new Date(), endOfDay(new Date(b.endDate)))
      ) {
        return 0;
      }

      if (a.endDate && isAfter(new Date(), endOfDay(new Date(a.endDate)))) {
        return 1;
      }

      if (b.endDate && isAfter(new Date(), endOfDay(new Date(b.endDate)))) {
        return -1;
      }

      return `${a.memberFirstName} ${a.memberLastName}`.localeCompare(`${b.memberFirstName} ${b.memberLastName}`);
    })
    .sort((a) => {
      if (a.pmId === a.memberId && (!a.endDate || !isAfter(new Date(), endOfDay(new Date(a.endDate))))) {
        return -1;
      }

      return 0;
    });
