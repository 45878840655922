import jwt_decode from 'jwt-decode';
import { GraphQLError } from 'graphql';

import {
  jwt_expired,
  invalid_token,
  invalid_header,
  jwt_malformed,
  jwt_signature_is_required,
  invalid_signature,
  invalid_algorithm,
} from 'consts';

interface DecodedJwtInterface {
  exp: number;
  iat: number;
  kid: string;
  sub: string;
}

const isValidToken = (token: string) => {
  try {
    const decoded = jwt_decode(token);

    return !!decoded;
  } catch (e) {
    console.error(e);

    return false;
  }
};

const isExpiredToken = (token: string): boolean => {
  try {
    const { exp } = jwt_decode(token) as DecodedJwtInterface;
    const now = Date.now();

    // pls clarify final tolerance to expiration on BE
    return now > exp * 1000;
  } catch (e) {
    console.error(e);

    return false;
  }
};

const isTokenExpiredError = (errors: GraphQLError | readonly GraphQLError[]) => {
  if (Array.isArray(errors)) {
    return !!errors.find((error) => error?.message === jwt_expired);
  } else {
    return (errors as GraphQLError)?.message === jwt_expired;
  }
};

const isTokenInvalidError = (errors: GraphQLError | readonly GraphQLError[]) => {
  if (Array.isArray(errors)) {
    return !!errors.find((error) => error?.message === invalid_token);
  } else {
    return (errors as GraphQLError)?.message === invalid_token;
  }
};

const notRefreshableAuthIssues = (errors: GraphQLError | readonly GraphQLError[]) => {
  const nonRefreshableIssues = new Set([
    invalid_token,
    invalid_header,
    jwt_malformed,
    jwt_signature_is_required,
    invalid_signature,
    invalid_algorithm,
  ]);

  if (Array.isArray(errors)) {
    return !!errors.find((error) => nonRefreshableIssues.has(error?.message));
  } else {
    return nonRefreshableIssues.has((errors as GraphQLError)?.message);
  }
};

export { isValidToken, isExpiredToken, isTokenExpiredError, notRefreshableAuthIssues, isTokenInvalidError };
