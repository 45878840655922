import React, { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isAfter, isSameDay } from 'date-fns';
import clsx from 'clsx';
import { getAssignmentDate, getAssignmentFromRequest, getGroupedRowAssignments } from 'views/ResourcePlanning/utils';
import { AssignmentRowItem } from 'views/ResourcePlanning/types';
import { AssignmentsRow } from './AssignmentsRow';

import { Boundaries } from 'hooks';
import { generateRandomId } from 'utils';
import { ResourcePlanningProjectsDataFragment } from 'generated/types';

import styles from './styles.module.scss';

type Props = {
  date: Date;
  project: ResourcePlanningProjectsDataFragment;
  memberId?: string;
};

export type AssignmentCell = {
  start?: Date;
  amount?: number;
  assigned?: boolean;
  hours?: number;
  continues?: boolean;
  last?: boolean;
  color?: string;
  id?: string;
};

export const MemberAssignments = memo<Props>(({ project, date, memberId }) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [boundaries, setBoundaries] = useState<Boundaries>({ left: 0, right: 0 });

  const assignmentsSortedByCreatedDate = useMemo<AssignmentRowItem[]>(() => {
    const assignments = (project.assignment || []) as AssignmentRowItem[];
    const requestedAssignments = (project.requests || []).map(getAssignmentFromRequest) || [];

    return [...assignments, ...requestedAssignments].sort((itemA, itemB) => {
      const startDateA = getAssignmentDate(itemA?.startDate);
      const startDateB = getAssignmentDate(itemB?.startDate);

      if (isSameDay(startDateB, startDateA)) return 0;

      return isAfter(startDateB, startDateA) ? -1 : 1;
    });
  }, [project]);

  const memoBound = useMemo(() => boundaries, [boundaries]);

  useLayoutEffect(() => {
    if (sectionRef?.current && sectionRef?.current?.getBoundingClientRect()) {
      const { left, right } = sectionRef.current?.getBoundingClientRect();
      setBoundaries({ left, right });
    }
  }, []);

  const assignmentRows = useMemo(() => getGroupedRowAssignments(assignmentsSortedByCreatedDate), [
    assignmentsSortedByCreatedDate,
  ]);

  return (
    <>
      {assignmentRows.map((items: AssignmentRowItem[] | undefined) => (
        <div key={generateRandomId()}>
          {items?.length && (
            <section
              className={clsx(styles.assignment)}
              ref={sectionRef}
              onDragOver={(e) => {
                e.preventDefault();
                e!.dataTransfer!.dropEffect = 'move';
              }}
            >
              <AssignmentsRow
                projectId={project.id}
                color={project.color}
                boundaries={memoBound}
                memberId={memberId}
                assignments={items}
                date={date}
              />
            </section>
          )}
        </div>
      ))}
    </>
  );
});

MemberAssignments.displayName = 'Timeline.MemberAssignments';
