import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  EditProjectMembershipAccessDate,
  ProjectMembershipAccessMenu,
  RightDrawer,
  SIZES,
  Tooltip,
} from 'components';
import { useErrorMsgBuilder, usePermissions, useProjectMembershipSubmit } from 'hooks';
import { ProjectByIdDocument, useProjectMembershipsQuery } from 'generated/graphql';
import { ActionsType, ProjectAccess } from 'generated/types';
import { getAcronym, getSortedProjectMemberships, graphqlOnError } from 'utils';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { useAuth } from 'contexts';
import { endOfDay, format, isAfter } from 'date-fns';
import { CreateProjectMembership, ShareProjectFormValues } from './components/CreateProjectMembership';
import { ApolloError } from '@apollo/client/errors';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface EditData {
  startDate?: string;
  endDate?: string;
  accessLevel: ProjectAccess;
  projectMembershipId: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  pmId: string;
  isNonBillableProject?: boolean;
}

export const SharedProjects: FC<Props> = ({ isOpen, onClose, projectId, pmId, isNonBillableProject }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess } = usePermissions({ projectId });

  const { data: { projectMemberships = [] } = {}, loading } = useProjectMembershipsQuery({
    onError: (err: ApolloError) => {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      data: { projectId },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !isOpen || !hasAccess(ActionsType.ShareProject),
  });
  const userIdsWithAccess = useMemo(() => projectMemberships.map(({ memberId }) => memberId), [projectMemberships]);
  const sortedProjectMemberships = useMemo(() => getSortedProjectMemberships(projectMemberships), [projectMemberships]);

  const {
    editProjectPm,
    createProjectMembership,
    editProjectMembership,
    deleteProjectMembership,
    createLoading,
  } = useProjectMembershipSubmit({ pmId });

  const onDelete = (projectMembershipId: string) =>
    deleteProjectMembership({
      variables: {
        companyId: userData!.company.id,
        projectId,
        projectMembershipId,
      },
    });

  const onCreateProjectMembership = async ({
    members,
    startDate,
    endDate,
    accessLevel,
    assignAsPM,
  }: ShareProjectFormValues) => {
    if (assignAsPM) {
      await editProjectPm({
        variables: {
          companyId: userData!.company.id,
          memberId: members[0].id,
          data: {
            projectsMembership: [{ accessLevel: accessLevel.id, projectId }],
            startDate: startDate ? format(new Date(startDate), DEFAULT_DATE_FORMAT) : undefined,
            endDate: endDate ? format(new Date(endDate), DEFAULT_DATE_FORMAT) : undefined,
          },
        },
        refetchQueries: ['ProjectMemberships', ProjectByIdDocument],
      });
      return;
    }

    await createProjectMembership({
      variables: {
        companyId: userData!.company.id,
        data: {
          projectId,
          membersId: members.map(({ id }) => id),
          startDate: startDate ? format(new Date(startDate), DEFAULT_DATE_FORMAT) : undefined,
          endDate: endDate ? format(new Date(endDate), DEFAULT_DATE_FORMAT) : undefined,
          accessLevel: accessLevel.id,
        },
      },
    });
  };

  const onEditProjectMembership = async ({ startDate, endDate, accessLevel, projectMembershipId }: EditData) => {
    await editProjectMembership({
      variables: {
        companyId: userData!.company.id,
        projectId,
        projectMembershipId,
        data: {
          startDate: startDate ? format(new Date(startDate), DEFAULT_DATE_FORMAT) : undefined,
          endDate: endDate ? format(new Date(endDate), DEFAULT_DATE_FORMAT) : undefined,
          accessLevel,
        },
      },
    });
  };

  if (!hasAccess(ActionsType.ShareProject)) {
    return null;
  }

  return (
    <RightDrawer open={isOpen} onClose={onClose} boxClassName={styles.drawer} title={t('shareProject.title')}>
      <div className={styles.container}>
        <CreateProjectMembership
          selectedUserIds={userIdsWithAccess}
          onSubmit={onCreateProjectMembership}
          loading={createLoading}
          isNonBillableProject={isNonBillableProject}
        />

        <h3 className={styles.listTitle}>{t('shareProject.projectCollaborators')}</h3>
        {!projectMemberships.length && loading ? (
          <div className="flex justify-content-center py-48">
            <CircularProgress color="inherit" size={16} />
          </div>
        ) : (
          ''
        )}
        {sortedProjectMemberships.map(
          ({ memberFirstName, memberLastName, memberId, pmId, color, accessLevel, id, startDate, endDate }) => (
            <div
              key={memberId}
              className={clsx(
                styles.memberItem,
                endDate && isAfter(new Date(), endOfDay(new Date(endDate))) && styles.memberOpacity,
              )}
            >
              <div className="flex align-items-center">
                <Avatar
                  avatarTitle={getAcronym(memberFirstName, memberLastName)}
                  size={SIZES.sm}
                  className="mr-8"
                  color={color}
                />
                <Tooltip
                  title={`${memberFirstName} ${memberLastName} ${pmId === memberId ? `(${t('roles.user')})` : ''}`}
                  textClassName={styles.memberItemTitle}
                >
                  {`${memberFirstName} ${memberLastName} ${pmId === memberId ? `(${t('roles.user')})` : ''}`}
                </Tooltip>
                <div className="ml-auto">
                  <ProjectMembershipAccessMenu
                    value={accessLevel}
                    onChange={({ id: accessLevel }) =>
                      onEditProjectMembership({ startDate, endDate, accessLevel, projectMembershipId: id })
                    }
                    onDelete={pmId !== memberId ? () => onDelete(id) : undefined}
                    isNonBillableProject={isNonBillableProject}
                  />
                </div>
              </div>
              <div className={styles.memberDate}>
                <EditProjectMembershipAccessDate
                  accessLevel={accessLevel}
                  startDate={startDate}
                  endDate={endDate}
                  onEdit={({ startDate, endDate }) =>
                    onEditProjectMembership({ startDate, endDate, accessLevel, projectMembershipId: id })
                  }
                  showConfirm
                />
              </div>
            </div>
          ),
        )}
      </div>
    </RightDrawer>
  );
};
