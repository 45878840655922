import React, { FC } from 'react';

import styles from './styles.module.scss';
import clsx from 'clsx';

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

interface Props {
  suffix?: string;
  prefix?: string;
  size?: Size;
  value: number;
  digitsAfterDot?: number;
  autoDigitsAfterDot?: boolean;
  formatNumberView?: (value: string | number) => string;
  className?: string;
  isCommaStyle?: boolean;
}

export const StyledNumberValue: FC<Props> = ({
  value,
  size = Size.small,
  digitsAfterDot = 1,
  autoDigitsAfterDot = true,
  prefix,
  suffix,
  formatNumberView,
  className,
  isCommaStyle,
}) => {
  const roundedValue = Math.round(value * 10 ** digitsAfterDot) / 10 ** digitsAfterDot;
  const integerValue = roundedValue
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, isCommaStyle ? ',' : ' ')
    .split('.')[0];
  const decimalValue = (autoDigitsAfterDot ? roundedValue : roundedValue.toFixed(digitsAfterDot))
    .toString()
    .split('.')[1];

  return (
    <div className={clsx(styles.container, className)}>
      <span
        className={clsx({
          [styles.largePrefix]: size === Size.large,
          [styles.mediumPrefix]: size === Size.medium,
          [styles.smallPrefix]: size === Size.small,
        })}
      >
        {prefix}
      </span>
      <span
        className={clsx({
          [styles.largeInteger]: size === Size.large,
          [styles.mediumInteger]: size === Size.medium,
          [styles.smallInteger]: size === Size.small,
        })}
      >
        {formatNumberView ? formatNumberView(integerValue) : integerValue}
      </span>
      {decimalValue && (
        <span
          className={clsx({
            [styles.largeDecimal]: size === Size.large,
            [styles.mediumDecimal]: size === Size.medium,
            [styles.smallDecimal]: size === Size.small,
          })}
        >
          .{formatNumberView ? formatNumberView(decimalValue) : decimalValue}
        </span>
      )}
      <span
        className={clsx({
          [styles.largeSuffix]: size === Size.large,
          [styles.mediumSuffix]: size === Size.medium,
          [styles.smallSuffix]: size === Size.small,
        })}
      >
        {suffix}
      </span>
    </div>
  );
};
