import React from 'react';

interface LogoutIconProps {
  className?: string;
}

export const LogoutIcon = ({ className }: LogoutIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4211 20.8947C10.4211 20.3134 9.94977 19.8421 9.36842 19.8421H4.10526V5.10526H9.36842C9.94977 5.10526 10.4211 4.63398 10.4211 4.05263C10.4211 3.47128 9.94977 3 9.36842 3H4.10526C2.94256 3 2 3.94256 2 5.10526V19.8421C2 21.0048 2.94256 21.9474 4.10526 21.9474H9.36842C9.94977 21.9474 10.4211 21.4761 10.4211 20.8947Z"
        fill="#B1BFC7"
      />
      <path
        d="M21.6985 13.2111C21.7961 13.1118 21.8699 12.9979 21.9201 12.8766C21.9712 12.7534 21.9996 12.6184 22 12.4768L22 12.4737L22 12.4705C21.9992 12.2022 21.8964 11.9341 21.6917 11.7294L17.4812 7.51884C17.0701 7.10776 16.4036 7.10776 15.9925 7.51884C15.5814 7.92991 15.5814 8.5964 15.9925 9.00748L18.4061 11.4211H8.31579C7.73444 11.4211 7.26316 11.8923 7.26316 12.4737C7.26316 13.055 7.73444 13.5263 8.31579 13.5263H18.4061L15.9925 15.9399C15.5814 16.351 15.5814 17.0175 15.9925 17.4285C16.4036 17.8396 17.0701 17.8396 17.4812 17.4285L21.6909 13.2188L21.6985 13.2111Z"
        fill="#B1BFC7"
      />
    </svg>
  );
};
