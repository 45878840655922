import React, { MutableRefObject, ReactNode } from 'react';
import { VariableSizeList, VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CircularProgress } from '@material-ui/core';
import { EmptyState } from 'components';

import clsx from 'clsx';
import styles from './styles.module.scss';

interface AutoSizerProps {
  height: number;
  width: number;
}

type GetItemSize = (index: number) => number;
export type GetItemSizeCallback<D> = (value: D) => GetItemSize;

interface Props<D extends Record<string, unknown> = Record<string, never>> {
  items: D[];
  renderItem: (item: D) => ReactNode;
  loading?: boolean;
  getListItemSize?: GetItemSizeCallback<D>;
  fetchMoreItems?: () => void;
  hasMoreItems?: boolean;
  className?: string;
  listRef?: MutableRefObject<VariableSizeList<{ width: number }> | null>;
}

const ESTIMATED_ITEM_HEIGHT = 150;
const emptyFunction = () => {
  // empty
};

export const InfiniteScrollList = <D extends Record<string, unknown> = Record<string, never>>({
  items,
  renderItem,
  loading,
  getListItemSize,
  fetchMoreItems,
  hasMoreItems,
  className,
  listRef,
}: Props<D>) => {
  const renderRow = ({ index, style, data }: any) => {
    const item = items[index];
    if (!item) return <div />;

    return (
      <div
        key={`list-item-${item.id}`}
        style={{ ...style, height: ESTIMATED_ITEM_HEIGHT, right: 0, width: data.width }}
      >
        {renderItem(item)}
      </div>
    );
  };

  if (loading) {
    return <CircularProgress size={64} style={{ color: '#24343D' }} />;
  }

  if (!items.length) {
    return <EmptyState className="mt-40" />;
  }

  const itemCount = hasMoreItems ? items.length + 1 : items.length;
  const isItemLoaded = (index: number) => !hasMoreItems || index < items.length;
  const getItemSize = (index: number) => {
    const item = items[index];

    if (!item) return 0;

    if (getListItemSize) {
      return getListItemSize(item)(index);
    }

    return ESTIMATED_ITEM_HEIGHT;
  };

  return (
    <div className={clsx(styles.box, className)}>
      <AutoSizer>
        {({ height, width }: AutoSizerProps) => (
          <div className={styles.infiniteScrollTBody} style={{ height: '100vh', width }}>
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={fetchMoreItems || emptyFunction}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={height}
                  itemCount={itemCount}
                  itemSize={getItemSize}
                  estimatedItemSize={ESTIMATED_ITEM_HEIGHT}
                  onItemsRendered={onItemsRendered}
                  ref={(list) => {
                    ref(list);

                    if (listRef) {
                      listRef.current = list;
                    }
                  }}
                  width={width}
                  itemData={{ width }}
                  style={{ overflowX: 'hidden' }}
                >
                  {renderRow}
                </List>
              )}
            </InfiniteLoader>
          </div>
        )}
      </AutoSizer>
    </div>
  );
};
