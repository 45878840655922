import React, { memo } from 'react';
import { format, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { LeaveIcon } from 'icons';
import { QUARTER_PERIOD } from 'consts';
import { useTimelineContext } from 'contexts';

import { WeeklyViewLeave } from '../../utils';

import styles from './styles.module.scss';

type DefaultWeekLabelProps = {
  totalHours: number;
  capacityPercent: number;
  shortLeaveWeek: boolean;
  mixedWeek: boolean;
  leaveHours?: number;
  workingHours?: number;
};

export const DefaultWeekLabel = memo<DefaultWeekLabelProps>(
  ({ totalHours, capacityPercent, leaveHours, shortLeaveWeek, mixedWeek, workingHours }) => {
    const { timelinePeriod } = useTimelineContext();
    const { t } = useTranslation();

    const isQuarterView = timelinePeriod === QUARTER_PERIOD;

    const mainTitle = isQuarterView
      ? t('resourcePlanning.quarterViewWeeklyCellLabel', { totalHours })
      : t('resourcePlanning.weeklyCellLabel', { totalHours, capacityPercent });

    const leaveDescription = leaveHours && (
      <>
        •{t('resourcePlanning.hours', { hours: leaveHours })}
        <LeaveIcon />
      </>
    );

    return (
      <span>
        <span>
          {mainTitle}
          {shortLeaveWeek && <LeaveIcon />}
        </span>
        {mixedWeek && leaveHours && workingHours && <span>{leaveDescription}</span>}
      </span>
    );
  },
);
export const LeaveWeekLabel = memo(() => {
  const { t } = useTranslation();
  return (
    <span className="gap-4">
      <LeaveIcon />
      {t('resourcePlanning.onLeave')}
    </span>
  );
});

type TooltipContentProps = {
  totalHours: number;
  leaveDays?: { [key: string]: WeeklyViewLeave };
};

export const TooltipContent = memo<TooltipContentProps>(({ totalHours, leaveDays }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.weeklyCellTooltipContent}>
      <span>
        {t('resourcePlanning.tooltipTotalHoursAssigned', {
          totalHours,
        })}
      </span>
      {leaveDays && (
        <>
          <br />
          {Object.keys(leaveDays).map((id) => {
            const startDate = format(new Date(leaveDays[id].start), 'dd/MM/yyyy');
            const endDate = format(new Date(leaveDays[id].end), 'dd/MM/yyyy');

            if (isSameDay(leaveDays[id].start, leaveDays[id].end)) {
              return (
                <span key={id}>
                  {startDate}
                  <LeaveIcon />
                </span>
              );
            }

            return (
              <span key={id}>
                {`${startDate}-${endDate}`}
                <LeaveIcon />
              </span>
            );
          })}
        </>
      )}
    </div>
  );
});

TooltipContent.displayName = 'PerWeekView.WeeklyCell.TooltipContent';
LeaveWeekLabel.displayName = 'PerWeekView.WeeklyCell.LeaveWeekLabel';
DefaultWeekLabel.displayName = 'PerWeekView.WeeklyCell.DefaultWeekLabel';
