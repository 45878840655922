import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { AvailableDomains } from 'generated/types';

export const ActivityTypeAll = 'all';

export const ActivityHistoryRoute = Route(
  {
    type: Route.query.oneOf(ActivityTypeAll, ...Object.values(AvailableDomains)),
  },
  '/settings/activity-log/',
);

export const ActivityHistory = ActivityHistoryRoute(
  lazy(() => import(/* webpackChunkName: "ActivityHistory" */ './ActivityHistory')),
);
