import { Object as ObjectType } from 'ts-toolbelt';
import { ResourcePlanningMember } from 'generated/types';
import { AssignmentPeriod, TimelineAvailability } from '../components/Availability';
import { DEFAULT_CAPACITY } from 'consts';

export type WeeklyViewLeave = {
  start: Date;
  end: Date;
};

export type WeeklyAvailability = {
  start?: Date;
  workingHours?: number;
  leaveHours?: number;
  amount?: number;
  weekend?: boolean;
  leaveId?: string;
  leaveDays?: { [key: string]: WeeklyViewLeave };
  assignmentPeriod?: AssignmentPeriod;
  publicHolidays?: string[];
};

export type AvailabilityPerWeek = {
  [key: string]: WeeklyAvailability;
};

const getPublicHolidays = (isHoliday: boolean, date?: Date, accPublicHolidays?: string[]): string[] | undefined => {
  if (isHoliday && date && accPublicHolidays) return [...accPublicHolidays, date.toString()];
  if (isHoliday && date) return [date.toString()];
  if (accPublicHolidays) return accPublicHolidays;
  return undefined;
};

const getLeaveDays = (
  leaveId?: string,
  date?: Date,
  accLeaveDays?: { [key: string]: WeeklyViewLeave },
): { [key: string]: WeeklyViewLeave } | undefined => {
  if (leaveId && date && accLeaveDays && accLeaveDays[leaveId]) {
    return { ...accLeaveDays, [leaveId]: { start: accLeaveDays[leaveId].start, end: date } };
  }
  if (leaveId && date && accLeaveDays) {
    return { ...accLeaveDays, [leaveId]: { start: date, end: date } };
  }
  if (leaveId && date) {
    return { [leaveId]: { start: date, end: date } };
  }
  if (accLeaveDays) {
    return accLeaveDays;
  }
  return undefined;
};

const getWorkingHours = (accHours?: number, recHours?: number): number | undefined => {
  if (accHours && recHours) return accHours + recHours;
  if (accHours) return accHours;
  if (recHours) return recHours;
  return undefined;
};

const getLeaveHours = (accHours?: number, leave?: boolean): number | undefined => {
  if (accHours && leave) return accHours + DEFAULT_CAPACITY;
  if (accHours) return accHours;
  if (leave) return DEFAULT_CAPACITY;
  return undefined;
};

export const getAvailabilityByWeek = (availability: TimelineAvailability[], holidays: Set<string>) => {
  return availability
    .filter((day) => !('amount' in day) || holidays.has(day?.start ? day.start.toString() : 'undefined'))
    .reduce<WeeklyAvailability>((acc, day) => {
      const isHoliday = holidays.has(day?.start ? day.start.toString() : '');

      const leaveId = day.leaveId;
      const assignmentPeriod = day.assignmentPeriod;
      const workingHours = getWorkingHours(acc.workingHours, day?.hours ?? day.assignedAllocation);
      const leaveHours = getLeaveHours(acc.leaveHours, day.leave);
      const publicHolidays = getPublicHolidays(isHoliday, day.start, acc.publicHolidays);
      const leaveDays = getLeaveDays(leaveId, day.start, acc.leaveDays);

      return {
        ...acc,
        workingHours,
        leaveHours,
        leaveId,
        assignmentPeriod,
        publicHolidays,
        leaveDays,
      };
    }, {});
};

export const isResourcePlanningMembers = (member: any): member is ObjectType.Partial<ResourcePlanningMember, 'deep'> =>
  member && 'projects' in member;
