import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import { idRegEx } from 'utils/regex';
import { Tags } from './Tags';

export const ProjectTagsRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    tagId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, tagId }) => `/settings/project-settings/tag/${mode}/${tagId}`,
);

export const ProjectTags = ProjectTagsRoute(Tags);
