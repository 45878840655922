import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import React, {
  forwardRef,
  memo,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GetProps } from 'react-router-hoc/lib/types';
import ReactDatePicker, { CalendarContainer, ReactDatePickerProps } from 'react-datepicker';
import { format, parse, startOfDay } from 'date-fns';
import { useDeviceTypeByWidth } from 'hooks';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.module.scss';
import { Arrow, CalendarIcon, ClearInputIcon } from 'icons';
import clsx from 'clsx';

const getDate = (date?: Value<boolean>): Date | [Date | null, Date | null] | null => {
  if (Array.isArray(date)) {
    return date.map((item) => (item !== null ? new Date(item) : null)) as [Date | null, Date | null];
  }
  if (date) {
    return new Date(date as any);
  }

  return (date as unknown) as null;
};

const DateInput = forwardRef<HTMLInputElement, GetProps<typeof TextField>>(({ ...props }, ref) => {
  return <TextField {...props} ref={ref} />;
});

DateInput.displayName = 'DatePicker.Input';

type Value<R extends boolean> = R extends true
  ? readonly [Date | string | null, Date | string | null] | null | undefined
  : Date | string | undefined | null;

type Props<R extends boolean> = {
  range?: R;
  value?: Value<R>;
  onChange?: (value: Value<R>) => void;
};

function GenericMemoComponent<R extends boolean>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: Props<R> &
    Omit<ReactDatePickerProps, 'value' | 'name' | 'onChange'> &
    Omit<GetProps<typeof TextField>, 'variant' | 'onChange'> & {
      hideCalendarIcon?: boolean;
    },
): ReactElement | null {
  return (undefined as unknown) as ReactElement | null;
}

type SelectedDate = Date | [Date | null, Date | null] | null;

export const DatePicker =
  // eslint-disable-next-line react/display-name
  memo(
    ({
      value: initialValue,
      onChange,
      className,
      name,
      range = false,
      error,
      helperText,
      InputProps,
      customInput,
      placeholder,
      showMonthYearPicker = false,
      hideCalendarIcon = false,
      disabled,
      ...rest
    }) => {
      const [value, setValue] = useState<SelectedDate>(getDate(initialValue));
      const datePicker = useRef<ReactDatePicker | null>(null);
      const [monthView, setMonthView] = useState(showMonthYearPicker);
      const [month, setMonth] = useState<Date | null>(null);
      const { isMobileDevice } = useDeviceTypeByWidth();

      useEffect(() => {
        setValue(getDate(initialValue));
      }, [initialValue]);

      const onDateSelect = (date: SelectedDate, event?: MouseEvent<HTMLDivElement>) => {
        const isMonth = new Set((event?.target as HTMLDivElement)?.classList.values()).has(
          'react-datepicker__month-text',
        );
        if (isMonth && !showMonthYearPicker) {
          setMonthView(false);
          return setMonth(month);
        }
        // Fix date for picker
        const parsedDate = Array.isArray(date)
          ? (date.map((item) => item && startOfDay(item)) as SelectedDate)
          : date && startOfDay(date);
        setValue(parsedDate);
        onChange?.(parsedDate as any);
      };

      const onClickOutside = useCallback(() => {
        if ((datePicker.current?.state as any).open) {
          datePicker.current?.setOpen(false);
        }
        setValue(getDate(initialValue));
      }, [initialValue]);

      const props = useMemo(
        () =>
          Object.assign(
            {},
            rest,
            range
              ? {
                  startDate: month ?? (Array.isArray(value) ? value[0] : undefined),
                  endDate: Array.isArray(value) ? value[1] : undefined,
                }
              : undefined,
          ),
        [rest, range, value],
      );

      const calendars = isMobileDevice ? 1 : +monthView || +range + 1;

      const onInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!event.target.value) return;
        const [start, end] = event.target.value.split('-').map((dateString: string) => {
          const date = parse(dateString.trim(), 'dd/MM/yyyy', new Date());
          if (!isNaN(date.getDate())) return date;
        });
        if (start && end && +start <= +end) setValue([start, end]);
      };

      const onClose = () => {
        if (value !== initialValue) onChange?.(value);
      };

      const calendarContainer = useCallback(
        ({ children }) => (
          <CalendarContainer
            className={clsx(styles.datepickerContainer, !props.inline && styles.detached, styles.monthView)}
          >
            {children}
          </CalendarContainer>
        ),
        [],
      );

      return (
        <ReactDatePicker
          ref={datePicker as any}
          name={name}
          wrapperClassName={className}
          fixedHeight
          disabled={disabled}
          customInput={
            customInput ?? (
              <DateInput
                InputProps={{
                  disabled,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        edge="end"
                        disabled={disabled}
                        className={clsx(
                          ((!value || disabled) && 'hidden') || (Array.isArray(value) && !value[0] && 'hidden'),
                        )}
                        onClick={(e) => {
                          onChange?.(range ? [null, null] : null);
                          setValue(range ? [null, null] : null);
                          e.stopPropagation();
                        }}
                      >
                        <ClearInputIcon />
                      </IconButton>
                      {!hideCalendarIcon && (
                        <IconButton disabled={disabled} size="small" edge="end" className="ml-4">
                          <CalendarIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  ...InputProps,
                }}
                error={error}
                helperText={helperText}
              />
            )
          }
          shouldCloseOnSelect={showMonthYearPicker || !monthView}
          showPopperArrow={false}
          showMonthYearPicker={monthView}
          onClickOutside={onClickOutside}
          onCalendarClose={onClose}
          selected={Array.isArray(value) ? value[0] : value}
          dateFormat="dd/MM/yyyy"
          monthsShown={calendars}
          enableTabLoop={false}
          selectsRange={range}
          placeholderText={placeholder}
          showFourColumnMonthYearPicker
          onChangeRaw={onInputChange}
          onChange={onDateSelect}
          calendarStartDay={1}
          renderCustomHeader={({
            decreaseMonth,
            increaseMonth,
            increaseYear,
            decreaseYear,
            customHeaderCount,
            monthDate,
          }) => (
            <header className={styles.datepickerHeader}>
              <section>
                {customHeaderCount == 0 && (
                  <Button
                    className={styles.datepickerHeaderButton}
                    color="inherit"
                    variant="text"
                    onClick={monthView ? decreaseYear : decreaseMonth}
                  >
                    <Arrow />
                  </Button>
                )}
              </section>

              <Button
                disabled={showMonthYearPicker}
                color="inherit"
                variant="text"
                onClick={() => setMonthView(!monthView)}
                className={styles.yearHeader}
              >
                <h3>{format(monthDate, monthView ? 'yyyy' : 'MMMM, yyyy')}</h3>
              </Button>
              <section>
                {customHeaderCount === calendars - 1 && (
                  <Button
                    className={clsx(styles.datepickerHeaderButton, styles.datepickerHeaderButtonRight)}
                    color="inherit"
                    variant="text"
                    onClick={monthView ? increaseYear : increaseMonth}
                  >
                    <Arrow />
                  </Button>
                )}
              </section>
            </header>
          )}
          calendarContainer={calendarContainer}
          {...props}
        />
      );
    },
  ) as typeof GenericMemoComponent & { displayName?: string };

DatePicker.displayName = 'DatePicker';
