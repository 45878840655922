import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../contexts';
import { useTranslation } from 'react-i18next';

export const useSetupTracking = (): void => {
  const { userData } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (userData) {
      mixpanel.identify(userData.id);
      mixpanel.register({
        $email: userData.member.email,
        Role: t(`roles.${userData.type}`),
        'Organization ID': userData.company.id,
      });

      mixpanel.people.set_once({
        $email: userData.member.email,
        $first_name: userData.member.first_name,
        $last_name: userData.member.last_name,
        $name: `${userData.member.first_name} ${userData.member.last_name}`,
        Role: t(`roles.${userData.type}`),
        'Organization ID': userData.company.id,
      });
    }
  }, [userData]);
};
