import { endOfMonth, format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { DateRangeTypes } from 'types';

export const buildDateRangeQuery = (
  value: DateRangeTypes | [Date, Date],
  isMonthView?: boolean,
): { start?: string; end?: string; range?: DateRangeTypes } => {
  let queryData;

  if (Array.isArray(value)) {
    const [start, end] = value;

    queryData = {
      start: format(new Date(start), DEFAULT_DATE_FORMAT),
      end: format(isMonthView ? endOfMonth(new Date(end)) : new Date(end), DEFAULT_DATE_FORMAT),
    };
  } else {
    queryData = {
      range: value,
      start: undefined,
      end: undefined,
    };
  }

  return queryData;
};
