import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { InfoIcon } from 'icons';

export const PeopleForceInfoBox: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <InfoIcon className={styles.infoIcon} />
      <div className={styles.content}>
        <span className={styles.title}>{t('settings.integrations.peopleForceInfo.title')}</span>
        <ol className="ml-4 mb-8">
          <li>{t('settings.integrations.peopleForceInfo.firstStep')}</li>
          <li>{t('settings.integrations.peopleForceInfo.secondStep')}</li>
          <li>{t('settings.integrations.peopleForceInfo.thirdStep')}</li>
          <li>{t('settings.integrations.peopleForceInfo.fourthStep')}</li>
        </ol>
        <span>{t('settings.integrations.peopleForceInfo.conclusion')}</span>
      </div>
    </div>
  );
};
