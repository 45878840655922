import { Route } from 'react-router-hoc';
import { lazy } from 'react';

export const FilesRoute = Route(
  {
    search: Route.query.string,
    filterByProjectId: Route.query.array(Route.query.string),
    filterByTagId: Route.query.array(Route.query.string),
  },
  '/files',
);

export const Files = FilesRoute(lazy(() => import(/* webpackChunkName: "Files" */ './Files')));
