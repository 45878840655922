import { useEffect } from 'react';

export const useSetAppTitle = (pageTitle: null | string = ''): void => {
  useEffect(() => {
    document.title = pageTitle ? `${pageTitle} | Skelar` : 'Skelar';

    return () => {
      document.title = 'Skelar';
    };
  }, [pageTitle]);
};
