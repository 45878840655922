import React, { FC, ReactNode } from 'react';
import { LoadingButton, Modal } from 'components';
import { Button } from '@material-ui/core';
import styles from './styles.module.scss';
import { TrashIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface Props {
  title: string;
  isOpen: boolean;
  isDelete?: boolean;
  hideDeleteIcon?: boolean;
  hideCancelButton?: boolean;
  disabledSubmitButton?: boolean;
  isLoadingSubmitButton?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children: ReactNode;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  className?: string;
}

export const ConfirmModal: FC<Props> = ({
  title,
  submitButtonTitle,
  cancelButtonTitle,
  isDelete,
  hideDeleteIcon = false,
  hideCancelButton = false,
  disabledSubmitButton,
  isLoadingSubmitButton,
  className,
  isOpen,
  onSubmit,
  onClose,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Modal title={title} isOpen={isOpen} onCloseModal={onClose}>
      <div
        className={clsx(styles.confirmModal, className)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className={styles.content}>{children}</div>
        <div className={styles.buttonBox}>
          {!hideCancelButton && (
            <Button onClick={onClose} variant="outlined" color="secondary">
              {cancelButtonTitle || t('forms.cancel')}
            </Button>
          )}
          <LoadingButton
            onClick={onSubmit}
            className={clsx(isDelete && styles.deleteButton)}
            startIcon={isDelete && !hideDeleteIcon && <TrashIcon />}
            disabled={disabledSubmitButton}
            loading={isLoadingSubmitButton}
          >
            {submitButtonTitle || t(isDelete ? 'forms.delete' : 'forms.save')}
          </LoadingButton>
        </div>
      </div>
    </Modal>
  );
};
