import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { ScenarioTabs } from 'types';

export * from './Details';

export const ScenariosRoute = Route(
  {
    mode: Route.params.oneOf('create').optional,
    tab: Route.params.oneOf(...Object.values(ScenarioTabs)),
  },
  ({ mode, tab }) => `/scenarios/${tab}/${mode}`,
);

export const Scenarios = ScenariosRoute(lazy(() => import(/* webpackChunkName: "Scenarios" */ './Scenarios')));
