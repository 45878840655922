import React, { MouseEvent, useMemo, useState } from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import { IconButton, Button } from '@material-ui/core';
import { toast } from 'react-toastify';

import {
  RightDrawer,
  Table,
  ViewHeading,
  GeneratePayroll,
  GeneratePayrollValues,
  TableActionCell,
  ExpandedColumn,
} from 'components';
import { TableCell } from 'types';
import { COMPANY_CURRENCY, END_DATE, NAME, START_DATE } from 'consts';
import {
  addTimezoneOffset,
  formatByThousands,
  getCurrencySymbol,
  graphqlOnError,
  toShortFormat,
  valueToCurrency,
} from 'utils';
import { useCreatePayrollMutation, useDeletePayrollMutation, usePayrollsQuery } from 'generated/graphql';
import { Payroll } from 'generated/types';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { links } from 'App';
import { TrashIcon } from 'icons';

const PayrollRoute = Route({ search: Route.query.string }, '/payroll');

const Payrolls = () => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { data: { payrolls = [] } = {}, refetch, loading } = usePayrollsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData?.company.id as string,
    },
  });

  const [createPayroll] = useCreatePayrollMutation({
    onCompleted() {
      toast.success(t('payroll.payrollCreatedSuccessfully'));
      refetch();
      handleDialogClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deletePayroll] = useDeletePayrollMutation({
    onCompleted() {
      toast.success(t('payroll.payrollDeletedSuccessfully'));
      refetch();
      handleDialogClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const handleDialogClose = () => setOpenDialog(false);

  const handleDialogOpen = () => setOpenDialog(true);

  const handleSubmit = async ({ startDate, endDate }: GeneratePayrollValues) => {
    await createPayroll({
      variables: {
        companyId: userData?.company.id as string,
        interval: {
          start: addTimezoneOffset(startDate),
          end: addTimezoneOffset(endDate),
          // TODO
          name: '',
        },
      },
    });
  };

  const handleDeletePayroll = (payrollId: string) => {
    confirm(t('payroll.confirmDeletePayroll')) &&
      deletePayroll({
        variables: {
          companyId: userData?.company.id as string,
          payrollId,
        },
      });
  };

  const columns = useMemo<ExpandedColumn<Payroll>[]>(
    () => [
      {
        id: 'name',
        Header: t('columns.payroll.name')!,
        accessor: NAME,
        Cell: function specialization({
          row: {
            original: { name },
          },
        }: TableCell<Payroll>) {
          return <span className="weight-600">{name}</span>;
        },
      },
      {
        Header: t('columns.payroll.startDate')!,
        accessor: START_DATE,
        Cell: function startDate({
          row: {
            original: { start_date },
          },
        }: TableCell<Payroll>) {
          return toShortFormat(new Date(start_date));
        },
      },
      {
        Header: t('columns.payroll.endDate')!,
        accessor: END_DATE,
        Cell: function endDate({
          row: {
            original: { end_date },
          },
        }: TableCell<Payroll>) {
          return toShortFormat(new Date(end_date));
        },
      },
      {
        Header: t('columns.payroll.employeesNumber')!,
        accessor: 'employees',
        Cell: function employees({
          row: {
            original: { employees },
          },
        }: TableCell<Payroll>) {
          return <span className="weight-600">{formatByThousands(employees)}</span>;
        },
      },
      {
        Header: t('columns.payroll.total')!,
        accessor: 'total',
        Cell: function total({
          row: {
            original: { total },
          },
        }: TableCell<Payroll>) {
          return (
            <span className="weight-600">
              {`${getCurrencySymbol(COMPANY_CURRENCY)} ${formatByThousands(valueToCurrency(total).toFixed(2))}`}
            </span>
          );
        },
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: function action({
          row: {
            original: { id },
          },
          isHovered,
        }: TableCell<Payroll> & { isHovered?: boolean }) {
          return (
            <TableActionCell isHovered={isHovered}>
              <IconButton
                className="removeIconButton"
                size="small"
                onClick={(e: MouseEvent<HTMLElement>) => {
                  handleDeletePayroll(id);
                  e.preventDefault();
                }}
              >
                <TrashIcon />
              </IconButton>
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  const getRedirectToDetailsLink = (rowId: string) => links.ViewPayrollRecord({ id: rowId });

  return (
    <>
      <ViewHeading hasSmartBackBtn={false} label={t('payroll.label')}>
        <div className="flex align-self-stretch">
          <Button onClick={handleDialogOpen}>{t('payroll.generatePayroll')}</Button>
        </div>
      </ViewHeading>

      <div className="layout-content-wrapper">
        <Table
          data={payrolls as Payroll[]}
          columns={columns}
          loading={loading}
          getRedirectRowLink={getRedirectToDetailsLink}
        />
      </div>

      <RightDrawer direction="right" open={openDialog} onClose={handleDialogClose} title={t('payroll.generatePayroll')}>
        <GeneratePayroll onSubmit={handleSubmit} onCancel={handleDialogClose} submitLabel={t('actions.generate')} />
      </RightDrawer>
    </>
  );
};

export default PayrollRoute(Payrolls);
