import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  ConfirmModal,
  EditProjectMembershipAccessDate,
  ProjectMembershipAccessMenu,
  SIZES,
  Tooltip,
} from 'components';
import { getAcronym } from 'utils';
import { endOfDay, isAfter } from 'date-fns';
import clsx from 'clsx';
import { Member, ProjectAccess } from 'generated/types';
import { useFormik } from 'formik';
import { useIsOpen } from 'hooks';

import styles from './styles.module.scss';

type ProjectManager = Pick<Member, 'id' | 'first_name' | 'last_name' | 'color'>;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  pm: ProjectManager | null;
  onSubmit: (value: PMAccessFormState) => void;
  isNonBillableProject?: boolean;
}

export interface PMAccessFormState {
  accessLevel?: ProjectAccess;
  startDate?: string;
  endDate?: string;
}

export const PMUpdateConfirm: FC<Props> = ({ pm, isOpen, onClose, onSubmit, isNonBillableProject }) => {
  const { t } = useTranslation();
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen();
  const { handleSubmit, setValues, setFieldValue, values } = useFormik<PMAccessFormState>({
    initialValues: { accessLevel: ProjectAccess.FullAccess },
    onSubmit: (values) => {
      if (values.accessLevel === ProjectAccess.FullAccess && !values.startDate && !values.endDate && !isOpenConfirm) {
        onOpenConfirm();
        return;
      }

      onSubmit(values);
      onCloseConfirm();
    },
  });

  if (!pm) {
    return <div />;
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <ConfirmModal
        title={t('projects.projectManagerConfirm.title')}
        submitButtonTitle={t('forms.save')}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onClose={onClose}
        className={styles.confirmModal}
      >
        {t('projects.projectManagerConfirm.description')}
        <div
          className={clsx(
            styles.memberItem,
            values.endDate && isAfter(new Date(), endOfDay(new Date(values.endDate))) && styles.memberOpacity,
          )}
        >
          <div className="flex align-items-center">
            <Avatar
              avatarTitle={getAcronym(pm.first_name, pm.last_name)}
              size={SIZES.sm}
              className="mr-8"
              color={pm.color}
            />
            <Tooltip
              title={`${pm.first_name} ${pm.last_name} (${t('roles.user')})`}
              textClassName={styles.memberItemTitle}
            >
              {`${pm.first_name} ${pm.last_name} (${t('roles.user')})`}
            </Tooltip>
            <div className="ml-auto">
              <ProjectMembershipAccessMenu
                value={values.accessLevel}
                onChange={({ id: accessLevel }) => setFieldValue('accessLevel', accessLevel)}
                isNonBillableProject={isNonBillableProject}
              />
            </div>
          </div>
          <div className={styles.memberDate}>
            <EditProjectMembershipAccessDate
              showConfirm={false}
              accessLevel={values.accessLevel}
              startDate={values.startDate}
              endDate={values.endDate}
              onEdit={({ startDate, endDate }) => setValues({ ...values, startDate, endDate })}
            />
          </div>
        </div>
      </ConfirmModal>

      <ConfirmModal
        title={t('shareProject.create.grantFullAccess')}
        submitButtonTitle={t('shareProject.create.grantFullAccess')}
        isOpen={isOpenConfirm}
        onSubmit={handleSubmit}
        onClose={onCloseConfirm}
      >
        {t('shareProject.create.confirmMessage')}
      </ConfirmModal>
    </div>
  );
};
