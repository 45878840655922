import React, { MouseEvent, FC } from 'react';
import clsx from 'clsx';

import { Avatar, SIZES } from 'components';
import { Member } from 'generated/types';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import { getAcronym } from 'utils';
import { links } from 'App';
import { useHistory } from 'react-router-dom';

import styles from '../styles.module.scss';

export interface TeamDropdownItemProps {
  role: string;
  member: Pick<Member, 'id' | 'first_name' | 'last_name' | 'avatar_url' | 'color'>;
  handleClose: (e: MouseEvent<HTMLElement>) => void;
}

export const TeamDropdownItem: FC<TeamDropdownItemProps> = ({ member, role, handleClose }) => {
  const history = useHistory();
  const { id, first_name, last_name, avatar_url, color } = member;

  return (
    <MenuItem
      className={clsx('flex', 'align-items-center', 'py-6')}
      onClick={(e) => {
        e.stopPropagation();
        handleClose(e);
        history.push(links.ViewTeamMember({ id }));
      }}
    >
      <ListItemIcon className={styles.avatar}>
        <Avatar src={avatar_url || ''} avatarTitle={getAcronym(first_name, last_name)} size={SIZES.sm} color={color} />
      </ListItemIcon>
      <span className={clsx(['flex', 'flex-column'])}>
        <span className={styles.title}>{`${first_name} ${last_name}`}</span>
        <span className={styles.subtitle}>{role}</span>
      </span>
    </MenuItem>
  );
};
