import { useCallback, useState } from 'react';

export const useIsOpen = (defaultOpen = false): [boolean, () => void, () => void] => {
  const [isOpen, setOpen] = useState<boolean>(defaultOpen);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return [isOpen, onOpen, onClose];
};
