export const searchFilter = (searchTerm: string, ...rest: Array<string | null | undefined>) => {
  // show all fields
  if (!searchTerm) {
    return true;
  }

  // reduce from search all fields with nullish value fields
  if (searchTerm && rest.every((item) => !item)) {
    return false;
  }

  return searchTerm
    .toLowerCase()
    .trim()
    .split(' ')
    .every((part) => rest.map((item) => item && item.toLowerCase()).some((el) => el?.includes(part)));
};
