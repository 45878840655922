import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { DateRangeTypes, DrawerQueries } from 'types';

export const ScenarioDetailsRoute = Route(
  {
    id: Route.params.regex(idRegEx),
    mode: Route.params.oneOf(DrawerQueries.memberMode, DrawerQueries.settingMode).optional,
    projectId: Route.query.regex(idRegEx),
    projectType: Route.query.string,
    range: Route.query.oneOf(...Object.values(DateRangeTypes)),
    start: Route.query.string,
    end: Route.query.string,
    companyCurrency: Route.query.boolean,
  },
  ({ id, mode }) => `/scenario/${id}/${mode}`,
);

export const ScenarioDetails = ScenarioDetailsRoute(
  lazy(() => import(/* webpackChunkName: "ScenarioDetails" */ './ScenarioDetails')),
);
