import React, { useCallback, useMemo, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { PassVisibilityBtn } from 'components';

// import styles from './ResetPassword.module.scss';

export interface ResetPasswordFormValues {
  password: string;
  repeatedPassword: string;
}

interface ResetPasswordProps {
  onSubmit: (values: ResetPasswordFormValues) => void | Promise<void>;
  password?: string;
  onCancel?: () => void;
}

export const ResetPassword = (props: ResetPasswordProps) => {
  const { t } = useTranslation();
  const { password, onSubmit, onCancel } = props;

  const [isOriginalPassVisible, setIsOriginalPassVisible] = useState(false);
  const [isRepeatedPassVisible, setIsRepeatedPassVisible] = useState(false);

  const toggleOriginalPasswordVisibility = useCallback(() => setIsOriginalPassVisible((prev) => !prev), []);
  const toggleRepeatedPasswordVisibility = useCallback(() => setIsRepeatedPassVisible((prev) => !prev), []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string().required(t('formErrors.newPassRequired')),
        repeatedPassword: Yup.string()
          .oneOf([Yup.ref('password')], t('formErrors.passDoesNotMatch'))
          .required(t('formErrors.confirmPassRequired')),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        password: password ?? '',
        repeatedPassword: password ?? '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputLabel className="required">{t('forms.newPassword')}</InputLabel>
          <Field
            component={TextField}
            type={isOriginalPassVisible ? 'text' : 'password'}
            name="password"
            placeholder={t('forms.newPassword')}
            className="mb-24"
            InputProps={{
              endAdornment: (
                <Tooltip
                  aria-label={t('forms.passwordVisibility')}
                  title={
                    isOriginalPassVisible ? (t('forms.hidePassword') as string) : (t('forms.showPassword') as string)
                  }
                  placement="right"
                >
                  <span>
                    <PassVisibilityBtn
                      isPassVisible={isOriginalPassVisible}
                      togglePasswordVisibility={toggleOriginalPasswordVisibility}
                    />
                  </span>
                </Tooltip>
              ),
            }}
          />

          <InputLabel className="required">{t('forms.confirmPassword')}</InputLabel>
          <Field
            component={TextField}
            type={isRepeatedPassVisible ? 'text' : 'password'}
            name="repeatedPassword"
            placeholder={t('forms.confirmPassword')}
            className="mb-24"
            InputProps={{
              endAdornment: (
                <Tooltip
                  aria-label={t('forms.passwordVisibility')}
                  title={
                    isRepeatedPassVisible ? (t('forms.hidePassword') as string) : (t('forms.showPassword') as string)
                  }
                  placement="right"
                >
                  <span>
                    <PassVisibilityBtn
                      isPassVisible={isRepeatedPassVisible}
                      togglePasswordVisibility={toggleRepeatedPasswordVisibility}
                    />
                  </span>
                </Tooltip>
              ),
            }}
          />

          <div className="flex">
            <Button type="submit" disabled={isSubmitting} className="mr-8">
              {t('forms.changePassword')}
            </Button>

            <Button disabled={isSubmitting} color="secondary" onClick={onCancel} variant="outlined">
              {t('forms.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
