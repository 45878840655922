import React, { FC, ReactNode } from 'react';
import { format } from 'date-fns';
import { TimeLogDataFragment } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { formatTimeLog } from 'utils';
import { RemoveConfirmIconButton, Tooltip } from 'components';
import clsx from 'clsx';
import { EMPTY_DATA_STATE } from 'consts';
import { ClockIcon } from 'icons';

import styles from './styles.module.scss';

interface Props {
  item: TimeLogDataFragment;
  onDelete: () => void;
  onEdit: () => void;
  optionalColumn: ReactNode;
  overtimeMultiplier?: number | null;
}

export const TimeLogView: FC<Props> = ({ item, optionalColumn, onDelete, onEdit, overtimeMultiplier }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <span className={styles.date} onClick={onEdit}>
        {format(new Date(item.date), 'MMM dd, yyyy')}
      </span>

      <div className={styles.divider} />

      <span className={styles.member} onClick={onEdit}>
        {optionalColumn}
      </span>

      <div className={styles.divider} />

      <span className={styles.logged} onClick={onEdit}>
        {formatTimeLog(item.minutes)}

        {item.overtime && (
          <Tooltip
            title={
              overtimeMultiplier !== item.overtimeMultiplier
                ? t('timeTracking.overtimeMultiplier', {
                    overtimeMultiplier: overtimeMultiplier ? ` (${item.overtimeMultiplier}x)` : '',
                  })!
                : t('timeTracking.overtime')!
            }
            alwaysShowTooltip
            className="flex align-items-center ml-auto"
          >
            <ClockIcon className={styles.overtimeIcon} />
          </Tooltip>
        )}
      </span>

      <div className={styles.divider} />

      <span className={styles.note} onClick={onEdit}>
        <Tooltip title={item.notes || ''} placement="top" textClassName={clsx('overflowed-text', styles.noteText)}>
          {item.notes || EMPTY_DATA_STATE}
        </Tooltip>
      </span>

      <div className={styles.divider} />

      <div className={styles.menu}>
        <RemoveConfirmIconButton
          onClick={onDelete}
          confirmTitle={t('timeTracking.delete.deleteLog')}
          confirmMessage={t('timeTracking.delete.confirmDelete')}
          confirmSubmitButtonTitle={t('timeTracking.delete.deleteLog')}
        />
      </div>
    </div>
  );
};
