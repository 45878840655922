import React, { FC, useState } from 'react';
import styles from './SectionCollapse.module.scss';
import { ChevronIcon } from 'icons';
import { Collapse } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
  title: string;
  className?: string;
  defaultOpen?: boolean;
  error?: boolean;
};

export const SectionCollapse: FC<Props> = ({ children, title, className, error, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <div className={className}>
      <div className={clsx(styles.sectionTitle, error && styles.error)} onClick={toggleOpen}>
        <p>{title}</p> <ChevronIcon className={clsx(styles.icon, open && styles.open, error && styles.error)} />
      </div>
      <Collapse className="pt-20" timeout="auto" in={open}>
        {children}
      </Collapse>
    </div>
  );
};
