import { useCreateMemberRoleMutation, useRolesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { Reference } from '@apollo/client';
import { useAuth } from '../contexts';
import { Role } from '../generated/types';

export const useCreatableProjectRoleData = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { roles = [] } = {}, loading: roleLoading } = useRolesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const [createRole] = useCreateMemberRoleMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createRole) return;

      cache.modify({
        fields: {
          roles(items: Reference[] = []) {
            return [...items, data.createRole];
          },
        },
      });
    },
  });

  const getCreatedProjectRole = async (value: Pick<Role, 'id' | 'name'> | null) => {
    if (value?.id) {
      return value;
    }

    if (!value?.name) {
      return null;
    }

    const { data } = await createRole({
      variables: {
        data: { name: value?.name || '' },
        companyId: userData!.company.id,
      },
    });

    return data?.createRole || null;
  };

  return { roles, roleLoading, getCreatedProjectRole };
};
