import { SortByFn } from 'react-table';
import { isAfter } from 'date-fns';
import { useCallback } from 'react';
import { ProjectDocumentDataFragment } from '../generated/types';

export const useFileTableSorts = () => {
  const sortNameType = useCallback<SortByFn<ProjectDocumentDataFragment>>((rowA, rowB) => {
    return rowA.original.document.name.localeCompare(rowB.original.document.name, 'en');
  }, []);

  const sortSizeType = useCallback<SortByFn<ProjectDocumentDataFragment>>((rowA, rowB) => {
    return rowA.original.document.size > rowB.original.document.size ? 1 : -1;
  }, []);

  const sortProjectNameType = useCallback<SortByFn<ProjectDocumentDataFragment>>((rowA, rowB) => {
    return rowA.original.projectName.localeCompare(rowB.original.projectName, 'en');
  }, []);

  const sortAddedByType = useCallback<SortByFn<ProjectDocumentDataFragment>>((rowA, rowB, columnId, desc) => {
    if (!rowA.original?.createdByMemberName) {
      return desc ? -1 : 1;
    }
    if (!rowB.original?.createdByMemberName) {
      return desc ? 1 : -1;
    }

    return rowA.original.createdByMemberName.localeCompare(rowB.original.createdByMemberName, 'en');
  }, []);

  const sortDateAddedType = useCallback<SortByFn<ProjectDocumentDataFragment>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.created_at), new Date(rowB.original.created_at)) ? 1 : -1;
  }, []);

  return { sortNameType, sortSizeType, sortProjectNameType, sortAddedByType, sortDateAddedType };
};
