import React, { FC, memo } from 'react';
import { Tooltip } from 'components';

import styles from './styles.module.scss';

interface Props {
  children: string;
}

export const ItemFieldTooltip: FC<Props> = memo(({ children }) => {
  return (
    <Tooltip title={children} placement="top" textClassName={styles.fieldTooltipValue}>
      {children}
    </Tooltip>
  );
});

ItemFieldTooltip.displayName = 'ItemFieldTooltip';
