import React, { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { InfoIcon } from 'icons';

interface Props {
  children?: ReactNode;
}

export const IntegrationInfoBox: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <InfoIcon className={styles.infoIcon} />
      <div className={styles.content}>
        <span className={styles.title}>{t('import.stepsToImport')}</span>
        <ol className="ml-4 mb-16">
          <li>{t('import.firstStep')}</li>
          <li>{t('import.secondStep')}</li>
          <li>{t('import.thirdStep')}</li>
          <li className="ml-16">{t('import.thirdStepFirstOption')}</li>
          <li className="ml-16">{t('import.thirdStepSecondOption')}</li>
          <li>{t('import.fourthStep')}</li>
        </ol>
        {children}
      </div>
    </div>
  );
};
