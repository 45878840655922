import { useCallback, useRef } from 'react';

export const useExpandRows = () => {
  const expandedRowsRef = useRef<string[]>([]);

  const checkIsRowExpanded = useCallback((id: string) => {
    return expandedRowsRef.current.includes(id);
  }, []);

  const onExpandRow = useCallback(
    (id: string) => () => {
      const isExpanded = checkIsRowExpanded(id);
      expandedRowsRef.current = isExpanded
        ? expandedRowsRef.current.filter((itemId) => itemId !== id)
        : [...expandedRowsRef.current, id];
    },
    [],
  );

  return { checkIsRowExpanded, onExpandRow };
};
