import React, { useEffect, useRef, FC, ReactNode } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styles from './styles.module.scss';
import clsx from 'clsx';

export const DRAG_ITEM = 'DRAG_ITEM';

export interface Props {
  id: string;
  children: ReactNode;
  index: number;
  hideDragPreview?: boolean;
  disableDragging?: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  changeIsDragging?: (value: boolean) => void;
  setDragItemId?: (props: string) => void;
  onEndMove?: () => void;
}

interface DragItemType {
  index: number;
  id: string;
  type: string;
}

export const DragItem: FC<Props> = ({
  id,
  children,
  index,
  hideDragPreview,
  disableDragging,
  moveCard,
  changeIsDragging,
  setDragItemId,
  onEndMove,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItemType, void, { handlerId: Identifier | null }>({
    accept: DRAG_ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItemType, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (!disableDragging) {
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: DRAG_ITEM,
    canDrag: !disableDragging,
    item: () => {
      setDragItemId?.(id);
      return { id, index };
    },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end: () => {
      changeIsDragging?.(false);
      onEndMove?.();
    },
  });

  useEffect(() => {
    if (hideDragPreview) {
      dragPreview(getEmptyImage(), { captureDraggingState: true });
    }
  }, []);

  useEffect(() => {
    if (isDragging) {
      changeIsDragging?.(true);
    }
  }, [isDragging]);

  hideDragPreview ? drop(ref) : drag(drop(ref));

  return (
    <div
      ref={ref}
      className={clsx(styles.dragItem, isDragging && styles.dragging, !hideDragPreview && styles.dragPreviewItem)}
      data-handler-id={handlerId}
    >
      <div ref={drag} className={clsx(styles.dragHandle, !disableDragging && 'pointer')} />
      {children}
    </div>
  );
};
