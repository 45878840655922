import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal, DatePicker, LoadingButton } from 'components';
import { Button, Popover } from '@material-ui/core';
import { ProjectAccess, Scalars } from 'generated/types';
import { ClockIcon, SettingsActiveLink as SettingsIcon } from 'icons';
import { getFormattedDateLabel } from './getFormattedDateLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { useIsOpen } from 'hooks';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  accessLevel?: string;
  startDate?: string;
  endDate?: string;
  onEdit: (value: { startDate?: string; endDate?: string }) => void;
  showConfirm?: boolean;
}
export const EditProjectMembershipAccessDate: FC<Props> = ({
  accessLevel,
  startDate,
  endDate,
  onEdit,
  showConfirm,
}) => {
  const { t } = useTranslation();
  const [startDateValue, setStartDateValue] = useState<string | undefined>(startDate);
  const [endDateValue, setEndDateValue] = useState<string | undefined>(endDate);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isOpenDateEdit, onOpenDateEdit, onCloseDateEdit] = useIsOpen(false);
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen();

  const handleSubmit = () => {
    if (
      accessLevel === ProjectAccess.FullAccess &&
      (startDate || endDate) &&
      !startDateValue &&
      !endDateValue &&
      !isOpenConfirm &&
      showConfirm
    ) {
      onOpenConfirm();
      return;
    }

    onEdit({ startDate: startDateValue, endDate: endDateValue });
    onCloseDateEdit();
  };

  return (
    <>
      <div ref={anchorRef} onClick={onOpenDateEdit}>
        <div className={clsx(styles.label, !startDate && !endDate && 'mr-24')}>
          <ClockIcon />
          {getFormattedDateLabel({
            t,
            startDate: startDate || '',
            endDate: endDate || '',
            emptyText: t('shareProject.edit.setAccessPeriod'),
          })}
          {startDate || endDate ? <SettingsIcon className={styles.settingIcon} /> : ''}
        </div>
      </div>
      <Popover
        anchorEl={anchorRef.current}
        onClose={onCloseDateEdit}
        open={isOpenDateEdit}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="flex flex-column gap-16 p-16">
          <span className={styles.editTitle}>{t('shareProject.edit.setAccessPeriod')}</span>
          <div>
            <InputLabel>{t('shareProject.create.accessStartsFrom')}</InputLabel>
            <DatePicker
              placeholder={t('shareProject.create.select')}
              value={startDate}
              onChange={(startDate: Scalars['DateTime']) => setStartDateValue(startDate)}
              popperProps={{ strategy: 'fixed' }}
            />
          </div>
          <div>
            <InputLabel>{t('shareProject.create.to')}</InputLabel>
            <DatePicker
              placeholder={t('shareProject.create.select')}
              value={endDate}
              onChange={(endDate: Scalars['DateTime']) => setEndDateValue(endDate)}
              popperProps={{ strategy: 'fixed' }}
            />
          </div>
          <div className="w-100 flex justify-content-end gap-12">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onCloseDateEdit();
              }}
              variant="outlined"
              color="secondary"
            >
              {t('forms.cancel')}
            </Button>
            <LoadingButton
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit();
              }}
            >
              {t('forms.save')}
            </LoadingButton>
          </div>
        </div>
      </Popover>

      <ConfirmModal
        title={t('shareProject.create.grantFullAccess')}
        submitButtonTitle={t('shareProject.create.grantFullAccess')}
        isOpen={isOpenConfirm}
        onSubmit={() => {
          handleSubmit();
          onCloseConfirm();
        }}
        onClose={onCloseConfirm}
      >
        {t('shareProject.create.confirmMessage')}
      </ConfirmModal>
    </>
  );
};
