import React, { FC, PropsWithChildren, ReactElement, useState } from 'react';
import { Popper as MuiPopper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import styles from './styles.module.scss';

interface Props {
  button: ReactElement;
  isOpen: boolean;
  onClose: () => void;
}

export const Popper: FC<PropsWithChildren<Props>> = ({ isOpen, onClose, button, children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const id = isOpen ? 'Popper' : undefined;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <button aria-describedby={id} onClick={handleClick} className={styles.button}>
          {button}
        </button>
        <MuiPopper
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={styles.popper}
          modifiers={{
            flip: {
              enabled: true,
            },
          }}
        >
          <div>{children}</div>
        </MuiPopper>
      </div>
    </ClickAwayListener>
  );
};
