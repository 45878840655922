import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const CommissionsHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <span className={styles.title}>{t('viewProjectDetail.commissions.commission')}</span>
      <span className={styles.effectiveFrom}>{t('viewProjectDetail.commissions.effectiveFrom')}</span>
    </div>
  );
};
