import { endOfDay, format, isAfter } from 'date-fns';

interface Props {
  startDate: string;
  endDate: string;
  t: (value: string) => string;
  emptyText?: string;
}
export const getFormattedDateLabel = ({ startDate, endDate, t, emptyText = '' }: Props) => {
  switch (true) {
    case !!startDate && !!endDate && isAfter(new Date(), endOfDay(new Date(endDate))):
      return `${t('shareProject.dateFormat.expiredOn')} ${format(new Date(endDate), 'd MMM yyyy')}`;
    case !!(startDate && endDate):
      return `${format(new Date(startDate), 'd MMM yyyy')} - ${format(new Date(endDate), 'd MMM yyyy')}`;
    case !!startDate && !endDate:
      return `${t('shareProject.dateFormat.startsOn')} ${format(new Date(startDate), 'd MMM yyyy')}`;
    case !startDate && !!endDate && isAfter(new Date(), endOfDay(new Date(endDate))):
      return `${t('shareProject.dateFormat.expiredOn')} ${format(new Date(endDate), 'd MMM yyyy')}`;
    case !startDate && !!endDate:
      return `${t('shareProject.dateFormat.expiresOn')} ${format(new Date(endDate), 'd MMM yyyy')}`;
    default:
      return emptyText;
  }
};
