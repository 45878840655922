import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns';

export const getPeriodLabel = (startDate: string | Date, endDate: string | Date): string => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const isSameYearPeriod = isSameYear(start, end);
  const isSameMonthPeriod = isSameMonth(start, end);
  const isSameDayPeriod = isSameDay(start, end);

  if (isSameDayPeriod) {
    return `${format(start, 'MMM dd, yyyy')}`;
  }

  return `${format(start, isSameYearPeriod ? 'MMM dd' : 'MMM dd, yyyy')}-${format(
    end,
    isSameMonthPeriod ? 'dd, yyyy' : 'MMM dd, yyyy',
  )}`;
};
