import React from 'react';
import clsx from 'clsx';
import { Route } from 'react-router-hoc';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { CompleteProfile as CompleteProfileForm, UnauthFormHeading, CompleteProfileFormValues } from 'components';
import { RouteProps } from 'types';
import { useAuth } from 'contexts';
import { useAcceptInvitationMutation } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';

export const CompleteProfile = ({ match: { query } }: RouteProps) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const [acceptInvitation] = useAcceptInvitationMutation({
    onCompleted(data) {
      toast.success(t('completeProfile.invitationConfirmedSuccess'));
      handleAuth(data.acceptInvitation.token);
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });
  const { handleAuth } = useAuth();

  const handleSubmit = async ({ firstName, lastName, password }: CompleteProfileFormValues) => {
    const { invitationId, secret } = query;

    await acceptInvitation({
      variables: {
        id: invitationId,
        secret,
        firstName,
        lastName,
        password,
      },
    });
  };

  const renderBrokenInvitePlaceholder = () => {
    return <div className="text-center">{t('completeProfile.brokenInvitationData')}</div>;
  };

  return (
    <div className={clsx('form-content', 'col-3')}>
      <UnauthFormHeading heading="Complete profile" />
      <div className="px-30 pt-24 pb-30">
        {!query.invitationId || !query.secret ? (
          renderBrokenInvitePlaceholder()
        ) : (
          <CompleteProfileForm onSubmit={handleSubmit} />
        )}
      </div>
    </div>
  );
};

export default Route(
  {
    secret: Route.query.string,
    invitationId: Route.query.string,
  },
  '/complete-profile',
)(CompleteProfile);
