import React, { FC, useCallback, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import { Autocomplete, MemberTag, Tag } from 'components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, Divider } from '@material-ui/core';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';
import { useFiltersData } from './useFiltersData';
import { usePermissions } from 'hooks';
import { ActionsType, Client, Member, MemberSeniority, Specialization } from 'generated/types';
import { getFullName } from 'utils';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete/Autocomplete';

export interface CommonFilterData {
  employmentType: string[];
  seniority: string[];
  specialization: string[];
  reportingTo: string[];
  client: string[];
  pm: string[];
  type: string[];
}

export interface CommonFilterFormValues {
  seniority: Pick<MemberSeniority, 'id' | 'name'>[];
  specialization: Pick<Specialization, 'id' | 'name'>[];
  client: Pick<Client, 'id' | 'name'>[];
  pm: Member[];
  reportingTo: Member[];
  type: string[];
  employmentType: string[];
}

export enum CommonFilterOptions {
  employmentType = 'employmentType',
  seniority = 'seniority',
  specialization = 'specialization',
  reportingTo = 'reportingTo',
  client = 'client',
  pm = 'pm',
  type = 'type',
}

interface Props {
  open: boolean;
  onSubmit: (data: CommonFilterData) => void;
  onClearFilters: () => void;
  filtersOptions: CommonFilterOptions[];
  employmentType: string[];
  seniority: string[];
  specialization: string[];
  reportingTo: string[];
  client: string[];
  pm: string[];
  type: string[];
}

const validationSchema = object({
  employmentType: array(string()),
  seniority: array(object()),
  specialization: array(object()),
  reportingTo: array(object()),
  client: array(object()),
  pm: array(object()),
  type: array(string()),
});

const SELECT_TAG_COLOR = '#ebedee';

export const CommonFiltersPopover: FC<Props> = ({
  onSubmit,
  onClearFilters,
  filtersOptions,
  employmentType,
  seniority,
  specialization,
  reportingTo,
  client,
  pm,
  type,
}) => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();

  const defaultInitialValues = {
    seniority: [],
    specialization: [],
    reportingTo: [],
    client: [],
    pm: [],
    type: (type as string[]) || [],
    employmentType: (employmentType as string[]) || [],
  };

  const { values, handleSubmit, setValues } = useFormik<CommonFilterFormValues>({
    onSubmit: (values) => {
      onSubmit({
        ...values,
        seniority: values.seniority.map(({ id }) => id),
        specialization: values.specialization.map(({ id }) => id),
        client: values.client.map(({ id }) => id),
        reportingTo: values.reportingTo.map(({ id }) => id),
        pm: values.pm.map(({ id }) => id),
      });
    },
    validationSchema,
    initialValues: defaultInitialValues,
  });

  const {
    employmentTypeOptions,
    projectTypeOptions,
    specializationsOptions,
    senioritiesOptions,
    membersOptions,
    clientsOptions,
    senioritiesLoading,
    specializationLoading,
    membersLoading,
    clientsLoading,
  } = useFiltersData({ filtersOptions, values });

  const initialValues = useMemo(
    () => ({
      seniority: senioritiesOptions.filter(({ id }) => seniority.includes(id)) || [],
      specialization: specializationsOptions.filter(({ id }) => specialization.includes(id)) || [],
      client: clientsOptions.filter(({ id }) => client.includes(id)) || [],
      reportingTo: (membersOptions.filter(({ id }) => reportingTo.includes(id)) as Member[]) || [],
      pm: (membersOptions.filter(({ id }) => pm.includes(id)) as Member[]) || [],
      employmentType: (employmentType as string[]) || [],
      type: (type as string[]) || [],
    }),
    [senioritiesLoading, specializationLoading, membersLoading, clientsLoading],
  );

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const clearFilters = () => {
    onClearFilters();
    setValues({
      employmentType: [],
      seniority: [],
      specialization: [],
      reportingTo: [],
      client: [],
      pm: [],
      type: [],
    });
  };

  const getNewFieldValue = (id: string, value: string[]): string[] => {
    const isActive = value.includes(id);

    return isActive ? value.filter((selectedId) => selectedId !== id) : [...value, id];
  };

  const renderTags = useCallback(
    (options: { id: string; name: string }[], getTagProps: AutocompleteGetTagProps) =>
      options.map(({ id, name }, index) => {
        const tagProps = getTagProps({ index });

        return (
          <Tag
            {...tagProps}
            key={id}
            name={name}
            className={clsx(styles.tag, styles.tagBorder, styles.tagSelected)}
            tooltipClassName={styles.tagTooltip}
          />
        );
      }),
    [],
  );

  const renderMemberTags = useCallback(
    (options: Member[], getTagProps: AutocompleteGetTagProps) =>
      options.map(({ id, color, first_name, last_name }, index) => {
        const tagProps = getTagProps({ index });

        return (
          <MemberTag
            {...tagProps}
            key={id}
            firstName={first_name!}
            lastName={last_name!}
            avatarColor={color}
            className={clsx(styles.tag, styles.tagBorder, styles.tagSelected)}
          />
        );
      }),
    [],
  );

  const filterCount =
    (values.employmentType?.length || 0) +
    (values.seniority?.length || 0) +
    (values.specialization?.length || 0) +
    (values.reportingTo?.length || 0) +
    (values.client?.length || 0) +
    (values.pm?.length || 0) +
    (values.type?.length || 0);

  return (
    <>
      <div className="flex align-items-center">
        <h3 className={styles.popoverTitle}>{t('common.filter.filters')}&nbsp;</h3>
        {filterCount ? (
          <span className={styles.filterCount}>
            {filterCount} {t('common.filter.selected')}
          </span>
        ) : (
          ''
        )}
      </div>

      <Divider className="w-100" />

      <form onSubmit={handleSubmit} className={clsx('form', styles.form)}>
        <div className={styles.formBox}>
          {filtersOptions.includes(CommonFilterOptions.employmentType) ? (
            <>
              <span className={styles.title}>{t('common.filter.employmentType')}</span>
              <div className={styles.tagBox}>
                {employmentTypeOptions.map(({ id, name }) => {
                  const isSelected = values.employmentType?.includes(id);

                  return (
                    <Tag
                      key={id}
                      name={name}
                      color={isSelected ? SELECT_TAG_COLOR : 'inherit'}
                      className={clsx(styles.tag, styles.tagBorder, isSelected && styles.tagSelected)}
                      tooltipClassName={styles.tagTooltip}
                      onClick={() =>
                        setValues({ ...values, employmentType: getNewFieldValue(id, values.employmentType) })
                      }
                    />
                  );
                })}
              </div>
              <Divider className={styles.divider} />
            </>
          ) : (
            ''
          )}

          {filtersOptions.includes(CommonFilterOptions.seniority) ? (
            <>
              <span className={styles.title}>{t('common.filter.seniority')}</span>
              <Autocomplete
                placeholder={t('common.filter.selectSeniority')}
                value={values.seniority}
                name="seniority"
                filterSelectedOptions
                multiple
                disableClearable
                getOptionLabel={({ name }: MemberSeniority) => name}
                renderTags={renderTags}
                options={senioritiesOptions}
                onChange={(seniority: CommonFilterFormValues['seniority']) => setValues({ ...values, seniority })}
              />
              <Divider className={styles.divider} />
            </>
          ) : (
            ''
          )}

          {filtersOptions.includes(CommonFilterOptions.specialization) ? (
            <>
              <span className={styles.title}>{t('common.filter.specialization')}</span>
              <Autocomplete
                placeholder={t('common.filter.selectSpecialization')}
                value={values.specialization}
                name="specialization"
                filterSelectedOptions
                multiple
                disableClearable
                getOptionLabel={({ name }: Specialization) => name}
                renderTags={renderTags}
                options={specializationsOptions}
                onChange={(specialization: CommonFilterFormValues['specialization']) =>
                  setValues({ ...values, specialization })
                }
              />
              <Divider className={styles.divider} />
            </>
          ) : (
            ''
          )}

          {filtersOptions.includes(CommonFilterOptions.client) ? (
            <>
              <span className={styles.title}>{t('common.filter.client')}</span>
              <Autocomplete
                placeholder={t('common.filter.selectClient')}
                value={values.client}
                name="client"
                filterSelectedOptions
                multiple
                disableClearable
                getOptionLabel={({ name }: Client) => name}
                renderTags={renderTags}
                options={clientsOptions}
                onChange={(client: CommonFilterFormValues['client']) => setValues({ ...values, client })}
              />
              <Divider className={styles.divider} />
            </>
          ) : (
            ''
          )}

          {filtersOptions.includes(CommonFilterOptions.reportingTo) && hasAccess(ActionsType.ViewUsers) ? (
            <>
              <span className={styles.title}>{t('common.filter.reportingTo')}</span>
              <Autocomplete
                placeholder={t('common.filter.selectReportingTo')}
                value={values.reportingTo}
                name="reportingTo"
                filterSelectedOptions
                multiple
                disableClearable
                getOptionLabel={({ first_name, last_name }: Member) =>
                  getFullName(first_name, last_name, t('notApplicable'))
                }
                renderTags={renderMemberTags}
                options={membersOptions}
                onChange={(reportingTo: CommonFilterFormValues['reportingTo']) => setValues({ ...values, reportingTo })}
              />
              <Divider className={styles.divider} />
            </>
          ) : (
            ''
          )}

          {filtersOptions.includes(CommonFilterOptions.pm) && hasAccess(ActionsType.ViewUsers) ? (
            <>
              <span className={styles.title}>{t('common.filter.projectManager')}</span>
              <Autocomplete
                placeholder={t('common.filter.selectProjectManager')}
                value={values.pm}
                name="projectManager"
                filterSelectedOptions
                multiple
                disableClearable
                getOptionLabel={({ first_name, last_name }: Member) =>
                  getFullName(first_name, last_name, t('notApplicable'))
                }
                renderTags={renderMemberTags}
                options={membersOptions}
                onChange={(pm: CommonFilterFormValues['pm']) => setValues({ ...values, pm })}
              />
              <Divider className={styles.divider} />
            </>
          ) : (
            ''
          )}

          {filtersOptions.includes(CommonFilterOptions.type) ? (
            <>
              <span className={styles.title}>{t('common.filter.projectType')}</span>
              <div className={styles.tagBox}>
                {projectTypeOptions.map(({ id, name }) => {
                  const isSelected = values.type?.includes(id);

                  return (
                    <Tag
                      key={id}
                      name={name}
                      color={isSelected ? SELECT_TAG_COLOR : 'inherit'}
                      className={clsx(styles.tag, styles.tagBorder, isSelected && styles.tagSelected)}
                      tooltipClassName={styles.tagTooltip}
                      onClick={() => setValues({ ...values, type: getNewFieldValue(id, values.type) })}
                    />
                  );
                })}
              </div>
              <Divider className={styles.divider} />
            </>
          ) : (
            ''
          )}
        </div>

        <div className={styles.buttons}>
          <Button type="submit">{t('common.filter.apply')}</Button>

          <Button color="secondary" variant="text" onClick={clearFilters}>
            {t('common.filter.clearAll')}
          </Button>
        </div>
      </form>
    </>
  );
};
