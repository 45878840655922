import React from 'react';
import { HeaderGroup } from 'react-table';
import clsx from 'clsx';
import { ACTIONS, SELECTION } from 'consts';

import styles from './Footer.module.scss';

interface TableFooterProps<D extends Record<string, unknown> = Record<string, never>> {
  footerGroups: Array<HeaderGroup<D>>;
  footerClassName?: string;
  isSelectMode: boolean;
  stickyLeftSelection?: boolean;
  stickyFooter?: boolean;
}
export const Footer = <D extends Record<string, unknown> = Record<string, never>>({
  footerGroups,
  footerClassName,
  isSelectMode,
  stickyLeftSelection,
  stickyFooter,
}: TableFooterProps<D>) => {
  return (
    <tfoot className={clsx(footerClassName, stickyFooter && styles.stickyFooter)}>
      {footerGroups.map((group) => (
        <tr {...group.getFooterGroupProps()} key={`footer-row${group.id}`}>
          {group.headers.map((column) => (
            <td
              {...column.getFooterProps()}
              key={`footer-column${column.id}`}
              className={clsx(styles.tableData, {
                [styles.stickyAction]: column.id === ACTIONS,
                [styles.stickyLeft]: column.sticky === 'left',
                [styles.stickyRight]: column.sticky === 'right',
                [styles.stickyLeftWithSelection]: column.sticky === 'left' && isSelectMode,
                [styles.selectionStickyLeft]: stickyLeftSelection && column.id === SELECTION,
              })}
            >
              {column.render('Footer')}
            </td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};
