import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { FeedbackModal, MobileSidebar } from 'components';

import styles from './MobileBaseLayout.module.scss';

interface BaseLayoutProps {
  children: ReactNode;
}

export const MobileBaseLayout = ({ children }: BaseLayoutProps) => (
  <div className={clsx(styles.wrapper, 'bg-gray', 'flex-column')}>
    <div className={clsx(styles.container, 'flex', 'flex-1')}>
      <MobileSidebar>
        <div className="bg-white flex-1">{children}</div>
      </MobileSidebar>
      <FeedbackModal />
    </div>
  </div>
);
