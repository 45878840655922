import { SortByFn } from 'react-table';
import { useCallback } from 'react';
import { Member } from '../generated/types';
import { isAfter } from 'date-fns';

export const useMemberTableSorts = () => {
  const sortMemberType = useCallback<SortByFn<Member>>((rowA, rowB) => {
    return `${rowA.original.first_name} ${rowA.original.last_name}`.localeCompare(
      `${rowB.original.first_name} ${rowB.original.last_name}`,
      'en',
    );
  }, []);

  const sortTitleType = useCallback<SortByFn<Member>>((rowA, rowB, _columnId, desc) => {
    if (!rowA.original?.job_title) {
      return desc ? -1 : 1;
    }
    if (!rowB.original?.job_title) {
      return desc ? 1 : -1;
    }

    return (rowA.original?.job_title || '').localeCompare(rowB.original?.job_title || '', 'en');
  }, []);

  const sortTypeMemberType = useCallback<SortByFn<Member>>((rowA, rowB) => {
    return rowA.original.employment_type.localeCompare(rowB.original.employment_type, 'en');
  }, []);

  const sortReportingToType = useCallback<SortByFn<Member>>((rowA, rowB, _columnId, desc) => {
    if (!rowA.original.reportingTo?.id) {
      return desc ? -1 : 1;
    }
    if (!rowB.original.reportingTo?.id) {
      return desc ? 1 : -1;
    }

    return `${rowA.original.reportingTo?.first_name} ${rowA.original.reportingTo?.last_name}`.localeCompare(
      `${rowB.original.reportingTo?.first_name} ${rowB.original.reportingTo?.last_name}`,
      'en',
    );
  }, []);

  const sortSpecializationMemberType = useCallback<SortByFn<Member>>((rowA, rowB, _columnId, desc) => {
    if (!rowA.original.member_specialization?.name) {
      return desc ? -1 : 1;
    }
    if (!rowB.original.member_specialization?.name) {
      return desc ? 1 : -1;
    }

    return (rowA.original.member_specialization?.name || '').localeCompare(
      rowB.original.member_specialization?.name || '',
      'en',
    );
  }, []);

  const sortSeniorityMemberType = useCallback<SortByFn<Member>>((rowA, rowB, _columnId, desc) => {
    if (!rowA.original.member_seniority?.name) {
      return desc ? -1 : 1;
    }
    if (!rowB.original.member_seniority?.name) {
      return desc ? 1 : -1;
    }

    return (rowA.original.member_seniority?.name || '').localeCompare(rowB.original.member_seniority?.name || '', 'en');
  }, []);

  const sortDateJoiningType = useCallback<SortByFn<Member>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.join_date), new Date(rowB.original.join_date)) ? 1 : -1;
  }, []);

  const sortDateExitType = useCallback<SortByFn<Member>>((rowA, rowB) => {
    return isAfter(new Date(rowA.original.exit_date), new Date(rowB.original.exit_date)) ? 1 : -1;
  }, []);

  return {
    sortTitleType,
    sortMemberType,
    sortSeniorityMemberType,
    sortReportingToType,
    sortSpecializationMemberType,
    sortTypeMemberType,
    sortDateJoiningType,
    sortDateExitType,
  };
};
