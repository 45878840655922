import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { DropdownMenu, Tooltip } from 'components';
import clsx from 'clsx';
import { Button, MenuItem } from '@material-ui/core';
import { ProjectAccess } from 'generated/types';
import { ChevronIcon, DoneIcon, TrashIcon } from 'icons';

interface AccessOption {
  id: ProjectAccess;
  name: string;
  description: string;
}

interface Props {
  onChange: (value: AccessOption) => void;
  onDelete?: () => void;
  value?: string;
  isNonBillableProject?: boolean;
}
export const ProjectMembershipAccessMenu: FC<Props> = ({ value, onChange, onDelete, isNonBillableProject }) => {
  const { t } = useTranslation();

  const accessLevelOptions = useMemo(
    () =>
      isNonBillableProject
        ? [
            {
              id: ProjectAccess.FullAccess,
              name: t('shareProject.accessOptions.fullAccess'),
              description: t('shareProject.accessOptions.fullAccessOnlySalaryDescription'),
            },
            {
              id: ProjectAccess.NonAccess,
              name: t('shareProject.accessOptions.none'),
              description: t('shareProject.accessOptions.noneOnlySalaryDescription'),
            },
          ]
        : [
            {
              id: ProjectAccess.FullAccess,
              name: t('shareProject.accessOptions.fullAccess'),
              description: t('shareProject.accessOptions.fullAccessDescription'),
            },
            {
              id: ProjectAccess.RatesAccess,
              name: t('shareProject.accessOptions.ratesOnly'),
              description: t('shareProject.accessOptions.ratesOnlyDescription'),
            },
            {
              id: ProjectAccess.NonAccess,
              name: t('shareProject.accessOptions.none'),
              description: t('shareProject.accessOptions.noneDescription'),
            },
          ],
    [isNonBillableProject],
  );
  const selectedOption = accessLevelOptions.find(({ id }) => value === id);

  return (
    <DropdownMenu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      trigger={(handleClick, open) => (
        <Button
          variant="text"
          onClick={handleClick}
          endIcon={<ChevronIcon className={clsx(styles.icon, open && styles.open)} />}
        >
          {selectedOption?.name}
        </Button>
      )}
    >
      {(handleClose) => {
        const options = accessLevelOptions.map((accessLevel) => {
          const isSelected = accessLevel.id === value;

          return (
            <MenuItem
              key={accessLevel.id}
              onClick={() => {
                onChange(accessLevel);
                handleClose();
              }}
              className={styles.menuItem}
            >
              <div className={styles.doneIconBox}>{isSelected ? <DoneIcon /> : ''}</div>
              <div className="flex flex-column">
                {accessLevel.name}
                <span className={styles.selectOptionDescription}>{accessLevel.description}</span>
              </div>
            </MenuItem>
          );
        });

        const removeOption = (
          <Tooltip title={t('shareProject.noPMRemove')!} placement="top" alwaysShowTooltip hideTooltip={!!onDelete}>
            <MenuItem
              onClick={() => {
                onDelete?.();
                handleClose();
              }}
              className="py-8"
              disabled={!onDelete}
            >
              <div className={styles.removeButton}>
                <TrashIcon />
                {t('shareProject.edit.delete')}
              </div>
            </MenuItem>
          </Tooltip>
        );

        return [...options, removeOption];
      }}
    </DropdownMenu>
  );
};
