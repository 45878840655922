import React from 'react';
import { ActivityHistoriesDataFragment } from 'generated/types';
import { getDateFrom } from 'utils';
import { ItemFieldTooltip } from 'components/common/ItemDisplayFields/ItemFieldsTooltip';

import styles from 'views/ActivityHistory/components/ActivityLog/styles.module.scss';

interface Props {
  t: (value: string) => string;
  log: ActivityHistoriesDataFragment;
  accordionTitle: string;
}

export const getAccordionCollapsedTitle = ({ t, log, accordionTitle }: Props) => {
  const { created_at, createdByMemberName, integration, data, changes } = log;

  const projectName =
    changes?.['name'] ||
    data['name'] ||
    changes?.['project'] ||
    data['project'] ||
    changes?.['projectName'] ||
    data['projectName'] ||
    '';
  const projectLabel = `${projectName}${projectName ? ':' : ''}`;
  const teamMemberFirstName =
    changes?.['first_name'] || data['first_name'] || changes?.['memberFirstName'] || data['memberFirstName'] || '';
  const teamMemberLastName =
    changes?.['last_name'] || data['last_name'] || changes?.['memberLastName'] || data['memberLastName'] || '';
  const teamMemberLabel = `${teamMemberFirstName} ${teamMemberLastName}${teamMemberFirstName ? ':' : ''}`;
  const createdByLabel = createdByMemberName ? (
    <>
      {t('activityHistory.by')} &nbsp;
      <ItemFieldTooltip>{createdByMemberName}</ItemFieldTooltip>
    </>
  ) : integration ? (
    ` ${t('activityHistory.by')} ${t('activityHistory.integration')}`
  ) : (
    ''
  );

  return (
    <div className="flex flex-wrap">
      <span className={styles.title}>{`${projectLabel || teamMemberLabel} ${accordionTitle}`}</span>
      <span className={styles.text}>
        {getDateFrom(created_at)} {createdByLabel}
      </span>
    </div>
  );
};
