import { RightTriangularArrow } from 'icons';
import React, { Dispatch, FC, SetStateAction } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { format, isFriday, isMonday, isSaturday, isSunday, isWeekend } from 'date-fns';

interface Props {
  day: Date;
  setIsWeekendVisible: Dispatch<SetStateAction<boolean>>;
  isWeekendVisible: boolean;
  isFirstDay?: boolean;
  isLastDay?: boolean;
  dateFormat?: string;
}

export const TableDayHeader: FC<Props> = ({
  day,
  dateFormat,
  setIsWeekendVisible,
  isWeekendVisible,
  isFirstDay,
  isLastDay,
}) => {
  const showRightArrowIcon = (isSunday(day) && isWeekendVisible) || (!isWeekendVisible && isFriday(day) && !isLastDay);
  const showLeftArrowIcon =
    (isSaturday(day) && isWeekendVisible) || (!isWeekendVisible && isMonday(day) && !isFirstDay);
  const isTogglingHideWeekend =
    isWeekend(day) || (!isWeekendVisible && isMonday(day)) || (!isWeekendVisible && isFriday(day));

  return (
    <div
      className={clsx(styles.container, isTogglingHideWeekend && styles.clickable)}
      onClick={() => isTogglingHideWeekend && setIsWeekendVisible((prev) => !prev)}
    >
      <>
        {showLeftArrowIcon && (
          <RightTriangularArrow
            className={clsx(styles.leftIcon, !isWeekendVisible && styles.rotated, !isWeekendVisible && styles.active)}
          />
        )}
        {format(day, dateFormat || 'eee d').toUpperCase()}
        {showRightArrowIcon && (
          <RightTriangularArrow
            className={clsx(styles.rightIcon, isWeekendVisible && styles.rotated, !isWeekendVisible && styles.active)}
          />
        )}
      </>
    </div>
  );
};
