import { OptionalDeep } from 'ts-toolbelt/out/Object/Optional';
import { Request } from 'generated/types';
import { getAllocationTimeAmount } from 'utils';

export const getAssignmentFromRequest = (item: OptionalDeep<Request> | undefined) => {
  return {
    allocationTimeAmount: getAllocationTimeAmount(Number(item?.data?.allocationTimeAmount)),
    created_at: item?.created_at,
    endDate: item?.data?.endDate,
    startDate: item?.data?.startDate,
    billable: Boolean(item?.data?.billable),
    id: item?.id,
    role: {
      id: String(item?.data?.roleId),
      name: String(item?.data?.role),
    },
    isRequest: true,
  };
};
