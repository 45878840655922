export enum DateRangeTypes {
  allTime = 'allTime',
  yearToDate = 'yearToDate',
  lastYear = 'lastYear',
  prevYear = 'prevYear',
  lastMonth = 'lastMonth',
  thisMonth = 'thisMonth',
  thisYear = 'thisYear',
  accessPeriod = 'accessPeriod',
}

export const DateRangeTypesLabel = Object.freeze<Record<DateRangeTypes, string>>({
  [DateRangeTypes.allTime]: 'datePicker.allTime',
  [DateRangeTypes.yearToDate]: 'datePicker.yearToDate',
  [DateRangeTypes.lastYear]: 'datePicker.lastYear',
  [DateRangeTypes.prevYear]: 'datePicker.prevYear',
  [DateRangeTypes.lastMonth]: 'datePicker.lastMonth',
  [DateRangeTypes.thisMonth]: 'datePicker.thisMonth',
  [DateRangeTypes.thisYear]: 'datePicker.thisYear',
  [DateRangeTypes.accessPeriod]: 'datePicker.accessPeriod',
});
