import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import clsx from 'clsx';

type Props = Omit<TooltipProps, 'children'> & {
  hideTooltip?: boolean;
  alwaysShowTooltip?: boolean;
  textClassName?: string;
  children?: string | ReactNode;
};

export const Tooltip: FC<Props> = ({ children, hideTooltip, alwaysShowTooltip, title, textClassName, ...props }) => {
  const textElementRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowed, setIsOverflow] = useState(false);

  useEffect(() => {
    if (!textElementRef.current || alwaysShowTooltip || !title) {
      return;
    }

    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, [textElementRef.current?.scrollWidth, title, textElementRef]);

  if (hideTooltip) {
    return <div className={textClassName}>{children || ''}</div>;
  }

  return (
    <MuiTooltip {...props} title={title} disableHoverListener={!isOverflowed && !alwaysShowTooltip}>
      <div ref={textElementRef} className={clsx(textClassName)}>
        {children || ''}
      </div>
    </MuiTooltip>
  );
};
