import { Reference } from '@apollo/client';
import { RightDrawer } from 'components';
import { useAuth } from 'contexts';
import { LeaveTypeDataFragmentDoc, useEditLeaveTypeMutation } from 'generated/graphql';
import { ILeaveType, LeaveTypeDataFragment } from 'generated/types';
import { useErrorMsgBuilder } from 'hooks';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { LeaveTypeForm } from '../components/LeaveTypeForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  leaveType: LeaveTypeDataFragment;
}

export const EditLeaveTypes: FC<Props> = ({ isOpen, onClose, leaveType }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [editLeaveType] = useEditLeaveTypeMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('settings.resourceManagement.leaveTypes.edit.notifications.success'));
      onClose();
    },
    update(cache, { data }) {
      if (!data?.editLeaveType) return;

      const updatedLeaveTypeRef = cache.writeFragment({
        data: data?.editLeaveType,
        fragment: LeaveTypeDataFragmentDoc,
      });

      cache.modify({
        id: cache.identify({ __typename: 'LeaveType', id: data.editLeaveType.id }),
        fields: {
          leaveTypes(leaveTypes: Reference[] = []) {
            return [...leaveTypes, updatedLeaveTypeRef];
          },
        },
      });
    },
  });

  const onSubmit = useCallback(
    async (data: ILeaveType) => {
      if (!userData) {
        return;
      }

      await editLeaveType({
        variables: {
          companyId: userData.company.id,
          leaveTypeId: leaveType.id,
          data: data,
        },
      });
    },
    [leaveType, userData?.company.id],
  );

  return (
    <RightDrawer
      direction="right"
      open={isOpen}
      onClose={onClose}
      title={t('settings.resourceManagement.skills.edit.title')}
    >
      <LeaveTypeForm onSubmit={onSubmit} onCancel={onClose} initialValues={leaveType} submitLabel={t('actions.edit')} />
    </RightDrawer>
  );
};
