import React, { useMemo } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export interface ForgotPasswordFormValues {
  email: string;
}

interface ForgotPassProps {
  onSubmit: (values: ForgotPasswordFormValues) => void | Promise<void>;
  onLogin?: () => void;
  email?: string;
}

export const ForgotPass = (props: ForgotPassProps) => {
  const { t } = useTranslation();
  const { email, onSubmit } = props;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email(t('forms.emailFormatError')).required(t('forms.emailRequiredError')),
      }),
    [],
  );

  const handleSubmit = async (
    values: ForgotPasswordFormValues,
    { setSubmitting }: FormikHelpers<ForgotPasswordFormValues>,
  ) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: email ?? '',
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputLabel className="required">{t('forms.emailAddress')}</InputLabel>
          <Field component={TextField} name="email" placeholder={t('forms.email')} type="email" className="mb-24" />

          <div className="flex">
            <Button type="submit" disabled={isSubmitting} className="mr-8">
              {t('forms.recoveryPassword')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
