import { Reference } from '@apollo/client';
import { RightDrawer } from 'components';
import { useAuth } from 'contexts';
import { SkillDataFragmentDoc, useEditSkillMutation } from 'generated/graphql';
import { ISkill, SkillDataFragment } from 'generated/types';
import { useErrorMsgBuilder } from 'hooks';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { SkillForm } from '../components/SkillForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  skill: SkillDataFragment;
}

export const EditSkill: FC<Props> = ({ isOpen, onClose, skill }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();

  const [editSkill] = useEditSkillMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('settings.resourceManagement.skills.edit.notifications.success'));
      onClose();
    },
    update(cache, { data }) {
      if (!data?.editSkill || data.editSkill.skillCategoryId === skill.skillCategoryId) return;

      const updatedSkillRef = cache.writeFragment({
        data: data?.editSkill,
        fragment: SkillDataFragmentDoc,
      });

      cache.modify({
        id: cache.identify({ __typename: 'SkillCategory', id: skill.skillCategoryId }),
        fields: {
          skills(skills: Reference[] = []) {
            return skills.filter((skillRef) => skillRef.__ref !== updatedSkillRef?.__ref);
          },
        },
      });

      cache.modify({
        id: cache.identify({ __typename: 'SkillCategory', id: data.editSkill.skillCategoryId }),
        fields: {
          skills(skills: Reference[] = []) {
            return [...skills, updatedSkillRef];
          },
        },
      });
    },
  });

  const { userData } = useAuth();

  const onSubmit = useCallback(
    async (data: ISkill) => {
      if (!userData) {
        return;
      }

      await editSkill({
        variables: {
          companyId: userData.company.id,
          skillId: skill.id,
          data: data,
        },
      });
    },
    [skill, userData?.company.id],
  );

  return (
    <RightDrawer
      direction="right"
      open={isOpen}
      onClose={onClose}
      title={t('settings.resourceManagement.skills.edit.title')}
    >
      <SkillForm onSubmit={onSubmit} onCancel={onClose} initialValues={skill} submitLabel={t('actions.edit')} />
    </RightDrawer>
  );
};
