import { CompanyIntegrationSyncDataFragment } from 'generated/types';
import { format, isAfter, parseISO } from 'date-fns';

export const getLastSyncDate = (integrationOptions: CompanyIntegrationSyncDataFragment[]) => {
  const item = [...integrationOptions].sort(({ lastSyncAt: lastSyncAtA }, { lastSyncAt: lastSyncAtB }) => {
    return isAfter(parseISO(lastSyncAtA), parseISO(lastSyncAtB)) ? -1 : 1;
  })[0];

  return {
    lastSyncDate: item?.lastSyncAt ? format(new Date(item.lastSyncAt), 'LLL d, h:mm a') : '',
    syncByMember: item?.syncByMember || '',
    lastSyncStatus: item?.lastSyncStatus || '',
  };
};
