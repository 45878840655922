import React, { FC } from 'react';
import { format } from 'date-fns';
import { MemberProjectAssignmentDataFragment } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { AssignmentInfo, Autocomplete, TimeLogInput } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IconButton, TextField } from '@material-ui/core';
import { CancelCircle, SubmitCircle } from 'icons';

import styles from './styles.module.scss';
import clsx from 'clsx';

export interface FormValues {
  assignment: MemberProjectAssignmentDataFragment | null;
  minutes: number;
  notes?: string | null;
  id?: string;
}

interface Props {
  date: string;
  id?: string;
  minutes?: number;
  notes?: string;
  onCancel: () => void;
  onSubmit: (value: FormValues) => void;
  assignment?: MemberProjectAssignmentDataFragment;
  assignments: MemberProjectAssignmentDataFragment[];
}

export const TimeLogWithAssignmentForm: FC<Props> = ({
  id,
  date,
  assignment,
  minutes = 0,
  notes = '',
  onCancel,
  onSubmit,
  assignments,
}) => {
  const { t } = useTranslation();

  const {
    handleChange,
    setFieldValue,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldTouched,
  } = useFormik<FormValues>({
    initialValues: { minutes, notes, assignment: assignment || null },
    validationSchema: Yup.object({
      assignment: Yup.object().nullable().required(t('validation.required')),
      minutes: Yup.number()
        .typeError(t('validation.invalid'))
        .min(0, t('validation.min', { digits: 0 }))
        .moreThan(0, t('validation.moreThan', { digits: 0 }))
        .required(t('validation.required')),
    }),
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      onSubmit({
        ...values,
        minutes: Number(values.minutes),
        id,
      });
      setSubmitting(false);
      onCancel();
    },
  });

  const renderMemberOption = ({ id, project, seniority, role }: MemberProjectAssignmentDataFragment) => {
    return (
      <div key={id} className="flex align-items-center">
        <AssignmentInfo data={{ ...project, color: project.color || '', seniority: seniority || '', role }} />
      </div>
    );
  };

  const getMemberOptionName = ({ project }: MemberProjectAssignmentDataFragment) => project.name || t('notApplicable');

  const showError = () => {
    setFieldTouched('assignment');
    setFieldTouched('minutes');
  };

  return (
    <form className={clsx(styles.box, styles.boxWithAssignment)}>
      <span className={styles.date}>{format(new Date(date), 'MMM dd, yyyy')}</span>

      <span className={styles.assignment}>
        <Autocomplete
          value={values.assignment}
          renderOption={renderMemberOption}
          placeholder={t('timeTracking.selectProjectAssignment')}
          getOptionLabel={getMemberOptionName}
          options={assignments}
          onChange={(assignment: FormValues['assignment']) => setFieldValue('assignment', assignment)}
          className="w-100"
          classes={{ inputRoot: 'white-background' }}
          error={touched.assignment ? errors.assignment : undefined}
        />
      </span>

      <span className={styles.logged}>
        <TimeLogInput
          name="minutes"
          className={styles.input}
          onChange={(minutes) => handleChange('minutes')(`${minutes}`)}
          onBlur={handleBlur}
          value={values.minutes}
          error={touched.minutes ? !!errors.minutes : undefined}
          helperText={touched.minutes && errors.minutes}
        />
      </span>

      <span className={styles.note}>
        <TextField
          name="notes"
          size="medium"
          className={styles.input}
          InputProps={{ className: 'white-background' }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.notes}
          placeholder={t('forms.typeNoteHere')}
        />
      </span>

      <div className={styles.menu}>
        <IconButton
          size="small"
          className={styles.iconButton}
          onClick={() => (Object.keys(errors).length ? showError() : handleSubmit())}
        >
          <SubmitCircle className={styles.icon} />
        </IconButton>
        <IconButton size="small" className={styles.iconButton} onClick={onCancel}>
          <CancelCircle className={styles.icon} />
        </IconButton>
      </div>
    </form>
  );
};
