import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';
import mixpanel from 'mixpanel-browser';

import {
  AbsoluteSpinner,
  Autocomplete,
  ConfirmModal,
  CustomToastWithLink,
  DatePicker,
  EmptyState,
  LoadingButton,
} from 'components';
import {
  ResourcePlanningMembersDocument,
  useCreateLeaveMutation,
  useDeleteLeaveMutation,
  useEditLeaveMutation,
  useLeaveQuery,
  useLeaveTypesQuery,
  useMemberByIdQuery,
  useTeamMembersForAssignmentsQuery,
} from 'generated/graphql';
import { addTimezoneOffset, getFullName, graphqlOnError, MembersBuilder, removeUTCTimezone, submitForm } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { ActionsType, Leave, LeaveType, Member, Scalars } from 'generated/types';
import { toast } from 'react-toastify';
import { client } from 'graphql-client';
import { links } from 'App';
import { ModalModeEnum } from 'types';

export interface LeaveFormValues {
  member: Pick<Member, 'id' | 'first_name' | 'last_name'> | null;
  leaveType: Pick<LeaveType, 'id' | 'name'> | null;
  startDate: string | Date;
  endDate: string | Date;
  notes: string;
  isMemberWithCostRates?: string;
}

interface NewLeaveProps {
  onSubmit?: (values: LeaveFormValues) => void | Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
  memberDisabled?: boolean;
  onDelete?: () => void;
  id?: string;
  memberId?: string;
}

export const NewLeave: FC<NewLeaveProps> = ({ onSubmit, onCancel, memberDisabled, submitLabel, id, memberId }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);

  const { data, loading: loadingLeave } = useLeaveQuery({
    variables: {
      companyId: userData!.company.id,
      leaveId: id!,
    },
    skip: !id || !userData!.company.id,
  });

  // TODO: we need to fetch active members only
  const { data: { members = [] } = {}, loading } = useTeamMembersForAssignmentsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const { data: { leaveTypes = [] as LeaveType[] } = {}, loading: leaveTypesLoading } = useLeaveTypesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const { data: { member } = {}, loading: loadingMember } = useMemberByIdQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      memberId: memberId!,
      withAssignments: false,
    },
    skip: !userData?.company.id || !memberId,
  });

  const [editLeave] = useEditLeaveMutation({
    onCompleted() {
      toast.success(t('resourcePlanning.leaveEditedSuccessfully'));
      onCancel();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [createLeave] = useCreateLeaveMutation({
    onCompleted(data) {
      const leaveLink = links.ResourcePlanning({ mode: ModalModeEnum.leave, id: data.createLeave.id });
      toast.success(
        CustomToastWithLink(
          leaveLink,
          t('resourcePlanning.createNewLeave'),
          t('resourcePlanning.leaveCreateSuccessfully'),
        ),
      );
      client.refetchQueries({
        include: [ResourcePlanningMembersDocument],
      });
      mixpanel.track('Leave created', {
        'Note set': !!data.createLeave.notes,
      });
      onCancel();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteLeave] = useDeleteLeaveMutation({
    onCompleted() {
      toast.success(t('resourcePlanning.leaveDeleteSuccessfully'));
      client.refetchQueries({
        include: [ResourcePlanningMembersDocument],
      });
      onCancel();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const teamMember = () => {
    const membersBuilder = new MembersBuilder(members);

    membersBuilder.sortMembersByName();
    membersBuilder.buildNonArchivedMembers();

    return membersBuilder.getMembers();
  };

  const getMemberOptionName = ({ first_name, last_name }: Member) =>
    getFullName(first_name, last_name, t('notApplicable'));

  const memberInfo = {
    id: data?.leave?.memberId ?? member?.id ?? '',
    first_name: data?.leave?.memberFirstName ?? member?.first_name ?? '',
    last_name: data?.leave?.memberLastName ?? member?.last_name ?? '',
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          member: Yup.object().nullable().required(t('forms.newLeave.memberRequired')),
          leaveType: Yup.object().nullable().required(t('forms.newLeave.leaveTypeRequired')),
          startDate: Yup.string().nullable().required(t('forms.newLeave.startRequired')),
          endDate: Yup.string().nullable().required(t('forms.newLeave.endRequired')),
          notes: Yup.string(),
        },
        [],
      ),
    [],
  );

  const handleLeave = (values: LeaveFormValues) => {
    if (onSubmit) {
      return onSubmit(values);
    }

    const dataValues = {
      leaveTypeId: values.leaveType!.id,
      startDate: addTimezoneOffset(new Date(values.startDate)),
      endDate: addTimezoneOffset(new Date(values.endDate)),
      notes: values.notes,
    };

    if (id) {
      return editLeave({
        variables: {
          data: dataValues,
          leaveId: id as string,
          companyId: userData?.company.id as string,
        },
      });
    }

    return createLeave({
      variables: { data: dataValues, memberId: values.member!.id, companyId: userData?.company.id as string },
    });
  };

  const handleDelete = () => {
    if (id && userData?.company.id) {
      deleteLeave({
        variables: {
          leaveId: id,
          companyId: userData.company.id,
        },
      });
    }
  };

  const handleSubmit = (values: LeaveFormValues, { setSubmitting }: FormikHelpers<LeaveFormValues>) => {
    submitForm(values, setSubmitting, handleLeave);
  };

  if (isPermissionsLoading || loadingMember || loadingLeave) {
    return (
      <div className={clsx('h-100', 'w-100')}>
        <AbsoluteSpinner />
      </div>
    );
  }

  if (!hasAccess(ActionsType.EditLeaves) && !hasAccess(ActionsType.CreateLeaves)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={!!id}
        initialValues={{
          member: data?.leave || member ? memberInfo : null,
          leaveType: data?.leave?.leaveType ?? null,
          startDate: data?.leave?.startDate ? removeUTCTimezone(data.leave.startDate) : new Date(),
          endDate: data?.leave?.endDate ? removeUTCTimezone(data.leave.endDate) : new Date(),
          notes: data?.leave?.notes ?? '',
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitCount, values, touched, errors, setValues, handleBlur }) => (
          <Form className="form">
            <div>
              <InputLabel className="required">{t('forms.newLeave.teamMember')}</InputLabel>
              <Field>
                {({
                  form: {
                    values: { member },
                  },
                }: FieldProps<LeaveFormValues['member'], LeaveFormValues>) => (
                  <Autocomplete
                    placeholder={t('forms.newLeave.teamMember')}
                    className="mb-24"
                    value={member}
                    name="member"
                    onBlur={handleBlur}
                    error={touched.member && errors.member}
                    disabled={memberDisabled}
                    getOptionLabel={getMemberOptionName}
                    options={loading && memberDisabled && data?.leave?.memberId ? memberInfo : teamMember()}
                    onChange={(member: LeaveFormValues['member']) => setValues({ ...values, member })}
                  />
                )}
              </Field>

              <InputLabel className="required">{t('forms.newLeave.startDateEndDate')}</InputLabel>
              <Field>
                {({
                  form: {
                    values: { startDate, endDate },
                  },
                }: FieldProps<Pick<Leave, 'startDate' | 'endDate'>, LeaveFormValues>) => {
                  return (
                    <DatePicker
                      className="mb-30"
                      value={[startDate, endDate] as const}
                      range
                      error={Boolean(submitCount && (errors.startDate || errors.endDate))}
                      helperText={!!submitCount && (errors.startDate || errors.endDate)}
                      onChange={(date: Scalars['DateTime']) => {
                        const [startDate, endDate] = date || [null, null];
                        setValues({
                          ...values,
                          startDate,
                          endDate,
                        });
                      }}
                    />
                  );
                }}
              </Field>

              <InputLabel className="required">{t('forms.newLeave.leaveType')}</InputLabel>
              <Field>
                {({
                  form: {
                    values: { leaveType },
                  },
                }: FieldProps<LeaveFormValues['leaveType'], LeaveFormValues>) => (
                  <Autocomplete
                    placeholder={t('forms.newLeave.leaveType')}
                    className="mb-24"
                    name="leaveType"
                    onBlur={handleBlur}
                    value={leaveType}
                    error={touched.leaveType && errors.leaveType}
                    options={leaveTypesLoading && data?.leave?.leaveType ? data.leave.leaveType : leaveTypes}
                    onChange={(leaveType: LeaveFormValues['leaveType']) => setValues({ ...values, leaveType })}
                  />
                )}
              </Field>

              <InputLabel>{t('forms.newLeave.notes')}</InputLabel>
              <Field
                component={TextField}
                multiline
                rows={3}
                name="notes"
                placeholder={t('forms.newLeave.notes')}
                className="mb-24"
              />
            </div>
            <div className="controls flex justify-content-between">
              <div>
                <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
                  {submitLabel ?? t('forms.newLeave.create')}
                </LoadingButton>
                <Button variant="outlined" color="secondary" onClick={onCancel} className="mr-8">
                  {t('forms.cancel')}
                </Button>
              </div>

              {id && hasAccess(ActionsType.DeleteLeaves) && (
                <>
                  <Button variant="outlined" className="danger-btn" onClick={() => setIsOpenDeleteConfirm(true)}>
                    {t('forms.delete')}
                  </Button>

                  <ConfirmModal
                    title={t('resourcePlanning.deleteLeave')}
                    submitButtonTitle={t('resourcePlanning.deleteLeave')}
                    isDelete
                    isOpen={isOpenDeleteConfirm}
                    onSubmit={() => {
                      handleDelete();
                      setIsOpenDeleteConfirm(false);
                    }}
                    onClose={() => setIsOpenDeleteConfirm(false)}
                  >
                    {t('resourcePlanning.leaveDeleteSubmissionQuestion')}
                  </ConfirmModal>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
