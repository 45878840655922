import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import { idRegEx } from 'utils/regex';
import ProjectRolesComponent from './ProjectRoles';

export const ProjectRolesRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    roleId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, roleId }) => `/settings/project-settings/project-roles/${mode}/${roleId}`,
);

export const ProjectRoles = ProjectRolesRoute(ProjectRolesComponent);
