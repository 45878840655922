import React, { memo } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { roundPercent } from 'views/Scenarios/utils/round';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils';
import { useSettings } from 'contexts';

type Props = {
  value: number;
  currency?: string | null;
  relative?: number;
  valueClassName?: string;
  relativeClassName?: string;
  showPercents?: boolean;
};

export const Price = memo<Props>(
  ({ value, relative, currency, valueClassName, relativeClassName, showPercents = true }) => {
    const { t } = useTranslation();
    const { isFinancialsHidden } = useSettings();

    return (
      <div>
        <span className={clsx(styles.value, valueClassName)}>
          {formatCurrency(value, currency, isFinancialsHidden)}
        </span>
        {!!relative && showPercents && (
          <span
            className={clsx(
              styles.relative,
              {
                [styles.relativePositive]: relative > 0,
                [styles.relativeNegative]: relative < 0,
              },
              relativeClassName,
            )}
          >
            {relative === -Infinity || relative === Infinity ? t('notApplicable') : `${roundPercent(relative)} %`}
          </span>
        )}
      </div>
    );
  },
);

Price.displayName = 'Timeline.Price';
