import React from 'react';
import { Route } from 'react-router-hoc';
import { ViewHeading } from 'components';

const ExpensesRoute = Route('/expenses');

const Expenses = () => {
  return (
    <div>
      <ViewHeading>Expenses</ViewHeading>
      <div className="layout-content-wrapper">Expenses</div>
    </div>
  );
};

export default ExpensesRoute(Expenses);
