import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import {
  useCreateMemberSpecializationMutation,
  useDeleteMemberSpecializationMutation,
  useEditMemberSpecializationMutation,
  useSpecializationsQuery,
} from 'generated/graphql';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { graphqlOnError } from 'utils';
import { ACTIONS, NAME } from 'consts';
import { RemoveConfirmIconButton, RightDrawer, Table, TableActionCell } from 'components';
import { DropdownOption, GetRouteProps, ModalModeEnum, TableCell } from 'types';
import { Specialization } from 'generated/types';
import { NewSpecialization, NewSpecializationFormValues } from './NewSpecialization';

import { IconButton, Tooltip } from '@material-ui/core';
import { EditIcon, PlusIcon } from 'icons';
import type { SpecializationRoute } from './index';

type Props = GetRouteProps<typeof SpecializationRoute>;

const Specializations: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { specializations = [] as DropdownOption[] } = {}, loading, refetch } = useSpecializationsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData?.company.id as string,
    },
  });

  const specializationToEdit = useMemo(() => {
    if (!params.specializationId) {
      return;
    }

    return specializations.find((specialization) => specialization.id === params.specializationId);
  }, [params.specializationId, specializations]);

  const onCloseCreateModal = useCallback(() => {
    push(link({ ...params, mode: undefined }));
  }, [params]);

  const onCloseEditModal = useCallback(() => {
    push(link({ ...params, mode: undefined, specializationId: undefined }));
  }, [params]);

  const [createSpecialization] = useCreateMemberSpecializationMutation({
    onCompleted() {
      toast.success(t('specialization.specializationCreatedSuccessfully'));
      onCloseCreateModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [editSpecialization] = useEditMemberSpecializationMutation({
    onCompleted() {
      toast.success(t('specialization.specializationEditedSuccessfully'));
      onCloseEditModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteSpecialization] = useDeleteMemberSpecializationMutation({
    onCompleted() {
      toast.success(t('specialization.specializationDeletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteSpecialization) });
        cache.gc();
      }
    },
  });

  const editSpecializations = (values: NewSpecializationFormValues) => {
    editSpecialization({
      variables: {
        data: {
          ...values,
        },
        companyId: userData?.company.id as string,
        memberSpecializationId: specializationToEdit?.id as string,
      },
    });
  };

  const handleCreateSpecialization = (values: NewSpecializationFormValues) => {
    createSpecialization({
      variables: {
        data: {
          ...values,
        },
        companyId: userData?.company.id as string,
      },
    });
  };

  const handleDeleteSpecialization = (id: string) =>
    deleteSpecialization({
      variables: {
        memberSpecializationId: id,
        companyId: userData?.company.id as string,
      },
    });

  const columns = useMemo(
    () => [
      {
        Header: t('columns.specialization.name'),
        accessor: NAME,
      },
      {
        Header: ' ',
        accessor: ACTIONS,
        Cell({ row: { original }, isHovered }: TableCell<Specialization>) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  onClick={() => push(link({ mode: ModalModeEnum.edit, specializationId: original.id }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => handleDeleteSpecialization(original.id)}
                confirmTitle={t('specialization.deleteSpecialization')}
                confirmMessage={t('specialization.deleteSpecializationQuestion')}
                confirmSubmitButtonTitle={t('specialization.deleteSpecialization')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('specialization.name')}</h2>

        <Button className="mr-8" onClick={() => push(link({ mode: ModalModeEnum.create }))} startIcon={<PlusIcon />}>
          {t('specialization.createSpecialization')}
        </Button>
      </div>

      <Table data={specializations as any} columns={columns as any} loading={loading} />

      <RightDrawer
        open={params.mode === ModalModeEnum.create}
        onClose={onCloseCreateModal}
        title={t('specialization.createSpecialization')}
      >
        <NewSpecialization onSubmit={handleCreateSpecialization} onCancel={onCloseCreateModal} />
      </RightDrawer>

      <RightDrawer
        open={!!specializationToEdit}
        onClose={onCloseEditModal}
        title={t('specialization.editSpecialization')}
      >
        <NewSpecialization
          onSubmit={editSpecializations}
          name={specializationToEdit?.name}
          submitLabel={t('actions.save')}
          onCancel={onCloseEditModal}
        />
      </RightDrawer>
    </>
  );
};

export default Specializations;
