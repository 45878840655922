import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { ModalModeEnum } from 'types';

export const AssignmentsRoute = Route(
  {
    id: Route.params.regex(idRegEx),
    assignment: Route.params.regex(idRegEx).optional,
    mode: Route.params.oneOf(...Object.values(ModalModeEnum)).optional,
  },
  ({ id, assignment, mode }) => `/team-member/view/${id}/${mode}/${assignment}/`,
);

export const Assignments = AssignmentsRoute(lazy(() => import(/* webpackChunkName: "Assignments" */ './Assignments')));
