import React, { useMemo, useState } from 'react';
import { Route } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';

import {
  DropdownMenu,
  NewNetPay,
  NewNetPayFormValues,
  NewPayrollEntity,
  PayrollEntityFormValues,
  RightDrawer,
  Table,
  ViewHeading,
} from 'components';
import { RouteProps, TableCell } from 'types';
import { ACTIONS, EMPTY_STATE, NAME } from 'consts';
import { currencyToValue, formatByThousands, getFullName, graphqlOnError, valueToCurrency } from 'utils';
import {
  useCreatePayrollEntityMutation,
  useDeletePayrollEntityMutation,
  useEditPayrollEntityMutation,
  usePayrollByIdQuery,
} from 'generated/graphql';
import { EmploymentType, PayrollEntity } from 'generated/types';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';

const ViewPayrollRecordRoute = Route({ id: Route.params.string }, ({ id }) => `/payroll/view/${id}`);

const PayrollRecord = ({
  match: {
    params: { id },
  },
}: RouteProps) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openPayrollEntityDialog, setOpenPayrollEntityDialog] = useState<boolean>(false);
  const [payrollToEdit, setPayrollToEdit] = useState<PayrollEntity | null>(null);

  const { data: { payrollEntities = [] as PayrollEntity[] } = {}, refetch, loading } = usePayrollByIdQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData?.company.id as string,
      payrollId: id,
    },
  });

  const filteredPayrollEntities = useMemo(
    () =>
      payrollEntities.length
        ? payrollEntities
            .slice()
            .sort(
              (
                { member: { first_name: firstNameA, last_name: lastNameA } },
                { member: { first_name: firstNameB, last_name: lastNameB } },
              ) => {
                const aFullName = `${firstNameA} ${lastNameA}`;
                const bFullName = `${firstNameB} ${lastNameB}`;

                return aFullName < bFullName ? -1 : 1;
              },
            )
        : [],
    [payrollEntities],
  );

  const [createPayrollEntity] = useCreatePayrollEntityMutation({
    onCompleted() {
      toast.success(t('payrollEntity.payrollEntityCreatedSuccessfully'));
      handlePayrollEntityDialogClose();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [editPayrollEntity] = useEditPayrollEntityMutation({
    onCompleted() {
      toast.success(t('payrollEntity.payrollEntityEditedSuccessfully'));
      handleDialogClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deletePayrollEntity] = useDeletePayrollEntityMutation({
    onCompleted() {
      toast.success(t('payrollEntity.payrollEntityDeletedSuccessfully'));
      refetch();
      handleDialogClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const handleDialogClose = () => setOpenDialog(false);

  const handleDialogOpen = () => setOpenDialog(true);

  const handlePayrollEntityDialogClose = () => setOpenPayrollEntityDialog(false);

  const handlePayrollEntityDialogOpen = () => setOpenPayrollEntityDialog(true);

  const handlePayrollEntityCreate = async ({ net_pay, notes, member }: PayrollEntityFormValues) => {
    await createPayrollEntity({
      variables: {
        companyId: userData?.company.id as string,
        payrollId: id,
        memberId: member!.id,
        data: {
          // TODO
          processed: false,
          net_pay: currencyToValue(net_pay),
          notes,
        },
      },
    });
  };

  const handleSubmit = async ({ net_pay, notes }: NewNetPayFormValues) => {
    await editPayrollEntity({
      variables: {
        companyId: userData?.company.id as string,
        payrollEntityId: payrollToEdit?.id as string,
        data: {
          processed: false,
          net_pay: currencyToValue(net_pay),
          notes,
        },
      },
    });
  };

  const handlePayrollToEdit = (payrollEntity: PayrollEntity) => {
    setPayrollToEdit(payrollEntity);
    handleDialogOpen();
  };

  const handleDeletePayroll = (payrollEntityId: string) => {
    confirm(t('payrollEntity.confirmDeletePayroll')) &&
      deletePayrollEntity({
        variables: {
          companyId: userData?.company.id as string,
          payrollEntityId,
        },
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: t('columns.payrollEntity.member'),
        accessor: NAME,
        Cell: function specialization({
          row: {
            original: {
              member: { first_name, last_name },
            },
          },
        }: TableCell<PayrollEntity>) {
          return <span className="weight-600">{getFullName(first_name, last_name, t('notApplicable'))}</span>;
        },
      },
      {
        Header: t('columns.payrollEntity.workDays'),
        accessor: '',
        Cell: function total({
          row: {
            original: {
              work_days,
              member: { employment_type },
            },
          },
        }: TableCell<PayrollEntity>) {
          return (
            <span className="weight-600">{employment_type === EmploymentType.Employee ? work_days : EMPTY_STATE}</span>
          );
        },
      },
      {
        Header: t('columns.payrollEntity.leaves'),
        accessor: '',
        Cell: function paidLeaves({
          row: {
            original: {
              leaves,
              member: { employment_type },
            },
          },
        }: TableCell<PayrollEntity>) {
          return (
            <span className="weight-600">{employment_type === EmploymentType.Employee ? leaves : EMPTY_STATE}</span>
          );
        },
      },
      {
        Header: t('columns.payrollEntity.netPay'),
        accessor: '',
        Cell: function netPay({
          row: {
            original: { net_pay },
          },
        }: TableCell<PayrollEntity>) {
          return <span className="weight-600">{formatByThousands(valueToCurrency(net_pay).toFixed(2))}</span>;
        },
      },
      {
        Header: ' ',
        accessor: ACTIONS,
        Cell: function action({ row: { original } }: TableCell<PayrollEntity>) {
          return (
            <DropdownMenu>
              {(handleClose) => [
                <MenuItem key="view-option" onClick={() => [handlePayrollToEdit(original), handleClose()]}>
                  {t('actions.edit')}
                </MenuItem>,
                <MenuItem
                  key="delete-option"
                  className="text-orange-dark"
                  onClick={() => [handleDeletePayroll(original.id), handleClose()]}
                >
                  {t('actions.delete')}
                </MenuItem>,
              ]}
            </DropdownMenu>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <ViewHeading label={`${!loading ? payrollEntities[0].payroll.name : ''} ${t('payrollEntity.label')}`}>
        <div className="flex align-self-stretch">
          <Button variant="outlined" onClick={handlePayrollEntityDialogOpen}>
            {t('payrollEntity.addPayrollEntity')}
          </Button>
        </div>
      </ViewHeading>

      <div className="layout-content-wrapper">
        <Table data={filteredPayrollEntities as any} columns={columns as any} loading={loading} />
      </div>

      <RightDrawer
        direction="right"
        open={openPayrollEntityDialog}
        onClose={handlePayrollEntityDialogClose}
        title={t('payrollEntity.addPayrollEntity')}
      >
        <NewPayrollEntity
          onSubmit={handlePayrollEntityCreate}
          onCancel={handlePayrollEntityDialogClose}
          submitLabel={t('payrollEntity.addPayrollEntity')}
        />
      </RightDrawer>

      <RightDrawer
        direction="right"
        open={openDialog}
        onClose={handleDialogClose}
        title={t('payrollEntity.editPayroll')}
      >
        <NewNetPay
          net_pay={valueToCurrency(payrollToEdit?.net_pay as number)}
          notes={payrollToEdit?.notes as string}
          onSubmit={handleSubmit}
          onCancel={handleDialogClose}
          submitLabel={t('payrollEntity.editPayroll')}
        />
      </RightDrawer>
    </>
  );
};

export default ViewPayrollRecordRoute(PayrollRecord);
