import React, { FC, useMemo } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from 'formik-material-ui';
import clsx from 'clsx';

import {
  Autocomplete,
  CreatableAutocomplete,
  DatePicker,
  EmptyState,
  LoadingButton,
  NumberTextField,
} from 'components';
import { DropdownOption } from 'types';
import {
  ActionsType,
  CompanyUser,
  EmploymentType,
  Member,
  MemberSeniority,
  Scalars,
  Specialization,
  Vendor,
} from 'generated/types';
import { UsersBuilder } from 'utils/usersBuilder';
import { useProjectManagersQuery, useRolesQuery, useTeamMembersQuery } from 'generated/graphql';
import { useAuth } from 'contexts';
import { getFullName, graphqlOnError, MembersBuilder, removeUTCTimezone, sortByField } from 'utils';
import {
  useCreatableSeniorityData,
  useCreatableSpecializationData,
  useCreatableVendorData,
  useErrorMsgBuilder,
  usePermissions,
} from 'hooks';
import { ASC, NAME } from 'consts';
import { DashIcon } from 'icons';
import { SectionCollapse } from 'components/SectionCollapse';

import { ScrollToError } from '../ScrollToError';

import styles from './NewTeamMember.module.scss';

export interface TeamMemberFormValues {
  first_name: string;
  last_name: string;
  email: string;
  employment_type: { id: EmploymentType; name: string };
  job_title: string;
  member_role: DropdownOption[];
  join_date: Date | string | null;
  exit_date: Date | string | null;
  seniority: Pick<MemberSeniority, 'id' | 'name'> | null;
  reportingTo: Pick<Member, 'id' | 'first_name' | 'last_name'> | null;
  costRateManagers: Pick<Member, 'id' | 'first_name' | 'last_name'>[];
  specialization: Pick<Specialization, 'id' | 'name'> | null;
  vendor: Pick<Vendor, 'id' | 'name'> | null;
  capacity: number;
}

interface NewUserProps {
  onSubmit: (values: TeamMemberFormValues) => void | Promise<void>;
  first_name?: string | null;
  last_name?: string | null;
  email?: string;
  employment_type?: EmploymentType;
  job_title?: string | null;
  reportingTo?: Pick<Member, 'id' | 'first_name' | 'last_name'> | null;
  join_date?: string;
  exit_date?: string;
  member_role?: DropdownOption[];
  costRateManagers?: Pick<Member, 'id' | 'first_name' | 'last_name'>[];
  seniority?: Pick<MemberSeniority, 'id' | 'name'> | null;
  specialization?: Pick<Specialization, 'id' | 'name'> | null;
  vendor?: Pick<Vendor, 'id' | 'name'> | null;
  capacity?: number | null;
  onCancel: () => void;
  submitLabel?: string;
  isEditing?: boolean;
}

const initialCapacityValue = 8;

export const NewTeamMember: FC<NewUserProps> = ({
  first_name,
  last_name,
  email,
  employment_type,
  job_title,
  join_date,
  exit_date,
  member_role,
  costRateManagers,
  seniority,
  specialization,
  vendor,
  capacity,
  reportingTo,
  submitLabel,
  onSubmit,
  onCancel,
  isEditing,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const employmentTypes = useMemo(
    () => [
      { id: EmploymentType.Contractor, name: t('employmentType.contractor') },
      { id: EmploymentType.Employee, name: t('employmentType.employee') },
    ],
    [],
  );

  const { seniorities, senioritiesLoading, getCreatedSeniority } = useCreatableSeniorityData();
  const { specializations, specializationLoading, getCreatedSpecialization } = useCreatableSpecializationData();
  const { vendors, vendorsLoading, getCreatedVendor } = useCreatableVendorData();

  const { data: roles, loading: rolesLoading } = useRolesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const { data: { users = [] as CompanyUser[] } = {}, loading: usersLoading } = useProjectManagersQuery({
    skip: !hasAccess(ActionsType.CreateMembers) && !hasAccess(ActionsType.EditMembers),
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const { data: { members = [] as Member[] } = {}, loading: membersLoading } = useTeamMembersQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const getManagersOptionName = ({ first_name, last_name }: Member) =>
    getFullName(first_name, last_name, t('notApplicable'));

  const getRoles = ({ member_role }: TeamMemberFormValues) =>
    roles?.roles.filter(({ id }) => !member_role.map(({ id }) => id).includes(id));

  const sortedSpecializations = useMemo(() => sortByField(specializations, ASC, NAME), [specializations]);

  const projectManagers = () => {
    const usersBuilder = new UsersBuilder(users as CompanyUser[]);

    usersBuilder.sortMembersByName();
    usersBuilder.buildRestoredUsers();

    usersBuilder.buildActiveUsers();
    usersBuilder.buildNonArchivedUsers();

    return usersBuilder.getUsers().map(({ member }) => member);
  };

  const costRateAccessManagers = ({ costRateManagers }: TeamMemberFormValues) => {
    const membersBuilder = new MembersBuilder(members as Member[]);

    membersBuilder.sortMembersByName();
    membersBuilder.buildActiveMembers();
    membersBuilder.buildNonArchivedMembers();
    membersBuilder.buildExistingCompanyUsers();

    return membersBuilder.getMembers().filter(({ id }) => !costRateManagers.map(({ id }) => id).includes(id));
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          first_name: Yup.string()
            .required(t('forms.newTeamMember.firstName.error'))
            .matches(/^\s*\S[\s\S]*$/, t('validation.blankspaces')),
          last_name: Yup.string()
            .required(t('forms.newTeamMember.lastName.error'))
            .matches(/^\s*\S[\s\S]*$/, t('validation.blankspaces')),
          email: Yup.string().email(t('forms.emailFormatError')).required(t('forms.emailRequiredError')),
          employment_type: Yup.object().nullable().required(t('forms.newTeamMember.employeeType.error')),
          job_title: Yup.string(),
          member_role: Yup.array(Yup.object()),
          costRateManagers: Yup.array(Yup.object()),
          seniority: Yup.object().nullable(),
          vendor: Yup.object().nullable(),
          reportingTo: Yup.object().nullable(),
          specialization: Yup.object().nullable(),
          capacity: Yup.number()
            .required(t('validation.required'))
            .max(12, t('forms.newTeamMember.capacityError.max'))
            .test('maxDigitsAfterDecimal', t('forms.newTeamMember.capacityError.maxDecimal'), (number) =>
              /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
            ),
          join_date: Yup.string().nullable(),
          exit_date: Yup.string().nullable(),
        },
        [['join_date', 'exit_date']],
      ),
    [],
  );

  if (isPermissionsLoading) {
    return <CircularProgress size={64} style={{ color: '#24343D' }} />;
  }

  if (
    !hasAccess(ActionsType.EditMembers) &&
    !hasAccess(ActionsType.EditArchivedMembers) &&
    !hasAccess(ActionsType.CreateMembers)
  ) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  const handleSubmit = async (values: TeamMemberFormValues) => {
    const seniority = await getCreatedSeniority(values.seniority);
    const specialization = await getCreatedSpecialization(values.specialization);
    const vendor = await getCreatedVendor(values.vendor);
    onSubmit({ ...values, seniority, specialization, vendor });
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        first_name: first_name ?? '',
        last_name: last_name ?? '',
        email: email ?? '',
        employment_type: employmentTypes.find(({ id }) => id === employment_type) ?? employmentTypes[1],
        job_title: job_title ?? '',
        join_date: join_date ? new Date(removeUTCTimezone(join_date)) : null,
        exit_date: exit_date ? new Date(removeUTCTimezone(exit_date)) : null,
        reportingTo: reportingTo ?? null,
        member_role: member_role ?? [],
        costRateManagers: costRateManagers ?? [],
        seniority: seniority ?? null,
        specialization: specialization ?? null,
        capacity: capacity ?? initialCapacityValue,
        vendor: vendor ?? null,
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitCount, touched, errors, values, setValues, handleBlur }) => (
        <Form className="form">
          <div>
            <SectionCollapse
              error={
                !!(submitCount && (errors.first_name || errors.last_name || errors.email || errors.employment_type))
              }
              title={t('forms.newTeamMember.basicInformation')}
              defaultOpen
            >
              <InputLabel required>{t('forms.newTeamMember.firstName.label')}</InputLabel>
              <Field component={TextField} name="first_name" type="text" className="mb-24" />
              <InputLabel required>{t('forms.newTeamMember.lastName.label')}</InputLabel>
              <Field component={TextField} name="last_name" type="text" className="mb-24" />
              <InputLabel required>{t('forms.emailAddress')}</InputLabel>
              <Field component={TextField} name="email" type="email" className="mb-24" />
              <InputLabel required>{t('forms.newTeamMember.employeeType.label')}</InputLabel>
              <Field>
                {({
                  form: {
                    values: { employment_type },
                  },
                }: FieldProps<TeamMemberFormValues['employment_type'], TeamMemberFormValues>) => (
                  <Autocomplete
                    placeholder={t('forms.newTeamMember.employeeType.placeholder')}
                    options={employmentTypes}
                    className="mb-24"
                    name="employment_type"
                    onBlur={handleBlur}
                    value={employment_type}
                    error={touched.employment_type ? errors.employment_type : undefined}
                    onChange={(employment_type: TeamMemberFormValues['employment_type']) =>
                      setValues({ ...values, employment_type })
                    }
                  />
                )}
              </Field>

              {values.employment_type?.id === EmploymentType.Contractor && (
                <>
                  <InputLabel>{t('forms.newTeamMember.vendor.label')}</InputLabel>
                  <Field>
                    {({
                      form: {
                        values: { vendor },
                      },
                    }: FieldProps<TeamMemberFormValues['vendor'], TeamMemberFormValues>) => (
                      <CreatableAutocomplete
                        placeholder={t('forms.newTeamMember.vendor.placeholder')}
                        className="mb-24"
                        name="vendor"
                        onBlur={handleBlur}
                        value={vendor}
                        error={touched.vendor ? errors.vendor : undefined}
                        options={vendorsLoading && vendor ? [vendor] : vendors}
                        onChange={(vendor: TeamMemberFormValues['vendor']) => setValues({ ...values, vendor })}
                        enableCreation={hasAccess(ActionsType.OtherSettings)}
                      />
                    )}
                  </Field>
                </>
              )}

              <InputLabel>{t('forms.newTeamMember.jobTitle.label')}</InputLabel>
              <Field component={TextField} name="job_title" type="text" className="mb-24" />
            </SectionCollapse>
            <SectionCollapse
              title={t('forms.newTeamMember.workInformation')}
              error={!!(submitCount && (errors.join_date || errors.capacity))}
              defaultOpen
            >
              <InputLabel>{t('forms.newTeamMember.reportingTo.label')}</InputLabel>
              <Field>
                {({
                  form: {
                    values: { reportingTo },
                  },
                }: FieldProps<TeamMemberFormValues['reportingTo'], TeamMemberFormValues>) => (
                  <Autocomplete
                    placeholder={t('forms.newTeamMember.reportingTo.placeholder')}
                    className="mb-24"
                    value={reportingTo}
                    name="reportingTo"
                    onBlur={handleBlur}
                    error={touched.reportingTo ? errors.reportingTo : undefined}
                    options={usersLoading && reportingTo ? reportingTo : projectManagers()}
                    getOptionLabel={getManagersOptionName}
                    onChange={(reportingTo: TeamMemberFormValues['reportingTo']) =>
                      setValues({ ...values, reportingTo })
                    }
                  />
                )}
              </Field>

              {isEditing && (
                <>
                  <InputLabel>{t('forms.newTeamMember.costRateAccess.label')}</InputLabel>
                  <Field>
                    {({
                      form: {
                        values: { costRateManagers },
                      },
                    }: FieldProps<TeamMemberFormValues['costRateManagers'], TeamMemberFormValues>) => (
                      <Autocomplete
                        placeholder={t('forms.newTeamMember.costRateAccess.placeholder')}
                        className="mb-24"
                        multiple
                        value={costRateManagers}
                        name="costRateManagers"
                        onBlur={handleBlur}
                        error={touched.costRateManagers ? errors.costRateManagers : undefined}
                        options={
                          membersLoading && costRateManagers ? [...costRateManagers] : costRateAccessManagers(values)
                        }
                        getOptionLabel={getManagersOptionName}
                        onChange={(costRateManagers: TeamMemberFormValues['costRateManagers']) =>
                          setValues({ ...values, costRateManagers })
                        }
                      />
                    )}
                  </Field>
                </>
              )}
              <div className="flex gap-16">
                <div className={styles.datePickerBox}>
                  <InputLabel>{t('forms.newTeamMember.joiningDate')}</InputLabel>
                  <Field>
                    {({
                      form: {
                        values: { join_date },
                      },
                    }: FieldProps<Pick<TeamMemberFormValues, 'join_date'>, TeamMemberFormValues>) => {
                      return (
                        <DatePicker
                          value={join_date}
                          className="mb-24"
                          placeholder={t('forms.newTeamMember.datePlaceholder')}
                          error={Boolean(submitCount && errors.join_date)}
                          helperText={!!submitCount && errors.join_date}
                          onChange={(join_date: Scalars['DateTime']) =>
                            setValues({
                              ...values,
                              join_date,
                            })
                          }
                        />
                      );
                    }}
                  </Field>
                </div>

                {isEditing && (
                  <>
                    <div className="pt-36">
                      <DashIcon />
                    </div>
                    <div>
                      <InputLabel>{t('forms.newTeamMember.exitDate')}</InputLabel>
                      <Field>
                        {({
                          form: {
                            values: { exit_date },
                          },
                        }: FieldProps<Pick<TeamMemberFormValues, 'exit_date'>, TeamMemberFormValues>) => {
                          return (
                            <DatePicker
                              value={exit_date}
                              className="mb-24"
                              placeholder={t('forms.newTeamMember.datePlaceholder')}
                              error={Boolean(submitCount && errors.exit_date)}
                              helperText={!!submitCount && errors.exit_date}
                              onChange={(exit_date: Scalars['DateTime']) =>
                                setValues({
                                  ...values,
                                  exit_date,
                                })
                              }
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </>
                )}
              </div>
              {isEditing && (
                <>
                  <InputLabel>{t('forms.newTeamMember.projectRoles.label')}</InputLabel>
                  <Field>
                    {({
                      form: {
                        values: { member_role },
                      },
                    }: FieldProps<TeamMemberFormValues['member_role'], TeamMemberFormValues>) => (
                      <Autocomplete
                        placeholder={t('forms.newTeamMember.projectRoles.placeholder')}
                        className="mb-24"
                        multiple
                        name="member_role"
                        onBlur={handleBlur}
                        value={member_role}
                        error={touched.member_role ? errors.member_role : undefined}
                        options={rolesLoading && member_role ? [...member_role] : getRoles(values)}
                        onChange={(member_role: TeamMemberFormValues['member_role']) =>
                          setValues({ ...values, member_role })
                        }
                      />
                    )}
                  </Field>
                </>
              )}

              <InputLabel>{t('forms.newTeamMember.seniority.label')}</InputLabel>
              <Field>
                {({
                  form: {
                    values: { seniority },
                  },
                }: FieldProps<TeamMemberFormValues['seniority'], TeamMemberFormValues>) => (
                  <CreatableAutocomplete
                    placeholder={t('forms.newTeamMember.seniority.placeholder')}
                    className="mb-24"
                    name="seniority"
                    onBlur={handleBlur}
                    value={seniority}
                    error={touched.seniority ? errors.seniority : undefined}
                    options={senioritiesLoading && seniority ? [seniority] : seniorities}
                    onChange={(seniority: TeamMemberFormValues['seniority']) => setValues({ ...values, seniority })}
                    enableCreation={hasAccess(ActionsType.OtherSettings)}
                  />
                )}
              </Field>
              <InputLabel>{t('forms.newTeamMember.specialization.label')}</InputLabel>
              <Field>
                {({
                  form: {
                    values: { specialization },
                  },
                }: FieldProps<TeamMemberFormValues['specialization'], TeamMemberFormValues>) => (
                  <CreatableAutocomplete
                    placeholder={t('forms.newTeamMember.specialization.placeholder')}
                    className="mb-24"
                    name="specialization"
                    onBlur={handleBlur}
                    value={specialization}
                    error={touched.specialization ? errors.specialization : undefined}
                    options={specializationLoading && specialization ? [specialization] : sortedSpecializations}
                    onChange={(specialization: TeamMemberFormValues['specialization']) =>
                      setValues({ ...values, specialization })
                    }
                    enableCreation={hasAccess(ActionsType.OtherSettings)}
                  />
                )}
              </Field>
              {isEditing && (
                <>
                  <InputLabel>{t('forms.newTeamMember.capacity')}</InputLabel>
                  <Field
                    component={NumberTextField}
                    name="capacity"
                    className={clsx(styles.capacityField, 'mb-24')}
                    error={touched.capacity ? errors.capacity : undefined}
                    helperText={!!submitCount && errors.capacity}
                  />
                </>
              )}
            </SectionCollapse>
          </div>

          <div className="controls">
            <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
              {submitLabel ?? t('actions.create')}
            </LoadingButton>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {t('forms.cancel')}
            </Button>
          </div>
          <ScrollToError isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
