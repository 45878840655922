import React, { FC } from 'react';
import styles from './styles.module.scss';
import {
  AvailableIntegrationDataFragment,
  CompanyIntegrationDataFragment,
  IntegrationStatus,
  SyncStatus,
} from 'generated/types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { getIntegrationIcon, getLastSyncDate } from '../../helpers';
import { IntegrationFields } from '../IntegrationFields';
import { RefreshIcon } from 'icons';
import { ConfirmModal } from 'components';
import { useIsOpen } from 'hooks';

interface Props {
  item: AvailableIntegrationDataFragment & {
    integration?: CompanyIntegrationDataFragment;
  };
  onChangeStatus: (id: string, status: IntegrationStatus) => void;
  onConnect: () => void;
  onEdit: () => void;
  onSync: () => void;
}

export const IntegrationItem: FC<Props> = ({ item, onChangeStatus, onEdit, onSync, onConnect }) => {
  const { name, integration } = item;
  const { t } = useTranslation();
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen();

  const isConnected = integration?.status === IntegrationStatus.Connected;
  const Icon = getIntegrationIcon(name);
  const { lastSyncDate, syncByMember, lastSyncStatus } = getLastSyncDate(integration?.integrationOptions || []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {Icon && <Icon />}
        <h4 className={styles.title}>{name}</h4>
        <span className={clsx(styles.statusBox, isConnected ? styles.connected : styles.disconnected)}>
          {isConnected
            ? t('settings.integrations.integration.connected')
            : t('settings.integrations.integration.notConnected')}
        </span>
        {lastSyncDate && lastSyncStatus !== SyncStatus.Pending && (
          <span className={styles.date}>
            {t('settings.integrations.integration.lastSyncedOn')}&nbsp;
            {lastSyncDate}
            {syncByMember && ` ${t('settings.integrations.integration.by')} ${syncByMember}`}
          </span>
        )}
        {lastSyncStatus === SyncStatus.Pending && (
          <span className={styles.date}>{t('settings.integrations.integration.syncInProgress')}</span>
        )}
      </div>
      <span className={styles.text}>{t('settings.integrations.integration.title', { name })}</span>
      <span className={styles.text}>{t('settings.integrations.integration.description', { name })}</span>

      {isConnected && integration && (
        <div className="mb-16">
          <IntegrationFields integration={integration} />
        </div>
      )}

      <div className={styles.buttonBox}>
        {isConnected ? (
          <>
            <Button variant="outlined" color="secondary" onClick={onEdit}>
              {t('settings.integrations.integration.edit')}
            </Button>
            <Button color="secondary" className={clsx(styles.button, styles.disconnectButton)} onClick={onOpenConfirm}>
              {t('settings.integrations.integration.disconnect')}
            </Button>
          </>
        ) : (
          <Button
            color="secondary"
            className={clsx(styles.button, styles.connectButton)}
            onClick={() => (integration ? onChangeStatus(integration!.id, IntegrationStatus.Connected) : onConnect())}
          >
            {t('settings.integrations.integration.connect')}
          </Button>
        )}
      </div>
      {isConnected && (
        <Button
          className="mt-16"
          variant="outlined"
          color="secondary"
          onClick={onSync}
          startIcon={<RefreshIcon className={clsx(lastSyncStatus === SyncStatus.Pending && styles.disableIcon)} />}
          disabled={lastSyncStatus === SyncStatus.Pending}
        >
          {t('settings.integrations.integration.syncNow')}
        </Button>
      )}

      <ConfirmModal
        title={`${t('settings.integrations.integration.disconnect')} ${item.name}`}
        submitButtonTitle={t('settings.integrations.integration.disconnect')}
        isDelete
        hideDeleteIcon
        isOpen={isOpenConfirm}
        onSubmit={() => {
          onChangeStatus(integration!.id, IntegrationStatus.Disconnected);
          onCloseConfirm();
        }}
        onClose={onCloseConfirm}
      >
        {t('settings.integrations.integration.confirmDisconnectMessage')}
      </ConfirmModal>
    </div>
  );
};
