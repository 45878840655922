import { endOfMonth, isAfter, startOfMonth } from 'date-fns';

interface Props {
  monthDay: Date;
  assignmentStartDate: Date;
  assignmentEndDate: Date;
}
export const getAssignmentMonthPeriod = ({ monthDay, assignmentStartDate, assignmentEndDate }: Props) => {
  const startOfMonthDate = startOfMonth(monthDay);
  const endOfMonthDate = endOfMonth(monthDay);
  return {
    startDate: isAfter(startOfMonthDate, assignmentStartDate) ? startOfMonthDate : assignmentStartDate,
    endDate: isAfter(assignmentEndDate, endOfMonthDate) ? endOfMonthDate : assignmentEndDate,
  };
};
