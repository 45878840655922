import { ASC, FIRST_NAME, LAST_NAME } from 'consts';
import { CompanyUser } from 'generated/types';
import { sortUsersByField } from 'utils';

export class UsersBuilder {
  private users: CompanyUser[];

  constructor(users: CompanyUser[]) {
    this.users = users;
  }

  sortMembersByName() {
    this.users = sortUsersByField(this.users, ASC, FIRST_NAME, LAST_NAME);
  }

  buildRestoredUsers() {
    this.users = this.users.filter(({ deactivatedAt }) => !deactivatedAt);
  }

  buildActiveUsers() {
    this.users = this.users.filter(({ member }) => !member.is_pending);
  }

  buildNonArchivedUsers() {
    this.users = this.users.filter(({ member }) => !member.archived_at);
  }

  getUsers() {
    return this.users;
  }
}
