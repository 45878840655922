import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { addBusinessDays, addDays, differenceInBusinessDays, differenceInCalendarDays, minutesInHour } from 'date-fns';
import { OptionalDeep } from 'ts-toolbelt/out/Object/Optional';
import { useHistory, useRouteMatch } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { DraggableEvent } from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { throttle } from 'lodash';

import { ActionsType, Assignment as AssignmentType, Scalars } from 'generated/types';
import { useEditAssignmentMutation } from 'generated/graphql';

import { useQueryParams } from 'utils/useQueryParams';
import { graphqlOnError } from 'utils';
import { MONTH_PERIOD } from 'consts';

import { useErrorMsgBuilder } from 'hooks/useErrorMsgBuilder';
import { useAuth, useTimelineContext } from 'contexts';
import { links } from 'App';
import { usePermissions } from './usePermissions';
import { ModalModeEnum } from 'types';
import { useEditDatesAssignmentRequest } from './useEditDatesAssignmentRequest';

export type Boundaries = {
  left: number;
  right: number;
};

export type TimelineRange = {
  start: Date;
  end: Date;
};

export type AssignmentHandle = {
  startDateRef: HTMLDivElement | null;
  endDateRef: HTMLDivElement | null;
  assignmentId?: string;
  resetPosition: () => void;
};

type MemberAssignmentType = OptionalDeep<AssignmentType> | undefined;

const getDaysDiff = (startPosition: number, event: DraggableEvent, cellWidth?: number) => {
  return cellWidth && 'pageX' in event ? Math.round((event.pageX - startPosition) / cellWidth) : 0;
};

export const useResizeAndMoveAssignment = (
  data: MemberAssignmentType,
  assignedRef: MutableRefObject<AssignmentHandle | null>,
  days: Date[],
  memberId?: string,
  projectId?: string,
  isRequest?: boolean,
): {
  assignmentsData: MemberAssignmentType;
  isDragging: boolean;
  onDrag: () => void;
  onStart: (e: DraggableEvent) => void;
  onStop: (e: DraggableEvent) => void;
} => {
  const { params } = useRouteMatch();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { t } = useTranslation();
  const query = useQueryParams();
  const { push } = useHistory();
  const { hasAccess } = usePermissions();
  const { cellWidth, collapsedWeekends, timelinePeriod } = useTimelineContext();

  const realTimeAssignments = useRef<MemberAssignmentType>(data);
  const draggingEdge = useRef<'start' | 'end' | null>(null);
  const startPosition = useRef(0);
  const prevDaysDiff = useRef(0);
  const cellWidthRef = useRef(cellWidth);
  const monthTimelineViewRef = useRef(timelinePeriod === MONTH_PERIOD);
  const collapsedWeekendsRef = useRef(collapsedWeekends);
  const prevPageX = useRef<number | null>(null);

  const [assignmentsData, setAssignmentsData] = useState<MemberAssignmentType>(data || {});
  const [isDragging, setIsDragging] = useState(false);

  const [editAssignment] = useEditAssignmentMutation({
    onCompleted() {
      toast.success(t('forms.newAssignment.editedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const { onEditAssignmentRequest } = useEditDatesAssignmentRequest(data?.id);

  const timelineRange: TimelineRange = {
    start: days[0],
    end: days[days.length - 1],
  };

  const getNewDateRange = (
    startDate: Scalars['DateTime'],
    endDate: Scalars['DateTime'],
    daysChange: number,
  ): { startDate: Scalars['DateTime']; endDate: Scalars['DateTime'] } => {
    const maxStartDiff = differenceInCalendarDays(new Date(timelineRange.start), new Date(startDate));
    const maxEndDiff = differenceInCalendarDays(new Date(timelineRange.end), new Date(endDate));
    const maxStartBusinessDiff = differenceInBusinessDays(new Date(timelineRange.start), new Date(startDate));
    const maxEndBusinessDiff = differenceInBusinessDays(new Date(timelineRange.end), new Date(endDate));

    const addDaysFunc = collapsedWeekendsRef.current && monthTimelineViewRef.current ? addBusinessDays : addDays;

    if (daysChange < 0) {
      const edgeCase = collapsedWeekendsRef.current ? maxStartBusinessDiff : maxStartDiff;
      const diff = daysChange < edgeCase ? edgeCase : daysChange;

      return {
        startDate: addDaysFunc(new Date(startDate.toString()), diff).toISOString(),
        endDate: addDaysFunc(new Date(endDate.toString()), diff).toISOString(),
      };
    }

    const edgeCase = collapsedWeekendsRef.current ? maxEndBusinessDiff : maxEndDiff;
    const diff = daysChange > edgeCase ? edgeCase : daysChange;

    return {
      startDate: addDaysFunc(new Date(startDate.toString()), diff).toISOString(),
      endDate: addDaysFunc(new Date(endDate.toString()), diff).toISOString(),
    };
  };

  const getNewStartDate = (
    startDate: Scalars['DateTime'],
    endDate: Scalars['DateTime'],
    daysChange: number,
  ): Scalars['DateTime'] => {
    const maxCalendarDiff = differenceInCalendarDays(new Date(endDate), new Date(startDate));
    const maxBusinessDiff = differenceInBusinessDays(new Date(endDate), new Date(startDate));
    const maxEdgeCalendarDiff = differenceInCalendarDays(new Date(timelineRange.start), new Date(startDate));
    const maxEdgeBusinessDiff = differenceInBusinessDays(new Date(timelineRange.start), new Date(startDate));

    const addDaysFunc = collapsedWeekendsRef.current && monthTimelineViewRef.current ? addBusinessDays : addDays;

    if (daysChange < 0) {
      const edgeCase = collapsedWeekendsRef.current ? maxEdgeBusinessDiff : maxEdgeCalendarDiff;
      const diff = daysChange < edgeCase ? edgeCase : daysChange;

      return addDaysFunc(new Date(startDate.toString()), diff).toISOString();
    }

    const edgeCase = collapsedWeekendsRef.current ? maxBusinessDiff : maxCalendarDiff;
    const diff = daysChange > edgeCase ? edgeCase : daysChange;

    return addDaysFunc(new Date(startDate.toString()), diff).toISOString();
  };

  const getNewEndDate = (
    startDate: Scalars['DateTime'],
    endDate: Scalars['DateTime'],
    daysChange: number,
  ): Scalars['DateTime'] => {
    const maxCalendarDiff = differenceInCalendarDays(new Date(startDate), new Date(endDate));
    const maxBusinessDiff = differenceInBusinessDays(new Date(startDate), new Date(endDate));

    const addDaysFunc = collapsedWeekendsRef.current && monthTimelineViewRef.current ? addBusinessDays : addDays;

    if (daysChange < 0) {
      const edgeCase = collapsedWeekendsRef.current ? maxBusinessDiff : maxCalendarDiff;
      const diff = daysChange > edgeCase ? daysChange : edgeCase;

      return addDaysFunc(new Date(endDate.toString()), diff).toISOString();
    }

    return addDaysFunc(new Date(endDate.toString()), daysChange).toISOString();
  };

  const onAssignmentUpdate = (
    event: DraggableEvent | MouseEvent,
    startPosition: number,
    dragType: 'start' | 'end' | 'full',
    data: MemberAssignmentType,
  ) => {
    const daysDiff = getDaysDiff(startPosition, event, cellWidth);

    let updatedAssignment = {} as OptionalDeep<AssignmentType>;

    if (data?.startDate && dragType === 'start') {
      updatedAssignment = {
        ...data,
        startDate: getNewStartDate(data!.startDate, data!.endDate, daysDiff),
      };
    }
    if (data?.endDate && dragType === 'end') {
      updatedAssignment = {
        ...data,
        endDate: getNewEndDate(data!.startDate, data!.endDate, daysDiff),
      };
    }
    if (data?.endDate && data?.startDate && dragType === 'full') {
      updatedAssignment = {
        ...data,
        ...getNewDateRange(data!.startDate, data!.endDate, daysDiff),
      };
    }

    const allocationTimeAmount = data?.allocationTimeAmount ? data?.allocationTimeAmount * minutesInHour : 0;

    const newData = {
      variables: {
        data: {
          memberId: memberId ?? '',
          roleId: data?.role?.id ?? '',
          projectId: projectId ?? '',
          allocationTimeAmount: allocationTimeAmount,
          startDate: updatedAssignment.startDate,
          endDate: updatedAssignment.endDate,
          calculationType: data?.bill_amount_calculation_type,
          timeTracking: false,
          billable: updatedAssignment.billable ?? false,
        },
        assignmentId: data?.id as string,
        companyId: userData!.company.id,
      },
    };

    if (daysDiff !== 0) {
      const updateFunction = isRequest
        ? () =>
            onEditAssignmentRequest({
              startDate: String(updatedAssignment.startDate),
              endDate: String(updatedAssignment.endDate),
            })
        : () => editAssignment(newData);

      updateFunction().then(() => {
        setAssignmentsData(updatedAssignment);
        realTimeAssignments.current = updatedAssignment;
        assignedRef?.current?.resetPosition();
        draggingEdge.current = null;
        prevDaysDiff.current = 0;

        mixpanel.track(isRequest ? 'Assignment request edited' : 'Assignment edited', {
          'Drag and drop': true,
          Bulk: false,
        });
      });
    } else {
      assignedRef?.current?.resetPosition();
    }
  };

  const onEdgeMove = (e: DragEvent, startPosition: number, data: MemberAssignmentType) => {
    const daysDiff = getDaysDiff(startPosition, e, cellWidthRef.current);

    if (draggingEdge.current === 'start' && prevDaysDiff.current !== daysDiff) {
      prevDaysDiff.current = daysDiff;

      setAssignmentsData({
        ...data,
        startDate: getNewStartDate(data!.startDate, data!.endDate, daysDiff),
      });
    }

    if (draggingEdge.current === 'end' && prevDaysDiff.current !== daysDiff) {
      prevDaysDiff.current = daysDiff;

      setAssignmentsData({ ...data, endDate: getNewEndDate(data!.startDate, data!.endDate, daysDiff) });
    }
  };

  const onStartPointStartDateListener = (e: DragEvent) => {
    startPosition.current = e.pageX;
    draggingEdge.current = 'start';
  };
  const onStartPointEndDateListener = (e: DragEvent) => {
    startPosition.current = e.pageX;
    draggingEdge.current = 'end';
  };
  const onStartDateMoveListener = (e: DragEvent) => {
    onAssignmentUpdate(e, startPosition.current, 'start', realTimeAssignments.current);
  };
  const onEndDateMoveListener = (e: DragEvent) => {
    onAssignmentUpdate(e, startPosition.current, 'end', realTimeAssignments.current);
  };
  const onChangeAssignmentTimeInterval = throttle((e: DragEvent) => {
    e.preventDefault();
    if (prevPageX.current !== e.pageX) {
      prevPageX.current = e.pageX;
      onEdgeMove(e, startPosition.current, realTimeAssignments.current);
    }
  }, 200);

  const onDrag = useCallback(() => {
    if (!isDragging) setIsDragging(true);
  }, [isDragging]);

  const onStart = useCallback((e: DraggableEvent) => {
    if ('pageX' in e) startPosition.current = e.pageX;
  }, []);

  const onStop = useCallback(
    (e: DraggableEvent) => {
      if (!isDragging && hasAccess(ActionsType.EditAssignments)) {
        const id = assignedRef?.current?.assignmentId;
        push(
          links.ResourcePlanning({
            ...params,
            ...query,
            mode: isRequest ? ModalModeEnum.requestEdit : ModalModeEnum.manage,
            id,
          }),
        );
        return;
      }

      setIsDragging(false);
      onAssignmentUpdate(e, startPosition.current, 'full', realTimeAssignments.current);
    },
    [isDragging],
  );

  useEffect(() => {
    assignedRef?.current?.startDateRef?.addEventListener('dragstart', onStartPointStartDateListener, false);
    assignedRef?.current?.startDateRef?.addEventListener('dragend', onStartDateMoveListener, false);

    assignedRef?.current?.endDateRef?.addEventListener('dragstart', onStartPointEndDateListener, false);
    assignedRef?.current?.endDateRef?.addEventListener('dragend', onEndDateMoveListener, false);

    document.addEventListener('dragover', onChangeAssignmentTimeInterval, false);

    return () => {
      assignedRef?.current?.startDateRef?.removeEventListener('dragstart', onStartPointStartDateListener);
      assignedRef?.current?.startDateRef?.removeEventListener('dragend', onStartDateMoveListener);

      assignedRef?.current?.endDateRef?.removeEventListener('dragstart', onStartPointEndDateListener);
      assignedRef?.current?.endDateRef?.removeEventListener('dragend', onEndDateMoveListener);

      document.removeEventListener('dragover', onChangeAssignmentTimeInterval, true);
    };
  }, [assignmentsData, cellWidth]);

  useEffect(() => {
    cellWidthRef.current = cellWidth;
    collapsedWeekendsRef.current = collapsedWeekends;
  }, [cellWidth, collapsedWeekends]);

  useEffect(() => {
    realTimeAssignments.current = data;
    setAssignmentsData(data);
  }, [data]);

  return { assignmentsData, isDragging, onDrag, onStart, onStop };
};
