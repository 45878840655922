import React, { FC } from 'react';
import { ProjectDataFragment } from 'generated/types';
import { RightDrawer } from 'components';
import styles from './styles.module.scss';
import { Rates } from './Rates';
import { ProjectHeader } from './components/ProjectHeader';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  project: ProjectDataFragment;
}

export const RateCard: FC<Props> = ({ isOpen, onClose, project }) => {
  return (
    <RightDrawer
      paperClassName={styles.paper}
      open={isOpen}
      onClose={onClose}
      title={<ProjectHeader project={project} />}
      titleClassName="w-100"
      headerClassName={styles.header}
      boxClassName={styles.box}
    >
      <Rates projectId={project.id} currency={project.fee_currency!} unit={project.unit} />
    </RightDrawer>
  );
};
