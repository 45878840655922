import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './styles.module.scss';

export const MilestonesHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <span className={clsx(styles.title, 'pl-16')}>{t('viewProjectDetail.milestones.title')}</span>
      <span className={styles.paymentDate}>{t('viewProjectDetail.milestones.paymentDate')}</span>
      <span className={styles.amount}>{t('viewProjectDetail.milestones.amount')}</span>
      <span className={styles.notes}>{t('viewProjectDetail.milestones.note')}</span>
      <span className={styles.menu} />
    </div>
  );
};
