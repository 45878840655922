import { minutesInHour } from 'date-fns';
import i18n from 'locales/i18n';

export const formatTimeLog = (duration: number) => {
  const hours = Math.floor(duration / minutesInHour);
  const minutes = duration % minutesInHour;

  return `${hours}${i18n.t('time.h')} ${minutes}${i18n.t('time.m')}`;
};

const hoursRegExp = /[0-9.,]*?(?= |h)|[0-9.,]*$/g;
const minutesRegExp = /[0-9.,]*?(?=m)/g;

export const parseTimeLog = (value: string) => {
  // get all hours
  const hours =
    value
      .match(hoursRegExp)
      ?.filter((entry) => !!+entry)
      .reduce((acc, value) => acc + +value, 0) || 0;
  // remove all hours matches
  const minutesString = value.replace(hoursRegExp, '');
  // get all minutes
  const minutes =
    minutesString
      .match(minutesRegExp)
      ?.filter((entry) => !!+entry)
      .reduce((acc, value) => acc + +value, 0) || 0;
  // remove all minutes matches
  const remainingSubstring = minutesString.replace(minutesRegExp, '').replace(/h|m| /g, '');

  return remainingSubstring.length ? NaN : Math.floor(minutes + hours * minutesInHour);
};
