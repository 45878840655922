import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Colors, MemberIntegrationStatus, SIZES, UserInfo } from 'components';
import { getAcronym, getFullName } from 'utils';
import clsx from 'clsx';
import { IconButton, Tooltip } from '@material-ui/core';
import { MailIcon } from 'icons/MailIcon';
import { MemberIntegrationStatusesDataFragment } from 'generated/types';

import styles from './styles.module.scss';
import { toast } from 'react-toastify';

interface Props {
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  employment_type?: string | null;
  job_title?: string | null;
  color?: string;
  memberIntegrationStatuses?: MemberIntegrationStatusesDataFragment | null;
}

export const Heading: FC<Props> = ({
  first_name,
  last_name,
  email,
  employment_type,
  job_title,
  color,
  memberIntegrationStatuses,
}) => {
  const { t } = useTranslation();

  const copyEmail = async (data: string) => {
    navigator.clipboard.writeText(data);

    toast.success(t('viewTeamMember.copySuccess'));
  };

  return (
    <>
      <UserInfo
        size={SIZES.md}
        title={getFullName(first_name, last_name, t('notApplicable'))}
        avatarTitle={getAcronym(first_name, last_name)}
        titleClassName={clsx(styles.title, 'text-22')}
        alwaysShowTitleTooltip
        color={color}
      />
      <span className={styles.divider} />
      <div className="flex align-items-center gap-12">
        {job_title && <span className={styles.jobTitle}>{job_title}</span>}
        <Badge className="mr-8" color={Colors.greyscale}>
          <span className="capitalize">{t(`badges.${employment_type}`)}</span>
        </Badge>
        {memberIntegrationStatuses?.statuses.map(({ integrationName, status }) => (
          <MemberIntegrationStatus key={integrationName} integrationName={integrationName} status={status} />
        ))}
        {email && (
          <Tooltip title={email} placement="top">
            <IconButton size="small" onClick={() => copyEmail(email)}>
              <MailIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
};
