import React from 'react';

interface EditIconProps {
  className?: string;
}

export const EditIcon = ({ className }: EditIconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2929 2.29289C15.6834 1.90237 16.3166 1.90237 16.7071 2.29289L20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L7.70711 20.7071C7.51957 20.8946 7.26522 21 7 21H3C2.44772 21 2 20.5523 2 20V16C2 15.7348 2.10536 15.4804 2.29289 15.2929L12.2927 5.2931L15.2929 2.29289ZM13 7.41421L4 16.4142V19H6.58579L15.5858 10L13 7.41421ZM17 8.58579L18.5858 7L16 4.41421L14.4142 6L17 8.58579Z"
        fill="#587e94"
      />
    </svg>
  );
};
