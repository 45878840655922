import { ExternalRateDataFragment } from 'generated/types';
import { NO_SENIORITY } from 'consts';

import { RateData } from '../Rates';

export const getRateData = (data: ExternalRateDataFragment[], byId = false) => {
  return data.reduce((acc: RateData, rate: ExternalRateDataFragment) => {
    const key = byId ? rate.role.id : rate.role.name;
    if (acc[key]) {
      const newSeniorityRate = acc[key][rate.seniority || NO_SENIORITY]
        ? [...acc[key][rate.seniority || NO_SENIORITY], rate]
        : [rate];

      return { ...acc, [key]: { ...acc[key], [rate.seniority || NO_SENIORITY]: [...newSeniorityRate] } };
    }

    return { ...acc, [key]: { [rate.seniority || NO_SENIORITY]: [rate] } };
  }, {});
};
