import { addYears, isAfter, isBefore, isSameDay, isWithinInterval, parseISO } from 'date-fns';

import { ExternalRateDataFragment } from 'generated/types';

const getRatesIntervals = (rates: ExternalRateDataFragment[]) => {
  return rates.reduce<{ start: Date; end: Date }[]>((acc, rec) => {
    return [
      ...acc,
      {
        start: parseISO(rec.start_date),
        // If the Rate period doesn't have an endDate we are creating an interval for checking overlap
        // where endDate will be the equal startDate of this costRate + 1 year
        end: rec.end_date ? parseISO(rec.end_date) : addYears(parseISO(rec.start_date), 1),
      },
    ];
  }, []);
};

export const isOverlapped = (startDate: Date, rates: ExternalRateDataFragment[], endDate?: Date) => {
  // It will be always the firs element of the array because we are sorting it by startDate before use
  const firstRate = rates[0];
  const lastRate = rates[rates.length - 1];
  const intervals = getRatesIntervals(rates);

  if (rates.length === 0) return false;
  if (
    endDate &&
    intervals.some(
      (interval) =>
        isWithinInterval(endDate, interval) ||
        isWithinInterval(startDate, interval) ||
        isSameDay(endDate, interval.start) ||
        isSameDay(startDate, interval.start) ||
        (isBefore(startDate, interval.start) && isAfter(endDate, interval.start)) ||
        (isBefore(startDate, interval.end) && isAfter(endDate, interval.end)),
    )
  ) {
    return true;
  }
  if (endDate) {
    return intervals.some((interval) => isWithinInterval(startDate, interval));
  }
  if (lastRate.end_date && isAfter(startDate, parseISO(lastRate.end_date))) {
    return false;
  }
  if (isBefore(startDate, parseISO(firstRate.start_date)) || isBefore(startDate, parseISO(lastRate.start_date))) {
    return true;
  }
  if (isAfter(startDate, parseISO(lastRate.start_date))) {
    return true;
  }
  return intervals.some((interval) => isWithinInterval(startDate, interval));
};
