import { FC, useEffect } from 'react';

export const ScrollToError: FC<{ isSubmitting: boolean }> = ({ isSubmitting }) => {
  useEffect(() => {
    const elementWithError = document.querySelector('.Mui-error, [data-error]');

    (elementWithError?.parentElement ?? elementWithError)?.scrollIntoView({ block: 'center' });
  }, [isSubmitting]);

  return null;
};
