import { ForwardedRef, MutableRefObject, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';

import { AssignmentHandle, Boundaries } from 'hooks';
import { useTimelineContext } from 'contexts';

export const useDraggableAssignment = (
  imperativeRef: ForwardedRef<AssignmentHandle>,
  resetPosition: () => void,
  amount?: number,
  id?: string,
): {
  localBoundaries: Boundaries;
  assignmentRef: MutableRefObject<HTMLDivElement | null>;
  startDateRef: MutableRefObject<HTMLDivElement | null>;
  endDateRef: MutableRefObject<HTMLDivElement | null>;
} => {
  const { collapsedWeekends } = useTimelineContext();

  const assignmentRef = useRef<null | HTMLDivElement>(null);
  const startDateRef = useRef<null | HTMLDivElement>(null);
  const endDateRef = useRef<null | HTMLDivElement>(null);

  const [localBoundaries, setLocalBoundaries] = useState<Boundaries>({ left: 0, right: 0 });

  useImperativeHandle(
    imperativeRef,
    () => ({
      startDateRef: startDateRef.current,
      endDateRef: endDateRef.current,
      assignmentId: id,
      resetPosition: resetPosition,
    }),
    [],
  );

  useLayoutEffect(() => {
    if (assignmentRef.current) {
      setLocalBoundaries(assignmentRef.current?.getBoundingClientRect());
    }
  }, [collapsedWeekends]);

  return { localBoundaries, startDateRef, endDateRef, assignmentRef };
};
