import React, { createRef, FC, useEffect, useState } from 'react';
import { useTimeLogsQuery } from 'generated/graphql';
import { generateRandomId, getAcronym, getFullName, graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { PlusIcon } from 'icons';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Member, MemberProjectDataFragment } from 'generated/types';
import { TimeLogForm, TimeLogFormValues, TimeLogItem, TimeLogsHeader } from '../components';

import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { ProjectInfoCell, UserInfo } from 'components';
import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.scss';
import { useTimeTrackingSubmit } from 'hooks/useTimeTrackingSubmit';

type MemberType = Pick<Member, 'id' | 'first_name' | 'last_name' | 'employment_type' | 'color'>;

interface Props {
  date?: Date;
  member?: MemberType;
  project?: MemberProjectDataFragment;
  isCreateMode?: boolean;
  assignmentId?: string;
  onSubmit: (data: TimeLogFormValues) => Promise<void> | void;
  overtimeMultiplier?: number | null;
  updatePopoverPosition?: () => void;
}

export const TimeLogList: FC<Props> = ({
  member,
  project,
  assignmentId,
  overtimeMultiplier,
  date,
  onSubmit,
  isCreateMode,
  updatePopoverPosition,
}) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const endListRef = createRef<HTMLDivElement>();
  const formattedDate = date ? format(date, DEFAULT_DATE_FORMAT) : '';
  const [creatingIdList, setCreatingIdList] = useState<string[]>(isCreateMode ? [generateRandomId()] : []);
  const onAddCreatingLog = () => {
    setCreatingIdList((prev) => [...prev, generateRandomId()]);
    endListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };
  const onRemoveCreatingLog = (item: string) => setCreatingIdList((prev) => prev.filter((id) => item !== id));

  useEffect(() => {
    updatePopoverPosition?.();
  }, [creatingIdList]);

  const { deleteTimeLog } = useTimeTrackingSubmit();

  const onDelete = (id: string) => {
    deleteTimeLog({
      variables: {
        companyId: userData!.company.id,
        timeLogIds: [id],
      },
    });
  };

  const { data: { timeLogs = [] } = {}, loading } = useTimeLogsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      updatePopoverPosition?.();
    },
    variables: {
      companyId: userData!.company.id,
      data: {
        assignmentId: assignmentId,
        date: formattedDate,
      },
    },
    fetchPolicy: 'network-only',
  });

  const optionalColumn = member ? (
    <UserInfo
      title={getFullName(member.first_name, member.last_name)}
      titleClassName={styles.additionalTitle}
      avatarTitle={getAcronym(member.first_name, member.last_name)}
      color={member.color || ''}
    />
  ) : project ? (
    <ProjectInfoCell hasTooltip title={project.name} color={project.color} titleClassName={styles.additionalTitle} />
  ) : (
    ''
  );

  const isAddScrollGap = timeLogs.length + creatingIdList.length >= 4;

  return (
    <div className={styles.container}>
      <TimeLogsHeader isShowProject={!!project} isShowMember={!!member} />
      {loading ? (
        <div className="flex align-items-center">
          <CircularProgress size={64} />
        </div>
      ) : (
        ''
      )}

      <div className={styles.listBox}>
        {timeLogs.map((item) => (
          <TimeLogItem
            key={item.id}
            optionalColumn={optionalColumn}
            item={item}
            onEdit={onSubmit}
            onDelete={onDelete}
            overtimeMultiplier={overtimeMultiplier}
          />
        ))}
        {!loading &&
          creatingIdList.map((id) => (
            <TimeLogForm
              key={id}
              date={formattedDate}
              optionalColumn={optionalColumn}
              onCancel={() => onRemoveCreatingLog(id)}
              onSubmit={onSubmit}
              overtimeMultiplier={overtimeMultiplier}
            />
          ))}
        <div ref={endListRef} className={isAddScrollGap ? styles.endListAnchor : ''} />
      </div>

      <Button
        variant="text"
        color="secondary"
        className={styles.button}
        startIcon={<PlusIcon className={styles.icon} />}
        onClick={onAddCreatingLog}
      >
        {t('timeTracking.addLog')}
      </Button>
    </div>
  );
};
