import { useDragLayer } from 'react-dnd';
import type { XYCoord } from 'dnd-core';
import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

const getItemStyles = (
  initialCursorOffset: XYCoord | null,
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) => {
  if (!initialOffset || !currentOffset || !initialCursorOffset) {
    return {
      display: 'none',
    };
  }

  const x = initialCursorOffset?.x + (currentOffset.x - initialOffset.x);
  const y = initialCursorOffset?.y + (currentOffset.y - initialOffset.y);
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

interface Props {
  children: ReactNode;
  className?: string;
}
export const CustomDragLayer: FC<Props> = ({ className, children }) => {
  const { isDragging, initialCursorOffset, initialFileOffset, currentFileOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialCursorOffset: monitor.getInitialClientOffset(),
    initialFileOffset: monitor.getInitialSourceClientOffset(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div className={clsx(styles.box, className)}>
      <div style={getItemStyles(initialCursorOffset, initialFileOffset, currentFileOffset)}>{children}</div>
    </div>
  );
};
