class SessionStorageManager {
  getItem = (key: string) => {
    try {
      const data = sessionStorage.getItem(key);

      return JSON.parse(data as string) ?? null;
    } catch (e) {
      console.error(e);

      return null;
    }
  };

  setItem = (key: string, value: unknown) => {
    try {
      const castedToString = JSON.stringify(value);
      castedToString && sessionStorage.setItem(key, castedToString);
    } catch (e) {
      console.error(e);
    }
  };

  removeItem = (key: string) => {
    sessionStorage.removeItem(key);
  };
}

export const sessionStorageManager = new SessionStorageManager();
