import { useMemo } from 'react';
import { useProjectClientQuery, useSenioritiesQuery, useSpecializationsQuery, useUsersQuery } from 'generated/graphql';
import { graphqlOnError, sortByField } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ASC, FIRST_NAME, LAST_NAME, NAME } from 'consts';
import { ApolloError } from '@apollo/client/errors';
import { ActionsType, CompanyUserStatus, EmploymentType, ProjectType } from 'generated/types';
import { CommonFilterOptions } from 'components';
import { CommonFilterFormValues } from './CommonFiltersPopover';

interface Props {
  filtersOptions: CommonFilterOptions[];
  values: CommonFilterFormValues;
}

export const useFiltersData = ({ filtersOptions, values }: Props) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();

  const queryOptions = {
    onError: (err: ApolloError) => {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  };

  const employmentTypeOptions = useMemo(() => {
    const employeeTypes = [
      { id: EmploymentType.Contractor, name: t('employmentType.contractor') },
      { id: EmploymentType.Employee, name: t('employmentType.employee') },
    ];

    return employeeTypes.sort((a) => (values.employmentType?.includes(a?.id) ? -1 : 1));
  }, []);

  const projectTypeOptions = useMemo(() => {
    const projectTypes = [
      { id: ProjectType.TimeAndMaterial, name: t('projectType.timeAndMaterial') },
      { id: ProjectType.FixedPrice, name: t('projectType.fixed') },
      { id: ProjectType.NonBillable, name: t('projectType.nonBillable') },
      { id: ProjectType.Retainer, name: t('projectType.retainer') },
    ];

    return projectTypes.sort((a) => (values.type?.includes(a?.id) ? -1 : 1));
  }, []);

  const { data: { specializations = [] } = {}, loading: specializationLoading } = useSpecializationsQuery({
    ...queryOptions,
    skip: !open || !filtersOptions.includes(CommonFilterOptions.specialization),
  });

  const specializationsOptions = useMemo(() => {
    return sortByField(specializations, ASC, NAME);
  }, [specializationLoading]);

  const { data: { seniorities = [] } = {}, loading: senioritiesLoading } = useSenioritiesQuery({
    ...queryOptions,
    skip: !open || !filtersOptions.includes(CommonFilterOptions.seniority),
  });

  const senioritiesOptions = useMemo(() => {
    return sortByField(seniorities, ASC, NAME);
  }, [senioritiesLoading]);

  const { data: { users = [] } = {}, loading: membersLoading } = useUsersQuery({
    ...queryOptions,
    skip:
      !open ||
      (!filtersOptions.includes(CommonFilterOptions.reportingTo) && !filtersOptions.includes(CommonFilterOptions.pm)) ||
      !hasAccess(ActionsType.ViewUsers),
  });

  const membersOptions = useMemo(() => {
    const members = users.filter(({ status }) => status !== CompanyUserStatus.Deactivated).map(({ member }) => member);
    return sortByField(members, ASC, FIRST_NAME, LAST_NAME);
  }, [membersLoading]);

  const { data: { clients = [] } = {}, loading: clientsLoading } = useProjectClientQuery({
    ...queryOptions,
    skip: !open || !filtersOptions.includes(CommonFilterOptions.client),
  });

  const clientsOptions = useMemo(() => {
    return sortByField(clients, ASC, NAME);
  }, [clientsLoading]);

  return {
    employmentTypeOptions,
    projectTypeOptions,
    specializationsOptions,
    senioritiesOptions,
    membersOptions,
    clientsOptions,
    senioritiesLoading,
    specializationLoading,
    membersLoading,
    clientsLoading,
  };
};
