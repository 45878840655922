import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { ModalModeEnum } from 'types';

export const ExpensesRoute = Route(
  {
    id: Route.params.regex(idRegEx),
    mode: Route.params.oneOf(...Object.values(ModalModeEnum)).optional,
    expenseId: Route.params.regex(idRegEx).optional,
  },
  ({ id, mode, expenseId }) => `/project/view/${id}/expenses/${mode}/${expenseId}`,
);

export const Expenses = ExpensesRoute(lazy(() => import(/* webpackChunkName: "Expenses" */ './Expenses')));
