import React, { FC } from 'react';
import { ButtonGroup as MuiButtonGroup } from '@material-ui/core';

import styles from './styles.module.scss';
import { ButtonGroupProps } from '@material-ui/core/ButtonGroup/ButtonGroup';

type Props = ButtonGroupProps & {
  active?: boolean;
};

export const ButtonGroup: FC<Props> = ({ children, ...props }) => {
  return (
    <MuiButtonGroup {...props} color="secondary" aria-label="button group" className={styles.buttonGroup}>
      {children}
    </MuiButtonGroup>
  );
};
