import { useCreateExpenseTypeMutation, useExpenseTypesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { Reference } from '@apollo/client';
import { useAuth } from '../contexts';
import { ExpenseType } from '../generated/types';

export const useCreatableExpenseTypeData = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { expenseTypes = [] } = {}, loading: expenseTypeLoading } = useExpenseTypesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const [createExpenseType] = useCreateExpenseTypeMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createExpenseType) return;

      cache.modify({
        fields: {
          expenseTypes(items: Reference[] = []) {
            return [...items, data.createExpenseType];
          },
        },
      });
    },
  });

  const getCreatedExpenseType = async (value: ExpenseType | null) => {
    if (value?.id) {
      return value;
    }

    if (!value?.name) {
      return null;
    }

    const { data } = await createExpenseType({
      variables: {
        data: { name: value?.name || '' },
        companyId: userData!.company.id,
      },
    });

    return data?.createExpenseType as ExpenseType;
  };

  return { expenseTypes: expenseTypes as ExpenseType[], expenseTypeLoading, getCreatedExpenseType };
};
