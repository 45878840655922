import React from 'react';
import { useTranslation } from 'react-i18next';

export interface TablePaginationProps {
  gotoPage: (page: number) => void;
  canPreviousPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  canNextPage: boolean;
  pageCount: number;
  pageIndex: number;
  pageOptions: number[];
  pageSize: number;
  setPageSize: (pagesize: number) => void;
}

const pageSizes = [10, 20, 30];

export const Pagination = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
}: TablePaginationProps) => {
  const { t } = useTranslation();

  const handlePageChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const page = value ? Number(value) - 1 : 0;
    gotoPage(page);
  };

  const handleSetPageSize = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(value));
  };

  return (
    <div className="pagination">
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {'<<'}
      </button>
      <button onClick={previousPage} disabled={!canPreviousPage}>
        {'<'}
      </button>
      <button onClick={nextPage} disabled={!canNextPage}>
        {'>'}
      </button>
      <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {'>>'}
      </button>
      <span>
        {t('pagination.page')}
        <strong>
          {pageIndex + 1} {t('pagination.of')} {pageOptions.length}
        </strong>
      </span>
      <span>
        | {t('pagination.goToPage')}
        <input type="number" value={pageIndex + 1} onChange={handlePageChange} />
      </span>
      <select value={pageSize} onChange={handleSetPageSize}>
        {pageSizes.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {t('pagination.show')} {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};
