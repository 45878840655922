import { ComponentsProps } from '@material-ui/core/styles/props';

export const props: ComponentsProps = {
  MuiButton: {
    disableElevation: true,
    color: 'primary',
    variant: 'contained',
  },
  MuiIconButton: {
    color: 'primary',
  },
  MuiTextField: {
    fullWidth: true,
    variant: 'outlined',
  },
};
