import React, { FC, useCallback, useMemo } from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { Alert, EditIcon, PlusIcon } from 'icons';
import { GetRouteProps } from 'types/utils/router';
import type { LeaveTypesRoute } from './index';
import { RemoveConfirmIconButton, Table, TableActionCell } from 'components';
import { useTranslation } from 'react-i18next';
import { useCompanyIntegrationsQuery, useDeleteLeaveTypeMutation, useLeaveTypesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { ModalModeEnum, TableCell } from 'types';
import { CreateLeaveType } from './Create';
import { toast } from 'react-toastify';
import { LeaveTypeDataFragment } from 'generated/types';
import { Column } from 'react-table';
import { EditLeaveTypes } from './Edit';

import styles from './styles.module.scss';

type Props = GetRouteProps<typeof LeaveTypesRoute>;

const LEAVES_INTEGRATION_OPTION = 'Leaves';

const LeaveTypes: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const {
    data: { companyIntegrations = [] } = {},
    loading: isCompanyIntegrationsLoading,
  } = useCompanyIntegrationsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const leaveIntegrations = companyIntegrations.reduce<string>((acc, integration) => {
    const integrationWithLeave = integration.integrationOptions.find(
      ({ integrationOption }) => integrationOption === LEAVES_INTEGRATION_OPTION,
    );
    return integrationWithLeave ? `${acc}${acc ? ', ' : ''}${integration.integration}` : acc;
  }, '');

  const { data: { leaveTypes = [] } = {}, loading } = useLeaveTypesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
    skip: !userData,
  });

  const [deleteLeaveType] = useDeleteLeaveTypeMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('settings.resourceManagement.leaveTypes.delete.notifications.success'));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteLeaveType) });
        cache.gc();
      }
    },
  });

  const leaveToEdit = useMemo(() => {
    if (!params.leaveTypeId) {
      return;
    }

    return leaveTypes.find((leaveType) => leaveType.id === params.leaveTypeId);
  }, [params.leaveTypeId, leaveTypes]);

  const onDeleteLeaveType = (leaveTypeId: string) => {
    userData &&
      deleteLeaveType({
        variables: {
          leaveTypeId,
          companyId: userData.company.id,
        },
      });
  };

  const onClose = useCallback(() => push(link({ ...params, mode: undefined, leaveTypeId: undefined })), [params]);

  const columns = useMemo<Array<Column<LeaveTypeDataFragment>>>(
    () => [
      {
        Header: t('settings.resourceManagement.leaveTypes.name')!,
        accessor: 'name',
      },
      {
        Header: t('settings.resourceManagement.leaveTypes.paid')!,
        accessor: 'paid',
        Cell({ value }: TableCell<LeaveTypeDataFragment>) {
          return t(`common.condition.${value}`);
        },
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell({ row: { original }, isHovered }: TableCell<LeaveTypeDataFragment> & { isHovered?: boolean }) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  disabled={!userData?.company?.id}
                  onClick={() => push(link({ mode: ModalModeEnum.edit, leaveTypeId: original.id }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => onDeleteLeaveType(original.id)}
                confirmTitle={t('settings.resourceManagement.leaveTypes.delete.title')}
                confirmMessage={t('settings.resourceManagement.leaveTypes.delete.submissionQuestion')}
                confirmSubmitButtonTitle={t('settings.resourceManagement.leaveTypes.delete.title')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('settings.resourceManagement.leaveTypes.tab')}</h2>

        <Button className="mr-8" startIcon={<PlusIcon />} onClick={() => push(link({ mode: ModalModeEnum.create }))}>
          {t('settings.resourceManagement.leaveTypes.add')}
        </Button>
      </div>

      {!isCompanyIntegrationsLoading && leaveIntegrations && (
        <div className={styles.warningBlock}>
          {t('settings.resourceManagement.leaveTypes.warningText', {
            organizationName: userData?.company.name,
            leaveIntegrations,
          })}
          <Alert className={styles.warningBlockIcon} />
        </div>
      )}

      <Table data={leaveTypes} emptyStateLabel={t('table.emptyState')} columns={columns} loading={loading} />

      <CreateLeaveType isOpen={params.mode === ModalModeEnum.create} onClose={onClose} />

      <EditLeaveTypes isOpen={!!leaveToEdit} onClose={onClose} leaveType={leaveToEdit!} />
    </>
  );
};

export default LeaveTypes;
