import React, { SetStateAction, useState } from 'react';
import { DatePicker, ProjectFormValues, Switch } from 'components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import { Field, FieldProps, FormikErrors } from 'formik';
import { Scalars } from 'generated/types';
import { DashIcon } from 'icons';

import styles from './styles.module.scss';

interface FormValues {
  pmFinAccessStart: string | Date | null;
  pmFinAccessEnd: string | Date | null;
}

interface Props<D extends FormValues> {
  submitCount: number;
  errors: FormikErrors<D>;
  setValues: (values: SetStateAction<D>, shouldValidate?: boolean) => void;
  values: D;
}

export const ProjectPMAccessDate = <D extends FormValues>({ submitCount, errors, values, setValues }: Props<D>) => {
  const { t } = useTranslation();
  const [isOpenPMAccessDates, setIsOpenPMAccessDates] = useState(
    !!values.pmFinAccessStart || !!values.pmFinAccessEnd || false,
  );

  const onToggleOpenPMAccessDates = () => {
    setIsOpenPMAccessDates((prev) => {
      if (prev) {
        setValues({
          ...values,
          pmFinAccessStart: null,
          pmFinAccessEnd: null,
        });
      } else {
        setValues({ ...values, pmFinAccessStart: new Date() });
      }

      return !prev;
    });
  };

  return (
    <div className="mb-24">
      <p className={styles.title}>
        <Switch
          checked={isOpenPMAccessDates}
          onChange={onToggleOpenPMAccessDates}
          color="primary"
          name="isOpenPMAccessDates"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          title={t('forms.newProject.restrictAccessPeriod')}
        />
      </p>
      {isOpenPMAccessDates && (
        <div className={clsx(styles.datePickersBox, 'mb-24')}>
          <div className="flex-1">
            <InputLabel>{t('forms.newProject.accessStartsFrom')}</InputLabel>
            <Field>
              {({
                form: {
                  values: { pmFinAccessStart },
                },
              }: FieldProps<ProjectFormValues['pmFinAccessStart'], ProjectFormValues>) => (
                <DatePicker
                  value={pmFinAccessStart}
                  error={Boolean(submitCount && errors.pmFinAccessStart)}
                  helperText={!!submitCount && errors.pmFinAccessStart}
                  onChange={(pmFinAccessStart: Scalars['DateTime']) =>
                    setValues({
                      ...values,
                      pmFinAccessStart,
                    })
                  }
                />
              )}
            </Field>
          </div>
          <div className="pt-36">
            <DashIcon />
          </div>
          <div className="flex-1">
            <InputLabel>{t('forms.newProject.to')}</InputLabel>
            <Field>
              {({
                form: {
                  values: { pmFinAccessEnd },
                },
              }: FieldProps<ProjectFormValues['pmFinAccessEnd'], ProjectFormValues>) => (
                <DatePicker
                  value={pmFinAccessEnd}
                  error={Boolean(submitCount && errors.pmFinAccessEnd)}
                  helperText={!!submitCount && errors.pmFinAccessEnd}
                  onChange={(pmFinAccessEnd: Scalars['DateTime']) =>
                    setValues({
                      ...values,
                      pmFinAccessEnd,
                    })
                  }
                />
              )}
            </Field>
          </div>
        </div>
      )}
    </div>
  );
};
