import React from 'react';
import { HeaderGroup } from 'react-table';
import clsx from 'clsx';

import styles from './Heading.module.scss';
import { DescSortTableIcon, SortTableIcon } from 'icons';
import { ACTIONS, SELECTION, SETTING } from 'consts';

export interface TableHeaderProps<D extends Record<string, unknown> = Record<string, never>> {
  headerGroups: Array<HeaderGroup<D>>;
  headingClassName?: string;
  insideAccordion?: boolean;
  isSelectMode?: boolean;
  isSetDefaultWidth?: boolean;
  stickyLeftSelection?: boolean;
}

const DEFAULT_CELL_WIDTH = 180;

export const Heading = <D extends Record<string, unknown> = Record<string, never>>({
  headerGroups,
  headingClassName,
  insideAccordion,
  isSelectMode,
  isSetDefaultWidth,
  stickyLeftSelection,
}: TableHeaderProps<D>) => {
  return (
    <thead className={clsx(styles.heading, insideAccordion && styles.insideAccordion, headingClassName)}>
      {headerGroups.map((headerGroup) => {
        const columnsWidth = isSetDefaultWidth
          ? headerGroup.headers.reduce((acc, column) => {
              return column.id === SELECTION ? acc : acc + +(column?.width || 0);
            }, 0)
          : 0;

        return (
          <tr className={styles.row} {...headerGroup.getHeaderGroupProps()} key={`heading-row${headerGroup.id}`}>
            {headerGroup.headers.map((column: HeaderGroup<D> & { sortable?: boolean }) => {
              const headerSortProps = column.sortable ? column.getSortByToggleProps() : undefined;
              const sortIcon = column.isSorted ? (
                <DescSortTableIcon className={clsx('ml-4', !column.isSortedDesc && styles.sortAsc)} />
              ) : (
                <SortTableIcon className="ml-4 d-none" />
              );

              return (
                <th
                  {...column.getHeaderProps(headerSortProps)}
                  style={
                    column.id !== SELECTION && isSetDefaultWidth
                      ? {
                          minWidth: column.minWidth || DEFAULT_CELL_WIDTH,
                          width: `${(+(column.width || 1) / columnsWidth) * 100}%`,
                        }
                      : {}
                  }
                  key={`heading-column${column.id}`}
                  className={clsx({
                    [styles.stickySetting]: column.id === SETTING,
                    [styles.stickyAction]: column.id === ACTIONS,
                    [styles.selection]: column.id === SELECTION,
                    [styles.selectionStickyLeft]: column.id === SELECTION && stickyLeftSelection,
                    [styles.stickyLeft]: column.sticky === 'left',
                    [styles.stickyLeftWithSelection]: column.sticky === 'left' && isSelectMode,
                    [styles.stickyRight]: column.sticky === 'right',
                  })}
                >
                  <div className={styles.content}>
                    <span className={styles.title}>{column.render('Header')}</span>
                    {column.sortable ? sortIcon : ''}
                  </div>
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
};
