import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { FeedbackModal, UserInfoNav } from 'components';
import { SkelarMinLogoIcon, SkelarTitle } from 'icons';

import styles from './EmployeeBaseLayout.module.scss';

interface BaseLayoutProps {
  children: ReactNode;
}

export const EmployeeBaseLayout = ({ children }: BaseLayoutProps) => {
  return (
    <div className={clsx(styles.wrapper, 'bg-gray', 'flex-column')}>
      <div className={styles.container}>
        <header className={styles.header}>
          <SkelarMinLogoIcon />
          <SkelarTitle />

          <div className="ml-auto">
            <UserInfoNav isFullView hideUserInfo />
          </div>
        </header>
        <div className="bg-white flex-1">{children}</div>
        <FeedbackModal />
      </div>
    </div>
  );
};
