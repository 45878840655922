import React, { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';

import { LoadingButton } from 'components';

export interface NewNetPayFormValues {
  net_pay: number;
  notes: string;
}

interface NewNetPayProps {
  onSubmit: (values: NewNetPayFormValues) => void | Promise<void>;
  net_pay?: number;
  notes?: string;
  onCancel: () => void;
  submitLabel?: string;
  cancelLabel?: string;
}

export const NewNetPay = ({ net_pay, notes, submitLabel, cancelLabel, onSubmit, onCancel }: NewNetPayProps) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        net_pay: Yup.number()
          .typeError(t('forms.newNetPay.netPayRequired'))
          .required(t('forms.newNetPay.netPayRequired'))
          .min(0, t('forms.newNetPay.positiveNetPay')),
        notes: Yup.string(),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        net_pay: net_pay ?? 0,
        notes: notes ?? '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputLabel className="required">{t('forms.newNetPay.netPay')}</InputLabel>
          <Field component={TextField} name="net_pay" placeholder={t('forms.newNetPay.netPay')} className="mb-24" />

          <InputLabel>{t('forms.newNetPay.notes')}</InputLabel>
          <Field
            component={TextField}
            multiline
            rows={3}
            name="notes"
            placeholder={t('forms.newNetPay.notes')}
            className="mb-24"
          />

          <div className="mb-24">
            <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
              {submitLabel ?? t('forms.create')}
            </LoadingButton>

            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {cancelLabel ?? t('forms.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
