import React from 'react';
import clsx from 'clsx';

import { TabItem } from './TabItem';

export interface TabsProps {
  children: React.ReactNode;
  className?: string;
}

export const Tabs = ({ children, className }: TabsProps) => {
  const mappedChildren = React.Children.map(children, (child) => {
    if ((child as React.ReactElement)?.type === TabItem) {
      return React.cloneElement(child as React.ReactElement);
    }

    return child;
  });

  return <div className={clsx('flex align-items-center', className)}>{mappedChildren}</div>;
};
