import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Colors } from 'components';
import { toShortFormat } from 'utils';
import { EMPTY_STATE } from 'consts';
import { Member } from 'generated/types';

import styles from './styles.module.scss';

interface Props {
  member: Partial<Member>;
}

const PROJECT_MANAGER = 'Project Manager';

export const MemberDataSection: FC<Props> = ({ member }) => {
  const { t } = useTranslation();

  const joinDate = () => (member?.join_date ? toShortFormat(member.join_date) : EMPTY_STATE);

  const exitDate = () => (member?.exit_date ? toShortFormat(member.exit_date) : EMPTY_STATE);

  const capacity = () => (member?.capacity ? `${member.capacity} ${t('viewTeamMember.hrs')}` : EMPTY_STATE);

  const seniority = () => (member?.member_seniority?.name ? member.member_seniority.name : EMPTY_STATE);
  const projectRoles = () =>
    member?.member_role?.length
      ? member.member_role.map(({ name }: any) => (
          <Badge key={name} color={name === PROJECT_MANAGER ? Colors.navy : Colors.yellow} className="mr-4 mb-4">
            {name}
          </Badge>
        ))
      : EMPTY_STATE;

  const specialization = () => (member?.member_specialization?.name ? member.member_specialization.name : EMPTY_STATE);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        {t('viewTeamMember.joiningDate').toUpperCase()}
        <span className={styles.value}>{joinDate()}</span>
      </div>

      <div className={styles.box}>
        {t('viewTeamMember.exitDate').toUpperCase()}
        <span className={styles.value}>{exitDate()}</span>
      </div>

      <div className={styles.box}>
        {t('viewTeamMember.capacity').toUpperCase()}
        <span className={styles.value}>{capacity()}</span>
      </div>

      <div className={styles.box}>
        {t('viewTeamMember.expertise').toUpperCase()}
        <span className={styles.value}>{seniority()}</span>
      </div>

      <div className={styles.box}>
        {t('viewTeamMember.projectRoles').toUpperCase()}
        <span className={styles.value}>{projectRoles()}</span>
      </div>

      <div className={styles.box}>
        {t('viewTeamMember.specialization').toUpperCase()}
        <span className={styles.value}>{specialization()}</span>
      </div>
    </div>
  );
};
