import { startOfYear, endOfMonth, startOfMonth, subMonths, format, endOfYear, subYears } from 'date-fns';
import { DateRangeTypes } from 'types';

const DATE_FORMAT = 'yyyy-MM-dd';

export const getPreviousDateRangeFromType = (range: DateRangeTypes) => {
  switch (range) {
    case DateRangeTypes.yearToDate: {
      const prevYear = subYears(new Date(), 1);
      return [format(startOfYear(prevYear), DATE_FORMAT), format(endOfMonth(prevYear), DATE_FORMAT)];
    }
    case DateRangeTypes.lastYear: {
      const prevYear = subYears(new Date(), 1);
      return [format(startOfMonth(subMonths(prevYear, 12)), DATE_FORMAT), format(endOfMonth(prevYear), DATE_FORMAT)];
    }
    case DateRangeTypes.lastMonth: {
      const prevLastMonth = subMonths(new Date(), 2);
      return [format(startOfMonth(prevLastMonth), DATE_FORMAT), format(endOfMonth(prevLastMonth), DATE_FORMAT)];
    }
    case DateRangeTypes.thisMonth: {
      const prevMonth = subMonths(new Date(), 1);
      return [format(startOfMonth(prevMonth), DATE_FORMAT), format(endOfMonth(prevMonth), DATE_FORMAT)];
    }
    case DateRangeTypes.thisYear: {
      const prevYear = subYears(new Date(), 1);
      return [format(startOfYear(prevYear), DATE_FORMAT), format(endOfYear(prevYear), DATE_FORMAT)];
    }
    case DateRangeTypes.prevYear: {
      const prevPrevYear = subYears(new Date(), 2);
      return [format(startOfYear(prevPrevYear), DATE_FORMAT), format(endOfYear(prevPrevYear), DATE_FORMAT)];
    }
    default:
      return [undefined, undefined];
  }
};
