import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { PopoverOrigin } from '@material-ui/core';

import { ActionsType } from 'generated/types';
import { usePermissions } from 'hooks';
import { DashboardIcon, DotsIcon, LogoutIcon, UserIcon } from 'icons';
import { useAuth, useSettings } from 'contexts';
import { DropdownMenu, SIZES, UserInfo } from 'components';
import IconButton from '@material-ui/core/IconButton';
import { getAcronym, getFullName } from 'utils';

import styles from './UserInfoNav.module.scss';

interface UserInfoNavProps {
  isFullView: boolean;
  hideUserInfo?: boolean;
}

export const UserInfoNav = ({ isFullView, hideUserInfo }: UserInfoNavProps) => {
  const { t } = useTranslation();
  const { handleLogout, userData } = useAuth();
  const { isEmployeeMode, onChangeIsEmployeeMode } = useSettings();
  const { hasAccess } = usePermissions();
  const {
    member: { first_name, last_name, color },
  } = userData!;

  const acronym = getAcronym(first_name, last_name);
  const fullName = getFullName(first_name, last_name, t('notApplicable'));
  const anchorOrigin: PopoverOrigin | undefined = !isFullView ? { vertical: 'top', horizontal: 'left' } : undefined;
  const employeeModeAnchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' };
  const haveAccessToDashboard = hasAccess(ActionsType.ViewResourcePlanningMenu);

  const renderMenuOptions = (handleClose: () => void) => [
    isEmployeeMode ? (
      <MenuItem
        key="back-to-dashboard-option"
        onClick={() => {
          onChangeIsEmployeeMode(false);
          handleClose();
        }}
        className={clsx(!haveAccessToDashboard && 'd-none')}
      >
        <DashboardIcon />

        <span className="ml-12">{t('actions.backToMainDashboard')}</span>
      </MenuItem>
    ) : (
      <MenuItem
        key="employee-portal-option"
        onClick={() => {
          onChangeIsEmployeeMode(true);
          handleClose();
        }}
        className={clsx(!haveAccessToDashboard && 'd-none')}
      >
        <UserIcon />

        <span className="ml-12">{t('actions.goToEmployeePortal')}</span>
      </MenuItem>
    ),

    <div key="divider" className={clsx(styles.divider, !haveAccessToDashboard && 'd-none')} />,

    <MenuItem
      key="logout-option"
      onClick={() => {
        handleLogout();
        handleClose();
      }}
    >
      <LogoutIcon />

      <span className="ml-12">{t('actions.logout')}</span>
    </MenuItem>,
  ];

  const userInfo = (
    <UserInfo
      size={SIZES.lg}
      avatarTitle={acronym}
      color={color}
      title={fullName}
      titleClassName={clsx(styles.titleClassNameOverride, 'd-block')}
      additionalInfoClassName={clsx(
        styles.additionalUserInfoOverride,
        !isFullView ? 'hidden' : 'visible fade-animation',
      )}
    />
  );

  return (
    <div className="flex justify-content-between align-items-center">
      {isFullView && !hideUserInfo && userInfo}
      <DropdownMenu
        anchorOrigin={isEmployeeMode ? employeeModeAnchorOrigin : anchorOrigin}
        trigger={(handleClick) =>
          isFullView ? (
            <IconButton onClick={handleClick} size="small" className={styles.iconButton}>
              <DotsIcon />
            </IconButton>
          ) : (
            <div onClick={handleClick} className="pointer">
              {userInfo}
            </div>
          )
        }
      >
        {renderMenuOptions}
      </DropdownMenu>
    </div>
  );
};
