import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { useProjectDocumentsLazyQuery, useProjectDocumentUrlLazyQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { EmptyState, LoadingButton, Tooltip, UploadFileModal } from 'components';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, useIsOpen } from 'hooks';
import styles from './styles.module.scss';
import { FileDownloadIcon, PaperClip } from 'icons';
import clsx from 'clsx';
import Menu from '@material-ui/core/Menu';
import { Button, IconButton, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  projectId: string;
  activeItemsNumber: number;
}

export const FileCell: FC<Props> = ({ activeItemsNumber = 0, projectId }) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const [isOpen, onOpen, onClose] = useIsOpen();

  const [fetchFiles, { data: { projectDocuments = [] } = {}, loading }] = useProjectDocumentsLazyQuery({
    variables: {
      companyId: userData!.company.id,
      projectId: projectId,
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [fetchFileUrl] = useProjectDocumentUrlLazyQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted(data) {
      window.open(data.projectDocumentUrl, '_blank');
    },
    fetchPolicy: 'network-only',
  });

  const getFileUrl = useCallback((projectDocumentId: string, download = false) => {
    fetchFileUrl({
      variables: {
        projectDocumentId,
        companyId: userData!.company.id,
        download,
      },
    });
  }, []);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleOpen = (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    handleClick(e);

    if (activeItemsNumber) {
      fetchFiles();
    }
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  return (
    <div className={styles.fileCell}>
      <LoadingButton
        size="medium"
        color="secondary"
        loading={loading}
        onClick={handleOpen}
        startIcon={<PaperClip className={styles.fileCellIcon} />}
        className={clsx(styles.fileCellButton, !activeItemsNumber && styles.disabled)}
      >
        {projectDocuments?.length || activeItemsNumber}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(anchorEl) && !(loading && !projectDocuments?.length)}
        onClose={handleClose}
      >
        {!activeItemsNumber && !projectDocuments?.length && (
          <EmptyState title="projectFile.emptyMessage" className={styles.emptyMessage} />
        )}
        {projectDocuments.map(({ id, document }) => (
          <MenuItem key={id} onClick={() => getFileUrl(id)} className="flex justify-content-between">
            <Tooltip title={document.name} placement="top" textClassName={styles.fileName}>
              {document.name}
            </Tooltip>
            <IconButton
              size="small"
              className={styles.downloadButton}
              onClick={(e) => {
                getFileUrl(id, true);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <FileDownloadIcon />
            </IconButton>
          </MenuItem>
        ))}
        <div className={styles.buttonBox}>
          <Button className="w-100" startIcon={<PaperClip />} onClick={onOpen}>
            {t('projectFile.uploadFile.label')}
          </Button>
        </div>
      </Menu>
      <UploadFileModal
        projectId={projectId}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          fetchFiles();
        }}
      />
    </div>
  );
};
