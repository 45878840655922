import { useEffect, useState } from 'react';

interface Props {
  callback: () => void;
  timeout?: number;
}

export const usePeriodicCallback = ({ callback, timeout = 5000 }: Props) => {
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    const updateCountIntervalId = setInterval(() => {
      setUpdateCount((prev) => prev + 1);
    }, timeout);

    return () => clearInterval(updateCountIntervalId);
  }, []);

  useEffect(() => {
    callback();
  }, [updateCount]);
};
