import { RightDrawer } from 'components';
import { useAuth } from 'contexts';
import { SkillDataFragmentDoc, useCreateSkillMutation } from 'generated/graphql';
import { ISkill, SkillDataFragment } from 'generated/types';
import { useErrorMsgBuilder } from 'hooks';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { SkillForm } from '../components/SkillForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateSkill: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();

  const [createSkillCategory] = useCreateSkillMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('settings.resourceManagement.skills.create.notifications.success'));
      onClose();
    },
    update(cache, { data }) {
      if (!data?.createSkill) {
        return;
      }

      const newSkillRef = cache.writeFragment({
        data: data?.createSkill,
        fragment: SkillDataFragmentDoc,
      });

      cache.modify({
        id: cache.identify({ __typename: 'SkillCategory', id: data.createSkill.skillCategoryId }),
        fields: {
          skills(skills: SkillDataFragment[] = []) {
            return [...skills, newSkillRef];
          },
        },
      });
    },
  });

  const { userData } = useAuth();

  const onSubmit = useCallback(async (data: ISkill) => {
    if (!userData) {
      return;
    }

    await createSkillCategory({
      variables: {
        companyId: userData.company.id,
        data: data,
      },
    });
  }, []);

  return (
    <RightDrawer
      direction="right"
      open={isOpen}
      onClose={onClose}
      title={t('settings.resourceManagement.skills.create.title')}
    >
      <SkillForm onSubmit={onSubmit} onCancel={onClose} />
    </RightDrawer>
  );
};
