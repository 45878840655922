import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { lazy } from 'react';
import { TimeTrackingTabs } from 'types';

export const MemberTimeTrackingRoute = Route(
  {
    id: Route.params.regex(idRegEx),
    tab: Route.params.oneOf(TimeTrackingTabs.list, TimeTrackingTabs.timesheet).optional,
    month: Route.query.string,
  },
  ({ id, tab }) => `/team-member/view/${id}/time-tracking/${tab}`,
);

export const MemberTimeTracking = MemberTimeTrackingRoute(
  lazy(() => import(/* webpackChunkName: "MemberTimeTracking" */ './MemberTimeTracking')),
);
