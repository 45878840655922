import React from 'react';
import { Route } from 'react-router-hoc';
import { ViewHeading } from 'components';

const ClientsRoute = Route('/clients');

const Clients = () => {
  return (
    <div>
      <ViewHeading label="Clients"></ViewHeading>
      <div className="layout-content-wrapper">Clients</div>
    </div>
  );
};

export default ClientsRoute(Clients);
