import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, DatePicker, LoadingButton, Tag, ProjectMembershipAccessMenu, ConfirmModal } from 'components';
import clsx from 'clsx';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useManagedProjectsQuery } from 'generated/graphql';
import { ManagedProjectDataFragment, ProjectAccess, Scalars } from 'generated/types';
import { graphqlOnError, sortByField } from 'utils';
import { ASC, PROJECT } from 'consts';
import { useAuth } from 'contexts';
import { useFormik } from 'formik';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import { DashIcon } from 'icons';
import { useErrorMsgBuilder, useIsOpen } from 'hooks';

import styles from './styles.module.scss';

interface AccessOption {
  id: ProjectAccess;
  name: string;
  description: string;
}

export interface ShareProjectFormValues {
  projects: ManagedProjectDataFragment[];
  accessLevel: AccessOption;
  startDate: string | Date | null;
  endDate: string | Date | null;
  assignAsPM: boolean;
}

interface Props {
  onSubmit: (value: ShareProjectFormValues) => void;
  loading: boolean;
  selectedProjectIds: string[];
}

const SELECT_TAG_COLOR = '#f5f6f7';

export const CreateMemberProjectsMembership: FC<Props> = ({ selectedProjectIds, onSubmit, loading }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen();

  const { data: { managedProjects: projects = [] } = {}, loading: projectsLoading } = useManagedProjectsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      isArchived: false,
    },
  });

  const renderProjectTags = (options: ManagedProjectDataFragment[], getTagProps: AutocompleteGetTagProps) =>
    options.map(({ id, name }, index) => {
      const tagProps = getTagProps({ index });

      return (
        <Tag
          {...tagProps}
          key={id}
          name={name!}
          color={SELECT_TAG_COLOR}
          className={styles.tag}
          tooltipClassName={styles.tagTooltip}
        />
      );
    });

  const accessLevelInitialOption = useMemo(
    () => ({
      id: ProjectAccess.FullAccess,
      name: t('shareProject.accessOptions.fullAccess'),
      description: t('shareProject.accessOptions.fullAccessDescription'),
    }),
    [],
  );

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    resetForm,
    errors,
    touched,
    submitCount,
  } = useFormik<ShareProjectFormValues>({
    onSubmit: (values, { resetForm }) => {
      if (
        values.accessLevel.id === ProjectAccess.FullAccess &&
        !values.startDate &&
        !values.endDate &&
        !isOpenConfirm
      ) {
        onOpenConfirm();
        return;
      }

      onSubmit(values);
      resetForm();
      onCloseConfirm();
    },
    initialValues: {
      projects: [],
      accessLevel: accessLevelInitialOption,
      startDate: new Date(),
      endDate: null,
      assignAsPM: false,
    },
  });

  const projectsOptions = useMemo(() => {
    const selectedAutocompleteProjectIds = values.projects.map(({ id }) => id);

    const members = projects.filter(
      (project) => !selectedAutocompleteProjectIds.includes(project.id) && !selectedProjectIds.includes(project.id),
    );
    return sortByField(members, ASC, PROJECT);
  }, [projectsLoading, selectedProjectIds, values.projects]);

  return (
    <form onSubmit={handleSubmit} className={clsx('form', styles.form)}>
      <div>
        <div className={styles.autocompletesContainer}>
          <Autocomplete
            placeholder={t('shareProject.create.selectProjects')}
            className={styles.projectAutocomplete}
            value={values.projects}
            name="projects"
            onBlur={handleBlur}
            error={touched.projects && errors.projects}
            multiple
            debug
            hidePopupIcon
            disableClearable
            renderTags={renderProjectTags}
            options={projectsOptions}
            noOptions={t('shareProject.create.noOptions')}
            onChange={(projects: ShareProjectFormValues['projects']) => setValues({ ...values, projects })}
          />
          <div className={styles.accessMenu}>
            <ProjectMembershipAccessMenu
              value={values.accessLevel.id}
              onChange={(accessLevel) => setValues({ ...values, accessLevel })}
            />
          </div>
        </div>
        {values.projects?.length ? (
          <>
            <div className="flex gap-4 my-24">
              <div className="flex-1">
                <InputLabel>{t('shareProject.create.accessStartsFrom')}</InputLabel>
                <DatePicker
                  placeholder={t('shareProject.create.select')}
                  value={values.startDate}
                  error={Boolean(submitCount && errors.startDate)}
                  helperText={!!submitCount && errors.startDate}
                  onChange={(startDate: Scalars['DateTime']) =>
                    setValues({
                      ...values,
                      startDate,
                    })
                  }
                />
              </div>
              <div className="pt-36">
                <DashIcon />
              </div>
              <div className="flex-1">
                <InputLabel>{t('shareProject.create.to')}</InputLabel>
                <DatePicker
                  placeholder={t('shareProject.create.select')}
                  value={values.endDate}
                  error={Boolean(submitCount && errors.endDate)}
                  helperText={!!submitCount && errors.endDate}
                  onChange={(endDate: Scalars['DateTime']) =>
                    setValues({
                      ...values,
                      endDate,
                    })
                  }
                />
              </div>
            </div>

            <FormControlLabel
              control={<Checkbox checked={values.assignAsPM} color="primary" />}
              label={t('shareProject.create.assignAsPM')}
              name="assignAsPM"
              onChange={handleChange}
              className="ml-0 mb-24"
              classes={{ label: clsx(styles.checkboxLabelText, values.assignAsPM && styles.active) }}
            />

            <div className="flex gap-12 justify-content-end">
              <Button color="secondary" variant="outlined" onClick={() => resetForm()}>
                {t('shareProject.create.cancel')}
              </Button>
              <LoadingButton loading={loading} type="submit">
                {t('shareProject.create.share')}
              </LoadingButton>
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <ConfirmModal
        title={t('shareProject.create.grantFullAccess')}
        submitButtonTitle={t('shareProject.create.grantFullAccess')}
        isOpen={isOpenConfirm}
        onSubmit={handleSubmit}
        onClose={onCloseConfirm}
      >
        {t('shareProject.create.confirmMessage')}
      </ConfirmModal>
    </form>
  );
};
