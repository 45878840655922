import React, { Children, memo, ReactNode } from 'react';
import isEqual from 'react-fast-compare';
import { DownTriangle } from 'icons';

import styles from './styles.module.scss';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

type RowsAccordionProps = {
  title: string;
  children: ReactNode;
  collapsed?: boolean;
  onChangeCollapse?: () => void;
};

type ChildElement = {
  props: { isHidden: boolean };
};

export const RowsAccordion = memo<RowsAccordionProps>(({ title, children, collapsed, onChangeCollapse }) => {
  const childrenCount = Children.toArray(children).filter((element) => !(element as ChildElement)?.props?.isHidden)
    .length;

  return (
    <Accordion
      className={styles.timelineAccordion}
      defaultExpanded
      expanded={typeof collapsed === 'boolean' ? !collapsed : undefined}
      onChange={onChangeCollapse || undefined}
      TransitionProps={{ timeout: 0 }}
    >
      <AccordionSummary
        className={styles.timelineAccordionSummary}
        expandIcon={<DownTriangle />}
        aria-controls="row-accordion-content"
        id="row-accordion-header"
      >
        <span className={styles.timelineAccordionSummaryText}>{title}</span>
        <span className={styles.timelineAccordionSummaryCount}>{childrenCount}</span>
      </AccordionSummary>
      <AccordionDetails className={styles.timelineAccordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
}, isEqual);

RowsAccordion.displayName = 'Timeline.Team.RowsAccordion';
