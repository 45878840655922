import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import { idRegEx } from 'utils/regex';
import UsersComponent from './Users';

export const UsersRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    userId: Route.params.regex(idRegEx).optional,
    search: Route.query.string,
  },
  ({ mode, userId }) => `/settings/users/${mode}/${userId}`,
);

export const Users = UsersRoute(UsersComponent);
