import React, { ReactElement, ReactNode } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';

import styles from './DialogWrapper.module.scss';
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from 'icons';
import clsx from 'clsx';

interface DialogWrapperProps extends Omit<DialogProps, 'title'> {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string | ReactElement;
  className?: string;
  contentClassName?: string;
  dialogActionsId?: string;
  hideActions?: boolean;
  additionalButtons?: ReactNode;
}

export const DialogWrapper = ({
  open,
  onClose,
  children,
  title,
  className,
  contentClassName,
  dialogActionsId,
  hideActions,
  additionalButtons,
  ...rest
}: DialogWrapperProps) => (
  <Dialog
    scroll="paper"
    classes={{
      paper: clsx(styles.paper, className),
    }}
    onClose={onClose}
    open={open}
    {...rest}
  >
    <div className={styles.header}>
      <DialogTitle className={styles.title}>{title}</DialogTitle>
      <div className="flex align-items-center gap-12">
        {additionalButtons}
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
    </div>
    <DialogContent dividers className={contentClassName}>
      {children}
    </DialogContent>
    {!hideActions && <DialogActions id={dialogActionsId || 'dialog-actions'} className={styles.actions} />}
  </Dialog>
);
