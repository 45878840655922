import React, { useMemo, useState } from 'react';
import { Row, TableBodyProps } from 'react-table';
import clsx from 'clsx';
import { Link } from 'components';

import { ACTIONS, SELECTION, SETTING } from 'consts';

import styles from './Body.module.scss';

export interface BodyProps<D extends Record<string, unknown> = Record<string, never>> {
  getTableBodyProps: () => TableBodyProps;
  rows: Row<D>[];
  prepareRow: (row: Row<D>) => void;
  onRowClick?: (rowId: string) => unknown;
  getRedirectRowLink?: (rowId: string) => string;
  getRowWarning?: (rowData: D) => boolean;
  showFooter?: boolean;
  isCouldBeSelected?: boolean;
  isSelectMode?: boolean;
  stickyLeftSelection?: boolean;
}

interface RowWrapperProps<D extends Record<string, unknown> = Record<string, never>> {
  row: Row<D>;
  prepareRow: (row: Row<D>) => void;
  onRowClick?: (rowId: string) => unknown;
  getRedirectRowLink?: (rowId: string) => string;
  getRowWarning?: (rowData: D) => boolean;
  showFooter?: boolean;
  isCouldBeSelected?: boolean;
  isSelectMode?: boolean;
  stickyLeftSelection?: boolean;
}

export const RowWrapper = <D extends Record<string, unknown> = Record<string, never>>({
  row,
  prepareRow,
  onRowClick,
  getRedirectRowLink,
  getRowWarning,
  showFooter,
  isCouldBeSelected,
  isSelectMode,
  stickyLeftSelection,
}: RowWrapperProps<D>) => {
  const [isHovered, setIsHovered] = useState(false);
  prepareRow(row);

  const rowColumns = useMemo(() => {
    return row.cells.map((cell, index) => {
      return (
        <td
          {...cell.getCellProps()}
          key={`body-column${cell.column.Header}-${index}`}
          className={clsx(styles.column, 'text-14 weight-600', {
            [styles.stickyAction]: cell.column.id === ACTIONS,
            [styles.stickySettings]: cell.column.id === SETTING,
            [styles.selection]: cell.column.id === SELECTION,
            [styles.selectionStickyLeft]: cell.column.id === SELECTION && stickyLeftSelection,
            [styles.stickyLeft]: cell.column.sticky === 'left',
            [styles.stickyLeftWithSelection]: cell.column.sticky === 'left' && isSelectMode,
            [styles.stickyRight]: cell.column.sticky === 'right',
          })}
        >
          {cell.render('Cell', { isHovered })}
        </td>
      );
    });
  }, [row, row.cells, isHovered]);

  return (
    <tr
      {...row.getRowProps()}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
      className={clsx(
        styles.row,
        !!onRowClick && 'pointer',
        showFooter && styles.rowBeforeFooter,
        row.canExpand && styles.canExpanded,
        row.isExpanded && styles.expanded,
        row.depth && styles.depth,
        !row.isSelected && isCouldBeSelected && styles.unselected,
        getRowWarning?.(row.original) && styles.warning,
      )}
      // eslint-disable-next-line
      onClick={() => {
        onRowClick?.((row.original as any).id);
      }}
      key={`body-row${row.original.id || row.id}`}
    >
      {getRedirectRowLink ? (
        <Link to={getRedirectRowLink((row.original as any).id)} color="inherit" className={styles.rowLink}>
          {rowColumns}
        </Link>
      ) : (
        rowColumns
      )}
    </tr>
  );
};

export const Body = <D extends Record<string, unknown> = Record<string, never>>({
  getTableBodyProps,
  rows,
  ...rowProps
}: BodyProps<D>) => {
  return (
    <tbody {...getTableBodyProps()} className={styles.tableBody}>
      {rows.map((row) => (
        <RowWrapper<D> key={`body-row${row.id}`} row={row} {...rowProps} />
      ))}
    </tbody>
  );
};
