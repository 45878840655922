import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  CompanyIntegrationDataFragmentDoc,
  useEditCompanyIntegrationOptionsMutation,
  useEditCompanyIntegrationStatusMutation,
} from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { AvailableIntegrationDataFragment, CompanyIntegrationDataFragment } from 'generated/types';
import { FormValues, IntegrationForm } from '../IntegrationForm';
import { Reference } from '@apollo/client';
import { DateFormats } from 'types';
import { IntegrationsEnum } from '../../Integrations.enum';

interface Props {
  item?: AvailableIntegrationDataFragment & {
    integration?: CompanyIntegrationDataFragment;
  };
  onCancel: () => void;
}

export const EditIntegration: FC<Props> = ({ item, onCancel }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [editIntegrationStatus, { loading: editLoading }] = useEditCompanyIntegrationStatusMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.editCompanyIntegrationStatus) return;

      const updatedIntegrationRef = cache.writeFragment({
        data: data.editCompanyIntegrationStatus,
        fragment: CompanyIntegrationDataFragmentDoc,
      });

      cache.modify({
        id: cache.identify({ __typename: 'CompanyIntegration', id: data.editCompanyIntegrationStatus.id }),
        fields: {
          companyIntegrations(items: Reference[] = []) {
            return items.map((itemRef) =>
              itemRef.__ref === updatedIntegrationRef?.__ref ? updatedIntegrationRef : itemRef,
            );
          },
        },
      });
    },
  });

  const [editCompanyIntegrationOptions, { loading }] = useEditCompanyIntegrationOptionsMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.editCompanyIntegrationOptions && !item?.integration) return;

      const updatedIntegrationRef = cache.writeFragment({
        data: { ...item?.integration, integrationOptions: data?.editCompanyIntegrationOptions },
        fragment: CompanyIntegrationDataFragmentDoc,
      });

      cache.modify({
        id: cache.identify({ __typename: 'CompanyIntegration', id: item?.integration?.id }),
        fields: {
          companyIntegrations(items: Reference[] = []) {
            return items.map((itemRef) =>
              itemRef.__ref === updatedIntegrationRef?.__ref ? updatedIntegrationRef : itemRef,
            );
          },
        },
      });
    },
  });

  const onEditOptions = useCallback(async ({ options, dateFormat }: FormValues) => {
    const optionsData = await editCompanyIntegrationOptions({
      variables: {
        companyId: userData!.company.id,
        companyIntegrationId: item!.integration!.id,
        integrationOptions: options,
      },
    });

    const dateFormatData =
      dateFormat.id !== item?.integration?.dateFormat
        ? await editIntegrationStatus({
            variables: {
              companyId: userData!.company.id,
              companyIntegrationId: item!.integration!.id,
              status: item!.status,
              dateFormat: dateFormat.id,
            },
          })
        : null;

    if (optionsData || dateFormatData) {
      toast.success(t('settings.integrations.notifications.updated'));
      onCancel();
    }
  }, []);

  const optionsInitial = item?.integration?.integrationOptions.map(({ integrationOptionId, syncOption }) => ({
    integrationOptionId,
    syncOption: syncOption?.id,
  }));

  return (
    <IntegrationForm
      hideTokenField
      onSubmit={onEditOptions}
      onCancel={onCancel}
      options={item?.options}
      dateFormatId={item?.integration?.dateFormat as DateFormats}
      optionsInitial={optionsInitial}
      submitButtonText={t('settings.integrations.form.save')}
      isLoading={loading || editLoading}
      showDateFormat={item?.name === IntegrationsEnum.zohoPeople}
    />
  );
};
