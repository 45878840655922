import React, { ReactElement } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

import { WarningIcon } from 'icons';

interface WarningTipProps {
  message?: string;
  icon?: ReactElement;
  className?: string;
}

export const WarningTip = ({ message = 'noCostRateAccessCalculation', icon, className }: WarningTipProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(message) as string} placement="top">
      <span>{icon ?? <WarningIcon className={className} />}</span>
    </Tooltip>
  );
};
