import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import { idRegEx } from 'utils/regex';
import HolidaysComponent from './Holidays';

export const HolidaysRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    holidayId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, holidayId }) => `/settings/resource-management/holidays/${mode}/${holidayId}`,
);

export const Holidays = HolidaysRoute(HolidaysComponent);
