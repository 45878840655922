import React from 'react';
import { Route } from 'react-router-hoc';

const NotFoundRoute = Route('/not-found');

const NotFound = () => {
  return <div>NotFound</div>;
};

export default NotFoundRoute(NotFound);
