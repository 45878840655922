import React from 'react';

interface SearchIconProps {
  className?: string;
}

export const SearchIcon = ({ className }: SearchIconProps) => {
  return (
    <svg
      width="16"
      height="16"
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66668 2.66634C4.45754 2.66634 2.66668 4.4572 2.66668 6.66634C2.66668 8.87548 4.45754 10.6663 6.66668 10.6663C8.87582 10.6663 10.6667 8.87548 10.6667 6.66634C10.6667 4.4572 8.87582 2.66634 6.66668 2.66634ZM1.33334 6.66634C1.33334 3.72082 3.72116 1.33301 6.66668 1.33301C9.6122 1.33301 12 3.72082 12 6.66634C12 7.89882 11.582 9.03365 10.8799 9.93677L14.4714 13.5283C14.7318 13.7886 14.7318 14.2107 14.4714 14.4711C14.2111 14.7314 13.789 14.7314 13.5286 14.4711L9.93711 10.8796C9.03398 11.5816 7.89915 11.9997 6.66668 11.9997C3.72116 11.9997 1.33334 9.61186 1.33334 6.66634Z"
        fill="#85929A"
      />
    </svg>
  );
};
