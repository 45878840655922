import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { ModalModeEnum } from 'types';

export const SkillsRoute = Route(
  {
    id: Route.params.regex(idRegEx),
    mode: Route.params.oneOf(...Object.values(ModalModeEnum)).optional,
  },
  ({ id, mode }) => `/team-member/view/${id}/skills/${mode}`,
);

export const Skills = SkillsRoute(lazy(() => import(/* webpackChunkName: "MemberSkillForm" */ './Skills')));
