import React, { FC, PropsWithChildren, ReactElement, useState } from 'react';
import { Popover as MuiPopover } from '@material-ui/core';

import styles from './styles.module.scss';

interface Props {
  button: ReactElement;
  isOpen: boolean;
  onClose: () => void;
}

export const Popover: FC<PropsWithChildren<Props>> = ({ isOpen, onClose, button, children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const id = isOpen ? 'popover' : undefined;

  return (
    <>
      <button aria-describedby={id} onClick={handleClick} className={styles.button}>
        {button}
      </button>
      <MuiPopover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>{children}</div>
      </MuiPopover>
    </>
  );
};
