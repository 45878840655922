import { ASC } from 'consts';

// eslint-disable-next-line
const sortUsersByField = <T extends { [x: string]: any }>(
  arr: T[],
  direction: 'ASC' | 'DESC',
  field: string,
  ...fields: string[]
): T[] => {
  fields.unshift(field);
  const clone = [...arr];

  const comparator = direction === ASC ? 1 : -1;

  return clone.sort((a, b) => {
    if (fields.every((item) => !a.member[item])) return comparator;
    if (fields.every((item) => !b.member[item])) return -comparator;

    const aFullName = `${fields.map((item) => a.member[item]).join('')}`.trim().toLowerCase();
    const bFullName = `${fields.map((item) => b.member[item]).join('')}`.trim().toLowerCase();

    if (aFullName === '') return comparator;
    if (bFullName === '') return -comparator;
    if (aFullName === bFullName) return 0;

    return aFullName < bFullName ? -comparator : comparator;
  });
};

export { sortUsersByField };
