import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import { idRegEx } from 'utils/regex';
import SeniorityComponent from './Seniority';

export const SeniorityRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    seniorityId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, seniorityId }) => `/settings/team-settings/seniorities/${mode}/${seniorityId}`,
);

export const Seniority = SeniorityRoute(SeniorityComponent);
