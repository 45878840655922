import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { EMPTY_DATA_STATE } from 'consts';
import clsx from 'clsx';

interface Props {
  label?: string;
  value?: ReactNode;
  newValue?: ReactNode;
  isEdited?: boolean;
}

export const Field: FC<Props> = ({ label = '', value, newValue, isEdited }) => {
  const { t } = useTranslation();

  const labelText = isEdited ? `${label} ${t('activityHistory.changed')}:` : `${label}:`;

  return (
    <div className={clsx(styles.contentBox, isEdited && styles.flexWrap)}>
      <span className={styles.labelText}>{labelText}</span>
      {isEdited && <>&nbsp;{t('activityHistory.from')}&nbsp;</>}

      <span className={styles.valueText}>{value || EMPTY_DATA_STATE}</span>

      {(isEdited || newValue) && (
        <>
          &nbsp;{t('activityHistory.to')}&nbsp;
          <span className={styles.valueText}>{newValue || EMPTY_DATA_STATE}</span>
        </>
      )}
    </div>
  );
};
