import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { lazy } from 'react';

export const BillingRoute = Route(
  {
    id: Route.params.regex(idRegEx).optional,
    reportId: Route.params.regex(idRegEx).optional,
    search: Route.query.string,
    status: Route.query.string,
  },
  ({ id, reportId }) => `/project/view/${id}/billing/${reportId}`,
);

export const ProjectBilling = BillingRoute(lazy(() => import(/* webpackChunkName: "Billing" */ './Billing')));
