import { CountPendingRequestDocument, useCountPendingRequestQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useAuth } from 'contexts';
import { useCallback, useEffect, useState } from 'react';
import { client } from 'graphql-client';
import { ActionsType } from '../generated/types';
import { usePermissions } from './usePermissions';

const FIFTEEN_MINUTES = 15000;

export const useCountPendingRequest = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { hasAccess } = usePermissions();
  const [pollInterval, setPollInterval] = useState(FIFTEEN_MINUTES);

  useEffect(() => {
    const startPolling = () => setPollInterval(FIFTEEN_MINUTES);
    const stopPolling = () => setPollInterval(0);
    window.addEventListener('focus', startPolling);
    window.addEventListener('blur', stopPolling);
    return () => {
      window.removeEventListener('focus', startPolling);
      window.removeEventListener('blur', stopPolling);
    };
  });

  const { data: { countPendingRequest = 0 } = {} } = useCountPendingRequestQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
    pollInterval,
    skip: !hasAccess(ActionsType.ManageRequest),
  });

  const decreaseCountPendingRequest = useCallback(
    (count?: number) => {
      const newData = { countPendingRequest: countPendingRequest - (count || 1) };

      client.writeQuery({
        query: CountPendingRequestDocument,
        variables: {
          companyId: userData!.company.id,
        },
        data: newData,
      });
    },
    [countPendingRequest],
  );

  return { countPendingRequest, decreaseCountPendingRequest };
};
