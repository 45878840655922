import React, { FC, useMemo } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { LoadingButton, DatePicker } from 'components';
import { removeUTCTimezone } from 'utils';

export interface NewHolidayFormValues {
  name: string;
  date: string | Date;
}

interface Props {
  onSubmit: (values: NewHolidayFormValues) => void | Promise<void>;
  name?: string;
  date?: string;
  onCancel: () => void;
  submitLabel?: string;
  cancelLabel?: string;
}

export const NewHoliday: FC<Props> = ({ name, submitLabel, cancelLabel, date, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t('forms.newHoliday.holidayNameRequired')),
        date: Yup.string().typeError(t('forms.newHoliday.dateRequired')).required(t('forms.newHoliday.dateRequired')),
      }),
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: name ?? '',
        date: date ? removeUTCTimezone(date) : new Date(),
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="form">
          <div>
            <InputLabel className="required">{t('forms.newHoliday.holidayName')}</InputLabel>
            <Field
              component={TextField}
              name="name"
              placeholder={t('forms.newHoliday.holidayName')}
              className="mb-24"
            />

            <InputLabel>{t('forms.newHoliday.date')}</InputLabel>

            <Field>
              {({
                form: {
                  setValues,
                  values: { date },
                  values,
                  errors,
                  submitCount,
                },
              }: FieldProps<any, Pick<NewHolidayFormValues, 'date'>>) => {
                return (
                  <DatePicker
                    className="mb-30"
                    value={date}
                    error={Boolean(submitCount && errors.date)}
                    helperText={!!submitCount && errors.date}
                    onChange={(date) => {
                      setValues({
                        ...values,
                        date: date as Date,
                      });
                    }}
                  />
                );
              }}
            </Field>
          </div>

          <div className="controls">
            <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
              {submitLabel ?? t('forms.create')}
            </LoadingButton>

            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {cancelLabel ?? t('forms.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
