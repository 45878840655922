import {
  ProjectByIdDocument,
  ProjectDataFragmentDoc,
  ProjectMembershipDataFragmentDoc,
  ProjectMembershipsDocument,
  useCreateMemberProjectsMembershipMutation,
  useCreateProjectMembershipMutation,
  useDeleteProjectMembershipMutation,
  useEditProjectMembershipMutation,
  useEditProjectPmFieldMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { Reference } from '@apollo/client';
import { client } from 'graphql-client';
import { useAuth } from 'contexts';
import mixpanel from 'mixpanel-browser';
import { accessLevelMixpanelLabels } from 'consts';

interface Props {
  pmId?: string;
  memberId?: string;
}

export const useProjectMembershipSubmit = ({ pmId, memberId }: Props) => {
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const { userData } = useAuth();

  const [
    createMemberProjectsMembership,
    { loading: createMemberProjectsMembershipLoading },
  ] = useCreateMemberProjectsMembershipMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted(data) {
      toast.success(t('shareProject.notifications.update'));

      if (data.createMemberProjectsMembership) {
        mixpanel.track('Project shared', {
          Access: accessLevelMixpanelLabels[data.createMemberProjectsMembership[0].accessLevel],
          'Start date': !!data.createMemberProjectsMembership[0].startDate,
          'End date': !!data.createMemberProjectsMembership[0].endDate,
          'Project manager': false,
        });
      }
    },
    update(cache, { data }) {
      if (!data?.createMemberProjectsMembership || !memberId) return;

      const createdProjectMembership = data.createMemberProjectsMembership || [];

      cache.updateQuery(
        {
          query: ProjectMembershipsDocument,
          variables: {
            companyId: userData!.company.id,
            data: { memberId },
          },
        },
        (data) => ({ projectMemberships: [...data.projectMemberships, ...createdProjectMembership] }),
      );
    },
  });

  const [createProjectMembership, { loading: createProjectMembershipLoading }] = useCreateProjectMembershipMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted(data) {
      toast.success(t('shareProject.notifications.update'));

      if (data.createProjectMembership) {
        mixpanel.track('Project shared', {
          Access: accessLevelMixpanelLabels[data.createProjectMembership[0].accessLevel],
          'Start date': !!data.createProjectMembership[0].startDate,
          'End date': !!data.createProjectMembership[0].endDate,
          'Project manager': false,
        });
      }
    },
    update(cache, { data }) {
      if (!data?.createProjectMembership) return;

      const createProjectMembershipRefs = data.createProjectMembership.map((item) =>
        cache.writeFragment({
          data: item,
          fragment: ProjectMembershipDataFragmentDoc,
        }),
      );

      cache.modify({
        fields: {
          projectMemberships(items: Reference[] = []) {
            return [...items, ...createProjectMembershipRefs];
          },
        },
      });
    },
  });

  const [editProjectMembership] = useEditProjectMembershipMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('shareProject.notifications.update'));
    },
    update(cache, { data }) {
      if (!data?.editProjectMembership) return;
      const membership = data.editProjectMembership;

      if (pmId && membership.memberId === pmId) {
        client.refetchQueries({ include: [ProjectByIdDocument] });
      }

      if (membership.memberId === membership.pmId) {
        cache.updateFragment(
          {
            id: `Project:${membership.projectId}`,
            fragmentName: 'ProjectData',
            fragment: ProjectDataFragmentDoc,
          },
          (data) => ({
            ...data,
            pmFinAccess: {
              ...(data?.pmFinAccess || {}),
              accessLevel: membership.accessLevel,
              startDate: membership.startDate,
              endDate: membership.endDate,
            },
          }),
        );
      }

      const updatedProjectMembershipRef = cache.writeFragment({
        data: data?.editProjectMembership,
        fragment: ProjectMembershipDataFragmentDoc,
      });

      cache.modify({
        id: cache.identify({ __typename: 'ProjectMemberships', id: data.editProjectMembership.id }),
        fields: {
          projectMemberships(projectMemberships: Reference[] = []) {
            return projectMemberships.map((projectMembershipsRef) =>
              projectMembershipsRef.__ref === updatedProjectMembershipRef?.__ref
                ? data.editProjectMembership
                : projectMembershipsRef,
            );
          },
        },
      });
    },
  });

  const [editProjectPm, { loading: editProjectPmLoading }] = useEditProjectPmFieldMutation({
    onCompleted(data) {
      toast.success(t('shareProject.notifications.update'));

      if (data.editProjectPMField[0].pmFinAccess) {
        const pmFinAccess = data.editProjectPMField[0].pmFinAccess;
        mixpanel.track('Project shared', {
          Access: accessLevelMixpanelLabels[pmFinAccess.accessLevel],
          'Start date': !!pmFinAccess.startDate,
          'End date': !!pmFinAccess.endDate,
          'Project manager': true,
        });
      }
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteProjectMembership] = useDeleteProjectMembershipMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('shareProject.notifications.update'));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteProjectMembership) });
        cache.gc();
      }
    },
  });

  return {
    editProjectPm,
    createProjectMembership,
    createMemberProjectsMembership,
    editProjectMembership,
    deleteProjectMembership,
    createLoading: createMemberProjectsMembershipLoading || createProjectMembershipLoading || editProjectPmLoading,
  };
};
