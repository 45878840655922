import React, { FC, useCallback, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AbsoluteSpinner, ConfirmModal, EmptyState } from 'components';
import { AssignmentDataFragmentDoc, useEditAssignmentsMutation } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, useIsOpen, usePermissions } from 'hooks';
import { ActionsType, ProjectType } from 'generated/types';
import { EditAssignmentsForm, FormValues } from '../EditAssignmentsForm';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { format } from 'date-fns';

interface NewAssignmentProps {
  assignmentsIds: string[];
  projectType?: ProjectType;
  onCancel: () => void;
  onUpdate?: () => void;
}

const MINUTES_IN_HOUR = 60;

export const EditAssignments: FC<NewAssignmentProps> = ({ assignmentsIds, projectType, onCancel, onUpdate }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [formValue, setFormValue] = useState<FormValues | null>(null);
  const [isOpenEditConfirm, onOpenEditConfirm, onCloseEditConfirm] = useIsOpen(false);
  const { hasAccess, isPermissionsLoading } = usePermissions();

  const disabledBillable = projectType !== ProjectType.TimeAndMaterial && projectType !== ProjectType.Retainer;

  const [editAssignments, { loading }] = useEditAssignmentsMutation({
    onCompleted() {
      toast.success(
        assignmentsIds.length === 1
          ? t('viewProjectDetail.assignments.editAssignments.editedSuccessfully')
          : t('viewProjectDetail.assignments.editAssignments.editedMultipleSuccessfully'),
      );
      mixpanel.track('Assignment edited', { 'Drag and drop': false, Bulk: true });
      onUpdate?.();
      onCancel();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.editAssignments) return;

      data.editAssignments.forEach((item) => {
        cache.updateFragment(
          {
            id: `Assignment:${item.id}`,
            fragmentName: 'AssignmentData',
            fragment: AssignmentDataFragmentDoc,
          },
          () => ({ ...item }),
        );
      });
    },
  });

  const onEditAssignments = ({
    role,
    seniority,
    allocationTimeAmount,
    startDate,
    endDate,
    billingType,
    billable,
  }: FormValues) => {
    editAssignments({
      variables: {
        assignmentsIds,
        companyId: userData!.company.id,
        data: {
          ...(endDate ? { endDate: format(new Date(endDate), DEFAULT_DATE_FORMAT) } : {}),
          ...(startDate ? { startDate: format(new Date(startDate), DEFAULT_DATE_FORMAT) } : {}),
          ...(role ? { roleId: role.id } : {}),
          ...(seniority ? { seniorityId: seniority.id } : {}),
          ...(allocationTimeAmount
            ? { allocationTimeAmount: Math.round(+allocationTimeAmount * MINUTES_IN_HOUR) }
            : {}),
          ...(billingType ? { calculationType: billingType.id } : {}),
          ...(billable ? { billable: billable.id } : {}),
        },
      },
    });
  };

  const onSubmitForm = useCallback((value: FormValues) => {
    setFormValue(value);
    onOpenEditConfirm();
  }, []);

  if (isPermissionsLoading) {
    return <AbsoluteSpinner />;
  }

  if (!hasAccess(ActionsType.EditAssignments)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <EditAssignmentsForm
        onCancel={onCancel}
        onSubmit={onSubmitForm}
        disabledBillable={disabledBillable}
        isLoading={loading}
      />

      <ConfirmModal
        title={t(
          assignmentsIds.length === 1 ? 'forms.newAssignment.editAssignment' : 'forms.newAssignment.editAssignments',
        )}
        isOpen={isOpenEditConfirm}
        onSubmit={() => {
          formValue && onEditAssignments(formValue);
          onCloseEditConfirm();
        }}
        onClose={() => {
          onCloseEditConfirm();
        }}
      >
        {t(assignmentsIds.length === 1 ? 'forms.newAssignment.confirmEdit' : 'forms.newAssignment.confirmEditMultiple')}
      </ConfirmModal>
    </>
  );
};
