import { useCreateMemberSpecializationMutation, useSpecializationsQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { Reference } from '@apollo/client';
import { useAuth } from '../contexts';
import { Specialization } from '../generated/types';

export const useCreatableSpecializationData = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { specializations = [] } = {}, loading: specializationLoading } = useSpecializationsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const [createSpecialization] = useCreateMemberSpecializationMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createSpecialization) return;

      cache.modify({
        fields: {
          specializations(items: Reference[] = []) {
            return [...items, data.createSpecialization];
          },
        },
      });
    },
  });

  const getCreatedSpecialization = async (value: Pick<Specialization, 'id' | 'name'> | null) => {
    if (value?.id) {
      return value;
    }

    if (!value?.name) {
      return null;
    }

    const { data } = await createSpecialization({
      variables: {
        data: { name: value?.name || '' },
        companyId: userData!.company.id,
      },
    });

    return data?.createSpecialization || null;
  };

  return { specializations, specializationLoading, getCreatedSpecialization };
};
