import { useExportReportLazyQuery } from 'generated/graphql';
import { downloadExcelXLS, graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useAuth } from 'contexts';
import { ReportDataFragment } from 'generated/types';
import mixpanel from 'mixpanel-browser';

interface Props {
  onCompleted?: () => void;
  reportData?: ReportDataFragment | null;
}

export const useExportReport = ({ onCompleted, reportData }: Props) => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [exportReportFile, { loading: loadingExportReport }] = useExportReportLazyQuery({
    onCompleted(data) {
      mixpanel.track('Report exported', { Type: 'billing-report', From: 'report-modal' });
      onCompleted?.();
      if (data?.exportReport) {
        downloadExcelXLS(data.exportReport, reportData?.title || '');
      }
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    fetchPolicy: 'no-cache',
  });

  const skip = !reportData?.id || !reportData?.project.id;
  const exportReport = skip
    ? () => void 0
    : () =>
        exportReportFile({
          variables: {
            companyId: userData!.company.id,
            reportId: reportData?.id || '',
            projectId: reportData?.project.id || '',
          },
        });

  return {
    exportReport,
    loadingExportReport,
  };
};
