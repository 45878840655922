import React, { FC } from 'react';
import { getFileSize, getFileIcon } from 'utils';
import { CancelIcon, TrashIcon } from 'icons';
import { IconButton, LinearProgress } from '@material-ui/core';
import styles from './styles.module.scss';
import { Tooltip } from 'components';

interface Props {
  name: string;
  size: number;
  loading?: boolean;
  handleDelete: () => void;
}

export const UploadFileItem: FC<Props> = ({ name, size, loading, handleDelete }) => {
  const Icon = getFileIcon(name);

  return (
    <div className={styles.container}>
      <Icon />
      <div className="flex flex-column">
        <span>
          <Tooltip title={name} textClassName={styles.text} placement="top">
            {name}
          </Tooltip>
        </span>
        <span className={styles.description}>{getFileSize(size)}</span>
      </div>

      <div className={styles.actions}>
        {loading && <LinearProgress className={styles.linearProgress} />}

        <IconButton size="small" onClick={handleDelete}>
          {loading ? <CancelIcon /> : <TrashIcon className={styles.trashIcon} />}
        </IconButton>
      </div>
    </div>
  );
};
