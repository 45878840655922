import { createStyles, Theme, withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

const ACCENT_COLOR = '#60D56C';
const BACKGROUND_COLOR = '#1F2C34';

export const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 52,
      height: 30,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 4,
      color: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      '&$checked': {
        transform: 'translateX(22px)',
        color: ACCENT_COLOR,
        '& + $track': {
          opacity: 1,
          backgroundColor: BACKGROUND_COLOR,
          borderColor: BACKGROUND_COLOR,
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${BACKGROUND_COLOR}`,
      borderRadius: 30 / 2,
      opacity: 1,
      backgroundColor: BACKGROUND_COLOR,
    },
    checked: {
      track: {
        backgroundColor: BACKGROUND_COLOR,
      },
    },
  }),
)(Switch);
