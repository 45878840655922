import React, { forwardRef } from 'react';
import { Button } from '@material-ui/core';
import { format, parse } from 'date-fns';
import { CalendarIcon, DownTriangle } from 'icons';
import clsx from 'clsx';

type Props = { value?: string; className?: string };

export const DateRangeButton = forwardRef<HTMLButtonElement, Props>(({ value, className, ...props }, ref) => {
  const values = value?.split('-') ?? [];
  const start = parse(values[0]?.trim(), 'dd/MM/yyyy', new Date());
  const end = values[1]?.trim() && parse(values[1].trim(), 'dd/MM/yyyy', new Date());
  return (
    <Button
      ref={ref}
      color="inherit"
      startIcon={<CalendarIcon />}
      variant="text"
      className={clsx('pr-16', className)}
      endIcon={<DownTriangle />}
      {...props}
    >
      {start && format(start, 'MMM yyyy')}
      {end && `- ${format(end, 'MMM yyyy')}`}
    </Button>
  );
});

DateRangeButton.displayName = 'RangeButton';
