import React from 'react';

interface ArrowBackProps {
  className?: string;
}

export const ArrowBack = ({ className }: ArrowBackProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47132 4.47132C8.73167 4.21097 8.73167 3.78886 8.47132 3.52851C8.21097 3.26816 7.78886 3.26816 7.52851 3.52851L3.56494 7.49209C3.42307 7.61435 3.33325 7.79534 3.33325 7.99731L3.33325 7.99862C3.33292 8.16967 3.39801 8.34082 3.52851 8.47132L7.52851 12.4713C7.78886 12.7317 8.21097 12.7317 8.47132 12.4713C8.73167 12.211 8.73167 11.7889 8.47132 11.5285L5.60679 8.66398H11.9999C12.3681 8.66398 12.6666 8.3655 12.6666 7.99731C12.6666 7.62912 12.3681 7.33065 11.9999 7.33065H5.612L8.47132 4.47132Z"
        fill="#161B1D"
      />
    </svg>
  );
};
