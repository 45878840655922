import React, { CSSProperties, memo, useMemo, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTimelineContext } from 'contexts';
import { useQueryParams } from 'utils/useQueryParams';
import { ActionsType } from 'generated/types';
import { AssignmentHandle, Boundaries, usePermissions, useResizeAndMoveAssignment, useTimelinePeriodDays } from 'hooks';
import { addTimezoneOffset } from 'utils';
import { links } from 'App';
import { ModalModeEnum } from 'types';
import { BillableSign } from 'components';

import { AssignmentRowItem } from 'views/ResourcePlanning/types';
import { getAssignmentDate, getMinColumnWidth, getStartEndPeriodDates } from 'views/ResourcePlanning/utils';
import {
  differenceInBusinessDays,
  differenceInCalendarDays,
  differenceInDays,
  endOfDay,
  isAfter,
  isBefore,
  isSameDay,
} from 'date-fns';
import { AssignmentTooltip } from './AssignmentTooltip';
import { DraggableArea } from './AssignmentAreas';

import styles from './styles.module.scss';

type Props = {
  projectId?: string;
  boundaries: Boundaries;
  memberId?: string;
  assignment: AssignmentRowItem;
  date: Date;
};

export const AssignmentsRowItem = memo<Props>(({ projectId, boundaries, memberId, assignment, date, ...props }) => {
  const { t } = useTranslation();
  const { collapsedWeekends, timelinePeriod } = useTimelineContext();
  const { days } = useTimelinePeriodDays(date);
  const { hasAccess } = usePermissions();
  const { params } = useRouteMatch();
  const query = useQueryParams();
  const { push } = useHistory();
  const minColumnWidth = getMinColumnWidth(timelinePeriod);
  const isRequest = assignment.isRequest;
  const [startPeriodDate, endPeriodDate] = getStartEndPeriodDates(date, timelinePeriod);
  const assignedRef = useRef<AssignmentHandle | null>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);

  const getDiff = (dayA: Date | number, dayB: Date | number) => {
    return collapsedWeekends ? differenceInBusinessDays(dayA, dayB) : differenceInCalendarDays(dayA, dayB);
  };

  const { assignmentsData, isDragging, onDrag, onStart, onStop } = useResizeAndMoveAssignment(
    assignment,
    assignedRef,
    days,
    memberId,
    projectId,
    isRequest,
  );

  const onSelect = () => {
    if (!hasAccess(ActionsType.EditAssignments) && hasAccess(ActionsType.SubmitAssignmentRequest)) {
      const mode = isRequest ? ModalModeEnum.requestEdit : ModalModeEnum.request;
      return push(links.ResourcePlanning({ ...params, ...query, mode, id: assignment.id }));
    }

    return hasAccess(ActionsType.EditAssignments)
      ? push(links.ResourcePlanning({ ...params, ...query, mode: ModalModeEnum.manage, id: assignment.id }))
      : undefined;
  };

  const startDateShift = differenceInDays(
    new Date(String(assignmentsData?.startDate)),
    new Date(String(assignment?.startDate)),
  );
  const endDateShift = differenceInDays(
    new Date(String(assignmentsData?.endDate)),
    new Date(String(assignment?.endDate)),
  );

  const label = (
    <span className={styles.projectLineLabel}>
      {t('resourcePlanning.hours', { hours: assignment?.allocationTimeAmount || 0 })} •{' '}
      <BillableSign billable={!!assignment?.billable} />
      {isRequest ? ' • ' + t('resourcePlanning.requested') : ''}
    </span>
  );

  const getAssignmentStyle = (amount?: number) =>
    ({
      position: 'relative',
      height: '36px',
      flex: `${amount ?? 1} 1 0px`,
      minWidth: `${(amount ?? 1) * minColumnWidth}px`,
    } as CSSProperties);

  const amount = useMemo(() => {
    const assignmentEndDate = new Date(String(assignment?.endDate));
    const assignmentStartDate = new Date(String(assignment?.startDate));

    const endDate = isAfter(assignmentEndDate, endPeriodDate) ? endPeriodDate : assignmentEndDate;
    const startDate = isAfter(startPeriodDate, assignmentStartDate) ? startPeriodDate : assignmentStartDate;

    return isSameDay(endDate, startDate) ? 1 : getDiff(addTimezoneOffset(endOfDay(endDate)), startDate);
  }, [collapsedWeekends]);

  const columnWidth = useMemo(() => {
    return boxRef.current?.clientWidth ? boxRef.current?.clientWidth / amount : minColumnWidth;
  }, [collapsedWeekends, !!boxRef.current]);

  const hiddenAssignmentWidth = useMemo(() => {
    const endDate = new Date(String(assignment?.endDate));
    const startDate = new Date(String(assignment?.startDate));

    const totalAmount = isSameDay(endDate, startDate) ? 1 : getDiff(addTimezoneOffset(endOfDay(endDate)), startDate);
    return (totalAmount - amount) * columnWidth;
  }, [collapsedWeekends, amount]);

  return (
    <div key={assignment.id} style={getAssignmentStyle(amount)} ref={boxRef}>
      <AssignmentTooltip assignment={assignment}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: `${startDateShift * columnWidth}px`,
            right: `${-endDateShift * columnWidth}px`,
            height: '36px',
          }}
        >
          <DraggableArea
            {...props}
            ref={assignedRef}
            onSelect={onSelect}
            id={assignment.id}
            boundaries={boundaries}
            onStart={onStart}
            onDrag={onDrag}
            onStop={onStop}
            isDragging={isDragging}
            isRequest={isRequest}
            label={label}
            disableStartDraggable={isBefore(getAssignmentDate(assignmentsData?.startDate), startPeriodDate)}
            disableEndDraggable={isAfter(getAssignmentDate(assignmentsData?.endDate), endPeriodDate)}
            hiddenAssignmentWidth={hiddenAssignmentWidth}
          />
        </div>
      </AssignmentTooltip>
    </div>
  );
});

AssignmentsRowItem.displayName = 'Timeline.AssignmentsRowItem';
