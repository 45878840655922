import React, { useMemo } from 'react';
import { Formik, Form, Field, FormikHelpers, FieldProps } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';

import { EditableMemberType } from 'generated/types';
import { Autocomplete, LoadingButton } from 'components';
import { submitForm } from 'utils';

export interface NewUserFormValues {
  email: string;
  role: Role | null;
}

interface NewUserProps {
  onSubmit: (values: NewUserFormValues) => void | Promise<void>;
  email?: string;
  role?: EditableMemberType;
  emailDisabled?: boolean;
  roleDisabled?: boolean;
  onCancel: () => void;
  submitLabel?: string;
  cancelLabel?: string;
}

interface Role {
  id: EditableMemberType;
  name: string;
}

export const NewUser = ({ email, role, emailDisabled, submitLabel, cancelLabel, onSubmit, onCancel }: NewUserProps) => {
  const { t } = useTranslation();

  const roles = useMemo(
    () => [
      { id: EditableMemberType.Admin, name: t('roles.admin') },
      { id: EditableMemberType.User, name: t('roles.user') },
      { id: EditableMemberType.Employee, name: t('roles.employee') },
    ],
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email(t('forms.emailFormatError')).required(t('forms.emailRequiredError')),
        role: Yup.object().nullable().required(t('forms.users.selectPermissionError.required')),
      }),
    [],
  );

  const handleSubmit = (values: NewUserFormValues, { setSubmitting }: FormikHelpers<NewUserFormValues>) => {
    submitForm(values, setSubmitting, onSubmit);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: email ?? '',
        role: roles.find((item) => item.id === role) || null,
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setValues, values }) => (
        <Form className="form">
          <div>
            <InputLabel className="required">{t('forms.emailAddress')}</InputLabel>
            <Field
              disabled={emailDisabled}
              component={TextField}
              name="email"
              placeholder={t('forms.emailAddress')}
              type="email"
              className="mb-24"
            />

            <InputLabel className="required">{t('forms.users.permission')}</InputLabel>
            <Field name="role">
              {({
                field: { value, onBlur },
                meta: { touched, error },
              }: FieldProps<NewUserFormValues['role'], NewUserFormValues>) => (
                <Autocomplete
                  placeholder={t('forms.users.selectPermission')}
                  name="role"
                  onBlur={onBlur}
                  className="mb-24"
                  value={value}
                  error={touched ? error : undefined}
                  onChange={(role: Role) => setValues({ ...values, role })}
                  options={roles}
                />
              )}
            </Field>
          </div>

          <div className="controls">
            <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
              {submitLabel ?? t('forms.create')}
            </LoadingButton>

            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {cancelLabel ?? t('forms.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
