import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';

// consts refers to type declaration, so i can't use here absolute path
import { POP, PUSH, REPLACE } from 'consts';
import { sessionStorageManager } from 'services';
import { useAuth } from './Auth';

const SESSION_KEY = 'ROUTES_STACK';

export const RouteTrackerContext = createContext<{
  hasPrev: boolean;
  currentPath: Location | undefined;
  prevPath: Location | undefined;
  routesStack: Location[];
  navigateBack: () => void;
  navigateToPrevRoute: () => void;
}>({
  hasPrev: false,
  currentPath: undefined,
  prevPath: undefined,
  routesStack: [],
  navigateBack: () => {
    console.error('not init');
  },
  navigateToPrevRoute: () => {
    console.error('not init');
  },
});

export const useRouteTracker = () => useContext(RouteTrackerContext);

interface RouteTrackerProviderProps {
  children: ReactNode;
}

export const RouteTrackerProvider = ({ children }: RouteTrackerProviderProps) => {
  const authData = useAuth();

  const [routesStack, setRoutingStack] = useState<Location[]>(() => {
    const fromSession = sessionStorageManager.getItem(SESSION_KEY);

    return fromSession || [];
  });
  const history = useHistory();

  useEffect(() => {
    authData.isAuthenticated &&
      // eslint-disable-next-line
      history.listen((...args: any[]) => {
        if (!authData.isAuthenticated) {
          sessionStorageManager.setItem(SESSION_KEY, JSON.stringify([]));

          return;
        }

        const [action, method] = (args as unknown) as [Location, typeof PUSH | typeof REPLACE | typeof POP];

        switch (method) {
          case PUSH:
            return setRoutingStack((prev) => {
              const lastItem = prev[prev.length - 1];
              return lastItem?.key === action.key ? prev : [...prev, action];
            });
          case REPLACE:
            return setRoutingStack((prev) => [...prev.slice(0, prev.length - 1), action]);
          case POP:
            return setRoutingStack((prev) => prev.slice(0, prev.length - 1));
        }
      });
  }, [authData.isAuthenticated]);

  useEffect(() => {
    sessionStorageManager.setItem(SESSION_KEY, routesStack);
  }, [routesStack]);

  const currentPath: Location | undefined = routesStack[routesStack.length - 1];
  const prevPath = routesStack[routesStack.length - 2];
  const hasPrev = !!routesStack.length;

  const navigateBack = () => hasPrev && history.go(-1);
  const navigateToPrevRoute = () => {
    const routes = [...routesStack].reverse();
    const currentPath = routes[0];

    const prevRouteIndex = routes.findIndex(
      (item) => item?.pathname.split('/')[1] !== currentPath.pathname.split('/')[1],
    );

    if (prevRouteIndex) {
      history.go(-prevRouteIndex);
      setRoutingStack((prev) => prev.slice(0, prev.length - (prevRouteIndex - 1)));
    } else {
      history.back();
    }
  };

  return (
    <RouteTrackerContext.Provider
      value={{
        hasPrev,
        currentPath,
        prevPath,
        routesStack,
        navigateBack,
        navigateToPrevRoute,
      }}
    >
      {children}
    </RouteTrackerContext.Provider>
  );
};
