import React, { FC, memo, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

import { CountChip, SidebarItem, UserInfoNav } from 'components';
import { links } from 'App';
import {
  ActivityActiveLink,
  ActivityLink,
  FileActiveLink,
  FileLink,
  MenuIcon,
  ProjectsActiveLink,
  ProjectsLink,
  SkelarMinLogoIcon,
  SkelarTitle,
  TeamActiveLink,
  TeamLink,
} from 'icons';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { isAdminPermission } from 'utils';
import { useAuth } from 'contexts';

import { ActivityTabs } from 'types';
import { useCountPendingRequest, useIsOpen } from 'hooks';
import { Badge, IconButton, SwipeableDrawer } from '@material-ui/core';

import styles from './MobileSidebar.module.scss';

export const MobileSidebar: FC<{ children?: ReactNode }> = memo(({ children }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const [isOpenDrawer, onOpenDrawer, onCloseDrawer] = useIsOpen(false);
  const { pathname } = useLocation();
  const { countPendingRequest } = useCountPendingRequest();

  const viewTeamMemberLink = links.ViewTeamMember({ id: '0' }).split('/')[1];
  const teamsRouteIsActive =
    pathname.includes(links.ArchivedTeamMembers()) ||
    pathname.includes(links.ActiveTeamMembers()) ||
    pathname.startsWith(viewTeamMemberLink, 1);

  const projectDetailLink = links.ProjectDetail({ id: '0' }).split('/')[1];
  const projectsRouteIsActive =
    pathname.includes(links.ActiveProjects()) ||
    pathname.includes(links.ArchivedProjects()) ||
    pathname.startsWith(projectDetailLink, 1);

  useEffect(() => {
    onCloseDrawer();
  }, [pathname]);

  return (
    <div className="flex flex-column w-100">
      <header className={styles.header}>
        <IconButton onClick={onOpenDrawer}>
          <MenuIcon className={styles.menuIcon} />
        </IconButton>
        <SkelarTitle />
      </header>
      {children}
      <SwipeableDrawer
        classes={{ paper: styles.drawer }}
        anchor="left"
        open={isOpenDrawer}
        onOpen={onOpenDrawer}
        onClose={onCloseDrawer}
      >
        <div className={clsx(styles.sidebar)}>
          <div className={clsx(styles.logoWrapper)}>
            <SkelarMinLogoIcon />
            <SkelarTitle />
          </div>

          <SidebarItem
            route={links.ActiveTeamMembers()}
            isActive={() => teamsRouteIsActive}
            activeClass={styles.active}
          >
            {teamsRouteIsActive ? <TeamActiveLink /> : <TeamLink />}
            <span>{t('navigation.teams')}</span>
          </SidebarItem>
          <SidebarItem
            route={links.ActiveProjects()}
            isActive={() => projectsRouteIsActive}
            activeClass={styles.active}
          >
            {projectsRouteIsActive ? <ProjectsActiveLink /> : <ProjectsLink />}
            <span>{t('navigation.projects')}</span>
          </SidebarItem>
          <SidebarItem
            className={clsx(!isAdminPermission(userData!.type) && 'd-none')}
            route={links.Files()}
            isActive={() => pathname.includes(links.Files())}
            activeClass={styles.active}
          >
            {pathname.includes(links.Files()) ? <FileActiveLink /> : <FileLink />}
            <span>{t('navigation.files')}</span>
          </SidebarItem>

          <div className={styles.navbarDivider} />

          <SidebarItem
            route={links.Activity({ tab: ActivityTabs.requestsInbox })}
            isActive={() => pathname.includes(links.Activity())}
            activeClass={styles.active}
          >
            <Badge
              invisible
              classes={{
                dot: styles.badge,
              }}
              badgeContent=" "
              variant="dot"
            >
              {pathname.includes(links.Activity()) ? <ActivityActiveLink /> : <ActivityLink />}
            </Badge>
            <span>{t('navigation.activity')}</span>
            {!!countPendingRequest && <CountChip className="ml-auto" count={countPendingRequest} />}
          </SidebarItem>

          <div className="mt-auto">
            <div className={styles.userInfoNav}>
              <UserInfoNav isFullView />
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
});

MobileSidebar.displayName = 'MobileSidebar';
