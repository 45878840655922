import React, { memo } from 'react';
import { FinancialHiddenIcon, FinancialIcon } from 'icons';

import styles from './styles.module.scss';

interface Props {
  billable: boolean;
}

export const BillableSign = memo(({ billable }: Props) => {
  return billable ? (
    <FinancialIcon className={styles.billableIcon} />
  ) : (
    <FinancialHiddenIcon className={styles.billableIcon} />
  );
});

BillableSign.displayName = 'BillableSign';
