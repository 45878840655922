import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, NumberTextField } from 'components';
import clsx from 'clsx';
import { IconButton, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CancelCircle, SubmitCircle } from 'icons';

import styles from './styles.module.scss';

export type FormValues = {
  title: string;
  amount?: number;
  notes?: string;
  paymentDate: string | null;
};

interface Props {
  onSubmit: (value: FormValues) => void;
  onCancel: () => void;
  initialValue?: FormValues;
  loading?: boolean;
}

export const MilestoneForm: FC<Props> = ({ initialValue, loading, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      title: Yup.string().required(t('validation.required')),
      notes: Yup.string(),
      amount: Yup.number()
        .nullable()
        .typeError(t('validation.validNumber'))
        .required(t('validation.required'))
        .test('moreThan', t('validation.moreThan', { digits: 0 }), (number) => +(number || 0) > 0)
        .test('maxDigitsAfterDecimal', t('validation.decimal', { digits: 2 }), (number) =>
          /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
        ),
      paymentDate: Yup.string().nullable().required(t('validation.required')),
    });
  }, []);

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    setValues,
    touched,
    errors,
    submitCount,
    setFieldTouched,
  } = useFormik<FormValues>({
    initialValues: initialValue || {
      title: '',
      amount: undefined,
      notes: '',
      paymentDate: null,
    },
    validationSchema,
    onSubmit,
    validateOnChange: true,
  });

  return (
    <form className={styles.row}>
      <TextField
        placeholder={t('viewProjectDetail.milestones.enterTitle')}
        name="title"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title?.toString()}
        className={clsx(styles.item, styles.title)}
        error={touched.title && !!errors.title}
        helperText={touched.title && errors.title}
        InputProps={{ className: styles.input }}
      />
      <DatePicker
        placeholder="MM/DD/YYYY"
        name="paymentDate"
        value={values.paymentDate}
        error={Boolean((submitCount || touched.paymentDate) && errors.paymentDate)}
        helperText={Boolean(submitCount || touched.paymentDate) && errors.paymentDate}
        onChange={(paymentDate) => {
          setFieldTouched('paymentDate', true);
          setValues({ ...values, paymentDate: paymentDate as FormValues['paymentDate'] });
        }}
        hideCalendarIcon={!!values.paymentDate}
        className={clsx(styles.item, styles.paymentDate)}
        InputProps={{ className: styles.input }}
      />
      <NumberTextField
        placeholder="0"
        name="amount"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.amount?.toString()}
        className={clsx(styles.item, styles.amount)}
        error={touched.amount && !!errors.amount}
        helperText={touched.amount && errors.amount}
        InputProps={{ className: styles.input }}
      />
      <TextField
        placeholder={t('viewProjectDetail.milestones.typeNoteHere')}
        name="notes"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.notes?.toString()}
        className={clsx(styles.item, styles.notes)}
        error={touched.notes && !!errors.notes}
        helperText={touched.notes && errors.notes}
        InputProps={{ className: styles.input }}
      />

      <div className={styles.menu}>
        <IconButton size="small" disabled={loading} className={styles.iconButton} onClick={() => handleSubmit()}>
          <SubmitCircle className={styles.icon} />
        </IconButton>
        <IconButton size="small" className={styles.iconButton} onClick={onCancel}>
          <CancelCircle className={styles.icon} />
        </IconButton>
      </div>
    </form>
  );
};
