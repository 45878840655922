import React, { FC } from 'react';
import { Switch, Tooltip } from 'components';
import { useBillableLeavesRulesQuery } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { LeaveRules } from './LeaveRules';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from 'icons';

import styles from './styles.module.scss';

interface Props {
  projectId: string;
  isBillableLeaves: boolean;
  onChangeIsBillableLeaves: () => void;
  onChangeIsLeavesUnsaved?: (value: boolean) => void;
}

export const BillableLeaves: FC<Props> = ({
  projectId,
  isBillableLeaves,
  onChangeIsBillableLeaves,
  onChangeIsLeavesUnsaved,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { billableLeavesRules = [] } = {}, loading } = useBillableLeavesRulesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      projectId,
    },
  });

  return (
    <div className="mb-24">
      <p className={styles.title}>
        <Switch
          checked={isBillableLeaves}
          onChange={onChangeIsBillableLeaves}
          color="primary"
          name="isShowPastRates"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          title={t('viewProjectDetail.billableLeaves.enableBillableLeaves')}
        />
        {isBillableLeaves && <div className={styles.count}>{billableLeavesRules.length}</div>}
        <Tooltip
          title={t('viewProjectDetail.billableLeaves.description')!}
          placement="bottom"
          alwaysShowTooltip
          className={styles.tooltip}
        >
          <InfoIcon />
        </Tooltip>
      </p>
      {isBillableLeaves && (
        <LeaveRules
          leavesRules={billableLeavesRules}
          loading={loading}
          projectId={projectId}
          onChangeIsLeavesUnsaved={onChangeIsLeavesUnsaved}
          isBillableLeaves={isBillableLeaves}
          onChangeIsBillableLeaves={onChangeIsBillableLeaves}
        />
      )}
    </div>
  );
};
