import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import {
  ProjectAssignmentsListDocument,
  ProjectMilestoneDataFragmentDoc,
  useCreateProjectMilestoneMutation,
} from 'generated/graphql';
import { FormValues, MilestoneForm } from '../components/MilestoneForm';
import { updateProjectTotalByMilestones } from '../utils/updateProjectTotalByMilestones';
import { toast } from 'react-toastify';
import { Reference } from '@apollo/client';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';

interface Props {
  projectId: string;
  onClose: () => void;
}

export const CreateMilestone: FC<Props> = ({ projectId, onClose }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [createProjectMilestone, { loading }] = useCreateProjectMilestoneMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      toast.success(t('viewProjectDetail.milestones.createSuccessfully'));
      onClose();
    },
    update(cache, { data }) {
      if (!data?.createProjectMilestone) return;

      const createdMilestoneRef = cache.writeFragment({
        data: data.createProjectMilestone,
        fragment: ProjectMilestoneDataFragmentDoc,
      });

      cache.modify({
        fields: {
          projectMilestones(items: Reference[] = []) {
            return [...items, createdMilestoneRef];
          },
        },
      });

      updateProjectTotalByMilestones({ projectId, companyId: userData!.company.id, cache });
    },
    refetchQueries: [ProjectAssignmentsListDocument],
  });

  const onCreate = useCallback(async (values?: FormValues) => {
    await createProjectMilestone({
      variables: {
        data: {
          title: values?.title || '',
          paymentDate: format(new Date(values?.paymentDate || ''), DEFAULT_DATE_FORMAT),
          notes: values?.notes,
          amount: +(values?.amount || 0),
        },
        companyId: userData!.company.id,
        projectId,
      },
    });
  }, []);

  return <MilestoneForm onSubmit={onCreate} loading={loading} onCancel={onClose} />;
};
