import { useEffect } from 'react';
import { SortingRule } from 'react-table';

interface Props<D extends Record<string, unknown> = Record<string, never>> {
  sortBy: SortingRule<D>[];
  onChangeTableSortBy?: (sortBy: SortingRule<D>) => void;
}

export const getTableSort = <D extends Record<string, unknown> = Record<string, never>>({
  sortBy,
  onChangeTableSortBy,
}: Props<D>) => {
  if (!onChangeTableSortBy) {
    return;
  }

  const { id, desc } = sortBy[0] || { id: null, desc: null };
  useEffect(() => {
    onChangeTableSortBy({ id, desc });
  }, [id, desc]);
};
