import React, { FC, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { CancelCircle, SubmitCircle } from 'icons';
import {
  Autocomplete,
  ConfirmModal,
  CreatableAutocomplete,
  DatePicker,
  NumberTextField,
  Radio,
  TeamMemberFormValues,
} from 'components';
import clsx from 'clsx';
import { FormHelperText, IconButton, InputAdornment, RadioGroup } from '@material-ui/core';
import * as Yup from 'yup';
import { getCurrencySymbol, isObjectEmpty, sortByField } from 'utils';
import { useFormik } from 'formik';
import { ActionsType, MemberSeniority, RateUnit } from 'generated/types';
import { useCreatableSeniorityData, useIsOpen, usePermissions } from 'hooks';
import { ConfirmModalOptions } from 'types';
import { ASC, NAME } from 'consts';

interface Unit {
  id: RateUnit;
  name: string;
}

export type FormValues = {
  seniority: Pick<MemberSeniority, 'id' | 'name'> | null;
  unit: Unit;
  amount: number;
  start_date: Date | string;
  end_date: Date | string | null;
  new_start_date?: Date | string;
};

interface Props {
  showSenioritySelect?: boolean;
  currencyCode?: string;
  unit: RateUnit;
  onSubmit: (value: FormValues) => void;
  onCancel: () => void;
  initialValue?: FormValues;
  isEditing?: boolean;
  isConfirmSubmitting?: boolean;
}

export const RateForm: FC<Props> = ({
  initialValue,
  showSenioritySelect,
  onSubmit,
  onCancel,
  currencyCode,
  unit,
  isEditing,
  isConfirmSubmitting,
}) => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen();
  const [confirmModalOption, setConfirmModalOption] = useState<ConfirmModalOptions>(ConfirmModalOptions.update);
  const isCreatingWithSenioritySelect = !isEditing && showSenioritySelect;

  const { seniorities, senioritiesLoading, getCreatedSeniority } = useCreatableSeniorityData();
  const sortedSeniorities = useMemo(() => sortByField(seniorities, ASC, NAME), [seniorities]);

  const unitOptions = useMemo(
    () => [
      { id: RateUnit.Hour, name: t('rateCard.unit.hour') },
      { id: RateUnit.Day, name: t('rateCard.unit.day') },
      { id: RateUnit.Month, name: t('rateCard.unit.month') },
    ],
    [],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      seniority: Yup.object().nullable(),
      amount: Yup.number()
        .typeError(t('rateCard.form.costDecimal'))
        .test('maxDigitsAfterDecimal', t('rateCard.form.costDecimal'), (number) =>
          /^\d+(\.\d{1,2})?$/.test(`${number}`),
        )
        .test('maxDigits', t('rateCard.form.maxDigits'), (number) => /^\d{1,6}(\.?)+(\.\d{1,2})?$/.test(`${number}`))
        .positive(t('rateCard.form.costPositiveError')),
      unit: Yup.object().nullable().required(t('rateCard.form.unitRequiredError')),
      start_date: Yup.string().nullable().required(t('rateCard.form.startDateRequired')),
      end_date: Yup.string().nullable(),
      new_start_date: Yup.string().nullable(),
    });
  }, []);

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    setValues,
    setSubmitting,
    setFieldTouched,
    isValid,
    touched,
    errors,
    isSubmitting,
    submitCount,
  } = useFormik<FormValues>({
    initialValues: initialValue || {
      seniority: null,
      unit: { id: unit, name: t(`rateCard.unit.${unit}`) },
      amount: 0,
      start_date: '',
      end_date: null,
      new_start_date: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const seniority = values.seniority ? await getCreatedSeniority(values.seniority) : null;
      onSubmit({ ...values, seniority });
    },
    validateOnChange: true,
  });

  const onSave = () => {
    if (isEditing) {
      const isOnlySeniorityTouched = Object.keys(touched).length === 1 && touched.hasOwnProperty('seniority');

      if (!isValid) {
        return;
      }

      if (!isObjectEmpty(touched) && !isOnlySeniorityTouched) {
        onOpenConfirm();
        return;
      }
    }

    return handleSubmit();
  };

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
        setSubmitting(false);
      }}
      className={clsx(styles.row, isCreatingWithSenioritySelect && styles.regularPadding)}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={clsx(styles.senioritySelect, {
          [styles.hidden]: !showSenioritySelect,
          [styles.senioritySelectFullWidth]: isCreatingWithSenioritySelect,
        })}
      >
        <CreatableAutocomplete
          placeholder={t('rateCard.form.seniority')}
          name="seniority"
          onBlur={handleBlur}
          className={clsx('w-100', styles.whiteBackground)}
          value={values.seniority}
          error={touched.seniority ? errors.seniority : undefined}
          options={senioritiesLoading ? [] : sortedSeniorities}
          onChange={(seniority: TeamMemberFormValues['seniority']) => setValues({ ...values, seniority })}
          enableCreation={hasAccess(ActionsType.OtherSettings)}
        />
      </div>

      <div className={styles.rate}>
        <div
          className={clsx(
            styles.inputWithSelect,
            !!(errors.amount && touched.amount) && styles.error,
            'flex align-items-start',
          )}
        >
          <NumberTextField
            name="amount"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.amount.toString()}
            InputProps={{
              startAdornment: <InputAdornment position="start">{getCurrencySymbol(currencyCode)}</InputAdornment>,
            }}
            className={styles.amountInput}
          />
          /&nbsp;
          <Autocomplete
            name="unit"
            onBlur={handleBlur}
            getOptionLabel={({ name }: Unit) => name}
            textFieldClassName={styles.unitSelectInput}
            className={styles.unitSelect}
            value={values.unit}
            disableClearable
            errors={Boolean((submitCount || touched.unit) && errors.unit)}
            options={unitOptions}
            onChange={(unit: FormValues['unit']) => setValues({ ...values, unit: unit })}
          />
        </div>
        {!!(errors.amount && touched.amount) && (
          <FormHelperText error className="weight-400">
            {errors.amount}
          </FormHelperText>
        )}
      </div>

      <div className={styles.datePicker}>
        <DatePicker
          placeholder={t('rateCard.form.from')}
          name="start_date"
          value={values.start_date}
          error={Boolean((submitCount || touched.start_date) && errors.start_date)}
          helperText={Boolean(submitCount || touched.start_date) && errors.start_date}
          onChange={(start_date) => {
            setFieldTouched('start_date', true);
            setValues({ ...values, start_date: start_date as FormValues['start_date'] });
            isEditing && setValues({ ...values, new_start_date: start_date as FormValues['new_start_date'] });
          }}
          hideCalendarIcon={!!values.start_date}
          InputProps={{ className: styles.dateFormItem }}
        />
      </div>
      <div className={styles.datePicker}>
        <DatePicker
          placeholder={t('rateCard.form.to')}
          name="end_date"
          value={values.end_date}
          error={Boolean((submitCount || touched.end_date) && errors.end_date)}
          helperText={Boolean(submitCount || touched.end_date) && errors.end_date}
          onChange={(end_date) => setValues({ ...values, end_date: end_date as FormValues['end_date'] })}
          hideCalendarIcon={!!values.end_date}
          InputProps={{ className: styles.dateFormItem }}
        />
      </div>

      <div className={styles.menu}>
        <IconButton
          size="small"
          className={styles.iconButton}
          onClick={onSave}
          disabled={typeof isConfirmSubmitting === 'undefined' ? isSubmitting : isSubmitting && isConfirmSubmitting}
        >
          <SubmitCircle className={styles.icon} />
        </IconButton>
        <IconButton size="small" className={styles.iconButton} onClick={onCancel}>
          <CancelCircle className={styles.icon} />
        </IconButton>
      </div>

      <ConfirmModal
        title={t('rateCard.form.saveRateChanges')}
        isOpen={isOpenConfirm}
        onSubmit={() => {
          handleSubmit();
          setSubmitting(false);
          onCloseConfirm();
        }}
        onClose={() => {
          onCloseConfirm();
          setConfirmModalOption(ConfirmModalOptions.update);
        }}
      >
        <div className={styles.confirmModalBox}>
          <RadioGroup
            name="saveRateChanges"
            aria-label="Save rate changes"
            onChange={(e) => setConfirmModalOption(e.target.value as ConfirmModalOptions)}
            value={confirmModalOption}
          >
            <Radio
              label={
                <div onClick={() => setConfirmModalOption(ConfirmModalOptions.update)} className={styles.radioItem}>
                  {t('rateCard.form.updateExistingRate')}
                </div>
              }
              value={ConfirmModalOptions.update}
            />
            {confirmModalOption === ConfirmModalOptions.update && (
              <span className={styles.radioItemContent}>{t('rateCard.form.useToFixIncorrectRate')}</span>
            )}
            <Radio
              className="mt-8"
              label={
                <div onClick={() => setConfirmModalOption(ConfirmModalOptions.create)} className={styles.radioItem}>
                  {t('rateCard.form.createNewRate')}
                </div>
              }
              value={ConfirmModalOptions.create}
            />
            {confirmModalOption === ConfirmModalOptions.create && (
              <div className={styles.radioItemContent}>
                <span>{t('rateCard.form.saveNewRateStartingFrom')}</span>
                <div>
                  <DatePicker
                    placeholder="DD/MM/YYYY"
                    name="new_start_date"
                    className={styles.startDatePicker}
                    value={values.new_start_date}
                    onChange={(start_date) =>
                      setValues({ ...values, new_start_date: start_date as FormValues['new_start_date'] })
                    }
                    hideCalendarIcon={!!values.new_start_date}
                  />
                </div>
              </div>
            )}
          </RadioGroup>
        </div>
      </ConfirmModal>
      <div className={styles.borderBottom} />
    </form>
  );
};
