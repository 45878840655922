export enum DateFormats {
  'dd-MMM-yyyy' = 'dd-MMM-yyyy',
  'dd/mm/yyyy' = 'dd/mm/yyyy',
  'yyyy-mm-dd' = 'yyyy-mm-dd',
  'yyyy/mm/dd' = 'yyyy/mm/dd',
  'dd.mm.yyyy' = 'dd.mm.yyyy',
  'mm-dd-yyyy' = 'mm-dd-yyyy',
  'dd-mm-yyyy' = 'dd-mm-yyyy',
  'dd-MMM-yy' = 'dd-MMM-yy',
  'mm/dd/yyyy' = 'mm/dd/yyyy',
}
