import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { DragIcon } from 'icons';

interface Props {
  title: ReactNode;
  checked: boolean;
  onCheck: () => void;
  isDragging?: boolean;
  disabledCheckbox?: boolean;
  disableDragging?: boolean;
}

export const ColumnSettingDragItem: FC<Props> = ({
  title,
  checked,
  onCheck,
  isDragging,
  disabledCheckbox,
  disableDragging,
}) => {
  return (
    <div className={clsx(styles.dragItem, isDragging && styles.dragging, disableDragging && styles.disabled)}>
      <FormControlLabel
        control={<Checkbox checked={checked} color="primary" />}
        disabled={disabledCheckbox}
        label={title}
        onChange={onCheck}
        className="ml-0"
        classes={{ label: clsx(styles.checkboxLabelText, checked && styles.active), disabled: styles.disabledCheckbox }}
      />

      <DragIcon className={clsx(styles.dragIcon, checked && !disableDragging && styles.activeDragIcon)} />
    </div>
  );
};
