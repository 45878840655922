import { endOfMonth, format } from 'date-fns';

const DATE_FORMAT = 'yyyy-MM-dd';

interface Props {
  startAccessDate?: string;
  endAccessDate?: string;
}

export const getProjectMembershipDateRange = ({ startAccessDate, endAccessDate }: Props) => {
  switch (true) {
    case !!(startAccessDate && endAccessDate):
      return [format(new Date(startAccessDate!), DATE_FORMAT), format(new Date(endAccessDate!), DATE_FORMAT)];
    case !!(!startAccessDate && endAccessDate):
      return [undefined, format(new Date(endAccessDate!), DATE_FORMAT)];
    case !!(startAccessDate && !endAccessDate):
      return [format(new Date(startAccessDate!), DATE_FORMAT), format(endOfMonth(new Date()), DATE_FORMAT)];
  }
};
