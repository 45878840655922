import { RequestsDocument, useCreateRequestMutation } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useAuth } from 'contexts';
import { RequestStatusType } from 'generated/types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';

interface Props {
  onCompleted?: () => void;
}

export const useCreateRequest = ({ onCompleted }: Props) => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { t } = useTranslation();

  return useCreateRequestMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted(data) {
      toast.success(t('request.notifications.create'));
      mixpanel.track('Request submitted', {
        Type: data.createRequest.entityType,
        'Note set': !!data.createRequest.note,
      });
      onCompleted?.();
    },
    refetchQueries: [
      {
        query: RequestsDocument,
        variables: {
          companyId: userData!.company.id,
          requestFilter: {
            status: RequestStatusType.Pending,
          },
        },
      },
      {
        query: RequestsDocument,
        variables: {
          companyId: userData!.company.id,
          requestFilter: {},
        },
      },
    ],
  });
};
