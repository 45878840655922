import { PopoverValue } from 'components/common';
import { useIsOpen } from 'hooks/useIsOpen';
import { Leave, Warning } from 'icons';
import React, { ChangeEvent, createRef, FC, useCallback, useMemo, useState } from 'react';
import { addTimezoneOffset, convertMinutesToHours } from 'utils';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { PopoverActions } from '@material-ui/core/Popover/Popover';
import { Tab, Tooltip } from '@material-ui/core';
import { PeriodTimeLogForm, PeriodTimeLogFormValues, PeriodType } from './PeriodTimeLogForm';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LeaveTimeLogDataFragment, Member, MemberProjectDataFragment } from 'generated/types';
import { endOfMonth, isWeekend, isWithinInterval, startOfMonth } from 'date-fns';
import { TimeLogList } from './TimeLogList';
import { TimeLogFormValues } from './components';

import styles from './styles.module.scss';

type MemberType = Pick<Member, 'id' | 'first_name' | 'last_name' | 'employment_type' | 'color'>;

interface CommonData {
  minutes: number;
  member?: MemberType;
  project?: MemberProjectDataFragment;
  assignmentId?: string;
  overtimeMultiplier?: number | null;
}

type Props =
  // non-editable cell
  | ({
      editable?: false;
      day?: undefined;
      onSubmit?: undefined;
      leaves?: undefined;
      monthMode?: undefined;
    } & CommonData)
  | ({
      editable: boolean;
      day: Date;
      onSubmit: (values: TimeLogFormValues | PeriodTimeLogFormValues) => Promise<void>;
      leaves?: LeaveTimeLogDataFragment[];
      monthMode?: boolean;
    } & CommonData);

enum Tabs {
  'byDay' = 'byDay',
  'byPeriod' = 'byPeriod',
}

export const TimeLogCell: FC<Props> = ({
  editable,
  day,
  minutes,
  leaves,
  onSubmit,
  monthMode,
  member,
  project,
  assignmentId,
  overtimeMultiplier,
}) => {
  const [isOpen, onOpen, onClose] = useIsOpen();
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.byDay);
  const { t } = useTranslation();
  const updatePositionRef = createRef<PopoverActions>();

  const leave = useMemo(
    () =>
      leaves?.find(({ startDate, endDate }) =>
        isWithinInterval(addTimezoneOffset(new Date(day!)), {
          start: new Date(startDate),
          end: new Date(endDate),
        }),
      ),
    [leaves],
  );

  const handleOpen = () => {
    setActiveTab(Tabs.byDay);
    onOpen();
  };

  const handleChangeTab = (_event: ChangeEvent<unknown>, newValue: Tabs) => {
    setActiveTab(newValue);
  };

  const updatePopoverPosition = useCallback(() => updatePositionRef.current?.updatePosition(), [updatePositionRef]);

  return (
    <PopoverValue
      InputValue={
        <div className={styles.input}>
          {leave && (
            <Tooltip title={`${t('timeTracking.leave')}: ${leave?.leaveType?.name}`} className={styles.leaveIcon}>
              <Leave />
            </Tooltip>
          )}
          {leave && !!minutes && (
            <Tooltip title={t('timeTracking.timeLogConflict') || ''} className={styles.warningIcon}>
              <Warning />
            </Tooltip>
          )}
          <span
            className={clsx(
              styles.inputLabel,
              !minutes && styles.empty,
              day && isWeekend(day) && !minutes && styles.weekend,
            )}
          >
            {convertMinutesToHours(minutes)}
          </span>
        </div>
      }
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={onClose}
      editable={editable}
      className={styles.popover}
      formClassName={clsx(styles.form, (activeTab === Tabs.byPeriod || monthMode) && styles.formByPeriod)}
      inputClassName={styles.inputWrapper}
      updatePositionRef={updatePositionRef}
    >
      {monthMode ? (
        <div className={styles.formBox}>
          <PeriodTimeLogForm
            initialData={{ notes: '', minutes: 0, startDate: startOfMonth(day!), endDate: endOfMonth(day!) }}
            periodType={PeriodType.TotalHrs}
            date={day!}
            onClose={onClose}
            onSubmit={onSubmit!}
            monthMode
          />
        </div>
      ) : undefined}

      {minutes && !monthMode ? (
        <div className={styles.formBox}>
          <TimeLogList
            member={member}
            project={project}
            date={day}
            assignmentId={assignmentId}
            overtimeMultiplier={overtimeMultiplier}
            onSubmit={onSubmit!}
            updatePopoverPosition={updatePopoverPosition}
          />
        </div>
      ) : undefined}

      {!minutes && !monthMode ? (
        <TabContext value={activeTab}>
          <TabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            className={styles.tabList}
            aria-label="form-tabs"
          >
            <Tab
              classes={{ root: styles.tab, selected: styles.selectedTab }}
              label={t('timeTracking.byDay')}
              value={Tabs.byDay}
            />
            <Tab
              classes={{ root: styles.tab, selected: styles.selectedTab }}
              label={t('timeTracking.byPeriod')}
              value={Tabs.byPeriod}
            />
          </TabList>
          <TabPanel value={Tabs.byDay} className={styles.tabPanel}>
            <TimeLogList
              member={member}
              project={project}
              date={day}
              assignmentId={assignmentId}
              overtimeMultiplier={overtimeMultiplier}
              onSubmit={onSubmit!}
              updatePopoverPosition={updatePopoverPosition}
              isCreateMode
            />
          </TabPanel>
          <TabPanel value={Tabs.byPeriod} className={styles.tabPanel}>
            <PeriodTimeLogForm date={day!} onClose={onClose} onSubmit={onSubmit!} />
          </TabPanel>
        </TabContext>
      ) : undefined}
    </PopoverValue>
  );
};
