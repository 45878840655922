import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { graphqlOnError } from 'utils';
import {
  ReportDocument,
  useEditReportMembersMutation,
  useEditReportMutation,
  useSetUpReportMilestonesMutation,
} from 'generated/graphql';
import mixpanel from 'mixpanel-browser';

interface Props {
  reportId: string;
  refetch?: () => void;
}
export const useEditReports = ({ reportId, refetch }: Props) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const [editReportTitle] = useEditReportMutation({
    onCompleted() {
      mixpanel.track('Report name updated');
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
      refetch?.();
    },
  });

  const [editReportMembers, { loading: loadingEditReportMembers }] = useEditReportMembersMutation({
    onCompleted() {
      mixpanel.track('Report members updated');
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      const editReportMembers = data?.editReportMembers;

      if (!editReportMembers) {
        return;
      }

      cache.updateQuery(
        {
          query: ReportDocument,
          variables: {
            companyId: userData!.company.id,
            reportId: reportId,
          },
        },
        (data) => ({
          report: {
            ...data.report,
            members: editReportMembers,
          },
        }),
      );
    },
  });

  const [setUpReportMilestones, { loading: loadingEditReportMilestones }] = useSetUpReportMilestonesMutation({
    onCompleted() {
      mixpanel.track('Report milestones updated');
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      const setUpReportMilestones = data?.setUpReportMilestones;

      if (!setUpReportMilestones) {
        return;
      }

      cache.updateQuery(
        {
          query: ReportDocument,
          variables: {
            companyId: userData!.company.id,
            reportId: reportId,
          },
        },
        (data) => ({
          report: {
            ...data.report,
            milestones: setUpReportMilestones,
          },
        }),
      );
    },
  });

  return {
    editReportTitle,
    editReportMembers,
    setUpReportMilestones,
    loading: loadingEditReportMilestones || loadingEditReportMembers,
  };
};
