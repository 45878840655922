import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';

import { props } from './props';
import { overrides } from './overrides';
import { typography } from './typography';
import { palette } from './palette';

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiAutocomplete: AutocompleteClassKey;
  }
}

export const theme = createMuiTheme({
  typography,
  palette,
  props,
  overrides,
});
