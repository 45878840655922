import React, { FC } from 'react';
import { TeamMemberDataFragmentDoc, useEditTeamMembersPmMutation } from 'generated/graphql';
import { getAcronym, getFullName, graphqlOnError } from 'utils';
import { UserInfo, UsersTagMenu } from 'components';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { ActionsType, Member } from 'generated/types';
import { client } from 'graphql-client';
import { toast } from 'react-toastify';

type ReportingTo = Pick<Member, 'id' | 'first_name' | 'last_name' | 'color'>;
interface Props {
  memberId: string;
  reportingTo?: ReportingTo;
}

export const ReportingToCell: FC<Props> = ({ reportingTo, memberId }) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();

  const [editReportingTo] = useEditTeamMembersPmMutation({
    onCompleted() {
      toast.success(t('teams.reportingToUpdateSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const cacheUpdate = (reportingTo: ReportingTo) => {
    const member = client.readFragment({
      id: `Member:${memberId}`,
      fragmentName: 'TeamMemberData',
      fragment: TeamMemberDataFragmentDoc,
    });

    client.writeFragment({
      id: `Member:${memberId}`,
      fragmentName: 'TeamMemberData',
      fragment: TeamMemberDataFragmentDoc,
      data: { ...member, reportingTo },
    });
  };

  const onEditReportingTo = async (pm: ReportingTo) => {
    cacheUpdate(pm);
    await editReportingTo({
      variables: {
        companyId: userData!.company.id,
        data: { membersId: [memberId], pmId: pm?.id || '' },
      },
    });
  };

  return hasAccess(ActionsType.ViewUsers) ? (
    <UsersTagMenu value={reportingTo || null} onChange={onEditReportingTo}>
      <UserInfo
        titleClassName={styles.reportingToTitle}
        avatarTitle={getAcronym(reportingTo?.first_name, reportingTo?.last_name)}
        title={getFullName(reportingTo?.first_name, reportingTo?.last_name, t('notApplicable'))}
        color={reportingTo?.color}
      />
    </UsersTagMenu>
  ) : (
    <UserInfo
      titleClassName={styles.reportingToTitle}
      avatarTitle={getAcronym(reportingTo?.first_name, reportingTo?.last_name)}
      title={getFullName(reportingTo?.first_name, reportingTo?.last_name, t('notApplicable'))}
      color={reportingTo?.color}
    />
  );
};
