import React, { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { EditIcon, PlusIcon } from 'icons';
import { RemoveConfirmIconButton, Tooltip } from 'components';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { ActionsType, Maybe, RateUnit } from 'generated/types';
import { format } from 'date-fns';
import { formatAmount, getCurrencySymbol } from 'utils';
import { EMPTY_DATA_STATE } from 'consts';
import { usePermissions } from 'hooks';
import { useSettings } from 'contexts';

interface Props {
  projectId: string;
  hideSeniority?: boolean;
  seniority?: Maybe<string>;
  start_date: Date;
  end_date: Date;
  rate_amount: number;
  unit: RateUnit;
  rateCurrency?: Maybe<string>;
  onDelete: () => void;
  onEdit: () => void;
  disableAddRate?: boolean;
  onAddRate?: () => void;
}

export const ViewRate: FC<Props> = ({
  projectId,
  hideSeniority,
  seniority,
  start_date,
  end_date,
  rate_amount,
  unit,
  rateCurrency,
  onDelete,
  onEdit,
  disableAddRate,
  onAddRate,
}) => {
  const { t } = useTranslation();
  const { isFinancialsHidden } = useSettings();
  const { hasAccess } = usePermissions({ projectId });

  return (
    <div className={styles.row} onClick={(e) => e.stopPropagation()}>
      <span
        className={clsx(styles.item, styles.seniority, hideSeniority && styles.hidden)}
        onClick={!hideSeniority ? onEdit : undefined}
      >
        {seniority}
      </span>

      <div className={clsx(styles.divider, hideSeniority && styles.hidden)} />

      <span className={clsx(styles.item, styles.rate)} onClick={onEdit}>
        <span className={styles.amount}>
          <span className={styles.currency}>{getCurrencySymbol(rateCurrency)}</span>
          {formatAmount(rate_amount, isFinancialsHidden)}
        </span>
        /&nbsp;
        <span className={styles.unit}>{unit}</span>
      </span>

      <div className={styles.divider} />

      <span className={clsx(styles.item, styles.date, styles.startDate)} onClick={onEdit}>
        {format(new Date(start_date), 'dd/MM/yyyy')}
      </span>

      <div className={styles.divider} />

      <span className={clsx(styles.item, styles.date, styles.endDate)} onClick={onEdit}>
        {end_date ? format(new Date(end_date), 'dd/MM/yyyy') : EMPTY_DATA_STATE}
      </span>
      <div className={styles.menu}>
        {onAddRate && !disableAddRate && hasAccess(ActionsType.EditProjectRatecards) && (
          <Tooltip title={t('rateCard.addNewRate')!} placement="top" alwaysShowTooltip className={styles.addIcon}>
            <IconButton
              className={styles.iconButton}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onAddRate();
              }}
            >
              <PlusIcon />
            </IconButton>
          </Tooltip>
        )}
        {hasAccess(ActionsType.EditProjectRatecards) && (
          <Tooltip title={t('actions.edit')!} placement="top" alwaysShowTooltip>
            <IconButton className={styles.iconButton} size="small" onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {hasAccess(ActionsType.DeleteProjectRatecards) && (
          <RemoveConfirmIconButton
            onClick={onDelete}
            className={styles.iconButton}
            confirmTitle={t('rateCard.rate.deleteRate')}
            confirmMessage={t('rateCard.rate.confirmDelete')}
            confirmSubmitButtonTitle={t('rateCard.rate.deleteRate')}
          />
        )}
      </div>
      <div className={styles.borderBottom} />
    </div>
  );
};
