import React, { ChangeEvent, FC } from 'react';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { FormControlLabel, styled, Switch as MuiSwitch } from '@material-ui/core';

import styles from './styles.module.scss';

export const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#d6dbdd',
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#60D56C',
  },
}));

export const Switch: FC<Partial<SwitchBaseProps>> = ({ title, checked, onChange, disabled }) => {
  if (!title) {
    return <StyledSwitch checked={checked} onChange={onChange} disabled={disabled} />;
  }

  return (
    <FormControlLabel
      control={<StyledSwitch checked={checked} onChange={onChange} disabled={disabled} />}
      label={title}
      className="m-0"
      classes={{ label: styles.label }}
      onChange={(e, value) => onChange?.(e as ChangeEvent<HTMLInputElement>, value)}
    />
  );
};
