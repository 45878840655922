export const STATUS = 'status';
export const EMAIL = 'email';
export const ROLE = 'role';
export const CREATION_DATE = 'created_at';
export const UPDATE_DATE = 'updated_at';
export const CREATED_BY = 'created_by';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const TYPE = 'type';
export const SPECIALIZATION = 'specialization';
export const JOB_TITLE = 'job_title';
export const SENIORITY = 'seniority';
export const UNIT = 'unit';
export const COST = 'rate_amount';
export const CURRENCY = 'rate_currency';
export const CLIENT = 'client';
export const PROJECT = 'name';
export const PROJECT_TYPE = 'project_type';
export const PROJECT_MANAGER = 'project_manager';
export const REPORTING_TO = 'reporting_to';
export const START_DATE = 'start_date';
export const TEAM = 'team';
export const END_DATE = 'end_date';
export const COSTS = 'costs';
export const SCHEDULED = 'scheduled';
export const EXTERNAL_RATE = 'externalRateAmount';
export const REVENUE = 'revenue';
export const DP = 'dp';
export const DPM = 'dpm';
export const ACTIONS = 'actions';
export const SETTING = 'setting';
export const SELECTION = 'selection';
export const ALLOCATION = 'allocationTimeAmount';
export const NAME = 'name';
export const MEMBER_NAME = 'memberName';
export const PM = 'pm';
export const FILE = 'file';
export const ACTIVE_ASSIGNMENTS = 'activeAssignments';
export const DATE = 'date';
export const MINUTES = 'minutes';
export const NOTES = 'notes';
export const TOTAL = 'total';
export const TITLE = 'title';
export const QUANTITY = 'quantity';
export const MARGIN = 'margin';
export const FORECAST_MARGIN = 'forecastMargin';
export const PROJECT_ACCESS = 'projectAccess';
export const AMOUNT = 'amount';
export const JOIN_DATE = 'join_date';
export const EXIT_DATE = 'exit_date';
export const PAYMENT_DATE = 'paymentDate';
export const PAID_AMOUNT = 'paidAmount';
export const INTEGRATION_STATUS = 'integrationStatus';
export const CURRENT_COMMISSION = 'currentCommission';
