import React, { FC, memo, ReactElement } from 'react';
import { ActivityHistoriesDataFragment } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { Avatar, SIZES } from 'components';
import {
  formatCurrency,
  getAcronym,
  getAllocationTimeAmount,
  replaceAmountWithHiddenSymbol,
  valueToCurrency,
} from 'utils';
import { Field } from './Field';
import { format } from 'date-fns';
import { useSettings } from 'contexts';
import { ItemFieldTooltip } from './ItemFieldsTooltip';

interface Props {
  log: Pick<ActivityHistoriesDataFragment, 'data' | 'changes'>;
  currencyCode?: string;
}

export const ItemDisplayEditingFields: FC<Props> = memo(({ log, currencyCode }) => {
  const { changes, data } = log;
  const { t } = useTranslation();
  const { isFinancialsHidden } = useSettings();

  const shownChangedProperties = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    jobTitle: 'job_title',
    projectRole: 'role',
    client: 'client',
    feeCurrency: 'feeCurrency',
    seniority: 'seniority',
    specialization: 'specialization',
    leaveType: 'leaveType',
  };

  const changedFields =
    changes &&
    Object.entries(shownChangedProperties).reduce<ReactElement[]>((acc, [key, value]) => {
      if (changes[value] === data[value]) {
        return acc;
      }

      const field = (
        <Field
          key={`${key}-${changes[value]}`}
          isEdited
          label={t(`activityHistory.logLabels.${key}`)}
          value={data[value]}
          newValue={changes[value]}
        />
      );

      return [...acc, field];
    }, []);

  const isReportingToChanged =
    (data['reportingToFirstName'] || changes['reportingToFirstName']) && data['reportingTo'] !== changes['reportingTo'];
  const reportingTo = isReportingToChanged && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.reportingTo`)}
      value={
        data['reportingToFirstName'] && (
          <>
            <Avatar
              avatarTitle={getAcronym(data['reportingToFirstName'], data['reportingToLastName'])}
              size={SIZES.xs}
              className="mr-4"
              color={data['reportingToColor']}
            />
            <ItemFieldTooltip>{`${data['reportingToFirstName']} ${data['reportingToLastName']}`}</ItemFieldTooltip>
          </>
        )
      }
      newValue={
        changes['reportingToFirstName'] && (
          <>
            <Avatar
              avatarTitle={getAcronym(changes['reportingToFirstName'], changes['reportingToLastName'])}
              size={SIZES.xs}
              className="mr-4"
              color={changes['reportingToColor']}
            />
            <ItemFieldTooltip>{`${changes['reportingToFirstName']} ${changes['reportingToLastName']}`}</ItemFieldTooltip>
          </>
        )
      }
    />
  );

  const isPMChanged =
    (data['pm_member_id'] || changes['pm_member_id']) && data['pm_member_id'] !== changes['pm_member_id'];
  const pm = isPMChanged && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.reportingTo`)}
      value={
        <>
          <Avatar
            avatarTitle={getAcronym(data['pmFirstName'], data['pmLastName'])}
            size={SIZES.xs}
            className="mr-4"
            color={data['pmColor']}
          />
          <ItemFieldTooltip>{`${data['pmFirstName']} ${data['pmLastName']}`}</ItemFieldTooltip>
        </>
      }
      newValue={
        <>
          <Avatar
            avatarTitle={getAcronym(changes['pmFirstName'], changes['pmLastName'])}
            size={SIZES.xs}
            className="mr-4"
            color={changes['pmColor']}
          />
          <ItemFieldTooltip>{`${changes['pmFirstName']} ${changes['pmLastName']}`}</ItemFieldTooltip>
        </>
      }
    />
  );

  const startDate = (data['start_date'] || '') !== (changes['start_date'] || '') && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.startDate`)}
      value={data['start_date'] && format(new Date(data['start_date']), 'dd/MM/yyyy')}
      newValue={changes['start_date'] && format(new Date(changes['start_date']), 'dd/MM/yyyy')}
    />
  );

  const isStartDateValueChanged =
    format(data['startDate'] ? new Date(data['startDate']) : new Date(), 'dd/MM/yyyy') !==
    format(changes['startDate'] ? new Date(changes['startDate']) : new Date(), 'dd/MM/yyyy');
  const startDateValue = isStartDateValueChanged && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.startDate`)}
      value={data['startDate'] && format(new Date(data['startDate']), 'dd/MM/yyyy')}
      newValue={changes['startDate'] && format(new Date(changes['startDate']), 'dd/MM/yyyy')}
    />
  );

  const endDate = (data['end_date'] || '') !== (changes['end_date'] || '') && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.endDate`)}
      value={data['end_date'] && format(new Date(data['end_date']), 'dd/MM/yyyy')}
      newValue={changes['end_date'] && format(new Date(changes['end_date']), 'dd/MM/yyyy')}
    />
  );

  const isEndDateValueChanged =
    format(data['endDate'] ? new Date(data['endDate']) : new Date(), 'dd/MM/yyyy') !==
    format(changes['endDate'] ? new Date(changes['endDate']) : new Date(), 'dd/MM/yyyy');
  const endDateValue = isEndDateValueChanged && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.endDate`)}
      value={data['endDate'] && format(new Date(data['endDate']), 'dd/MM/yyyy')}
      newValue={changes['endDate'] && format(new Date(changes['endDate']), 'dd/MM/yyyy')}
    />
  );

  const joinDate = (data['join_date'] || '') !== (changes['join_date'] || '') && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.joinDate`)}
      value={data['join_date'] && format(new Date(data['join_date']), 'dd/MM/yyyy')}
      newValue={changes['join_date'] && format(new Date(changes['join_date']), 'dd/MM/yyyy')}
    />
  );

  const leaveDate = (data['leave_date'] || '') !== (changes['leave_date'] || '') && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.leaveDate`)}
      value={data['leave_date'] && format(new Date(data['leave_date']), 'dd/MM/yyyy')}
      newValue={changes['leave_date'] && format(new Date(changes['leave_date']), 'dd/MM/yyyy')}
    />
  );

  const effectiveFromDate = (data['effectiveFrom'] || '') !== (changes['effectiveFrom'] || '') && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.effectiveFrom`)}
      value={data['effectiveFrom'] && format(new Date(data['effectiveFrom']), 'dd/MM/yyyy')}
      newValue={changes['effectiveFrom'] && format(new Date(changes['effectiveFrom']), 'dd/MM/yyyy')}
    />
  );

  const date = (data['date'] || '') !== (changes['date'] || '') && (
    <Field
      isEdited
      label={t(`activityHistory.logLabels.date`)}
      value={data['date'] && format(new Date(data['date']), 'dd/MM/yyyy')}
      newValue={changes['date'] && format(new Date(changes['date']), 'dd/MM/yyyy')}
    />
  );

  const allocation = (data['allocation_time_amount'] || '') !== (changes['allocation_time_amount'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.allocation')}
      value={`${getAllocationTimeAmount(data['allocation_time_amount'])} ${t('activityHistory.hrsPerDay')}`}
      newValue={`${getAllocationTimeAmount(changes['allocation_time_amount'])} ${t('activityHistory.hrsPerDay')}`}
    />
  );

  const allocationTimeAmount = (data['allocationTimeAmount'] || '') !== (changes['allocationTimeAmount'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.allocation')}
      value={`${getAllocationTimeAmount(data['allocationTimeAmount'])} ${t('activityHistory.hrsPerDay')}`}
      newValue={`${getAllocationTimeAmount(changes['allocationTimeAmount'])} ${t('activityHistory.hrsPerDay')}`}
    />
  );

  const isTypeChanged =
    (data['typeName'] || '') !== (changes['typeName'] || '') || (data['type'] || '') !== (changes['type'] || '');
  const type = isTypeChanged && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.type')}
      value={data['typeName'] || t(`projectType.${data['type']}`)}
      newValue={changes['typeName'] || t(`projectType.${changes['type']}`)}
    />
  );

  const pmFinAccess = (data['pmFinAccess'] || '') !== (changes['pmFinAccess'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.pmFinAccess')}
      value={t(`activityHistory.financialAccess.${data['pmFinAccess']}`)}
      newValue={t(`activityHistory.financialAccess.${changes['pmFinAccess']}`)}
    />
  );

  const dataCurrency = (data['currency'] && data['currency']['code']) || currencyCode;
  const changesCurrency = (changes['currency'] && changes['currency']['code']) || currencyCode;
  const isRateAmountChanged = (data['rate_amount'] || '') !== (changes['rate_amount'] || '');
  const rateAmount = isRateAmountChanged && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.amount')}
      value={formatCurrency(valueToCurrency(data['rate_amount']), dataCurrency, isFinancialsHidden)}
      newValue={formatCurrency(valueToCurrency(changes['rate_amount']), changesCurrency, isFinancialsHidden)}
    />
  );
  const isAmountChanged = (data['amount'] || '') !== (changes['amount'] || '');
  const amount = isAmountChanged && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.amount')}
      value={formatCurrency(valueToCurrency(data['amount']), dataCurrency, isFinancialsHidden)}
      newValue={formatCurrency(valueToCurrency(changes['amount']), changesCurrency, isFinancialsHidden)}
    />
  );
  const isBillableAmountChanged = (data['billable_amount'] || '') !== (changes['billable_amount'] || '');
  const billableAmount = isBillableAmountChanged && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.billableAmount')}
      value={formatCurrency(valueToCurrency(data['billable_amount']), dataCurrency, isFinancialsHidden)}
      newValue={formatCurrency(valueToCurrency(changes['billable_amount']), changesCurrency, isFinancialsHidden)}
    />
  );

  const isBillable = (data['is_billable'] || '') !== (changes['is_billable'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.isBillable')}
      value={t(`activityHistory.condition.${data['is_billable']}`)}
      newValue={t(`activityHistory.condition.${changes['is_billable']}`)}
    />
  );

  const nonBillable = (data['nonBillable'] || '') !== (changes['nonBillable'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.nonBillable')}
      value={t(`activityHistory.condition.${data['nonBillable']}`)}
      newValue={t(`activityHistory.condition.${changes['nonBillable']}`)}
    />
  );

  const billable = (data['billable'] || '') !== (changes['billable'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.isBillable')}
      value={t(`activityHistory.condition.${data['billable']}`)}
      newValue={t(`activityHistory.condition.${changes['billable']}`)}
    />
  );

  const billingType = (data['bill_amount_calculation_type'] || '') !==
    (changes['bill_amount_calculation_type'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.unit')}
      value={
        data['bill_amount_calculation_type'] && t(`activityHistory.billingType.${data['bill_amount_calculation_type']}`)
      }
      newValue={
        changes['bill_amount_calculation_type'] &&
        t(`activityHistory.billingType.${changes['bill_amount_calculation_type']}`)
      }
    />
  );

  const billingCalculationType = (data['calculationType'] || '') !== (changes['calculationType'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.billingType')}
      value={data['calculationType'] && t(`activityHistory.billingType.${data['calculationType']}`)}
      newValue={changes['calculationType'] && t(`activityHistory.billingType.${changes['calculationType']}`)}
    />
  );

  const isCommissionChanged = (data['commission'] || '') !== (changes['commission'] || '');
  const commission = isCommissionChanged && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.commission')}
      value={`${replaceAmountWithHiddenSymbol(valueToCurrency(data['commission']), isFinancialsHidden)}%`}
      newValue={`${replaceAmountWithHiddenSymbol(valueToCurrency(changes['commission']), isFinancialsHidden)}%`}
    />
  );

  const unit = (data['unit'] || '') !== (changes['unit'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.unit')}
      value={t(`activityHistory.unit.${data['unit']}`)}
      newValue={t(`activityHistory.unit.${changes['unit']}`)}
    />
  );

  const employmentType = (data['employment_type'] || '') !== (changes['employment_type'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.employeeType')}
      value={t(`employmentType.${data['employment_type']}`)}
      newValue={t(`employmentType.${changes['employment_type']}`)}
    />
  );

  const capacity = (data['capacity'] || '') !== (changes['capacity'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.capacity')}
      value={`${data['capacity']} ${t('activityHistory.hrsPerDay')}`}
      newValue={`${changes['capacity']} ${t('activityHistory.hrsPerDay')}`}
    />
  );

  const notes = (data['notes'] || '') !== (changes['notes'] || '') && (
    <Field
      isEdited
      label={t('activityHistory.logLabels.notes')}
      value={<ItemFieldTooltip>{data['notes']}</ItemFieldTooltip>}
      newValue={<ItemFieldTooltip>{changes['notes']}</ItemFieldTooltip>}
    />
  );

  return (
    <>
      {changedFields}
      {employmentType}
      {startDate}
      {startDateValue}
      {endDate}
      {endDateValue}
      {reportingTo}
      {pm}
      {type}
      {pmFinAccess}
      {date}
      {joinDate}
      {leaveDate}
      {effectiveFromDate}
      {allocation}
      {allocationTimeAmount}
      {amount}
      {billableAmount}
      {isBillable}
      {rateAmount}
      {nonBillable}
      {billable}
      {billingType}
      {billingCalculationType}
      {commission}
      {unit}
      {capacity}
      {notes}
    </>
  );
});

ItemDisplayEditingFields.displayName = 'ItemDisplayEditingFields';
