import React, { useEffect } from 'react';
import { useBlockLayout, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import clsx from 'clsx';
import { isWeekend } from 'date-fns';
import { AbsoluteSpinner, EmptyState, ExpandedColumn } from 'components';

import styles from './styles.module.scss';

interface Props<D extends Record<string, unknown>> {
  isWeekendVisible?: boolean;
  loading?: boolean;
  tableClassName?: string;
  columns: ExpandedColumn<D>[];
  data: D[];
}

export const TimelineTable = <D extends Record<string, unknown>>({
  loading,
  tableClassName,
  isWeekendVisible,
  columns,
  data,
}: Props<D>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
    setHiddenColumns,
    allColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useSticky,
  );

  useEffect(() => {
    if (isWeekendVisible) {
      setHiddenColumns([]);

      return;
    }

    const weekendsIds = allColumns.reduce((acc: string[], column) => {
      if (isWeekend(new Date(column.id))) {
        acc.push(column.id);
      }

      return acc;
    }, []);

    setHiddenColumns(weekendsIds);
  }, [isWeekendVisible, allColumns]);

  if (loading) {
    return <AbsoluteSpinner />;
  }

  return (
    <div {...getTableProps()} className={clsx(styles.table, tableClassName)}>
      <div className={styles.header}>
        {headerGroups.map((headerGroup) => (
          // eslint-disable-next-line react/jsx-key
          <div {...headerGroup.getHeaderGroupProps()} className={styles.row}>
            {headerGroup.headers.map((column, index) => (
              // eslint-disable-next-line react/jsx-key
              <div {...column.getHeaderProps()}>
                <div
                  className={clsx(
                    styles.cell,
                    index === 0 && styles.name,
                    isWeekend(new Date(column.id)) && styles.weekend,
                    index === headerGroup.headers.length - 2 && styles.lastCalendarCell,
                  )}
                >
                  {column.render('Header')}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {data.length ? (
        <>
          <div {...getTableBodyProps()} className={styles.body}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <div {...row.getRowProps()} className={clsx(styles.row, index % 2 !== 0 ? styles.odd : styles.even)}>
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <div {...cell.getCellProps()}>{cell.render('Cell')}</div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className={styles.footer}>
            {footerGroups.map((footerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <div {...footerGroup.getHeaderGroupProps()} className={styles.row}>
                {footerGroup.headers.map((column, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <div {...column.getHeaderProps()}>
                    <div className={clsx(styles.cell, index === 0 && styles.footerName)}>{column.render('Footer')}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      ) : (
        <EmptyState className={styles.empty} />
      )}
    </div>
  );
};
