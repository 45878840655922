import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { FullLogo } from 'icons';

import styles from './UnauthLayout.module.scss';

interface UnauthLayoutProps {
  children: ReactNode;
}

export const UnauthLayout = ({ children }: UnauthLayoutProps) => {
  return (
    <div className={clsx(styles.wrapper, 'flex', 'justify-content-center', 'align-items-center')}>
      <FullLogo className={styles.companyLogo} />
      {children}
    </div>
  );
};
