import React, { FC } from 'react';
import { LockIcon } from 'icons';

import styles from './styles.module.scss';
import { Tooltip } from 'components';
import { useTranslation } from 'react-i18next';

interface Props {
  item: string;
}
export const RequestTooltip: FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Tooltip className={styles.requestBox} title={t('request.tooltip', { item })!} alwaysShowTooltip>
      <LockIcon className="mr-4" /> {t('request.title')}
    </Tooltip>
  );
};
