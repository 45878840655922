import { ActivityHistoryAction, AvailableDomains } from 'generated/types';

interface Props {
  type: AvailableDomains;
  action: ActivityHistoryAction;
  isArchived?: boolean;
  isRestored?: boolean;
}

export const getLogTitle = ({ type, action, isArchived, isRestored }: Props) => {
  const prefix = type
    .split('_')
    .map((string, index) => (index ? string.charAt(0).toUpperCase() + string.slice(1) : string))
    .join('');

  if (isArchived) {
    return `${prefix}Archive`;
  }

  if (isRestored) {
    return `${prefix}Restore`;
  }

  const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1);

  return `${prefix}${capitalizedAction}`;
};
