import { AssignmentFormValues } from 'components';
import { addTimezoneOffset } from 'utils';

const MINUTES_IN_HOUR = 60;

export const getAssignmentFormattedFormData = ({
  member,
  role,
  seniority,
  project,
  allocationTimeAmount,
  startDate,
  endDate,
  billingType,
  billable,
}: AssignmentFormValues) => ({
  memberId: member?.id ?? '',
  roleId: role?.id ?? '',
  projectId: project?.id ?? '',
  seniorityId: seniority?.id ?? null,
  allocationTimeAmount: Math.round(+allocationTimeAmount * MINUTES_IN_HOUR),
  startDate: addTimezoneOffset(new Date(startDate)),
  endDate: addTimezoneOffset(new Date(endDate)),
  timeTracking: false,
  calculationType: billingType!.id,
  billable,
});

export const getAssignmentRequestFormattedFormData = ({
  member,
  role,
  seniority,
  project,
  allocationTimeAmount,
  startDate,
  endDate,
  billingType,
  billable,
}: AssignmentFormValues) => ({
  memberId: member?.id ?? '',
  memberFirstName: member?.first_name ?? '',
  memberLastName: member?.last_name ?? '',
  memberColor: member?.color ?? '',
  roleId: role?.id ?? '',
  role: role?.name ?? '',
  projectId: project?.id ?? '',
  project: project?.name ?? '',
  projectColor: project?.color ?? '',
  seniorityId: seniority?.id ?? '',
  seniority: seniority?.name ?? '',
  allocationTimeAmount: Math.round(+allocationTimeAmount * MINUTES_IN_HOUR),
  startDate: addTimezoneOffset(new Date(startDate)),
  endDate: addTimezoneOffset(new Date(endDate)),
  timeTracking: false,
  calculationType: billingType!.id,
  billable,
});
