import { FileDefault, FileImage, FilePDF, FileSpreadsheets, FileText, FileVideo, FileZip } from 'icons';

const pdfFormat = 'pdf';
const archiveFormat = ['7z', 'arj', 'deb', 'pkg', 'rar', 'rpm', 'gz', 'z', 'zip'];
const textFormat = ['doc', 'docx', 'odt', 'rtf', 'tex', 'txt', 'wpd'];
const imageFormat = ['ai', 'bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'ps', 'psd', 'svg', 'tif', 'tiff', 'webp'];
const spreadsheetFormat = ['ods', 'xls', 'xlsm', 'xlsx'];
const videoFormat = [
  '3g2',
  '3gp',
  'avi',
  'flv',
  'h264',
  'm4v',
  'mkv',
  'mov',
  'mp4',
  'mpg',
  'mpeg',
  'rm',
  'swf',
  'vob',
  'webm',
  'wmv',
];

export const getFileIcon = (name: string) => {
  const type = name.split('.').pop() || '';

  switch (true) {
    case pdfFormat === type:
      return FilePDF;
    case imageFormat.includes(type):
      return FileImage;
    case videoFormat.includes(type):
      return FileVideo;
    case archiveFormat.includes(type):
      return FileZip;
    case textFormat.includes(type):
      return FileText;
    case spreadsheetFormat.includes(type):
      return FileSpreadsheets;
    default:
      return FileDefault;
  }
};
