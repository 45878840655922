import { Button, InputLabel, TextField } from '@material-ui/core';
import { AbsoluteSpinner, Autocomplete, LoadingButton } from 'components';
import React, { FC, useMemo } from 'react';
import { ISkill, SkillDataFragment } from 'generated/types';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { graphqlOnError, submitForm } from 'utils';
import { DropdownOption } from 'types';
import { useSkillCategoriesQuery } from 'generated/graphql';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';

type FormValues = {
  name?: string;
  category?: DropdownOption;
};

interface Props {
  onSubmit: (data: ISkill) => Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
  initialValues?: SkillDataFragment;
}

export const SkillForm: FC<Props> = ({ onSubmit, submitLabel, onCancel, initialValues }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { skillCategories = [] } = {}, loading } = useSkillCategoriesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      withSkills: true,
    },
    skip: !userData,
  });

  const validationSchema = useMemo(
    () =>
      object({
        name: string().required('validation.required'),
        category: object().nullable().required('validation.required'),
      }),
    [],
  );

  const {
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    errors,
    touched,
    submitCount,
    isSubmitting,
    setSubmitting,
    setFieldValue,
  } = useFormik<FormValues>({
    onSubmit: (data) => {
      submitForm({ name: data.name!, skillCategoryId: `${data.category!.id}` }, setSubmitting, onSubmit);
    },
    validationSchema,
    initialValues: {
      name: initialValues?.name || '',
      category: skillCategories.find((category) => category.id === initialValues?.skillCategoryId) || undefined,
    },
  });

  const categoriesOptions = useMemo(() => skillCategories.map(({ id, name }) => ({ id, name })), [skillCategories]);

  if (loading) {
    return <AbsoluteSpinner />;
  }

  return (
    <form onSubmit={handleSubmit} className="form">
      <div>
        <div className="mb-24">
          <InputLabel>{t('settings.resourceManagement.skills.skillName')}</InputLabel>
          <TextField
            error={Boolean((submitCount || touched.name) && errors.name)}
            helperText={(submitCount || touched.name) && t(errors.name!)}
            onBlur={handleBlur}
            onChange={handleChange}
            name="name"
            value={values.name}
          />
        </div>

        <div>
          <InputLabel>{t('settings.resourceManagement.skills.category')}</InputLabel>
          <Autocomplete
            value={values.category}
            options={categoriesOptions}
            placeholder={t('settings.resourceManagement.skills.categoryPlaceholder')}
            error={touched.category && errors.category ? t(errors.category) : undefined}
            onChange={(value: DropdownOption | null) => setFieldValue('category', value)}
            name="category"
          />
        </div>
      </div>

      <div className="controls">
        <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
          {submitLabel ?? t('actions.create')}
        </LoadingButton>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {t('forms.cancel')}
        </Button>
      </div>
    </form>
  );
};
