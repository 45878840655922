import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export interface EmptyStateProps {
  title?: string;
  className?: string;
}

export const EmptyState = ({ title = 'projects.noResultsFound', className }: EmptyStateProps) => {
  const { t } = useTranslation();

  return <div className={clsx([styles.emptyState, 'flex', 'justify-content-center', className])}>{t(title)}</div>;
};
