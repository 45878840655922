export const TEAM_TABLE_HIDDEN_COLUMNS = 'TEAM_TABLE_HIDDEN_COLUMNS';
export const TEAM_TABLE_COLUMN_ORDER = 'TEAM_TABLE_COLUMN_ORDER';

export enum TeamTableColumns {
  Name = 'name',
  JobTitle = 'job_title',
  Type = 'type',
  Email = 'email',
  ReportingTo = 'reporting_to',
  Specialization = 'specialization',
  Seniority = 'seniority',
  ActiveAssignments = 'activeAssignments',
  CurrentSalary = 'currentSalary',
  IntegrationStatus = 'integrationStatus',
  JoiningDate = 'join_date',
  ExitDate = 'exit_date',
}
